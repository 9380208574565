import React, { Component } from "react";
import { Typography, Card, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { getUserData } from "../redux/actions/userProfileAction";
import axios from "axios";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  CREATE_DRIVER,
  CREATE_SUB_ORG_TYPE,
  CREATE_SUB_ORG,
  CREATE_ENTITY,
  CREATE_ROUTE,
  CREATE_INVENTORY,
  CREATE_UNIT,
  CREATE_PRODUCT_CATEGORY,
  CREATE_PRODUCT,
  CREATE_ASSET,
  ASSET_PROVIDER,
  CREATE_ROASTER_PLAN,
  CREATE_ROASTER,
  ENTITY_TYPE,
} from "../api/constants";
import { GET_ACCESS_TOKEN } from "../utilities/utilities";

const { Title } = Typography;

class GettingStarted extends Component {
  state = {
    user: 0,
    sub_org_type: 0,
    sub_org: 0,
    place: 0,
    place_type: 0,
    route: 0,
    inventory: 0,
    unit: 0,
    product_category: 0,
    product: 0,
    asset: 0,
    finder_asset: 0,
    roster_plan: 0,
    roster: 0,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getUser();
    this.getSubOrgType();
    this.getSubOrg();
    this.getPlace();
    this.getRoute();
    this.getUnit();
    this.getProductCategory();
    this.getProduct();
    this.getInventory();
    this.getAsset();
    this.getAssetFinder();
    this.getRosterPlan();
    this.getRoster();
    this.getEntityTypeList();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getEntityTypeList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ENTITY_TYPE}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            place_type: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("error from entity type list api", error);
        });
    });
  };

  getUser = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("user", response);
          this.setState({
            user: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("user", error);
        });
    });
  };
  getSubOrgType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG_TYPE}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("suborgtype", response);
          this.setState({
            sub_org_type: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("suborgtype", error);
        });
    });
  };
  getSubOrg = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("suborg", response);
          this.setState({
            sub_org: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("suborg", error);
        });
    });
  };
  getPlace = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("place", response);
          this.setState({
            place: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("place", error);
        });
    });
  };
  getRoute = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROUTE}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("route", response);
          this.setState({
            route: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("route", error);
        });
    });
  };
  getUnit = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_UNIT}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("unit", response);
          this.setState({
            unit: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("unit", error);
        });
    });
  };
  getProductCategory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT_CATEGORY}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("product_category", response);
          this.setState({
            product_category: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("product_category", error);
        });
    });
  };
  getProduct = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("product", response);
          this.setState({
            product: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("product", error);
        });
    });
  };
  getInventory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("inventory", response);
          this.setState({
            inventory: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("inventory", error);
        });
    });
  };
  getAsset = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ASSET}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("asset", response);
          this.setState({
            asset: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("asset", error);
        });
    });
  };
  getAssetFinder = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_PROVIDER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("finder_asset", response);
          this.setState({
            finder_asset: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("finder_asset", error);
        });
    });
  };
  getRosterPlan = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROASTER_PLAN}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("roster_plan", response);
          this.setState({
            roster_plan: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("roster_plan", error);
        });
    });
  };
  getRoster = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROASTER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("roster", response);
          this.setState({
            roster: response.data?.data?.length ?? 0,
          });
        })
        .catch((error) => {
          console.log("roster", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "2px solid rgb(235, 237, 240)",
            padding: 15,
          }}
        >
          <Title level={3}>Getting Started Checklist</Title>
          <Title level={4}>
            Before getting started, there are a few things that will help you to
            get the best service
          </Title>
          {this.props.userProfileData.user.user_create ? (
            <Link to="/createUser">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.user > 0 ? "green" : "grey",
                }}
              />

              <Button type="link">Add users to your organization</Button>
            </Link>
          ) : null}
          {this.props.userProfileData.sub_org.sub_org_type
            .sub_org_type_create ? (
            <Link to="/createSubOrgType">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.sub_org_type > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">
                Add team or department type which is known as sub org type here
              </Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.sub_org.sub_org_create ? (
            <Link to="/createSubOrg">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.sub_org > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">
                Add teams or departments which is known as sub org here
              </Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.place.place_create ? (
            <Link to="/createEntityType">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.place_type > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add frequently visited place types</Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.place.place_create ? (
            <Link to="/createEntity">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.place > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add frequently visited places</Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.task.route.route_create ? (
            <Link to="/createRoute">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.route > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Setup optimized routes</Button>{" "}
            </Link>
          ) : null}
          <Divider />
          <Title level={4}>
            If you are using inventory don't forget to add
          </Title>
          {this.props.userProfileData.order_and_delivery.unit.unit_create ? (
            <Link to="/createUnit">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.unit > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">
                Add your preferable units of measurement
              </Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.order_and_delivery.product
            .product_category.product_category_create ? (
            <Link to="/createProductCategory">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.product_category > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add product categories</Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.order_and_delivery.product
            .product_create ? (
            <Link to="/createProduct">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.product > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add product</Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.inventory.create_inventory ? (
            <Link to="/createInventory">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.inventory > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add Inventory</Button>{" "}
            </Link>
          ) : null}
          <Divider />
          <Title level={4}>
            If you are using logistics and transport management, then add your
          </Title>
          {this.props.userProfileData.asset.asset_create ? (
            <Link to="/createAsset">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.asset > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Assets</Button>{" "}
            </Link>
          ) : null}

          {this.props.userProfileData.asset.connect_asset_to_user ? (
            <Link to="/connecttoFinder">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.finder_asset > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Connect to Finder</Button>{" "}
            </Link>
          ) : null}
          <Divider />
          <Title level={4}>If you are using attendance with rosters</Title>
          {this.props.userProfileData.attendance.roaster_plan
            .roaster_plan_create ? (
            <Link to="/createRoasterPlan">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.roster_plan > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add the roster Plans</Button>{" "}
            </Link>
          ) : null}
          {this.props.userProfileData.attendance.roaster.roaster_create ? (
            <Link to="/createRoaster">
              <CheckCircleIcon
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 18,
                  marginBottom: -3,
                  marginRight: -12,
                  color: this.state.roster > 0 ? "green" : "grey",
                }}
              />
              <Button type="link">Add the roster plans to users</Button>{" "}
            </Link>
          ) : null}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(GettingStarted);
