import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  TRACKING_STATUS_CHOICES,
  TRACKING_PERMISSION
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateTracking extends Component {
  state = {
    attendance_tracking_status_type_choices: [],
    task_tracking_status_type_choices: [],
    task_tracking_status: "on",
    attendance_tracking_status: "on",
    loading: false
  };

  componentDidMount() {
    this.getTrackingPermissionList();
  }

  getTrackingPermissionList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${TRACKING_STATUS_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            attendance_tracking_status_type_choices:
              response.data.attendance_tracking_status_type_choices,
            task_tracking_status_type_choices:
              response.data.task_tracking_status_type_choices
          });
          console.log("data from tracking permission list api", response);
        })
        .catch(error => {
          console.log("error from tracking permission list api", error);
        });
    });
  };

  setTaskTracking = value => {
    this.setState({ task_tracking_status: value }, () => {
      console.log(this.state.task_tracking_status);
    });
  };
  setAttendanceTracking = value => {
    this.setState({ attendance_tracking_status: value }, () => {
      console.log(this.state.attendance_tracking_status);
    });
  };

  updateSettings = () => {
      this.setState({ loading: true });
      let data = {
        task_tracking_status: this.state.task_tracking_status,
        attendance_tracking_status: this.state.attendance_tracking_status
      };
      GET_ACCESS_TOKEN().then(token => {
        let url = `${TRACKING_PERMISSION}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log("response from tracking settings post", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from tracking settings post", error);
          });
      });
    
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Tracking Settings"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Enable Task Tracking</Text>
            <Select
              value={this.state.task_tracking_status}
              onChange={this.setTaskTracking}
              placeholder="Enable Task Tracking"
            >
              {this.state.task_tracking_status_type_choices.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Enable Attendance Tracking</Text>
            <Select
              value={this.state.attendance_tracking_status}
              onChange={this.setAttendanceTracking}
              placeholder="Enable Attendance Tracking"
            >
              {this.state.attendance_tracking_status_type_choices.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateSettings}
            >
              Confirm Settings
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
