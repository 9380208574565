import React, { Component } from "react";
import {
  Table,
  Card,
  // PageHeader,
  Button,
  Tooltip,
  Pagination,
  Select,
  Typography,
  Divider,
  Input,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";
// import swal from "sweetalert2";

import { CREATE_INCIDENT,INCIDENT_BILL_REPORT } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text, Title } = Typography;

class IncidentList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    asset_name: "",
    task_name: "",
    order_name: "",
    incident_type: "",
    reason: "",
    name: "",
    created_for: "",
    start_time: moment().startOf("month").format("x"),
    start_time_view: moment().startOf("month"),
    end_time: Date.now(),
    end_time_view: moment(),
    search_date_time_type: "created_on",
    loading: false,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getIncidentList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getIncidentList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INCIDENT}?asset_name=${this.state.asset_name}&task_name=${this.state.task_name}&order_name=${this.state.order_name}&incident_type=${this.state.incident_type}&reason=${this.state.reason}&name=${this.state.name}&created_for=${this.state.created_for}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&search_date_time_type=${this.state.search_date_time_type}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from incident list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from incident list api", error);
        });
    });
  }, 500);

  downloadIncidentBillReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INCIDENT_BILL_REPORT}?asset_name=${this.state.asset_name}&task_name=${this.state.task_name}&order_name=${this.state.order_name}&incident_type=${this.state.incident_type}&reason=${this.state.reason}&name=${this.state.name}&created_for=${this.state.created_for}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&search_date_time_type=${this.state.search_date_time_type}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => res.blob())
      .then((blob) => {
        console.log("response from incident bill report", blob);
        FileSaver.saveAs(
          blob,
          `incident_bill_report_${moment().format(
            "DD-MMMM-YYYY_hh:mm A"
          )}.xlsx`
        );
      })
        .catch((error) => {
          console.log("error from incident bill report api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getIncidentList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getIncidentList();
    });
  };

  refresh = () => {
    this.setState(
      {
        page: 0,
        limit: 10,
        asset_name: "",
        task_name: "",
        order_name: "",
        incident_type: "",
        reason: "",
        name: "",
        created_for: "",
        start_time: moment().startOf("month").format("x"),
        start_time_view: moment().startOf("month"),
        end_time: Date.now(),
        end_time_view: moment(),
        search_date_time_type: "created_on",
      },
      () => {
        this.getIncidentList();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.incident.incident_create ? (
          <Link to="/createIncident">
            <Tooltip placement="top" title="Create Incident">
            <Button
                shape="circle"
                icon="plus"
                className="tooltip-button"
              />
            </Tooltip>
          </Link>
        ) : null}

        <div style={{ margin: 3 }}>
          <Title level={4}>Select date range to find Incident</Title>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  style={{ marginTop: 14 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14
                    },
                  }}
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                      },
                      () => this.getIncidentList()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  style={{ marginTop: 14 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14
                    },
                  }}
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).add(59, "seconds").format("x"),
                        end_time_view: value,
                      },
                      () => this.getIncidentList()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text style={{ marginLeft: 5 }}>Filter Time By : </Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getIncidentList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="modified_on">Modified On</Option>
              </Select>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.downloadIncidentBillReport}
            >
              Download Report
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Name</Text>
                  <Input
                    value={this.state.name}
                    onChange={(evt) => {
                      this.setState({ name: evt.target.value, page: 0 }, () => {
                        this.getIncidentList();
                      });
                    }}
                  />
                </div>
              </>
            }
            key="name"
            render={(text, record) => <span>{record.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Incident Type</Text>
                  <Input
                    value={this.state.incident_type}
                    onChange={(evt) => {
                      this.setState(
                        { incident_type: evt.target.value, page: 0 },
                        () => {
                          this.getIncidentList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="incident_type"
            render={(text, record) => <span>{record.incident_type ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Reason</Text>
                  <Input
                    value={this.state.reason}
                    onChange={(evt) => {
                      this.setState(
                        { reason: evt.target.value, page: 0 },
                        () => {
                          this.getIncidentList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="reason"
            render={(text, record) => <span>{record.reason ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Asset</Text>
                  <Input
                    value={this.state.asset_name}
                    onChange={(evt) => {
                      this.setState(
                        { asset_name: evt.target.value, page: 0 },
                        () => {
                          this.getIncidentList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="asset_name"
            render={(text, record) => <span>{record.asset?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Task</Text>
                  <Input
                    value={this.state.task_name}
                    onChange={(evt) => {
                      this.setState(
                        { task_name: evt.target.value, page: 0 },
                        () => {
                          this.getIncidentList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="task_name"
            render={(text, record) => <span>{record.task?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Order</Text>
                  <Input
                    value={this.state.order_name}
                    onChange={(evt) => {
                      this.setState(
                        { order_name: evt.target.value, page: 0 },
                        () => {
                          this.getIncidentList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="order_name"
            render={(text, record) => <span>{record.order?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>created For</Text>
                  <Input
                    value={this.state.created_for}
                    onChange={(evt) => {
                      this.setState(
                        { created_for: evt.target.value, page: 0 },
                        () => {
                          this.getIncidentList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="created_for"
            render={(text, record) => (
              <span>{record.created_for?.name ?? ""}</span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Incident date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="incident_date"
            render={(text, record) => (
              <span>
                {timeConverter(record.incident_date?.$date ?? Date.now())}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {record.has_bill ? (
                  <Link
                    to={{
                      pathname: "/incidentBills",
                      state: {
                        incident_id: record._id.$oid,
                        incident_name: record.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View Bills
                    </Button>
                  </Link>
                ) : null}
                <Link
                  to={{
                    pathname: "/createBill",
                    state: {
                      incident_id: record._id.$oid,
                      incident_name: record.name,
                    },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Create Bill
                  </Button>
                </Link>
                {this.props.userProfileData.incident.incident_update ? (
                  <Link
                    to={{
                      pathname: "/editIncident",
                      state: { incident_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(IncidentList);
