import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  DatePicker
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { GENERATE_MONTHLYBILL, CREATE_ORGANIZATION } from "../../api/constants";
import { GET_ACCESS_TOKEN,LOCAL_USER_INFO } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateMonthlyBill extends Component {
  state = {
    org_id: "",
    org_name: "",
    month_number: 1,
    year_number: null,
    day_number: 1,
    loading: false,
    data: [],
    isStaff:false
  };

  componentDidMount() {
    let dt = new Date();
    let year = dt.getFullYear();
    this.setState({ year_number: year,isStaff:LOCAL_USER_INFO().user.is_staff });
    this.getOrganizationList();
    if(LOCAL_USER_INFO().user.is_staff===false){
      swal.fire("Error","You are not a Staff","error");
    }
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ORGANIZATION}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
          console.log("data from organization list api", response);
        })
        .catch(error => {
          console.log("error from organization list api", error);
        });
    });
  };

  setOrganization = value => {
    this.setState({ org_name: value.label, org_id: value.key }, () => {
      console.log(this.state.org_name);
    });
  };

  setDate = dateStrings => {
    let date = dateStrings._d;
    let cyear = date.getFullYear();
    let cmonth = date.getMonth() + 1;
    let cday = date.getDate();
    this.setState(
      { month_number: cmonth, year_number: cyear, day_number: cday },
      () => {
        console.log("year", this.state.year_number);
        console.log("month", this.state.month_number);
        console.log("day", this.state.day_number);
      }
    );
  };

  createMonthlyBill = () => {
    if(this.state.org_id === "" || this.state.org_name==="" ||this.state.year_number===null){
      swal.fire("info","Please enter all informations","info");
    }else{
    this.setState({ loading: true });
    const data = {
      org_id: this.state.org_id,
      org_name: this.state.org_name,
      month_number: this.state.month_number,
      year_number: this.state.year_number,
      day_number: this.state.day_number
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${GENERATE_MONTHLYBILL}${this.state.org_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from create monthly bill", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from create monthly bill", error);
        });
    });
  }
}
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    
      return (
        <Card style={{ margin: 10 }}>
          
            {this.state.isStaff===true?(
              <div>
                <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Create Monthly Bill"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <Text>Organization</Text>
              <Select
                labelInValue
                value={{ key: this.state.org_id, label: this.state.org_name }}
                onChange={this.setOrganization}
                placeholder="Select Organization"
              >
                {this.state.data.map(r => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
  
            <Form.Item>
              <Text>Date</Text>
              <DatePicker
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
                onChange={this.setDate}
              />
            </Form.Item>
  
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.createMonthlyBill}
              >
                Create Monthly Bill
              </Button>
            </Form.Item>
          </Form>
              </div>
            ):null}
          
          
        </Card>
      );
    
   
  }
}
