import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input,
  Tag
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_CUSTOM_FIELD,
  CUSTOM_FIELD_TYPE,
  CUSTOM_FIELD_FOR,
  CREATE_TASK_TYPE
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateCustomField extends Component {
  state = {
    // key_name: "",
    label: "",
    _type: "str",
    type_choices: [],
    field_for: "task",
    field_for_choices: [],
    showChoices: false,
    choice: "",
    choices: [],
    taskTypes: [],
    task_type: ""
  };

  componentDidMount() {
    this.getFieldForChoices();
    this.getTypeChoices();
    this.getTaskType();
  }

  getTaskType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from task type list", response);

          this.setState({
            taskTypes: response.data.data
          });
        })
        .catch(error => {
          console.log("error from task type list api", error);
        });
    });
  };

  getTypeChoices = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CUSTOM_FIELD_TYPE}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from custom field type choices", response);
          this.setState({
            type_choices: response.data.custom_field_type_choices
          });
        })
        .catch(error => {
          console.log("error from custom field type choices", error);
        });
    });
  };

  getFieldForChoices = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CUSTOM_FIELD_FOR}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from custom field for choices", response);
          this.setState({
            field_for_choices: response.data.custom_field_for_choices
          });
        })
        .catch(error => {
          console.log("error from custom field for choices", error);
        });
    });
  };

  // setKeyName = evt => {
  //   evt.preventDefault();
  //   this.setState({ key_name: evt.target.value }, () => {
  //     console.log(this.state.key_name);
  //   });
  // };
  setLabel = evt => {
    evt.preventDefault();
    this.setState({ label: evt.target.value }, () => {
      console.log(this.state.label);
    });
  };

  setType = value => {
    this.setState({ _type: value }, () => {
      console.log(this.state._type);
      if (
        this.state._type === "dropdown" ||
        this.state._type === "checkbox" ||
        this.state._type === "multiselect" ||
        this.state._type === "radio"
      ) {
        this.setState({ showChoices: true });
      } else {
        this.setState({ showChoices: false });
      }
    });
  };

  setChoice = evt => {
    evt.preventDefault();
    this.setState({ choice: evt.target.value }, () => {
      console.log(this.state.choice);
    });
  };

  setChoices = () => {
    if (this.state.choice === "") {
      return;
    } else {
      let array = [];
      array.push(this.state.choice);
      this.setState({ choices: [...this.state.choices, ...array] }, () => {
        this.setState({ choice: "" });
        console.log(this.state.choices);
      });
    }
  };

  setFieldfor = value => {
    this.setState({ field_for: value }, () => {
      console.log(this.state.field_for);
    });
  };
  setTaskType = value => {
    this.setState({ task_type: value }, () => {
      console.log(this.state.task_type);
    });
  };

  createCustomField = () => {
    if (this.state.label === "") {
      swal.fire("Info", "Please enter all informations", "info");
    } else if (this.state.showChoices && this.state.choices.length === 0) {
      swal.fire("Info", "Please enter choice options", "info");
    } else {
      if (this.state.showChoices) {
        const data = {
          key_name: this.state.label,
          label: this.state.label,
          _type: this.state._type,
          field_for: this.state.field_for,
          task_type: this.state.task_type,
          choices: this.state.choices
        };

        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${CREATE_CUSTOM_FIELD}?access_token=${token}`;
          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              console.log("response from create custom Field", response);
            })
            .catch(error => {
              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              console.log("error from create custom Field", error);
            });
        });
      } else {
        const data = {
          key_name: this.state.label,
          label: this.state.label,
          _type: this.state._type,
          field_for: this.state.field_for,
          task_type: this.state.task_type
        };

        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url = `${CREATE_CUSTOM_FIELD}?access_token=${token}`;
          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              console.log("response from create custom Field", response);
            })
            .catch(error => {
              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              console.log("error from create custom Field", error);
            });
        });
      }
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Custom Field"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          {/* <Form.Item>
            <Text>Key Name</Text>
            <Input
              placeholder="Key Name"
              type="text"
              value={this.state.key_name}
              onChange={this.setKeyName}
            />
          </Form.Item> */}
          <Form.Item>
            <Text>Field Name</Text>
            <Input
              placeholder="Field Name"
              type="text"
              value={this.state.label}
              onChange={this.setLabel}
            />
          </Form.Item>
          <Form.Item>
            <Text>Field Type</Text>
            <Select
              value={this.state._type}
              onChange={this.setType}
              placeholder="Type"
            >
              {this.state.type_choices.map(r => {
                return (
                  <Option key={r}>
                    {r === "int"
                      ? "Number"
                      : r === "decimal"
                      ? "Decimal (Custom)"
                      : r === "float"
                      ? "Decimal"
                      : r === "str"
                      ? "Text"
                      : r}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {this.state.showChoices ? (
            <Form.Item>
              <Text>Choices</Text>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  placeholder="Choices"
                  type="text"
                  value={this.state.choice}
                  onChange={this.setChoice}
                />
                <Button style={{ marginLeft: 5 }} onClick={this.setChoices}>
                  Add
                </Button>
              </div>
              <Text>Choice Names</Text>
              <Card style={{ marginTop: 5 }}>
                {this.state.choices.map((choice, index) => {
                  return (
                    <Tag color="blue" key={index}>
                      {choice}
                    </Tag>
                  );
                })}
              </Card>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Text>Field For</Text>
            <Select
              value={this.state.field_for}
              onChange={this.setFieldfor}
              placeholder="Field For"
            >
              {this.state.field_for_choices.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>
          {this.state.field_for==="task"?(
             <Form.Item>
             <Text>Task Type</Text>
             <Select
               value={this.state.task_type}
               onChange={this.setTaskType}
               placeholder="Task Type"
             >
               {this.state.taskTypes.map(r => {
                 return <Option key={r.name}>{r.name}</Option>;
               })}
             </Select>
           </Form.Item>
          ):null}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createCustomField}
            >
              Create Field
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
