import React, { Component } from "react";
import {
  Table,
  Divider,
  Card,
  Button,
  Tooltip,
  Select,
  Typography,
  Pagination,
  Input,
  Tag,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  CREATE_MAINTENANCE,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text, Title } = Typography;

class MaintenanceList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org: "",
  };
  componentDidMount() {
    this.props.getUserData();
    this.getCurrentUserSubOrgs();

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getMaintenanceList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  handleSuborgSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState({
            current_user_sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  }, 500);

  setCurrentUserSubOrg = (value) => {
    this.setState({ current_user_sub_org_id: value, sub_org: value }, () => {
      this.getMaintenanceList();
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

        axios({
          method: "get",
          url: url,
        })
          .then((response) => {
            console.log("response from first child sub org list", response);

            this.setState({
              first_child_sub_orgs: response.data.data,
            });
          })
          .catch((error) => {
            console.log("error from first child sub org list api", error);
          });
      });
    });
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_place_search: value },
      () => {
        this.getMaintenanceList();
      }
    );
  };

  getMaintenanceList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_MAINTENANCE}?sub_org=${this.state.sub_org}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from maintenance list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from maintenance list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        page: 0,
        limit: 10,
        current_user_sub_org_id: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.current_user_sub_org_id,
        sub_org: this.props.userProfileData.data_level.access_all_data
          ? ""
          : this.state.sub_org,
      },
      () => {
        this.getMaintenanceList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getMaintenanceList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getMaintenanceList();
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.place.place_create ? (
          <Link to="/createMaintenance">
            <Tooltip placement="top" title="Create Maintenance">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card style={{ marginTop: 5 }}>
            <Title level={4}>Filter Maintenance With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text> Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSuborgSearch}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginLeft: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Asset Reg. No.</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="registration_number"
            render={(text, record) => (
              <span>{record?.registration_number ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="sub_org"
            render={(text, record) => (
              <span>{record?.sub_org?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org Ancestors</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="sub_org_ancestors"
            render={(text, record) => (
              <span>
                {record?.sub_org_ancestors.length > 0
                  ? record.sub_org_ancestors.map((so, index) => (
                      <Tag color="blue" key={index}>
                        {so.sub_org_name}
                      </Tag>
                    ))
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org Descendants</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="sub_org_descendants"
            render={(text, record) => (
              <span>
                {record?.sub_org_descendants.length > 0
                  ? record.sub_org_descendants.map((so, index) => (
                      <Tag color="blue" key={index}>
                        {so.sub_org_name}
                      </Tag>
                    ))
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Odometer Reading</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="odometer_reading"
            render={(text, record) => (
              <span>{record?.odometer_reading ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Issue Detail</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="issue_detail"
            render={(text, record) => <span>{record?.issue_detail ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Priority Level</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="priority_level"
            render={(text, record) => (
              <span>{record?.priority_level ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Maintenance Status</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="maintenance_status"
            render={(text, record) => (
              <span>{record?.maintenance_status ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Maintenance Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="maintenance_type"
            render={(text, record) => (
              <span>
                {record?.maintenance_type.length > 0
                  ? record.maintenance_type.map((mt, index) => (
                      <Tag color="blue" key={index}>
                        {mt}
                      </Tag>
                    ))
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Assigned Workshop</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="assigned_workshop"
            render={(text, record) => (
              <span>{record?.assigned_workshop?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Approximate Days</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="approximate_days"
            render={(text, record) => (
              <span>{record?.approximate_days ?? 0}</span>
            )}
          />

          <Column
            title={
              <>
                <Text ellipsis={true}>Approximate Start Time</Text>

                <Input disabled />
              </>
            }
            key="approximate_start_time"
            render={(text, record) => (
              <span>{timeConverter(record.approximate_start_time.$date)}</span>
            )}
          />
          <Column
            title={
              <>
                <Text ellipsis={true}>Created On</Text>

                <Input disabled />
              </>
            }
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editMaintenance",
                    state: { maintenance_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Edit
                  </Button>
                </Link>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(MaintenanceList);
