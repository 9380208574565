import React, { Component } from "react";
import {
  // Card,
  // Form,
  // Input,
  Button,
  Typography,
  PageHeader,
  Empty,
  Tag,
  // Select,
} from "antd";
import CardMaterial from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import swal from "sweetalert2";
import {
  // CANCEL_PAYMENT_INT,
  // CREATE_PLAN,
  EDIT_ORGANIZATION,
  PLAN_BY_COUNTRY,
  STRIPE_CHECKOUT,
  STRIPE_CANCEL_SUBSCRIPTION,
  // ORGANIZATION_PLAN,
  // EXTEND_SUBSCRIPTION,
  // REVERSE_GEO_COUNTRY,
  // CHANGE_ORG_PLAN,
  // ADD_ORG_PLAN
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import CheckIcon from "@material-ui/icons/Check";

import "../login.css";
// import moment from "moment";
// import { id } from "date-fns/locale";

const { Text, Title } = Typography;
// const { Option } = Select;

export default class SubscribeOrganizationPlan extends Component {
  state = {
    organization_id: "",
    pid: "",
    plan_name: "",
    subscription_id: "",
    subscription_cancel: false,
    loading: false,
    organization_name: "",
    planData: [],
    country_name: "",
    filtered_country: [],
    valid_till: "",
    existing_plans: false,
  };

  componentDidMount() {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
    this.getOrgInfo();
  }

  getOrgInfo = () => {
    this.setState({ organization_id: this.props.location.state.org_id });

    GET_ACCESS_TOKEN().then((token) => {
      let org_id = this.props.location.state.org_id;
      let url = `${EDIT_ORGANIZATION}${org_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("subscribe page", response.data);
        this.setState(
          {
            organization_name: response.data.data.name,
            country_name: response.data.data.country,
            valid_till: response?.data?.data?.plans[0]?.valid_till?.$date ?? "",
            pid: response?.data?.data?.plans[0]?._id?.$oid ?? "",
            plan_name: response?.data?.data?.plans[0]?.name ?? "",
            subscription_id:
              response?.data?.data?.plans[0]?.subscription_id ?? "",
            subscription_cancel:
              response?.data?.data?.plans[0]?.subscription_cancel ?? false,
            existing_plans:
              response?.data?.data?.plans?.length > 0 ? true : false,
          },
          () => {
            if (this.state.subscription_cancel) {
              swal.fire(
                "Info",
                "Your subscription has been cancelled! Please subscribe to a plan",
                "info"
              );
            }
            this.getPlanList();
          }
        );
      });
    });
  };

  getPlanList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${PLAN_BY_COUNTRY}?country=${this.state.country_name}&limit=${limit}&access_token=${token}`;
      // const url = `${PLAN_BY_COUNTRY}?country=Australia&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from plan list api", response);
          this.setState({
            planData: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from plan list api", error);
        });
    });
  };
  stripeCheckout = (stripe_price_id) => {
    const data = {
      price_id: stripe_price_id,
      country: this.state.country_name,
      // country: "Australia",
      email: "",
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${STRIPE_CHECKOUT}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          // this.getOrgInfo();
          // swal.fire("Success", response.data.message, "success");
          window.location.replace(response.data.data);
          console.log("stripe checkout", response);
        })
        .catch((error) => {
          // swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("stripe checkout", error);
        });
    });
  };
  cancelSubscription = () => {
    const data = {
      subscription_id: this.state.subscription_id,
      country: this.state.country_name,
      // country: "Australia",
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${STRIPE_CANCEL_SUBSCRIPTION}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          this.getOrgInfo();
          console.log("cancel subscription", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("cancel subscription", error);
        });
    });
  };

  // setPlan = value => {
  //   this.setState({ pid: value }, () => {
  //     console.log(this.state.pid);
  //   });
  // };

  // subscribeOrg = () => {
  //   swal
  //     .fire({
  //       title: "Are you sure you want to subscribe this organization?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes"
  //     })
  //     .then(result => {
  //       if (result.value) {
  //         this.setState({ loading: true });
  //         const data = {
  //           organization_id: this.state.organization_id,
  //           subscription_date:
  //             Date.now() > this.state.valid_till
  //               ? Date.now()
  //               : moment(this.state.valid_till)
  //                   .add(1, "second")
  //                   .format("x")
  //         };
  //         console.log(data);
  //         GET_ACCESS_TOKEN().then(token => {
  //           let url = `${EXTEND_SUBSCRIPTION}${this.state.organization_id}?access_token=${token}`;
  //           axios({
  //             method: "put",
  //             url: url,
  //             data: data,
  //             headers: {
  //               "content-type": "application/x-www-form-urlencoded"
  //             }
  //           })
  //             .then(response => {
  //               this.setState({ loading: false });
  //               swal.fire("Success", response.data.message, "success");
  //               console.log("response from subscribe org", response);
  //               setTimeout(() => window.location.reload(), 1000);
  //             })
  //             .catch(error => {
  //               this.setState({ loading: false });
  //               swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //               console.log("error from subscribe org", error);
  //             });
  //         });
  //       }
  //     });
  // };

  // updatePlan=()=>{
  //   const data = {
  //     pid: this.state.pid
  //   };
  //   console.log(data);
  //   GET_ACCESS_TOKEN().then(token => {
  //     let url = `${CHANGE_ORG_PLAN}${this.state.organization_id}?access_token=${token}`;
  //     axios({
  //       method: "put",
  //       url: url,
  //       data: data,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded"
  //       }
  //     })
  //       .then(response => {
  //         this.getOrgInfo();
  //         swal.fire("Success", response.data.message, "success");
  //         console.log("response from change plan", response);
  //       })
  //       .catch(error => {
  //         swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //         console.log("error from change plan", error);
  //       });
  //   });
  // }
  // addPlan=()=>{
  //   const data = {
  //     pid: this.state.pid
  //   };
  //   console.log(data);
  //   GET_ACCESS_TOKEN().then(token => {
  //     let url = `${ADD_ORG_PLAN}${this.state.organization_id}?access_token=${token}`;
  //     axios({
  //       method: "put",
  //       url: url,
  //       data: data,
  //       headers: {
  //         "content-type": "application/x-www-form-urlencoded"
  //       }
  //     })
  //       .then(response => {
  //         this.getOrgInfo();
  //         swal.fire("Success", response.data.message, "success");
  //         console.log("response from add plan", response);
  //       })
  //       .catch(error => {
  //         swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //         console.log("error from add plan", error);
  //       });
  //   });
  // }
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <div style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Plan Details"
        />
        {this.state.plan_name ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Tag
              style={{
                fontSize: 15,
                padding: 10,
                backgroundColor: "#1A3337",
                color: "white",
              }}
            >
              {this.state.plan_name}
            </Tag>
            {this.state.subscription_id && !this.state.subscription_cancel&&this.state.country_name !== "Bangladesh" ? (
              <Button
                style={{ fontSize: 15, marginTop: 5 }}
                type="link"
                onClick={this.cancelSubscription}
              >
                Cancel Subscription
              </Button>
            ) : null}
          </div>
        ) : null}

        {this.state.country_name === "Bangladesh" ? (
          <div style={{ position: "relative", top: window.innerHeight / 2.5 }}>
            <Empty />
          </div>
        ) : (
          <div className="cardscontainer">
            {this.state.planData.map((plan) => (
              <CardMaterial
                key={plan._id.$oid}
                className="cardsingle"
                style={{
                  marginTop: 30,
                  boxShadow:
                    "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <CardContent style={{ height: "83%", marginBottom: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Title level={4} style={{ color: "#1A3337" }}>
                      {plan.name}
                    </Title>
                    <Text
                      style={{
                        fontSize: 13,
                        color: "#5A6C57",
                      }}
                    >
                      {plan.description}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <Title level={3} style={{ color: "#ff9900" }}>
                        {plan.price.amount}
                      </Title>
                      <Text
                        style={{
                          paddingTop: 7,
                          paddingLeft: 5,
                          fontSize: 15,
                          fontWeight: "bolder",
                        }}
                      >{`${
                        plan.price.currency_symbol
                      }/${plan.price.interval.toUpperCase()}`}</Text>
                    </div>
                    <div
                      style={{
                        width: "75%",
                        marginLeft: "auto",
                        marginTop: -20,
                      }}
                    >
                      {plan.pack_details
                        .filter((pack) => pack.nos > 0)
                        .map((pack) => (
                          <div
                            key={pack.service_id.$oid}
                            style={{ textAlign: "left" }}
                          >
                            <Text
                              style={{
                                fontSize: 13,
                              }}
                            >
                              <CheckIcon
                                style={{
                                  width: 13,
                                  height: 13,
                                  marginTop: 18,
                                  marginBottom: -3,
                                  marginRight: 5,
                                  color: "#347928",
                                }}
                              />

                              {`${pack.nos} ${pack.service_name}`}
                            </Text>
                          </div>
                        ))}
                    </div>
                  </div>
                </CardContent>
                <CardActions style={{ position: "", bottom: 0 }}>
                  <Button
                    className="login-form-button"
                    style={{
                      width: "100%",
                      marginTop: "auto",
                    }}
                    onClick={() =>
                      this.stripeCheckout(plan.price?.stripe_price_id ?? "")
                    }
                  >
                    Choose Plan
                  </Button>
                </CardActions>
              </CardMaterial>
            ))}
          </div>
        )}

        {/* {moment(this.state.valid_till)
          .subtract(24, "hours")
          .format("x") <= Date.now() ? (
          <Card>
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item>
                <Text>Organization Name</Text>
                <Input
                  placeholder="Organization Name"
                  type="text"
                  value={this.state.organization_name}
                  disabled
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.subscribeOrg}
                >
                  Extend Subscription
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : null} */}
        {/* <Card>
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
                <Text>Organization Name</Text>
                <Input
                  placeholder="Organization Name"
                  type="text"
                  value={this.state.organization_name}
                  disabled
                />
              </Form.Item>
            <Form.Item>
              <Text>Select Plan</Text>
              <Select
                value={this.state.pid}
                onChange={this.setPlan}
                placeholder="Select Plan"
              >
                {this.state.filtered_country.map(r => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={()=>{
                  this.state.existing_plans?this.updatePlan():this.addPlan()
                }}
              >
                {this.state.existing_plans?"Change Plan":"Add Plan"}
              </Button>
            </Form.Item>
          </Form>
        </Card> */}
      </div>
    );
  }
}
