import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_YOUTUBE_LINK, YOUTUBE_LINK_TYPES } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditYoutubeLink extends Component {
  state = {
    name: "",
    type: "",
    link_url: "",
    youtube_link_id: "",
    loading: false,
    data: []
  };
  componentDidMount() {
    this.setState({
      youtube_link_id: this.props.location.state.youtube_link_id
    });
    this.getLink();
    this.getLinkType();
  }

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setLinkUrl = evt => {
    evt.preventDefault();
    this.setState({ link_url: evt.target.value }, () => {
      console.log(this.state.link_url);
    });
  };

  setLinkType = value => {
    this.setState({ type: value }, () => {
      console.log(this.state.type);
    });
  };

  getLink = () => {
    GET_ACCESS_TOKEN().then(token => {
      let youtube_link_id = this.props.location.state.youtube_link_id;
      let url = `${EDIT_YOUTUBE_LINK}${youtube_link_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit unit", response);
        this.setState({
          name: response.data.data.name,
          type: response.data.data.type,
          link_url: response.data.data.link_url
        });
      });
    });
  };

  getLinkType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${YOUTUBE_LINK_TYPES}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.youtube_link_types });
          console.log("data from youtube link type api", response);
        })
        .catch(error => {
          console.log("error from youtube link type api", error);
        });
    });
  };

  editYoutubeLink = () => {
    this.setState({ loading: true });
    const data = {
      youtube_link_id: this.state.youtube_link_id,
      name: this.state.name,
      type: this.state.type,
      link_url: this.state.link_url
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_YOUTUBE_LINK}${this.state.youtube_link_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit youtube Link", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit youtube Link", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Edit Youtube Link"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Form.Item>
            <Text>Select Link Type</Text>
            <Select
              value={this.state.type.toString()}
              onChange={this.setLinkType}
              placeholder="Select Link Type"
            >
              {this.state.data.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Youtube Link URL</Text>
            <Input
              placeholder="Youtube Link URL"
              type="text"
              value={this.state.link_url}
              onChange={this.setLinkUrl}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editYoutubeLink}
            >
              Update Link
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
