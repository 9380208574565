import React from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";

import { CREATE_QRCODE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;

export default class QrcodeList extends React.Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
  };
  componentDidMount() {
    this.getQrcodeList();
  }

  getQrcodeList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_QRCODE}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from qrcode list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from qrcode list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getQrcodeList();
      }
    );
  };

  //   deleteQrcode = id => {
  //     this.setState({ loading: true });
  //     GET_ACCESS_TOKEN().then(token => {
  //       const url = `${EDIT_PARCEL}${id}?access_token=${token}`;

  //       axios({
  //         method: "delete",
  //         url: url
  //       })
  //         .then(response => {
  //           this.setState({ loading: false });
  //           swal.fire("Success", response.data.message, "success");
  //           setTimeout(() => window.location.reload(), 1000);
  //           console.log("deleted", response);
  //         })
  //         .catch(error => {
  //           this.setState({ loading: false });
  //           swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //           console.log("error from del", error);
  //         });
  //     });
  //   };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.qr_code.qr_code_create ? (
          <Link to="/createQrcode">
            <Tooltip placement="top" title="Create QR Code">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

         <PageHeader
          title="QR Codes"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Code For" dataIndex="for_name" key="for_name" />
          <Column title="ID" dataIndex="id" key="id" />
          <Column title="Code URL" dataIndex="url" key="url" />
          <Column
            title="Image path"
            render={(record) => (
              <span>{`https://api.conveyance.app/v1/${record.uploaded_path}`}</span>
            )}
          />

          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Modified On"
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />

          {/* <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editParcel",
                    state: { parcel_id: record._id.$oid }
                  }}
                >
                  <button
                    className="borderless-button"
                    style={{ color: "green" }}
                  >
                    View/Edit
                  </button>
                </Link>
                <div>
                  {LOCAL_USER_INFO().user.is_staff === true ? (
                    <div>
                      <Divider type="vertical" />
                      <button
                        className="borderless-button"
                        style={{ color: "red" }}
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteParcel(record._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  ) : null}
                </div>
              </span>
            )}
          /> */}
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
