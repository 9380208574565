import {combineReducers} from "redux";
import startTaskreducer from "./startTaskReducer";
import taskListParamsReducer from "./taskListParamsReducer";
import orderListParamsReducer from "./orderListParamsReducer";
import purchaseOrderListParamsReducer from "./purchaseOrderListParamsReducer";
import billListParamsReducer from "./billListParamsReducer";
import assetListParamsReducer from "./assetListParamsReducer";
import attendanceListParamsReducer from "./attendanceListParamsReducer";
import suborgListParamsReducer from "./suborgListParamsReducer";
import placesListParamsReducer from "./placesListParamsReducer";
import userListParamsReducer from "./userListParamsReducer";
import requisitionListParamsReducer from "./requisitionListParamsReducer";
import menuReducer from "./menuReducer";
import sideBarScrollReducer from "./sideBarScrollReducer";
import userProfileReducer from "./userProfileReducer";

export default combineReducers({
    startTask:startTaskreducer,
    taskListParams:taskListParamsReducer,
    orderListParams:orderListParamsReducer,
    purchaseOrderListParams:purchaseOrderListParamsReducer,
    billListParams:billListParamsReducer,
    assetListParams:assetListParamsReducer,
    attendanceListParams:attendanceListParamsReducer,
    suborgListParams:suborgListParamsReducer,
    placesListParams:placesListParamsReducer,
    requisitionListParams:requisitionListParamsReducer,
    userListParams:userListParamsReducer,
    userProfile:userProfileReducer,
    menu:menuReducer,
    sideBarScrollPosition:sideBarScrollReducer,
});