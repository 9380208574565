import React, { Component } from "react";
import { Card, Typography, PageHeader, Icon, Timeline } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_VISIT_PLAN,
  PLAN_ROUTE_SUGGESTION,
  PLAN_TO_CHAIN_TASK
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  randomId
} from "../../utilities/utilities";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import ReactGA from "react-ga4";

import CardMaterial from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ButtonMaterial from "@material-ui/core/Button";

const { Text } = Typography;
// const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});

const polyline = new L.Icon({
  iconUrl: require("../.././assets/polyline.png"),
  iconSize: [25, 40]
});

class ViewPlan extends Component {
  state = {
    plan_id: "",
    start_loc_name: "",
    start_loc: {},
    end_loc_name: "",
    end_loc: {},
    plan_name: "",
    plan_note: "",
    plan_date: "",
    visit_infos: [],
    created_on: "",
    modified_on: "",
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 8,
    asset_name: "",
    assign_asset: "",
    assign_person_name: "",
    assign_to: "",
    total_visit_hour: 0,
    polyLineData: [],
    waypoints: [],
    viewAs: "custom",
    taskGenData: []
  };
  componentDidMount() {
    this.props.getUserData();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({ plan_id: this.props.location.state.plan_id });
    this.getVisitPlan();
    this.getRouteSuggestion();
  }

  setView = value => {
    this.setState({ viewAs: value }, () => {
      console.log(this.state.viewAs);
    });
  };

  getVisitPlan = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_VISIT_PLAN}${this.props.location.state.plan_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        this.setState({
          plan_name: response.data?.data?.plan_name ?? "",
          start_loc_name: response.data?.data?.start_loc_name ?? "",
          start_loc: response.data?.data?.start_loc ?? {},
          asset_name: response.data?.data?.asset_name ?? "",
          assign_asset: response.data?.data?.asset_id ?? "",
          assign_person_name: response.data?.data?.assign_person_name ?? "",
          assign_to: response.data?.data?.assign_person ?? "",
          total_visit_hour: response.data?.data?.total_visit_hour ?? 0,
          end_loc_name: response.data?.data?.end_loc_name ?? "",
          end_loc: response.data?.data?.end_loc ?? {},
          plan_note: response.data?.data?.note ?? "",
          plan_date: response.data?.data?.visiting_time?.$date ?? "",
          created_on: response.data?.data?.created_on?.$date ?? "",
          modified_on: response.data?.data?.modified_on?.$date ?? "",
          visit_infos: response.data?.data?.visiting_location_infos ?? []
        });
        console.log("response from visit plan details", response);
      });
    });
  };

  getRouteSuggestion = () => {
    const data = {
      visit_plan_id: this.props.location.state.plan_id
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${PLAN_ROUTE_SUGGESTION}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({
            polyLineData:
              response.data?.data?.trips[0]?.geometry?.coordinates ?? [],
            waypoints: response.data?.data?.waypoints ?? []
          });
          console.log("response from plan route suggestion", response);
        })
        .catch(error => {
          console.log("error from plan route suggestion",  error?.response?.data?.message??error.message);
        });
    });
  };

  generateChainTask = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${PLAN_TO_CHAIN_TASK}?access_token=${token}`;
      let data = {
        task_gen_datas: this.state.taskGenData,
        assigned_to: this.state.assign_to,
        assigned_asset: this.state.assign_asset,
        assign_connected_users: []
      };
      console.log(data);
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Success", response.data.message, "success");
          console.log(
            "response from generate chain task from plan post",
            response
          );
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from generate chain task from plan post", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="View Visit Plan"
          onBack={() => this.props.history.goBack()}
        />
        <div>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2"
              }}
              type={"roadmap"}
            />

            {this.state.waypoints.length > 0 ? (
              <div>
                {this.state.waypoints.map((points, index) => {
                  return (
                    <Marker
                      position={[points.location[1], points.location[0]]}
                      icon={points.waypoint_index === 0 ? green : polyline}
                      key={points.waypoint_index}
                    >
                      <Popup>
                        <span>
                          {points.waypoint_index === 0
                            ? `${points.waypoint_index + 1}. Start Location : ${
                                this.state.start_loc_name
                              }:`
                            : `${points.waypoint_index + 1}. Location : ${
                                points.name
                              }`}
                        </span>
                      </Popup>
                    </Marker>
                  );
                })}
              </div>
            ) : null}
            {this.state.polyLineData.length > 0 ? (
              <Polyline
                color="red"
                positions={[this.state.polyLineData.map(m => [m[1], m[0]])]}
              />
            ) : null}
          </Map>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CardMaterial
              style={{
                marginTop: 5,
                marginRight: 5,
                borderRadius: 5
              }}
            >
              
                <CardContent>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2
                      style={{
                        fontFamily: "AirbnbCerealApp-ExtraBold",
                        marginLeft: 5,
                        letterSpacing: 1
                      }}
                    >
                      Name : {this.state.plan_name}
                    </h2>
                    <p >
                      <Icon
                        type="book"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Note : {this.state.plan_note}
                    </p>
                    <p >
                      <Icon
                        type="clock-circle"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Total Visit Hour : {this.state.total_visit_hour}
                    </p>

                    <p >
                      {" "}
                      <Icon
                        type="environment"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Start Location : {this.state.start_loc_name}
                    </p>
                    <p >
                      {" "}
                      <Icon
                        type="environment"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      End Location : {this.state.end_loc_name}
                    </p>
                    <p >
                      {" "}
                      <Icon
                        type="mobile"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Assigned Person Name : {this.state.assign_person_name}
                    </p>
                    <p >
                      {" "}
                      <Icon
                        type="car"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Asset Name : {this.state.asset_name}
                    </p>
                    <p >
                      {" "}
                      <Icon
                        type="clock-circle"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Visiting Time : {timeConverter(this.state.plan_date)}
                    </p>
                    <p >
                      {" "}
                      <Icon
                        type="clock-circle"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Created On : {timeConverter(this.state.created_on)}
                    </p>
                    <p >
                      {" "}
                      <Icon
                        type="clock-circle"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      Modified On : {timeConverter(this.state.modified_on)}
                    </p>
                  </div>
                </CardContent>
             
            </CardMaterial>
            <CardMaterial
              style={{
                marginTop: 5,
                borderRadius: 5
              }}
            >
         
                <CardContent>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <Card style={{ marginBottom: 10, marginTop: 10 }}>
                      <div style={{ textAlign: "center" }}>
                        <Text>View Locations as : {""}</Text>
                        <Select
                          value={this.state.viewAs}
                          onChange={this.setView}
                          placeholder="View as"
                        >
                          <Option key="suggestion">Suggestion</Option>
                          <Option key="custom">Custom</Option>
                        </Select>
                      </div>
                    </Card> */}
                    <h2
                      style={{
                        fontFamily: "AirbnbCerealApp-ExtraBold",
                        marginLeft: 5,
                        letterSpacing: 1
                      }}
                    >
                      Select Visit Plan Locations
                    </h2>

                    <Timeline>
                      <Timeline.Item color="teal">
                        <p >
                          {" "}
                          <Icon
                            type="environment"
                            style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                          />
                          Start Location : {this.state.start_loc_name}
                        </p>
                      </Timeline.Item>

                      {this.state.visit_infos.map((loc, index) => (
                        <Timeline.Item
                          key={index}
                          color={
                            this.state.taskGenData.some(
                              el => el.id === loc.visiting_info_id
                            )
                              ? "red"
                              : "gold"
                          }
                          onClick={() => {
                            if (
                              this.state.taskGenData.some(
                                el => el.id === loc.visiting_info_id
                              )
                            ) {
                              this.setState(
                                {
                                  taskGenData: this.state.taskGenData.filter(
                                    item => item.id !== loc.visiting_info_id
                                  )
                                },
                                () => {
                                  console.log(
                                    "after remove",
                                    this.state.taskGenData
                                  );
                                }
                              );
                            } else {
                              if (this.state.taskGenData.length === 0) {
                                let taskObj = {
                                  name: `${this.state.plan_name},Chain : ${loc.visiting_info_id}`,
                                  details: this.state.plan_note,
                                  end_loc: loc.loc,
                                  end_loc_name: loc.loc_name,
                                  task_code: randomId(10),
                                  price: 0,
                                  start_loc: this.state.start_loc,
                                  start_loc_name: this.state.start_loc_name,
                                  end_time:
                                    this.state.plan_date +
                                    loc.visit_duration * 3600000,
                                  start_time: this.state.plan_date,
                                  assigned_to: "",
                                  start_dist: 1000,
                                  end_dist: 1000,
                                  trip_duration: 0,
                                  delay_mins: null,
                                  assigned_asset: "",
                                  prev_task_id: "",
                                  m_task_id: "",
                                  contact_no: "",
                                  contact_name: "",
                                  drop_contact_name: loc.representative_name,
                                  drop_contact_no: loc.representative_mobile,
                                  colors: [],
                                  id: loc.visiting_info_id
                                };

                                let temp = [];
                                temp.push(taskObj);
                                this.setState(
                                  {
                                    taskGenData: [
                                      ...this.state.taskGenData,
                                      ...temp
                                    ]
                                  },
                                  () => {
                                    console.log(
                                      "plan task data first",
                                      this.state.taskGenData
                                    );
                                  }
                                );
                              } else {
                                let taskObj = {
                                  name: `${this.state.plan_name},Chain : ${loc.visiting_info_id}`,
                                  details: this.state.plan_note,
                                  end_loc: loc.loc,
                                  end_loc_name: loc.loc_name,
                                  task_code: randomId(10),
                                  price: 0,
                                  start_loc: this.state.taskGenData[
                                    this.state.taskGenData.length - 1
                                  ].end_loc,
                                  start_loc_name: this.state.taskGenData[
                                    this.state.taskGenData.length - 1
                                  ].end_loc_name,
                                  end_time:
                                    this.state.taskGenData[
                                      this.state.taskGenData.length - 1
                                    ].end_time +
                                    loc.visit_duration * 3600000,
                                  start_time: this.state.taskGenData[
                                    this.state.taskGenData.length - 1
                                  ].end_time,
                                  assigned_to: this.state.assign_to,
                                  start_dist: 1000,
                                  end_dist: 1000,
                                  trip_duration: 0,
                                  delay_mins: null,
                                  assigned_asset: this.state.assign_asset,
                                  prev_task_id: "",
                                  m_task_id: "",
                                  contact_no: "",
                                  contact_name: "",
                                  drop_contact_name: loc.representative_name,
                                  drop_contact_no: loc.representative_mobile,
                                  colors: [],
                                  id: loc.visiting_info_id
                                };

                                let temp = [];
                                temp.push(taskObj);
                                this.setState(
                                  {
                                    taskGenData: [
                                      ...this.state.taskGenData,
                                      ...temp
                                    ]
                                  },
                                  () => {
                                    console.log(
                                      "plan task data rest",
                                      this.state.taskGenData
                                    );
                                  }
                                );
                              }
                            }
                          }}
                        >
                          <p >
                            {" "}
                            <Icon
                              type="environment"
                              style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                            />
                            <Text>Location : {loc.loc_name}</Text>
                          </p>
                          <p >
                            {" "}
                            <Icon
                              type="contacts"
                              style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                            />
                            <Text>
                              Contact Person Name : {loc.representative_name}
                            </Text>
                          </p>
                          <p >
                            {" "}
                            <Icon
                              type="mobile"
                              style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                            />
                            <Text>
                              Contact Person Mobile :{" "}
                              {loc.representative_mobile}
                            </Text>
                          </p>
                          <p >
                            {" "}
                            <Icon
                              type="audit"
                              style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                            />
                            <Text>Visiting Org : {loc.visiting_org}</Text>
                          </p>
                          <p >
                            {" "}
                            <Icon
                              type="clock-circle"
                              style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                            />
                            <Text>Visit Duration : {loc.visit_duration}</Text>
                          </p>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </CardContent>
              
              {this.state.taskGenData.length > 0 &&
              this.props.userProfileData.visit_plan
                .generate_chain_task_from_selected_plan ? (
                <CardActions>
                  <ButtonMaterial
                    color="secondary"
                    onClick={this.generateChainTask}
                  >
                    Generate Chain Task
                  </ButtonMaterial>
                </CardActions>
              ) : null}
            </CardMaterial>
          </div>
        </div>
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(ViewPlan);
