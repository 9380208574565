import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import {
  CREATE_SALES_TARGET
} from "../../api/constants";
import DateFnsUtils from "@date-io/date-fns";
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga4";


import "../login.css";

const { Text } = Typography;

export default class CreateSalesTarget extends Component {
  state = {
    target_amount:0,
    assigned_user:"",
    assigned_user_name:"",
    start_datetime: Date.now(),
    start_datetime_view: moment(),
    end_datetime: Date.now(),
    end_datetime_view: moment()
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({
        assigned_user:this.props?.location?.state?.assigned_user,
        assigned_user_name:this.props?.location?.state?.assigned_user_name,
    })
  
  }

 

  setStartDate = (value, dateString) => {
    this.setState(
      { start_datetime: moment(value).format("x"), start_datetime_view: value },
      () => {
        console.log("Selected Date: ", this.state.start_datetime);
      }
    );
  };
  setEndDate = (value, dateString) => {
    this.setState(
      { end_datetime: moment(value).format("x"), end_datetime_view: value },
      () => {
        console.log("Selected Date: ", this.state.end_datetime);
      }
    );
  };

  setTargetAmount = evt => {
    evt.preventDefault();
    this.setState({ target_amount: evt.target.value }, () => {
      console.log(this.state.target_amount);
    });
  };

  

  createSalesTarget = () => {
   
      const data = {
        assigned_user: this.state.assigned_user,
        target_amount: Number(this.state.target_amount),
        start_datetime: this.state.start_datetime,
        end_datetime: this.state.end_datetime,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_SALES_TARGET}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            console.log("response from create sales target", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from create sales target", error);
          });
      });
    
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title={`Create Sales Target for ${this.state.assigned_user_name}`}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
         
          <Form.Item>
            <Text>Target Amount</Text>
            <Input
              placeholder="Target Amount"
              type="number"
              value={this.state.target_amount}
              onChange={this.setTargetAmount}
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Start Date *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_datetime_view}
                  onChange={this.setStartDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>End Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_datetime_view}
                  onChange={this.setEndDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
        

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createSalesTarget}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
