import React, { Component } from "react";
import { Card, PageHeader, Button, Typography, Select, Form } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import FileSaver from "file-saver";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  BROTHERS_ASSET_REPORT,
  CREATE_ASSET,
  FIRST_CHAIN_TASK
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class AssetReport extends Component {
  state = {
    asset_id: "",
    asset_name: "",
    asset_data: [],
    chained_task: true,
    task_id: "",
    task_name: "",
    task_data: [],
    time_from: Date.now(),
    time_from_view: moment(),
    time_to: Date.now(),
    time_to_view: moment(),
    timezone_name: "",
    timezone_choice: "org_timezone"
  };
  componentDidMount() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);
    this.getTaskData();
    this.getAssetList();
  }

  getTaskData = () => {
   
    GET_ACCESS_TOKEN().then(token => {
      const url = `${FIRST_CHAIN_TASK}?limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from Task list chain", response);
          this.setState({
            task_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from Task list api chain", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ASSET}?limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from asset list", response);
          this.setState({
            asset_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from asset list api", error);
        });
    });
  };

  setTimezonetype = value => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  setTask = value => {
    this.setState({ task_name: value.label, task_id: value.key }, () => {
      console.log(this.state.task_id);
    });
  };

  setAsset = value => {
    this.setState({ asset_name: value.label, asset_id: value.key }, () => {
      console.log(this.state.asset_id);
    });
  };

  setChainTask = value => {
    this.setState({ chained_task: CHECK_BOOL(value) }, () => {
      console.log(this.state.chained_task);
    });
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { time_from: moment(value).format("x"), time_from_view: value },
      () => {
        console.log("Selected Time: ", this.state.time_from);
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      { time_to: moment(value).format("x"), time_to_view: value },
      () => {
        console.log("Selected Time: ", this.state.time_to);
      }
    );
  };

  assetReport = () => {
    if (this.state.task_id === "" || this.state.asset_id === "") {
      swal.fire("Info", "Please Select a Task and an Asset");
    } else {
      GET_ACCESS_TOKEN().then(token => {
        const url = `${BROTHERS_ASSET_REPORT}?fmt=xls&task_id=${this.state.task_id}&chained_task=${this.state.chained_task}&asset_id=${this.state.asset_id}&time_from=${this.state.time_from}&time_to=${this.state.time_to}&timezone_name=${this.state.timezone_name}&timezone_choice=${this.state.timezone_choice}&access_token=${token}`;
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            console.log("response from asset report fmt", blob);

            FileSaver.saveAs(
              blob,
              `asset_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
          })
          .catch(error => {
            console.log("error from asset report api",  error?.response?.data?.message??error.message);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
        
          title="Download Asset Report"
        />
        <Card style={{ marginTop: 5 }}>
          <div style={{ display: "flex",alignSelf:"center",justifyContent:"center" }}>
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item
                style={{
                  margin: 10
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={{ marginTop: 15, marginRight: 5 }}>
                    Start Time :{" "}
                  </Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ width: 300 }}
                      inputVariant="outlined"
                      value={this.state.time_from_view}
                      onChange={this.setStartTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>

              <Form.Item
                style={{
                  margin: 10
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={{ marginTop: 15, marginRight: 9 }}>
                    End Time :{" "}
                  </Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ width: 300 }}
                      inputVariant="outlined"
                      value={this.state.time_to_view}
                      onChange={this.setEndTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>

              <Form.Item style={{ margin: 10 }}>
                <Text> Timezone : {""}</Text>
                <Select
                  value={this.state.timezone_choice}
                  onChange={this.setTimezonetype}
                  placeholder="View as"
                >
                  <Option key="user_timezone">User</Option>
                  <Option key="org_timezone">Organization</Option>
                </Select>
              </Form.Item>
              {/* <Form.Item style={{ margin: 10 }}>
                <Text> Chained Task : {""}</Text>
                <Select
                  value={this.state.chained_task.toString()}
                  onChange={this.setChainTask}
                  placeholder="Chained Task :"
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item> */}
              <Form.Item
                style={{
                  margin: 10
                }}
              >
                <Text>Select Task : </Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.task_id,
                    label: this.state.task_name
                  }}
                  onChange={this.setTask}
                  placeholder="Select Task"
                  style={{ minWidth: 200 }}
                >
                  {this.state.task_data.map(r => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  margin: 10
                }}
              >
                <Text>Select Asset : </Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.asset_id,
                    label: this.state.asset_name
                  }}
                  onChange={this.setAsset}
                  placeholder="Select Asset"
                  style={{ minWidth: 200 }}
                >
                  {this.state.asset_data.map(r => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>

          <Button
            className="login-form-button"
            onClick={() => this.assetReport()}
            style={{ marginTop: 10, width: 250 }}
          >
            {" "}
            Download Asset Report
          </Button>
        </Card>
      </Card>
    );
  }
}
