import React, { Component } from "react";
import { Card, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import moment from "moment";
import {
  EDIT_LEAVE,
  CREATE_PROCEDURE,
  LEAVE_APPROVAL,
  APPROVAL_SYSTEM_CHOICES
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;
// const { Option } = Select;

class LeaveDetails extends Component {
  state = {
    leave_id: "",
    leave_type: "",
    leave_date: "",
    leave_end_date: "",
    leave_joining_date: "",
    remarks: "",
    created_by_name: "",
    created_for: "",
    created_for_id: "",
    approved: "pending",
    authorized: "pending",
    recommended: "pending",
    created_on: "",
    recommender_id: "",
    recommender_name: "",
    authorizer_id: "",
    authorizer_name: "",
    approver_id: "",
    approver_name: "",
    approval_system_choices: [],
    leave_file_url: null,
    sub_org_name:"",
    sub_org_type_name:""
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
    // this.props.getUserData();
    this.getProcedureList();
    this.getLeaveDetails();
    this.getApprovalSystemChoices();
    this.setState({ leave_id: this.props.location.state.leave_id });
  }

  getApprovalSystemChoices = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${APPROVAL_SYSTEM_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from approval system choices", response);
          this.setState({
            approval_system_choices:
              response.data.approval_system_status_choices
          });
        })
        .catch(error => {
          console.log("error from approval system choices", error);
        });
    });
  };

  getLeaveDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let leave_id = this.props.location.state.leave_id;
      let url = `${EDIT_LEAVE}${leave_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit leave", response.data.data);
        this.setState({
          leave_type: response.data?.data?.leave_type.name??"",
          leave_date: response.data?.data?.leave_start??"",
          leave_end_date: response.data?.data?.leave_end??"",
          leave_joining_date: response.data?.data?.joining??"",
          leave_file_url: response.data?.data?.leave_request_attachment??"",
          remarks: response.data?.data?.remarks??"",
          created_by_name: response.data?.data?.created_by_name??"",
          recommender_name: response.data?.data?.recommender_name??"",
          authorizer_name: response.data?.data?.authorizer_name??"",
          approver_name: response.data?.data?.approver_name??"",
          created_for: response.data?.data?.created_for?.name??"",
          created_for_id: response.data?.data?.created_for?.id??"",
          created_on: response.data?.data?.created_on.$date,
          approved: response.data?.data?.approved ?? "pending",
          recommended: response.data?.data?.recommended ?? "pending",
          authorized: response.data?.data?.authorized ?? "pending",
          sub_org_name:response.data?.data?.sub_org?.name??"",
          sub_org_type_name:response.data?.data?.sub_org?.sub_org_type_data?.name??""
        });
      });
    });
  };

  getProcedureList = () => {
    let limit = 1000;
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PROCEDURE}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from procedure list", response);
          let requistion = response.data.data.filter(
            proce => proce.name.toLowerCase() === "attendance leave"
          );
          let recommender_id = requistion[0].recommender;
          let authorizer_id = requistion[0].authorizer;
          let approver_id = requistion[0].approver;
          this.setState(
            {
              recommender_id: recommender_id,
              authorizer_id: authorizer_id,
              approver_id: approver_id
            },
            () => {
              console.log("recommender", this.state.recommender_id);
              console.log("auhtorizer", this.state.authorizer_id);
              console.log("approver", this.state.approver_id);
            }
          );
        })
        .catch(error => {
          console.log("error from procedure list api", error);
        });
    });
  };

  setRecomended = checked => {
    this.setState({ recommended: checked }, () => {
      console.log("recommended", this.state.recommended);
    });
  };
  setAuthorized = checked => {
    this.setState({ authorized: checked }, () => {
      console.log("authorized", this.state.authorized);
    });
  };
  setApproved = checked => {
    this.setState({ approved: checked }, () => {
      console.log("approved", this.state.approved);
    });
  };

  updateLeaveStatus = (status) => {
    let data = {
      [status]: true
    };
    console.log("leave update data", data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${LEAVE_APPROVAL}${this.state.leave_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          this.getLeaveDetails();
          console.log("response from update leave status", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          this.getLeaveDetails();
          console.log("error from update leave status", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="View Leave"
          onBack={() => this.props.history.goBack()}
        />
        <Card
          style={{
            marginTop: 5,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <p >
            <Text style={{ fontWeight: "bold" }}>Leave Type</Text>:{" "}
            {this.state.leave_type}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Leave Start Date</Text>:{" "}
            {this.state.leave_date}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Leave End Date</Text>:{" "}
            {this.state.leave_end_date}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Joining Date</Text>:{" "}
            {this.state.leave_joining_date}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Remarks</Text>:{" "}
            {this.state.remarks}
          </p>
          {GET_USER_LOCAL().org_admin && this.state.leave_file_url !== null ? (
            <p >
              <Text style={{ fontWeight: "bold" }}>Required Document</Text>:{" "}
              <a href={this.state.leave_file_url}>Download</a>
            </p>
          ) : null}

          <p >
            <Text style={{ fontWeight: "bold" }}>Created By</Text>:{" "}
            {this.state.created_by_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Created For</Text>:{" "}
            {this.state.created_for}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Sub Org Name</Text>:{" "}
            {this.state.sub_org_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Sub Org Type</Text>:{" "}
            {this.state.sub_org_type_name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
          {/* <p >
            <Text style={{ fontWeight: "bold" }}>Recommended</Text>:{" "}
            {this.state.recommended.toString()}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Authorized</Text>:{" "}
            {this.state.authorized.toString()}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Approved</Text>:{" "}
            {this.state.approved.toString()}
          </p> */}
          <p style={{ fontFamily: "AirbnbCerealApp-Book", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Recommended by</Text>:{" "}
            {this.state.recommender_name}
          </p>
          <span >
            <Text style={{ fontWeight: "bold" }}>Recommended</Text>:{" "}
            {this.state.recommended}
            {/* <Select
              style={{ minWidth: 100 }}
              value={this.state.recommended}
              onChange={value => this.setState({ recommended: value })}
              disabled={
                GET_USER_LOCAL()._id.$oid === this.state.recommender_id ||
                GET_USER_LOCAL().org_admin
                  ? false
                  : true
              }
            >
              {this.state.approval_system_choices.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select> */}
          </span>
          <p
            style={{
              fontFamily: "AirbnbCerealApp-Book",
              marginBottom: 10,
              marginTop: 10
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Authorized by</Text>:{" "}
            {this.state.authorizer_name}
          </p>
          <span >
            <Text style={{ fontWeight: "bold" }}>Authorized</Text>:{" "}
            {this.state.authorized}
            {/* <Select
              style={{ minWidth: 100 }}
              value={this.state.authorized}
              onChange={value => this.setState({ authorized: value })}
              disabled={
                GET_USER_LOCAL()._id.$oid === this.state.authorizer_id ||
                GET_USER_LOCAL().org_admin
                  ? false
                  : true
              }
            >
              {this.state.approval_system_choices.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select> */}
          </span>
          <p
            style={{
              fontFamily: "AirbnbCerealApp-Book",
              marginBottom: 10,
              marginTop: 10
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Approved by</Text>:{" "}
            {this.state.approver_name}
          </p>
          <span >
            <Text style={{ fontWeight: "bold" }}>Approved</Text>:{" "}
            {this.state.approved}
            {/* <Select
              style={{ minWidth: 100 }}
              value={this.state.approved}
              onChange={value => this.setState({ approved: value })}
              disabled={
                GET_USER_LOCAL()._id.$oid === this.state.approver_id ||
                GET_USER_LOCAL().org_admin
                  ? false
                  : true
              }
            >
              {this.state.approval_system_choices.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select> */}
          </span>
          <div style={{display:"flex",flexDirection:"row"}}>
          <div style={{margin:5}}>
          {(this.props.userProfileData.data_level.access_suborg_data ||
          this.props.userProfileData.data_level.access_all_data) &&
          this.state.created_for_id!==GET_USER_LOCAL()._id.$oid ? (
            <Button
              type="primary"
              htmlType="submit"
              onClick={()=>this.updateLeaveStatus("approve_all")}
            >
              Accept All
            </Button>
          ) : null}
          </div>
          <div style={{margin:5}}>
          {(this.props.userProfileData.data_level.access_suborg_data ||
          this.props.userProfileData.data_level.access_all_data)&&
          this.state.created_for_id!==GET_USER_LOCAL()._id.$oid  ? (
            <Button
              type="danger"
              htmlType="submit"
              onClick={()=>this.updateLeaveStatus("reject_all")}
            >
              Reject All
            </Button>
          ) : null}
          </div>
          </div>
        </Card>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(
  LeaveDetails
);
