import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Icon,
  Radio,
  Checkbox,
  Tag,
  Modal,
  Slider
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_ENTITY,
  // REVERSE_GEO,
  CREATE_CUSTOM_FIELD,
  ENTITY_TAG_PRINT,
  ADD_CUSTOM_FIELDS_TO_TASK,
  MULTI_IMAGE_UPLOAD,
  CREATE_CUSTOM_FORM,
  SUBMIT_CUSTOM_FORM,
  EDIT_CUSTOM_FORM_SUBMITTED_DATA,
} from "../../api/constants";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import {
  GET_ACCESS_TOKEN,
  GET_USER_LOCAL,
  isValidURL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Text,Title } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});

class ViewShop extends Component {
  state = {
    name: "",
    shop: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    post_code: "",
    shop_id: "",
    city_id: "",
    country_id: "",
    loading: false,
    start_location: "",
    value: [],
    data: [],
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    zoom: 15,
    org_id: "",
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {},
    state:"",
    sub_org:"",
    sub_org_type:"",
    customFormData: [],
    custom_form_id: "",
    custom_form_fields: [],
    show_custom_form_modal: false,
    custom_form_field_post_object: {},
    custom_form_submitted_data: [],
    document_id: "",
    view_submitted_data_modal: false,
    disable_custom_form_submit_button: false,
    custom_form_title:""
  };
  componentDidMount() {
    this.props.getUserData();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.getCustomFields();
    this.getCustomFormData();
    this.getShop();
    this.setState({ shop_id: this.props.location.state.shop_id });
  }

  getCustomFormSubmissionData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SUBMIT_CUSTOM_FORM}?associated_with=${this.state.shop_id}&form_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_form_submitted_data: response.data.data,
          });
          console.log(
            "response from get custom form submission data ",
            response
          );
        })
        .catch((error) => {
          // swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from get custom form submission ", error);
        });
    });
  };

  getCustomFormData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?associated_with=places&_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form lists", response);
          this.setState(
            {
              customFormData: response.data?.data ?? [],
            },
            () => {
              this.getCustomFormSubmissionData();
            }
          );
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };
  getCustomFormDataWithId = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?associated_with=places&_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form lists", response);
          this.setState(
            {
              custom_form_id:
                response.data.data.length === 1
                  ? response.data.data[0]._id.$oid
                  : "",
              custom_form_fields:
                response.data.data.length === 1
                  ? response.data.data[0].fields
                  : [],
              custom_form_title:
                response.data.data.length === 1
                  ? response.data.data[0].title
                  : "",
            },
            () => {
              this.getCustomFormSubmissionData();
            }
          );
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };

  updateCustomFormData = () => {
    if (Object.keys(this.state.custom_form_field_post_object).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        form_id: this.state.custom_form_id,
        submission_data: this.state.custom_form_field_post_object,
        associated_with: this.state.shop_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${SUBMIT_CUSTOM_FORM}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (
              response.data.message === "Form submission created successfully"
            ) {
              this.getCustomFormSubmissionData();
              this.setState({ custom_form_field_post_object: {} });
            }
            console.log("response from custom form task data post ", response);
          })
          .catch((error) => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from custom form task data post ", error);
          });
      });
    }
  };
  editCustomFormSubmittedData = () => {
    if (Object.keys(this.state.custom_form_field_post_object).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        form_id: this.state.custom_form_id,
        submission_data: this.state.custom_form_field_post_object,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_CUSTOM_FORM_SUBMITTED_DATA}${this.state.document_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (
              response.data.message === "Form submission updated successfully"
            ) {
              this.getCustomFormSubmissionData();
              this.setState({ custom_form_field_post_object: {} });
            }
            console.log(
              "response from custom form task submission data put ",
              response
            );
          })
          .catch((error) => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log(
              "error from custom form task submission data put ",
              error
            );
          });
      });
    }
  };


  getCustomFields = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=entity&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({
            custom_fields: response.data.data
          });
          console.log("response from get custom fields", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };

  getShop = () => {
    GET_ACCESS_TOKEN().then(token => {
      let shop_id = this.props.location.state.shop_id;
      let url = `${EDIT_ENTITY}${shop_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit entity", response);
        this.setState(
          {
            name: response.data.data.name,
            sub_org: response.data?.data?.sub_org?.name??"",
            sub_org_type: response.data?.data?.sub_org?.sub_org_type_data?.name??"",
            shop: response.data.data.entity_type,
            start_location: response.data.data.address,
            city: response.data.data.city,
            country: response.data.data.country,
            state: response.data?.data?.state??"",
            post_code: response.data.data.post_code,
            start_location_lon: response.data.data.loc.coordinates[0],
            start_location_lat: response.data.data.loc.coordinates[1],
            getCustomFieldData: response.data?.data?.custom_fields ?? {}
          }
          // () => {
          //   axios
          //     .get(
          //       REVERSE_GEO(
          //         this.state.start_location_lat,
          //         this.state.start_location_lon
          //       )
          //     )
          //     .then(response => {
          //       let name = response.data.features[0].properties.display_name;
          //       let id = response.data.features[0].properties.place_id;
          //       this.setState({
          //         start_location: name,
          //         value: { key: id, label: name }
          //       });
          //     });
          // }
        );
      });
    });
  };

  entityPrintTag = (item_id, item) => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ENTITY_TAG_PRINT}?entity_id=${this.state.shop_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log("response from entity tag print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.entity_tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from entity tag print list fmt", error);
        });
    });
  };

  updateCustomEntityData = checked => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "entity",
        custom_fields: this.state.custom_field_data
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.shop_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            if (response.data.message === "Data successfully updated!") {
              this.getShop();
            }
            console.log(
              "response from custom field entity data update ",
              response
            );
          })
          .catch(error => {
            this.getShop();
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from custom field entity data update ", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const { custom_field_data,custom_form_field_post_object } = this.state;
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="View Place"
          onBack={() => this.props.history.goBack()}
        />
        <div>
          <Map
            center={[
              this.state.start_location_lat,
              this.state.start_location_lon
            ]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}

            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2"
              }}
              type={"roadmap"}
            />

            <Marker
              position={[
                this.state.start_location_lat,
                this.state.start_location_lon
              ]}
              draggable={false}
              icon={green}
            >
              <Popup>
                <span>From :{this.state.start_location}</span>
              </Popup>
            </Marker>
          </Map>
        </div>
        <Card
          style={{
            marginTop: 5,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <p >
            <Text style={{ fontWeight: "bold" }}>Place Name</Text>:{" "}
            {this.state.name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Type</Text>: {this.state.shop}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Address</Text>:{" "}
            {this.state.start_location}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>City</Text>: {this.state.city}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>State</Text>: {this.state.state}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Country</Text>:{" "}
            {this.state.country}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Sub Org</Text>:{" "}
            {this.state.sub_org}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Sub Org Type</Text>:{" "}
            {this.state.sub_org_type}
          </p>
          <Button
            onClick={this.entityPrintTag}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Print Place Tag
          </Button>
        </Card>
        <Card
          style={{
            marginTop: 10,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Select Custom Form</Text>
              <Select
                style={{ width: 200 }}
                value={this.state.custom_form_id}
                onChange={(value) =>
                  this.setState({ custom_form_id: value }, () =>
                    this.getCustomFormDataWithId()
                  )
                }
              >
                {this.state.customFormData.map((r) => {
                  return <Option key={r._id.$oid}>{r.title}</Option>;
                })}
              </Select>
            </div>
            {this.state.custom_form_fields.length > 0 ? (
              <Button
                style={{ marginLeft: 10, marginTop: 21 }}
                type="primary"
                onClick={() => this.setState({ show_custom_form_modal: true })}
              >
                View Form
              </Button>
            ) : null}
          </div>
        </Card>
        {this.state.custom_form_submitted_data.length > 0 &&
        this.props.userProfileData.custom_form.custom_form_data ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Title style={{ marginLeft: 1 }} level={4}>
              Custom Form Submitted Data
            </Title>
            <>
              <>
                <table
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddingTop: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <thead>
                    <tr>
                      {Object.keys(
                        this.state.custom_form_submitted_data[0].submitted_data
                      ).map((data, index) => (
                        <th
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          {data}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.custom_form_submitted_data.map((el, index) => (
                      <tr key={index}>
                        {Object.keys(el.submitted_data).map((el2, index) => (
                          <td
                            style={{
                              border: "1px solid grey",
                              borderCollapse: "collapse",
                              paddingTop: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                            key={index}
                          >
                            {Array.isArray(el.submitted_data[el2]) ? (
                              <div>
                                {el.submitted_data[el2].map((i, index) => {
                                  if (isValidURL(i)) {
                                    return (
                                      <img
                                        src={i}
                                        alt=""
                                        style={{
                                          margin: 10,
                                          height: 200,
                                          width: 200,
                                        }}
                                        key={index}
                                      />
                                    );
                                  } else {
                                    return (
                                      <Text
                                        className="task-view-text"
                                        style={{ fontSize: 15 }}
                                        key={index}
                                      >
                                        {`${i},`}
                                      </Text>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <Text
                                className="task-view-text"
                                style={{ fontSize: 15 }}
                                key={index}
                              >
                                {el.submitted_data[el2]}
                              </Text>
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          <Button
                            type="link"
                            onClick={() =>
                              this.setState({
                                document_id: el._id.$oid,
                                view_submitted_data_modal: true,
                              })
                            }
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            </>
          </Card>
        ) : null}
        {Object.keys(this.state.getCustomFieldData).length !== 0 &&
        this.props.userProfileData.place.place_detail
          .view_custom_field_data_related_to_place ? (
          <Card
            style={{
              marginTop: 5,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Custom Field Data"
            />
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 &&
        this.props.userProfileData.place.place_detail
          .update_custom_field_data_related_to_place ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 5,
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)"
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>{`${f.label} (Type : ${f._type}) : `}</Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={value => {
                            let obj = {
                              [f.key_name]: value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={e => {
                            let obj = {
                              [f.key_name]: e.target.value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={checkedValues => {
                            let obj = {
                              [f.key_name]: checkedValues
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={value => {
                            let obj = {
                              [f.key_name]: value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={evt => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then(token => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data"
                                  }
                                })
                                  .then(response => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map(res => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          )
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch(error => {
                                    swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value)
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText
                                  ]
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      )
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white"
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: []
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomEntityData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}
        <Modal
          title={this.state.custom_form_title}
          visible={this.state.show_custom_form_modal}
          onCancel={() => this.setState({ show_custom_form_modal: false })}
          footer={null}
        >
          <Form>
            {this.state.custom_form_fields.map((f, index) => (
              <Form.Item key={f.name}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>{`${f.name} : `}</Text>
                  {f.field_type === "text" ? (
                    <Input
                      type={f.input_type}
                      onChange={(evt) => {
                        if (f.input_type === "text") {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          let obj = {
                            [f.name]: Number(evt.target.value),
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form text field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : f.field_type === "dropdown" ? (
                    <Select
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from dropdown input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item) => {
                        return <Option key={item}>{item}</Option>;
                      })}
                    </Select>
                  ) : f.field_type === "checkbox" ? (
                    <Checkbox.Group
                      options={f.options}
                      onChange={(checkedValues) => {
                        let obj = {
                          [f.name]: checkedValues,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from checkbox input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "radio" ? (
                    <Radio.Group
                      onChange={(e) => {
                        let obj = {
                          [f.name]: e.target.value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item, index) => {
                        return (
                          <Radio key={index} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : f.field_type === "image_upload" ? (
                    <Input
                      type="file"
                      multiple={true}
                      name={"file[]"}
                      onChange={(evt) => {
                        console.log(evt.target.files);
                        const { files } = evt.target;
                        GET_ACCESS_TOKEN().then((token) => {
                          const img = new FormData();

                          for (let i = 0; i < files.length; i++) {
                            img.append(`imgFile`, files[i]);
                          }

                          console.log("in upload", img);

                          axios({
                            method: "put",
                            url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                            data: img,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                            .then((response) => {
                              swal.fire("Info", response.data.message, "info");
                              if (
                                response.data.message ===
                                "Image successfully uploaded!"
                              ) {
                                let temp = [];
                                response.data.data.map((res) => {
                                  temp.push(
                                    `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                  );
                                  return null;
                                });

                                let customObj = {
                                  [f.name]: temp,
                                };
                                this.setState(
                                  {
                                    custom_form_field_post_object:
                                      Object.assign(
                                        this.state
                                          .custom_form_field_post_object,
                                        customObj
                                      ),
                                  },
                                  () => {
                                    console.log(
                                      "from image input",
                                      this.state.custom_form_field_post_object
                                    );
                                  }
                                );
                              }
                              console.log("response from image api", response);
                            })
                            .catch((error) => {
                              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                              console.log("Error from upload", error);
                            });
                        });
                      }}
                    />
                  ) : f.field_type === "numeric_slider" ? (
                    <Slider
                      min={Number(f.min_value)}
                      max={Number(f.max_value)}
                      defaultValue={0}
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "conditional" ? (
                    <Input
                      disabled={
                        custom_form_field_post_object.hasOwnProperty(
                          f.condition_field
                        ) &&
                        custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                          ? false
                          : true
                      }
                      onChange={(evt) => {
                        if (
                          custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                        ) {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data for cnditional field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateCustomFormData}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={this.state.custom_form_title}
          visible={this.state.view_submitted_data_modal}
          onCancel={() => this.setState({ view_submitted_data_modal: false })}
          footer={null}
        >
          <Form>
            {this.state.custom_form_fields.map((f, index) => (
              <Form.Item key={f.name}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>{`${f.name} : `}</Text>
                  {f.field_type === "text" ? (
                    <Input
                      type={f.input_type}
                      onChange={(evt) => {
                        if (f.input_type === "text") {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          let obj = {
                            [f.name]: Number(evt.target.value),
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form text field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : f.field_type === "dropdown" ? (
                    <Select
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from dropdown input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item) => {
                        return <Option key={item}>{item}</Option>;
                      })}
                    </Select>
                  ) : f.field_type === "checkbox" ? (
                    <Checkbox.Group
                      options={f.options}
                      onChange={(checkedValues) => {
                        let obj = {
                          [f.name]: checkedValues,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from checkbox input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "radio" ? (
                    <Radio.Group
                      onChange={(e) => {
                        let obj = {
                          [f.name]: e.target.value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item, index) => {
                        return (
                          <Radio key={index} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : f.field_type === "image_upload" ? (
                    <Input
                      type="file"
                      multiple={true}
                      name={"file[]"}
                      onChange={(evt) => {
                        console.log(evt.target.files);
                        const { files } = evt.target;
                        GET_ACCESS_TOKEN().then((token) => {
                          const img = new FormData();

                          for (let i = 0; i < files.length; i++) {
                            img.append(`imgFile`, files[i]);
                          }

                          console.log("in upload", img);

                          axios({
                            method: "put",
                            url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                            data: img,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                            .then((response) => {
                              swal.fire("Info", response.data.message, "info");
                              if (
                                response.data.message ===
                                "Image successfully uploaded!"
                              ) {
                                let temp = [];
                                response.data.data.map((res) => {
                                  temp.push(
                                    `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                  );
                                  return null;
                                });

                                let customObj = {
                                  [f.name]: temp,
                                };
                                this.setState(
                                  {
                                    custom_form_field_post_object:
                                      Object.assign(
                                        this.state
                                          .custom_form_field_post_object,
                                        customObj
                                      ),
                                  },
                                  () => {
                                    console.log(
                                      "from image input",
                                      this.state.custom_form_field_post_object
                                    );
                                  }
                                );
                              }
                              console.log("response from image api", response);
                            })
                            .catch((error) => {
                              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                              console.log("Error from upload", error);
                            });
                        });
                      }}
                    />
                  ) : f.field_type === "numeric_slider" ? (
                    <Slider
                      min={Number(f.min_value)}
                      max={Number(f.max_value)}
                      defaultValue={0}
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "conditional" ? (
                    <Input
                      disabled={
                        custom_form_field_post_object.hasOwnProperty(
                          f.condition_field
                        ) &&
                        custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                          ? false
                          : true
                      }
                      onChange={(evt) => {
                        if (
                          custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                        ) {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data for cnditional field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.editCustomFormSubmittedData}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(ViewShop);
