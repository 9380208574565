import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Switch,
  Divider
} from "antd";
// import {
//   FacebookMessengerShareButton,
//   ViberShareButton,
//   WhatsappShareButton
// } from "react-share";
// import { FacebookMessengerIcon, ViberIcon, WhatsappIcon } from "react-share";
import axios from "axios";
import swal from "sweetalert2";
import { SEND_INVITE, CREATE_ORGANIZATION } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import { countryCode } from "../countryCode";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class SendInvitation extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    org: "",
    org_id: "",
    send_email: false,
    is_operator: false,
    data: [],
    filtered: [],
    msg: "",
    show_social: false,
    loading: false,
    get_connected_orgs: true,
    countryCode: [],
    code: "+880"
  };
  componentDidMount() {
    // if(GET_USER_LOCAL().org_admin){
    //   this.setState({get_connected_orgs:true});
    // }
    this.setState({
      countryCode: countryCode
    });
    this.getOrganizationList();
  }

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 10000;
      const url = `${CREATE_ORGANIZATION}?limit=${limit}&get_connected_orgs=${this.state.get_connected_orgs}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from organization list", response);
          this.setState({ data: response.data.data });
        })
        .catch(error => {
          console.log("error from org list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setEmail = evt => {
    evt.preventDefault();
    this.setState({ email: evt.target.value }, () => {
      console.log(this.state.email);
    });
  };
  setCode = value => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };
  setMobile = evt => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value }, () => {
      console.log(this.state.mobile);
    });
  };

  setOrg = value => {
    this.setState({ org: value.label, org_id: value.key }, () => {
      console.log(this.state.org_id);
    });
  };

  handleSearch = value => {
    const result = this.state.data.filter(org => {
      return org.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  sendEmail = checked => {
    this.setState({ send_email: checked }, () => {
      console.log(this.state.send_email);
    });
  };

  setOperator = checked => {
    this.setState({ is_operator: checked }, () => {
      console.log(this.state.is_operator);
    });
  };

  sendInvite = () => {
    if (this.state.name === "" || this.state.organization === "") {
      swal.fire("Info", "Please enter all informations", "info");
    } else if (this.state.email === "" && this.state.mobile === "") {
      swal.fire("Info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        email: this.state.email,
        mobile:
          this.state.mobile !== "" ? this.state.code + this.state.mobile : "",
        org: this.state.org_id,
        send_email: this.state.send_email,
        is_operator: this.state.is_operator
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${SEND_INVITE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from send inviatation", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from send inviatation", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const UserList = this.state.data.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Invite User"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Form.Item>
            <Text>Search Organization</Text>
            <Select
              showSearch={true}
              labelInValue
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              value={{ key: this.state.org_id, label: this.state.org }}
              onChange={this.setOrg}
              placeholder="Select Organization"
            >
              {this.state.filtered.length === 0 ? UserList : FliteredList}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Email</Text>
            <Input
              placeholder="Email"
              type="text"
              value={this.state.email}
              onChange={this.setEmail}
            />
          </Form.Item>
          <Divider>Or</Divider>
          <Form.Item>
            <Text>Mobile</Text>
            <Select
              value={this.state.code}
              onChange={this.setCode}
              placeholder="Select Coode"
            >
              {this.state.countryCode.map(r => {
                return (
                  <Option key={r.dial_code}>
                    {r.name} {r.dial_code}
                  </Option>
                );
              })}
            </Select>
            <Input
              placeholder="Example-1711123456"
              type="text"
              value={this.state.mobile}
              onChange={this.setMobile}
            />
          </Form.Item>

          <Form.Item>
            <Text style={{ marginRight: 5 }}>Send Email</Text>
            <Switch onChange={this.sendEmail} />
          </Form.Item>
          {/* <Form.Item>
            <Text style={{ marginRight: 18 }}>Operator</Text>
            <Switch onChange={this.setOperator} />
          </Form.Item> */}
          {/* {this.state.show_social === true ? (
            <Form.Item>
              <FacebookMessengerShareButton
                url="http://web.conveyance.app"
                quote={this.state.msg}
                children={this.state.msg}
              >
                <FacebookMessengerIcon round />
              </FacebookMessengerShareButton>
              <WhatsappShareButton
                url={this.state.msg}
                quote={this.state.msg}
                children={this.state.msg}
              >
                <WhatsappIcon round />
              </WhatsappShareButton>
              <ViberShareButton
                url={this.state.msg}
                quote={this.state.msg}
                children={this.state.msg}
              >
                <ViberIcon round />
              </ViberShareButton>
            </Form.Item>
          ) : null} */}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.sendInvite}
            >
              Invite User
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
