import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Pagination,
  Select,
  Typography,
  Input,
  Button,
  Modal,
  Form,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";

import {
  CREATE_WALLET,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
  ADD_BALANCE_TO_WALLET,
  GET_ORDERS_BY_ENTITY,
  ADJUST_WALLET_WITH_ORDER,
  WALLET_PAYMENT_METHOD_CHOICES,
  REFUND_MONEY_TO_WALLET,
  ADJUST_WALLET_WITH_BALANCE,
  SUB_ORG_WISE_DUE_COLLECTION_REPORT,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  CHECK_BOOL,
  //   GET_USER_LOCAL,
} from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class WalletList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    entity_name: "",
    wallet_id: "",
    amount_to_add: "",
    auto_adjust_to_dues: false,
    show_add_balance_modal: false,
    show_adjust_order_modal: false,
    entity_id: "",
    orders_by_entity: [],
    selected_order_id: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_wallet_search: "",
    sub_org_name: "",
    sub_org_parent_name: "",
    wallet_payment_methods: [],
    add_method: "cash",
    start_time: Date.now(),
    start_time_view: moment(),
    end_time: Date.now(),
    end_time_view: moment(),
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();
    this.getWalletPaymentMethodChoices();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_wallet_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getWalletList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getWalletPaymentMethodChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_PAYMENT_METHOD_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from wallet payment method choices", response);

          this.setState({
            wallet_payment_methods: response.data.payment_method_choices,
          });
        })
        .catch((error) => {
          console.log("error from wallet payment method choices", error);
        });
    });
  };

  getOrdersByEntity = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_ORDERS_BY_ENTITY}${this.state.entity_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order by entity list", response);

          this.setState({
            orders_by_entity: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from order by entity api", error);
        });
    });
  };

  getWalletList = debounce(() => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_WALLET}?page=${this.state.page}&limit=${this.state.limit}&sub_org=${this.state.sub_org_id_wallet_search}&entity_name=${this.state.entity_name}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from wallet list", response);

          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from wallet list api", error);
        });
    });
  }, 500);

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_wallet_search: value },
      () => {
        this.getWalletList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_wallet_search: value },
      () => {
        this.getWalletList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getWalletList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getWalletList();
    });
  };

  adjustWalletWithOrder = () => {
    const data = {
      order_id: this.state.selected_order_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ADJUST_WALLET_WITH_ORDER}${this.state.selected_order_id}?access_token=${token}`;

      fetch(url, {
        method: "put",
        body: JSON.stringify(data),
      })
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(
            blob,
            `adjust_wallet_with_order_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
          this.setState({
            show_adjust_order_modal: false,
            entity_id: "",
            orders_by_entity: [],
            selected_order_id: "",
          });
          this.getWalletList();
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from adjust wallet with order", error);
        });
    });
  };
  refundMoneyToWallet = (wallet_id) => {
    const data = {
      wallet_id: wallet_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${REFUND_MONEY_TO_WALLET}${wallet_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from refund money to wallet", response);
          this.getWalletList();
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from refund money to wallet", error);
        });
    });
  };

  adjustWalletWithDues = (wallet_id) => {
    const data = {
      wallet_id: wallet_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ADJUST_WALLET_WITH_BALANCE}${wallet_id}?access_token=${token}`;

      fetch(url, {
        method: "put",
        body: JSON.stringify(data),
      })
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(
            blob,
            `adjust_wallet_with_balance_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
          this.getWalletList();
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from adjust wallet with dues", error);
        });
    });
  };

  downloadDueReport = () => {
    if (this.state.sub_org_id_wallet_search !== "") {
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${SUB_ORG_WISE_DUE_COLLECTION_REPORT}${this.state.sub_org_id_wallet_search}?fmt=xls&end_time=${this.state.end_time}&start_time=${this.state.start_time}&access_token=${token}`;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            console.log(
              "response from wallet combined history excel report",
              blob
            );
            FileSaver.saveAs(
              blob,
              `wallet_combined_history_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
          })
          .catch((error) => {
            console.log(
              "error from wallet combined history excel report",
              error
            );
          });
      });
    } else {
      swal.fire("Info", "Please Select a Sub Organization", "info");
    }
  };

  updateWalletBalance = () => {
    if (
      this.state.amount_to_add === "" ||
      Number(this.state.amount_to_add) <= 0
    ) {
      swal.fire("Info", "Please add a positive amount for wallet", "info");
    } else {
      const data = {
        wallet_id: this.state.wallet_id,
        amount_to_add: Number(this.state.amount_to_add),
        auto_adjust_to_dues: this.state.auto_adjust_to_dues,
        add_method: this.state.add_method,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${ADD_BALANCE_TO_WALLET}${this.state.wallet_id}?access_token=${token}`;

        fetch(url, {
          method: "put",
          body: JSON.stringify(data),
        })
          .then((res) => res.blob())
          .then((blob) => {
            console.log("response from add money to wallet", blob);
            if (this.state.auto_adjust_to_dues) {
              FileSaver.saveAs(
                blob,
                `wallet_add_balance_invoice_${moment().format(
                  "DD-MMMM-YYYY_hh:mm A"
                )}.pdf`
              );
            }

            this.setState({
              wallet_id: "",
              amount_to_add: "",
              auto_adjust_to_dues: false,
              show_add_balance_modal: false,
            });
            this.getWalletList();
          })
          .catch((error) => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from add money to wallet", error);
          });
      });
    }
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Wallets"
        />
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card>
            <Title level={4}>Filter Wallet With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div style={{ margin: 5,display:"flex",flexDirection:"column" }}>
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ margin: 5,display:"flex",flexDirection:"column" }}>
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}
        <Card style={{ marginTop: 3 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  style={{ marginTop: 14 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14
                    },
                  }}
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      start_time: moment(value).format("x"),
                      start_time_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  style={{ marginTop: 14 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14
                    },
                  }}
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      end_time: moment(value).add(59, "seconds").format("x"),
                      end_time_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <Button
              style={{
                backgroundColor: "#1A3337",
                color: "white",
                marginTop: 20,
              }}
              onClick={() => this.downloadDueReport()}
            >
              Download Due Collection Report
            </Button>
          </div>
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
        </div>

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <Text ellipsis={true}>Place Name</Text>

                <Input
                  style={{ minWidth: 90 }}
                  value={this.state.entity_name}
                  onChange={(evt) => {
                    this.setState(
                      { entity_name: evt.target.value, page: 0 },
                      () => {
                        this.getWalletList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="entity_name"
            key="entity_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Contact Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="contact_name"
            key="contact_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Contact Number</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="contact_number"
            key="contact_number"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Parent Sub Org</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="parent_sub_org"
            render={(text, record) => (
              <span>{record.sub_org?.parent_sub_org_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="sub_org"
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org type</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="sub_org_type"
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Bill Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="total_bill_amount"
            render={(text, record) => (
              <span>{record.total_bill_amount ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Due Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="total_due_amount"
            render={(text, record) => (
              <span>{record.total_due_amount ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Received Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="total_received_amount"
            render={(text, record) => (
              <span>{record.total_received_amount ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Refundable Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="refundable_amount"
            render={(text, record) => (
              <span>{record.refundable_amount ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Refunded Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="total_refunded_amount"
            render={(text, record) => (
              <span>{record.total_refunded_amount ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Remaining Refundable Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="remaining_refundable_amount"
            render={(text, record) => (
              <span>{record.remaining_refundable_amount ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Wallet Balance</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="wallet_balance"
            render={(text, record) => (
              <span>{record.wallet_balance ?? ""}</span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Last Received Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="last_received_date"
            render={(text, record) => (
              <span>
                {record.last_received_date
                  ? timeConverter(record.last_received_date.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.order_and_delivery.wallet
                  .wallet_history ? (
                  <Link
                    to={{
                      pathname: "/walletHistory",
                      state: {
                        wallet_id: record._id.$oid,
                      },
                    }}
                  >
                    <Button style={{ color: "green" }} type="link">
                      View History
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.order_and_delivery.wallet
                  .add_money_to_wallet ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.setState({
                        show_add_balance_modal: true,
                        wallet_id: record._id.$oid,
                      });
                    }}
                  >
                    Add Balance
                  </Button>
                ) : null}

                {record.total_due_amount > 0 &&
                record.wallet_balance > 0 &&
                this.props.userProfileData.order_and_delivery.wallet
                  .adjust_order_with_wallet_balance ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.setState(
                        {
                          show_adjust_order_modal: true,
                          entity_id: record.entity_id,
                        },
                        () => this.getOrdersByEntity()
                      );
                    }}
                  >
                    Adjust
                  </Button>
                ) : null}
                {record.total_due_amount > 0 && record.wallet_balance > 0 ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.adjustWalletWithDues(record._id.$oid);
                    }}
                  >
                    Adjust With Balance
                  </Button>
                ) : null}
                {record.refundable_amount > 0 ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      this.refundMoneyToWallet(record._id.$oid);
                    }}
                  >
                    Add Refund To Wallet
                  </Button>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          title="Add Balance to Wallet"
          visible={this.state.show_add_balance_modal}
          onOk={() => {
            this.setState({ show_add_balance_modal: false });
            this.updateWalletBalance();
          }}
          onCancel={() => {
            this.setState({ show_add_balance_modal: false });
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <Form>
            <Form.Item>
              <Text>Amount</Text>
              <Input
                placeholder="Amount"
                type="number"
                value={this.state.amount_to_add}
                onChange={(evt) =>
                  this.setState({ amount_to_add: evt.target.value })
                }
              />
            </Form.Item>
            <Form.Item>
              <Text>Payment Method Type</Text>
              <Select
                value={this.state.add_method}
                onChange={(value) => this.setState({ add_method: value })}
              >
                {this.state.wallet_payment_methods.map((r) => (
                  <Option key={r}>{r}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Auto Adjust Dues</Text>
              <Select
                disabled={
                  this.props.userProfileData.order_and_delivery.wallet
                    .auto_adjust_order_with_wallet_balance
                    ? false
                    : true
                }
                value={this.state.auto_adjust_to_dues.toString()}
                onChange={(value) =>
                  this.setState({ auto_adjust_to_dues: CHECK_BOOL(value) })
                }
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Adjust"
          visible={this.state.show_adjust_order_modal}
          onOk={() => {
            this.setState({ show_adjust_order_modal: false });
            this.adjustWalletWithOrder();
          }}
          onCancel={() => {
            this.setState({
              show_adjust_order_modal: false,
              entity_id: "",
              orders_by_entity: [],
              selected_order_id: "",
            });
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <Form>
            <Form.Item>
              <Text>Select Order To Adjust</Text>
              <Select
                value={this.state.selected_order_id}
                disabled={this.state.orders_by_entity.length > 0 ? false : true}
                onChange={(value) =>
                  this.setState({ selected_order_id: value })
                }
              >
                {this.state.orders_by_entity.map((order) => (
                  <Option key={order._id.$oid}>{`${order.name}, Due-${
                    order.due_amount
                  }, Created On-${timeConverter(
                    order.created_on.$date
                  )}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(WalletList);
