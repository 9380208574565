import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_SUB_ORG_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

class EditSubOrgType extends Component {
  state = {
    name: "",
    seq_no: 1,
    create_with_same_seq_no: false,
    sub_org_type_id: "",
  };

  componentDidMount() {
    this.setState({
      sub_org_type_id: this.props.location.state.sub_org_type_id,
    });
    this.getSubOrgTypeDetails();
  }

  getSubOrgTypeDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let sub_org_type_id = this.props.location.state.sub_org_type_id;
      let url = `${EDIT_SUB_ORG_TYPE}${sub_org_type_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit suborg", response);
        this.setState({
          name: response.data?.data?.name ?? "",
          seq_no: response.data?.data?.seq_no ?? 1,
          create_with_same_seq_no:
            response.data?.data?.create_with_same_seq_no ?? false,
        });
      });
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setSequenceNumber = (evt) => {
    evt.preventDefault();
    this.setState({ seq_no: evt.target.value }, () => {
      console.log(this.state.seq_no);
    });
  };

  setSameSequenceNumber = (value) => {
    this.setState({ create_with_same_seq_no: CHECK_BOOL(value) }, () => {
      console.log(this.state.create_with_same_seq_no);
    });
  };

  editSubOrgType = () => {
    const data = {
      name: this.state.name,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_SUB_ORG_TYPE}${this.state.sub_org_type_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          this.getSubOrgTypeDetails();
          console.log("response from edit sub type org", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit sub type org", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Edit Sub Organization Type"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Sequence Number</Text>
            <Input
              placeholder="Service Details"
              type="number"
              disabled
              value={this.state.seq_no}
              onChange={this.setSequenceNumber}
            />
          </Form.Item>

          <Form.Item>
            <Text>Create With Same Sequence Number</Text>
            <Select
              disabled
              value={this.state.create_with_same_seq_no?.toString()}
              onChange={this.setSameSequenceNumber}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          {this.props.userProfileData.sub_org.sub_org_type
            .sub_org_type_update ? (
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.editSubOrgType}
              >
                Update
              </Button>
            </Form.Item>
          ) : null}
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(EditSubOrgType);