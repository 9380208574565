import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { ORGANIZATION_SETTINGS_LIST,GET_EMAIL_SETTINGS } from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EmailSettings extends Component {
  state = {
    org_setting_id: "",
    order_delivery_via_task: false,
  };

  componentDidMount() {
    this.getOrganizationSettingsList();
  }

  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? ""
            },
            () => {
              this.getEmailSettings();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch(error => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getEmailSettings = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${GET_EMAIL_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            order_delivery_via_task: response.data?.data?.order_delivery_via_task??false
          });
          console.log("response from email settings", response);
        })
        .catch(error => {
          console.log("error from email settings api", error);
        });
    });
  };


  setOrderDeliveryViaTask = value => {
    this.setState({ order_delivery_via_task: CHECK_BOOL(value) }, () => {
      console.log(this.state.order_delivery_via_task);
    });
  };



  updateEmailSettings = () => {
    const data = {
        order_delivery_via_task:this.state.order_delivery_via_task
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${GET_EMAIL_SETTINGS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          this.getOrganizationSettingsList();
          console.log("response from update email Settings", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from update email Settings", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Email Settings"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >


            <Form.Item>
              <Text>Order Delivery Via Task</Text>
              <Select
                value={this.state.order_delivery_via_task.toString()}
                onChange={this.setOrderDeliveryViaTask}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateEmailSettings}
              >
                Update Email Settings
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    );
  }
}
