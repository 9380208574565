import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_PROCEDURE,
  CREATE_ROLE,
  PROCEDURE_CHOICES
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditProcedure extends Component {
  state = {
    name: "",
    recommnender: "",
    recommnender_key: "",
    authorizer: "",
    authorizer_key: "",
    approver: "",
    approver_key: "",
    loading: false,
    data: [],
    procedure_id: "",
    procedure_choices: []
  };

  componentDidMount() {
    this.getDriverList();
    this.getProcedure();
    this.getProcedureChoices();
    this.setState({ procedure_id: this.props.location.state.procedure_id });
  }

  getProcedure = () => {
    GET_ACCESS_TOKEN().then(token => {
      let procedure_id = this.props.location.state.procedure_id;
      let url = `${EDIT_PROCEDURE}${procedure_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit procedure", response);
        this.setState({
          name: response.data.data.name,
          recommnender_key: response.data.data.recommender,
          authorizer_key: response.data.data.authorizer,
          approver_key: response.data.data.approver
        });
      });
    });
  };

  getProcedureChoices = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${PROCEDURE_CHOICES}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from procedure choices api", response);
          this.setState({ procedure_choices: response.data.procedure_choices });
        })
        .catch(error => {
          console.log("error from procedure choices api", error);
        });
    });
  };

  getDriverList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${CREATE_ROLE}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  };

  setName = value => {
    this.setState({ name: value }, () => {
      console.log(this.state.name);
    });
  };

  setRecommender = value => {
    this.setState(
      { recommnender: value.label, recommnender_key: value.key },
      () => {
        console.log(this.state.recommnender);
      }
    );
  };
  setAuthorizer = value => {
    this.setState(
      { authorizer: value.label, authorizer_key: value.key },
      () => {
        console.log(this.state.authorizer);
      }
    );
  };
  setApprover = value => {
    this.setState({ approver: value.label, approver_key: value.key }, () => {
      console.log(this.state.approver);
    });
  };

  editProcedure = () => {
    this.setState({ loading: true });
    let data = {
      name: this.state.name,
      recommender: this.state.recommnender_key,
      authorizer: this.state.authorizer_key,
      approver: this.state.approver_key
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_PROCEDURE}${this.state.procedure_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit procedure", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit procedure", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Update Procedure"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Select
              value={this.state.name.toString()}
              disabled
              onChange={this.setName}
              placeholder="Select Procedure"
            >
              {this.state.procedure_choices.map(name => {
                return <Option key={name}>{name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Recommender</Text>
            <Select
              labelInValue
              value={{
                key: this.state.recommnender_key,
                label: this.state.recommnender
              }}
              onChange={this.setRecommender}
              placeholder="Select Recommender"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{`${r.name} (Org Status - ${r.organization_status})`}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Authorizer</Text>
            <Select
              labelInValue
              value={{
                key: this.state.authorizer_key,
                label: this.state.authorizer
              }}
              onChange={this.setAuthorizer}
              placeholder="Select Authorizer"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{`${r.name} (Org Status - ${r.organization_status})`}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Approver</Text>
            <Select
              labelInValue
              value={{
                key: this.state.approver_key,
                label: this.state.approver
              }}
              onChange={this.setApprover}
              placeholder="Select Approver"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{`${r.name} (Org Status - ${r.organization_status})`}</Option>;
              })}
            </Select>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.editProcedure}
              >
                Update Procedure
              </Button>
            </Form.Item>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
