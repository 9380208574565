import React, { Component } from "react";
import {
  Table,
  Divider,
  Card,
  Button,
  Tooltip,
  Select,
  Typography,
  Pagination,
  Icon,
  Input,
} from "antd";
import { connect } from "react-redux";
import { savePlacesListParams } from "../../redux/actions/placesListPramsAction";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver";

import {
  CREATE_ENTITY,
  EDIT_ENTITY,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text, Title } = Typography;

class ShopList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    category: "place_report_without_custom_fields",
    timezone_name: "",
    timezone_choice: "org_timezone",
    loading: false,
    limit: 10,
    sort_by: "name",
    sort_type: "asc",
    is_inventory: "",
    name: "",
    address: "",
    city: "",
    state: "",
    contact_name: "",
    contact_number: "",
    contact_email: "",
    sub_org_name: "",
    sub_org_type_name: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_place_search: "",
    created_by: "",
    place_type: "",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
  };
  componentDidMount() {
    this.props.getUserData();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("redux", this.props.placesListParams);
    if (Object.keys(this.props.placesListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          name: this.props?.placesListParams?.name ?? "",
          sub_org_name: this.props?.placesListParams?.sub_org_name ?? "",
          sub_org_id_place_search:
            this.props?.placesListParams?.sub_org_id_place_search ?? "",
          sub_org_type_name:
            this.props?.placesListParams?.sub_org_type_name ?? "",
          address: this.props?.placesListParams?.address ?? "",
          city: this.props?.placesListParams?.city ?? "",
          state: this.props?.placesListParams?.state ?? "",
          created_by: this.props?.placesListParams?.created_by ?? "",
          place_type: this.props?.placesListParams?.place_type ?? "",
          contact_name: this.props?.placesListParams?.contact_name ?? "",
          contact_number: this.props?.placesListParams?.contact_number ?? "",
          contact_email: this.props?.placesListParams?.contact_email ?? "",
          is_inventory: this.props?.placesListParams?.is_inventory ?? "",
          limit: this.props?.placesListParams?.limit ?? 10,
          page: this.props?.placesListParams?.page ?? 0,
          sort_by: this.props?.placesListParams?.sort_by ?? "name",
          sort_type: this.props?.placesListParams?.sort_type ?? "asc",
          start_time: this.props?.placesListParams?.start_time ?? "",
          start_time_view:
            this.props?.placesListParams?.start_time_view ?? moment(),
          end_time: this.props?.placesListParams?.end_time ?? "",
          end_time_view:
            this.props?.placesListParams?.end_time_view ?? moment(),
        },
        () => {
          this.getCurrentUserSubOrgs();
        }
      );
    } else {
      this.getCurrentUserSubOrgs();
    }

     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_place_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getShopList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  
  handleSuborgSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState({
            current_user_sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  }, 500);

  setTimezonetype = (value) => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  setReporttype = (value) => {
    this.setState({ category: value }, () => {
      console.log(this.state.category);
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_place_search: value },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_place_search: value },
      () => {
        this.getShopList();
      }
    );
  };

  getShopList = debounce(() => {
    let reduxdata = {
      page: this.state.page,
      limit: this.state.limit,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      is_inventory: this.state.is_inventory,
      name: this.state.name,
      sub_org_type_name: this.state.sub_org_type_name,
      sub_org_name: this.state.sub_org_name,
      sub_org_id_place_search: this.state.sub_org_id_place_search,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      created_by: this.state.created_by,
      place_type: this.state.place_type,
      contact_name: this.state.contact_name,
      contact_number: this.state.contact_number,
      contact_email: this.state.contact_email,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
    };
    this.props.savePlacesListParams(reduxdata);
    console.log("reduxdata going in", reduxdata);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?search_end_time=${this.state.end_time}&search_start_time=${this.state.start_time}&place_type=${this.state.place_type}&created_by=${this.state.created_by}&sub_org=${this.state.sub_org_id_place_search}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_name=${this.state.sub_org_name}&is_inventory=${this.state.is_inventory}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&contact_email=${this.state.contact_email}&contact_number=${this.state.contact_number}&contact_name=${this.state.contact_name}&state=${this.state.state}&city=${this.state.city}&address=${this.state.address}&name=${this.state.name}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from shop list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        page: 0,
        limit: 10,
        sort_by: "name",
        sort_type: "asc",
        is_inventory: "",
        name: "",
        sub_org_name: "",
        sub_org_type_name: "",
        // sub_org_id_place_search: "",
        // current_user_sub_org_id: "",
        // first_child_sub_org_id: "",
        // first_child_sub_orgs: [],
        address: "",
        city: "",
        state: "",
        created_by: "",
        place_type: "",
        contact_name: "",
        contact_number: "",
        contact_email: "",
        start_time: "",
        start_time_view: moment(),
        end_time: "",
        end_time_view: moment(),
        current_user_sub_org_id: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.current_user_sub_org_id,
        sub_org_id_place_search: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.sub_org_id_place_search,
      },
      () => {
        this.getShopList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getShopList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getShopList();
    });
  };

  handleSaveFile = (checked) => {
    console.log(this.state.saveFile);

    GET_ACCESS_TOKEN().then((token) => {
      const url =
        this.state.timezone_choice === "org_timezone"
          ? `${CREATE_ENTITY}?search_end_time=${this.state.end_time}&search_start_time=${this.state.start_time}&place_type=${this.state.place_type}&created_by=${this.state.created_by}&sub_org=${this.state.sub_org_id_place_search}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_name=${this.state.sub_org_name}&is_inventory=${this.state.is_inventory}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&contact_email=${this.state.contact_email}&contact_number=${this.state.contact_number}&contact_name=${this.state.contact_name}&state=${this.state.state}&city=${this.state.city}&address=${this.state.address}&name=${this.state.name}&timezone_choice=${this.state.timezone_choice}&category=${this.state.category}&fmt=xls&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`
          : `${CREATE_ENTITY}?search_end_time=${this.state.end_time}&search_start_time=${this.state.start_time}&place_type=${this.state.place_type}&created_by=${this.state.created_by}&sub_org=${this.state.sub_org_id_place_search}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_name=${this.state.sub_org_name}&is_inventory=${this.state.is_inventory}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&contact_email=${this.state.contact_email}&contact_number=${this.state.contact_number}&contact_name=${this.state.contact_name}&state=${this.state.state}&city=${this.state.city}&address=${this.state.address}&name=${this.state.name}&timezone_choice=${this.state.timezone_choice}&category=${this.state.category}&timezone_name=${this.state.timezone_name}&fmt=xls&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from place list fmt", blob);

          FileSaver.saveAs(
            blob,
            `places_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from place list fmt", error);
        });
    });
  };

  deleteShop = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ENTITY}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.place.place_create ? (
          <Link to="/createEntity">
            <Tooltip placement="top" title="Create Place">
            <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text> Timezone : {""}</Text>
              <Select
                value={this.state.timezone_choice}
                onChange={this.setTimezonetype}
                placeholder="View as"
              >
                <Option key="user_timezone">User</Option>
                <Option key="org_timezone">Organization</Option>
              </Select>
            </div>
            <div style={{ marginLeft: 5, display: "flex", flexDirection: "column" }}>
              <Text>Report Type : {""}</Text>
              <Select
                style={{ minWidth: 175 }}
                value={this.state.category}
                onChange={this.setReporttype}
                placeholder="View as"
              >
                <Option key="place_report_without_custom_fields">
                  Without Custom Fields
                </Option>
                <Option key="place_report_with_custom_fields">
                  With Custom Fields
                </Option>
              </Select>
            </div>
            <div style={{ marginLeft: 5,marginTop:21.5, textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#1A3337", color: "white" }}
                onClick={this.handleSaveFile}
              >
                Download Report
              </Button>
            </div>
          </div>
        </Card>
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card style={{ marginTop: 5 }}>
            <Title level={4}>Filter Place With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                  style={{ marginTop: 24 }}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 14,
                          // width: 130,
                        },
                      }}
                    inputVariant="outlined"
                    value={this.state.start_time_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          start_time: moment(value).format("x"),
                          start_time_view: value,
                          page: 0,
                        },
                        () => {
                          this.getShopList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                  style={{ marginTop: 24 }}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 14,
                          // width: 130,
                        },
                      }}
                    inputVariant="outlined"
                    value={this.state.end_time_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          end_time: moment(value).format("x"),
                          end_time_view: value,
                          page: 0,
                        },
                        () => {
                          this.getShopList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div
                style={{ marginTop: 10, display: "flex", flexDirection: "column" }}
              >
                <Text> Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSuborgSearch}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{ marginTop: 10,marginLeft:5, display: "flex", flexDirection: "column" }}
              >
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}
       
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created By</Text>
                  <Input
                    value={this.state.created_by}
                    onChange={(evt) => {
                      this.setState(
                        { created_by: evt.target.value, page: 0 },
                        () => {
                          this.getShopList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            dataIndex="created_by_name"
            key="created_by_name"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Place Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getShopList();
                    });
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Place Type</Text>
                  <Input
                    value={this.state.place_type}
                    onChange={(evt) => {
                      this.setState(
                        { place_type: evt.target.value, page: 0 },
                        () => {
                          this.getShopList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            dataIndex="entity_type"
            key="entity_type"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Inventory</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "is_inventory", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "is_inventory", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.is_inventory}
                  onChange={(evt) => {
                    this.setState(
                      { is_inventory: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            key="is_inventory"
            render={(text, record) => (
              <span>{record?.is_inventory?.toString()}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Purchase Order</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="enable_purchase_order"
            render={(text, record) => (
              <span>{record?.enable_purchase_order?.toString()}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Address</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "address", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "address", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.address}
                  onChange={(evt) => {
                    this.setState(
                      { address: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="address"
            key="address"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>City</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "city", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "city", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.city}
                  onChange={(evt) => {
                    this.setState({ city: evt.target.value, page: 0 }, () => {
                      this.getShopList();
                    });
                  }}
                />
              </>
            }
            dataIndex="city"
            key="city"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>State</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "state", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "state", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.state}
                  onChange={(evt) => {
                    this.setState({ state: evt.target.value, page: 0 }, () => {
                      this.getShopList();
                    });
                  }}
                />
              </>
            }
            dataIndex="state"
            key="state"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Country</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="country"
            key="country"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Sub Org Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "sub_org_name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "sub_org_name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.sub_org_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_name: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            key="sub_org_name"
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Sub Org Type</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "sub_org_type_name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "sub_org_type_name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.sub_org_type_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_type_name: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            key="sub_org_type_name"
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          {/* <Column title="Post Code" dataIndex="post_code" key="post_code" /> */}
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Contact Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.contact_name}
                  onChange={(evt) => {
                    this.setState(
                      { contact_name: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Contact Name"
            render={(text, record) => <span>{record.contact?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Contact Number</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_number", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_number", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.contact_number}
                  onChange={(evt) => {
                    this.setState(
                      { contact_number: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Contact Number"
            render={(text, record) => (
              <span>{record.contact?.mobile ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Conact Email</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_email", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_email", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.contact_email}
                  onChange={(evt) => {
                    this.setState(
                      { contact_email: evt.target.value, page: 0 },
                      () => {
                        this.getShopList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Contact Email"
            render={(text, record) => (
              <span>{record.contact?.email ?? ""}</span>
            )}
          />
          {/* <Column title="Total Received Amount" key="Total Received Amount" />
          <Column title="Total Due Amount" key="Total Due Amount" />
          <Column title="Wallet Balance" key="Wallet Balance" /> */}
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Created On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.place.add_place_reminder ? (
                  <Link
                    to={{
                      pathname: "/createReminder",
                      state: {
                        entity_id: record._id.$oid,
                        entity_name: record.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Add Reminder
                    </Button>
                  </Link>
                ) : null}

                {/* <Button type="link" style={{ color: "#04293A" }}>
                  Add Amount to Wallet
                </Button> */}
                {this.props.userProfileData.place.place_detail
                  .view_place_detail ? (
                  <Link
                    to={{
                      pathname: "/viewEntity",
                      state: { shop_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}

                <Divider type="vertical" />
                {this.props.userProfileData.place.place_update ? (
                  <Link
                    to={{
                      pathname: "/editEntity",
                      state: { shop_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}

                <Divider type="vertical" />
                {this.props.userProfileData.place.place_delete ? (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteShop(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  placesListParams: state.placesListParams.placesListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { savePlacesListParams, getUserData })(
  ShopList
);
