export const SAVE_START_TASK="SAVE_START_TASK";
export const SAVE_TASK_LIST_PARAMS="SAVE_TASK_LIST_PARAMS";
export const SAVE_ORDER_LIST_PARAMS="SAVE_ORDER_LIST_PARAMS";
export const SAVE_PURCHASE_ORDER_LIST_PARAMS="SAVE_PURCHASE_ORDER_LIST_PARAMS";
export const SAVE_BILL_LIST_PARAMS="SAVE_BILL_LIST_PARAMS";
export const SAVE_ASSET_LIST_PARAMS="SAVE_ASSET_LIST_PARAMS";
export const SAVE_PLACES_LIST_PARAMS="SAVE_PLACES_LIST_PARAMS";
export const SAVE_REQUISITION_LIST_PARAMS="SAVE_REQUISITION_LIST_PARAMS";
export const SAVE_USER_LIST_PARAMS="SAVE_USER_LIST_PARAMS";
export const SAVE_ATTENDANCE_LIST_PARAMS="SAVE_ATTENDANCE_LIST_PARAMS";
export const SAVE_SUBORG_LIST_PARAMS="SAVE_SUBORG_LIST_PARAMS";
export const SAVE_USER_PROFILE="SAVE_USER_PROFILE";
export const API_ERROR="API_ERROR";
export const SAVE_DEFAULT_OPEN_SUBMENU="SAVE_DEFAULT_OPEN_SUBMENU";
export const SAVE_SIDEBAR_SCROLL_POSITION="SAVE_SIDEBAR_SCROLL_POSITION";