import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input,
  Tag,
  Table,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  CUSTOM_FORM_TYPE_CHOICES,
  CUSTOM_FORM_STATUS_CHOICES,
  CREATE_CUSTOM_FORM,
  CREATE_TASK_TYPE,
  EDIT_CUSTOM_FORM,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class EditCustomForm extends Component {
  state = {
    title: "",
    purpose: "",
    fields_choices: [],
    selected_field: "",
    fields: [],
    associated_with: "order",
    taskTypes: [],
    task_type: "",
    published: false,
    name: "",
    label: "",
    input_type: "text",
    options: [],
    option_text: "",
    search_endpoint: "",
    condition_field: "",
    condition_value: "",
    min_value: "",
    max_value: "",
    mandatory: false,
    customFormTableView: [],
    custom_form_id: "",
    form_status_choices: [],
    form_status: "",
  };

  componentDidMount() {
    this.setState({ custom_form_id: this.props.location.state.custom_form_id });
    this.getTypeChoices();
    this.getTaskType();
    this.getStatusChoices();
    this.getCustomFormDetails();
    // this.getForm();
  }

  getCustomFormDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?_id=${this.props.location.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form details", response);
          this.setState({
            fields: response.data?.data[0].fields ?? [],
            title: response.data?.data[0].title ?? "",
            purpose: response.data?.data[0].purpose ?? "",
            published: response.data?.data[0].published ?? false,
            associated_with: response.data?.data[0].associated_with ?? "",
            task_type: response.data?.data[0]?.task_type ?? "",
            form_status: response.data?.data[0]?.form_status ?? "",
          });
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };

  getTaskType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TASK_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from task type list", response);

          this.setState({
            taskTypes: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from task type list api", error);
        });
    });
  };
  //   getForm = () => {
  //     GET_ACCESS_TOKEN().then((token) => {
  //       const url = `${CREATE_CUSTOM_FORM}?limit=1000&access_token=${token}`;

  //       axios({
  //         method: "get",
  //         url: url,
  //       })
  //         .then((response) => {
  //           this.setState({ customFormTableView: response.data?.data ?? [] });
  //           console.log("response from get form", response);
  //         })
  //         .catch((error) => {
  //           console.log("error from get form", error);
  //         });
  //     });
  //   };

  getTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CUSTOM_FORM_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log(
            "response from custom form fields type choices",
            response
          );
          this.setState({
            fields_choices: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from custom field type choices", error);
        });
    });
  };
  getStatusChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CUSTOM_FORM_STATUS_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form status choices", response);
          this.setState({
            form_status_choices: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from custom field type choices", error);
        });
    });
  };

  setTitle = (evt) => {
    evt.preventDefault();
    this.setState({ title: evt.target.value }, () => {
      console.log(this.state.title);
    });
  };
  setPurpose = (evt) => {
    evt.preventDefault();
    this.setState({ purpose: evt.target.value }, () => {
      console.log(this.state.purpose);
    });
  };

  setSelectedField = (value) => {
    this.setState({ selected_field: value }, () => {
      this.setState({
        name: "",
        label: "",
        input_type: "text",
        options: [],
        search_endpoint: "",
        condition_field: "",
        condition_value: "",
        min_value: "",
        max_value: "",
        mandatory: true,
      });
      console.log(this.state.selected_field);
    });
  };

  setChoice = (evt) => {
    evt.preventDefault();
    this.setState({ choice: evt.target.value }, () => {
      console.log(this.state.choice);
    });
  };

  setFieldfor = (value) => {
    this.setState({ field_for: value }, () => {
      console.log(this.state.field_for);
    });
  };
  setFormStatus = (value) => {
    this.setState({ form_status: value }, () => {
      console.log(this.state.form_status);
    });
  };
  setAssociatedWith = (value) => {
    this.setState({ associated_with: value }, () => {
      console.log(this.state.associated_with);
    });
  };
  setTaskType = (value) => {
    this.setState({ task_type: value }, () => {
      console.log(this.state.task_type);
    });
  };
  setPublished = (value) => {
    this.setState({ published: CHECK_BOOL(value) }, () => {
      console.log(this.state.published);
    });
  };

  setOption = (evt) => {
    evt.preventDefault();
    this.setState({ option_text: evt.target.value }, () => {
      console.log(this.state.option_text);
    });
  };

  setOptions = () => {
    if (this.state.option_text === "") {
      return;
    } else {
      let array = [];
      array.push(this.state.option_text);
      this.setState({ options: [...this.state.options, ...array] }, () => {
        this.setState({ option_text: "" });
        console.log(this.state.options);
      });
    }
  };

  addFields = () => {
    if (this.state.name === "" || this.state.label === "") {
      swal.fire("Info", "Field Name and Label are Mandatory", "info");
    } else if (this.state.fields.some((f) => f.name === this.state.name)) {
      swal.fire("Info", "Two Field Name Can't be Same", "info");
    } else {
      let tempObj = {};
      if (this.state.selected_field === "text") {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          input_type: this.state.input_type,
          mandatory: this.state.mandatory,
          validation: this.state.input_type === "text" ? "" : "",
        };
      } else if (
        this.state.selected_field === "radio" ||
        this.state.selected_field === "dropdown" ||
        this.state.selected_field === "checkbox" ||
        this.state.selected_field === "hierarchical_dropdown"
      ) {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          options: this.state.options,
          mandatory: this.state.mandatory,
          validation: "",
        };
      } else if (this.state.selected_field === "searchable_dropdown") {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          options: this.state.options,
          mandatory: this.state.mandatory,
          search_endpoint: this.state.search_endpoint,
          validation: "",
        };
      } else if (this.state.selected_field === "conditional") {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          condition_field: this.state.condition_field,
          condition_value: this.state.condition_value,
          mandatory: this.state.mandatory,
          validation: "",
        };
      } else if (this.state.selected_field === "numeric_slider") {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          min_value: this.state.min_value,
          max_value: this.state.max_value,
          mandatory: this.state.mandatory,
          validation: "",
        };
      } else if (this.state.selected_field === "table") {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          columns: this.state.options,
          mandatory: this.state.mandatory,
          validation: "",
        };
      } else {
        tempObj = {
          field_type: this.state.selected_field,
          name: this.state.name,
          label: this.state.label,
          mandatory: this.state.mandatory,
          validation: "",
        };
      }

      let tempArray = [];
      tempArray.push(tempObj);
      this.setState({ fields: [...this.state.fields, ...tempArray] }, () => {
        console.log("after adding fields", this.state.fields);
      });
    }
  };

  updateCustomForm = () => {
    if (this.state.fields.length === 0) {
      swal.fire("Info", "Please enter fields for the form", "info");
    } else {
      const data = {
        form_title: this.state.title,
        form_purpose: this.state.purpose,
        form_fields: this.state.fields,
        form_status: this.state.form_status,
        associated_with: this.state.associated_with,
        task_type: this.state.task_type,
        published: this.state.published,
      };

      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_CUSTOM_FORM}${this.state.custom_form_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.getCustomFormDetails();
            swal.fire("Info", response.data.message, "info");
            console.log("response from edit custom form", response);
          })
          .catch((error) => {
            this.getCustomFormDetails();
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from edit custom form", error);
          });
      });
    }
  };
  render() {
    return (
      <>
        <Card style={{ margin: 10 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)",
            }}
            onBack={() => this.props.history.goBack()}
            title="Edit Custom Form"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Form.Item>
              <Text>Title</Text>
              <Input
                placeholder="Title"
                type="text"
                value={this.state.title}
                onChange={this.setTitle}
              />
            </Form.Item>
            <Form.Item>
              <Text>Purpose</Text>
              <Input
                placeholder="Purpose"
                type="text"
                value={this.state.purpose}
                onChange={this.setPurpose}
              />
            </Form.Item>
            <Form.Item>
              <Text>Associated With</Text>
              <Select
                value={this.state.associated_with}
                onChange={this.setAssociatedWith}
                placeholder="Associated With"
              >
                <Option key="tasks">Tasks</Option>
                <Option key="orders">Orders</Option>
                <Option key="places">Places</Option>
                <Option key="incidents">Incidents</Option>
              </Select>
            </Form.Item>
            {this.state.associated_with === "tasks" ? (
              <Form.Item>
                <Text>Task Type</Text>
                <Select
                  value={this.state.task_type}
                  onChange={this.setTaskType}
                  placeholder="Task Type"
                >
                  {this.state.taskTypes.map((r) => {
                    return <Option key={r.name}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            ) : null}
            {this.state.fields.length > 0 ? (
              <Card>
                <Table
                  dataSource={this.state.fields}
                  pagination={false}
                  rowKey={(record) => record.name}
                  size="small"
                  bordered
                >
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Field Type"
                    dataIndex="field_type"
                    key="field_type"
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                      <span>
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => {
                            let filteredList = this.state.fields.filter(
                              (v) => v.name !== record.name
                            );
                            this.setState({ fields: filteredList });
                          }}
                        >
                          Remove
                        </Button>
                      </span>
                    )}
                  />
                </Table>
              </Card>
            ) : null}
            <Form.Item>
              <Text>Add Field Types</Text>
              <Select
                value={this.state.selected_field}
                onChange={this.setSelectedField}
              >
                {this.state.fields_choices.map((r) => {
                  return <Option key={r.field_type}>{r.field_type}</Option>;
                })}
              </Select>
            </Form.Item>

            {this.state.selected_field !== "" ? (
              <Card>
                {/* <Form.Item>
                  <Text>Name</Text>
                  <Input
                    placeholder="Name"
                    type="text"
                    value={this.state.name}
                    onChange={(evt) =>
                      this.setState({
                        name: evt.target.value,
                      })
                    }
                  />
                </Form.Item> */}
                <Form.Item>
                  <Text>Label</Text>
                  <Input
                    placeholder="Label"
                    type="text"
                    value={this.state.label}
                    onChange={(evt) =>
                      this.setState({
                        label: evt.target.value,
                        name: evt.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Mandatory Field</Text>
                  <Select
                    value={this.state.mandatory.toString()}
                    onChange={(value) => {
                      this.setState({ mandatory: CHECK_BOOL(value) }, () => {
                        console.log(this.state.mandatory);
                      });
                    }}
                  >
                    <Option key={true}>True</Option>
                    <Option key={false}>False</Option>
                  </Select>
                </Form.Item>
                {this.state.selected_field === "text" ? (
                  <Form.Item>
                    <Text>Input Type</Text>
                    <Select
                      value={this.state.input_type}
                      onChange={(value) => this.setState({ input_type: value })}
                      placeholder="Input Type"
                    >
                      <Option key="text">Text</Option>
                      <Option key="number">Number</Option>
                    </Select>
                  </Form.Item>
                ) : this.state.selected_field === "radio" ||
                  this.state.selected_field === "dropdown" ||
                  this.state.selected_field === "checkbox" ||
                  this.state.selected_field === "searchable_dropdown" ? (
                  <>
                    <Form.Item>
                      <Text>Options</Text>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Input
                          placeholder="Options"
                          type="text"
                          value={this.state.option_text}
                          onChange={this.setOption}
                        />
                        <Button
                          type="primary"
                          style={{ marginLeft: 5 }}
                          onClick={this.setOptions}
                        >
                          Add Option
                        </Button>
                      </div>
                      <Text>Option Names</Text>
                      <Card style={{ marginTop: 5 }}>
                        {this.state.options.map((choice, index) => {
                          return (
                            <Tag color="blue" key={index}>
                              {choice}
                            </Tag>
                          );
                        })}
                      </Card>
                    </Form.Item>
                    {this.state.selected_field === "searchable_dropdown" ? (
                      <Form.Item>
                        <Text>Search Endpoint</Text>
                        <Input
                          placeholder="Search Endpoint"
                          type="text"
                          value={this.state.search_endpoint}
                          onChange={(evt) =>
                            this.setState({
                              search_endpoint: evt.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    ) : null}
                  </>
                ) : this.state.selected_field === "conditional" ? (
                  <>
                    <Form.Item>
                      <Text>Condition Field</Text>
                      <Input
                        placeholder="Condition Field"
                        type="text"
                        value={this.state.condition_field}
                        onChange={(evt) =>
                          this.setState({
                            condition_field: evt.target.value,
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Text>Condition Value</Text>
                      <Input
                        placeholder="Condition Value"
                        type="text"
                        value={this.state.condition_value}
                        onChange={(evt) =>
                          this.setState({
                            condition_value: evt.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </>
                ) : this.state.selected_field === "numeric_slider" ? (
                  <>
                    <Form.Item>
                      <Text>Minimum Value</Text>
                      <Input
                        placeholder="Minimum Value"
                        type="number"
                        value={this.state.min_value}
                        onChange={(evt) =>
                          this.setState({
                            min_value: evt.target.value,
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Text>Maximun Value</Text>
                      <Input
                        placeholder="Maximun Value"
                        type="number"
                        value={this.state.max_value}
                        onChange={(evt) =>
                          this.setState({
                            max_value: evt.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </>
                ) : this.state.selected_field === "table" ? (
                  <Form.Item>
                    <Text>Columns</Text>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        placeholder="Columns"
                        type="text"
                        value={this.state.option_text}
                        onChange={this.setOption}
                      />
                      <Button
                        type="primary"
                        style={{ marginLeft: 5 }}
                        onClick={this.setOptions}
                      >
                        Add Column Name
                      </Button>
                    </div>
                    <Text>Column Names</Text>
                    <Card style={{ marginTop: 5 }}>
                      {this.state.options.map((choice, index) => {
                        return (
                          <Tag color="blue" key={index}>
                            {choice}
                          </Tag>
                        );
                      })}
                    </Card>
                  </Form.Item>
                ) : null}
                <Button type="primary" onClick={this.addFields}>
                  Add Field
                </Button>
              </Card>
            ) : null}

            <Form.Item>
              <Text>Form Status</Text>
              <Select
                value={this.state.form_status}
                onChange={this.setFormStatus}
              >
                {this.state.form_status_choices.map((ch) => (
                  <Option key={ch.form_status}>{ch.form_status}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Published</Text>
              <Select
                value={this.state.published.toString()}
                onChange={this.setPublished}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            {this.props.userProfileData.custom_form.custom_form_update ? (
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.updateCustomForm}
                >
                  Update Form
                </Button>
              </Form.Item>
            ) : null}
          </Form>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {  getUserData })(
  EditCustomForm
);
