import React, { Component } from "react";
import {
  Select,
  Card,
  Input,
  Typography,
  Button,
  PageHeader,
  Form,
  Layout,
  Timeline,
  Descriptions,
  Avatar,
  Icon,
  Switch
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { TASK_LINK, ORDER_FROM_TASK_LINK } from "../../api/constants";
import { CHECK_BOOL, timeConverter } from "../../utilities/utilities";
import { countryCode } from "../countryCode";

import "../login.css";
import Logo from "../../assets/logo.png";
import Play from "../../assets/play.png";

const { Option } = Select;
const { Footer } = Layout;
const { Text, Title } = Typography;

export default class TaskLink extends Component {
  state = {
    showForm: false,
    mobile: "",
    loading: false,
    query: "",
    start_location: "",
    end_location: "",
    value: [],
    value1: [],
    data: [],
    fetch: false,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    name: "",
    details: "",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    task_code: "",
    showContactDropdown: true,
    contact_id: "",
    contact_no: "",
    contact_name: "",
    contact_info: "",
    showDropcDropdown: true,
    drop_contact_id: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_info: "",
    price: "",
    assigned_to: "",
    assigned_to_label: "",
    userData: [],
    assigned_asset: "",
    assigned_asset_label: "",
    assetData: [],
    delay_mins: null,
    colors: [],
    colorId: [],
    trip_duration: 0,
    accepted: false,
    started: false,
    completed: false,
    cancelled: false,
    is_public: false,
    isChainTask: false,
    assigned_user_name: "",
    assigned_user_no: "",

    prev_task_id: "",
    m_task_id: "",
    start_dist: null,
    end_dist: null,
    comments: "",
    task_id: "",
    showInputLocStart: false,
    showInputLocEnd: false,
    disable: false,
    currentUserid: "",
    editStart: false,
    editEnd: false,
    checkAssign: "",
    checkPublic: false,
    checkStarted: false,
    checkCompleted: false,
    polyData: [],
    assignedUserLat: "",
    assignedUserLon: "",
    userLocLon: 0,
    userLocLat: 0,
    commentsArray: [],
    task_link: "",
    picked_from: false,
    dropped_to: false,
    showDrop: false,
    showPick: false,
    task_status: "",
    width: window.innerWidth,
    height: window.innerHeight,
    started_on: "",
    completed_on: "",
    countryCode: [],
    code: "+880",
    org_image: null,
    org_name: "",
    order_ids: [],
    order_items: [],
    selected_order_item: {},
    selected_order_items: [],
    order_id: ""
  };
  componentDidMount() {
    console.log("task link ", this.props.match.params.task_link);
    this.setState({
      countryCode: countryCode
    });
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        userLocLat: latitude,
        userLocLon: longitude
      });
    };
    navigator.geolocation.getCurrentPosition(success);

    const handleResize = () => {
      this.setState({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
  }

  setCode = value => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };

  setMobile = evt => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value }, () => {
      console.log(this.state.mobile);
    });
  };

  handlePicked = value => {
    this.setState({ picked_from: CHECK_BOOL(value) }, () => {
      console.log(this.state.picked_from);
    });
  };

  handleDropped = value => {
    this.setState({ dropped_to: CHECK_BOOL(value) }, () => {
      console.log(this.state.dropped_to);
    });
  };
  handleStatus = value => {
    this.setState({ task_status: value }, () => {
      console.log(this.state.task_status);
    });
  };

  setContactNumber = evt => {
    this.setState({ contact_no: evt.target.value });
    console.log(this.state.contact_no);
  };

  setDropContactNumber = evt => {
    this.setState({ drop_contact_no: evt.target.value });
    console.log(this.state.drop_contact_no);
  };

  getTaskInfo = () => {
    this.setState({ loading: true });
    const data = {
      user_no:
        this.state.mobile !== "" ? this.state.code + this.state.mobile : ""
    };
    let url = `${TASK_LINK}${this.props.match.params.task_link}`;
    axios({
      method: "get",
      url: url,
      params: data,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => {
        this.setState({ loading: false });
        console.log("response from task link", response);
        if (response.data.message === "Task not found") {
          swal.fire("Error", "Task not Found.Please Check Your Mobile Number");
        }
        this.setState(
          {
            showForm: true,
            name: response.data.data.name,
            details: response.data.data.details,
            start_location: response.data.data.start_loc_name || "",
            end_location: response.data.data.end_loc_name || "",
            start_location_lat: response.data.data.start_loc.coordinates[1],
            start_location_lon: response.data.data.start_loc.coordinates[0],
            end_location_lat: response.data.data.end_loc.coordinates[1],
            end_location_lon: response.data.data.end_loc.coordinates[0],
            start_time: response.data.data.start_time.$date,
            start_time_view: response.data.data.start_time.$date,
            end_time_view: response.data.data.end_time.$date,
            end_time: response.data.data.end_time.$date,
            task_code: response.data.data.task_code,
            contact_id: response.data.data.contact_id,
            contact_no: response.data.data.contact_no,
            contact_name: response.data.data.contact_name,
            drop_contact_id: response.data.data.drop_contact_id,
            drop_contact_no: response.data.data.drop_contact_no,
            drop_contact_name: response.data.data.drop_contact_name,
            price: response.data.data.price,
            assigned_to: response.data.data.assigned_to,
            assigned_asset: response.data.data.assigned_asset,
            delay_mins: response.data.data.delay_mins,
            colors: response.data.data.colors,
            trip_duration: response.data.data.trip_duration,
            accepted: response.data.data.accepted,
            started: response.data.data.started,
            completed: response.data.data.completed,
            cancelled: response.data.data.cancelled,
            is_public: response.data.data.is_public,
            picked_from: response.data.data.picked_from,
            dropped_to: response.data.data.dropped_to,
            assigned_user_name: response.data.data.assigned_user_name,
            assigned_user_no: response.data.data.assigned_user_no,
            started_on: response.data.data.started_on
              ? response.data.data.started_on.$date
              : "",
            completed_on: response.data.data.completed_on
              ? response.data.data.completed_on.$date
              : "",

            prev_task_id: response.data.data.prev_task_id,
            m_task_id: response.data.data.m_task_id,
            start_dist: response.data.data.start_dist,
            end_dist: response.data.data.end_dist,
            task_status: response.data.data.task_status,
            org_image: response.data?.data?.org_image ?? null,
            org_name: response.data?.data?.org_name ?? "",
            order_ids: response.data?.data?.order_ids ?? []
          },
          () => {
            if (this.state.code + this.state.mobile === this.state.contact_no) {
              this.setState({ showPick: true });
            } else if (
              this.state.code + this.state.mobile ===
              this.state.drop_contact_no
            ) {
              this.setState({ showDrop: true });
            }
          }
        );
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log("error from task link", error);
      });
  };

  getOrderDetails = () => {
    const data = {
      drop_contact_no: this.state.drop_contact_no
    };
    let url = `${ORDER_FROM_TASK_LINK}${this.props.match.params.task_link}`;
    axios({
      method: "get",
      url: url,
      params: data,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => {
        console.log("response from order to task link", response);
        this.setState({
          order_items: response?.data?.data?.items ?? [],
          order_id: response?.data?.data?.order_id ?? ""
        });
      })
      .catch(error => {
        console.log("error from order to task link", error);
      });
  };

  giveFeedback=()=>{
    const data = {
      order_id: this.state.order_id,
      drop_contact_no: this.state.drop_contact_no,
      items: this.state.selected_order_items
    };
    console.log(data);

    let url = `${ORDER_FROM_TASK_LINK}${this.props.match.params.task_link}`;
    axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => {
        swal.fire("Info", response.data.message, "info");
        console.log("response from feedback  ", response);
      })
      .catch(error => {
        swal.fire("Error",  error?.response?.data?.message??error.message, "error");
        console.log("error from feedback", error);
      });
  }

  updateTaskInfo = () => {
    this.setState({ loading: true });
    if (this.state.showPick) {
      const data = {
        contact_no: this.state.contact_no,
        picked_from: this.state.picked_from,
        picked_loc: {
          coordinates: [this.state.userLocLon, this.state.userLocLat]
        }
      };
      console.log(data);

      let url = `${TASK_LINK}${this.props.match.params.task_link}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });

          swal.fire("Info", response.data.message, "info");
          this.getTaskInfo();
          console.log("response from edit task", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit task", error);
        });
    } else if (this.state.showDrop) {
      const data = {
        drop_contact_no: this.state.drop_contact_no,
        dropped_to: this.state.dropped_to,
        dropped_loc: {
          coordinates: [this.state.userLocLon, this.state.userLocLat]
        }
      };
      console.log(data);

      let url = `${TASK_LINK}${this.props.match.params.task_link}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });

          swal.fire("Info", response.data.message, "info");
          this.getTaskInfo();
          console.log("response from edit task", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit task", error);
        });
    }
  };

  render() {
    return (
      <div>
        {this.state.showForm ? (
          <div
            style={{
              textAlign: "center",
              paddingBottom: 10,
              paddingTop: 5,
              backgroundColor: "#FAFAFA"
            }}
          >
            {this.state.org_image === null ? (
              <div>
                <Avatar size={50}>
                  {" "}
                  <Text style={{ fontSize: 27 }}>
                    {this.state.org_name[0].toUpperCase()}
                  </Text>
                </Avatar>
                <Text style={{ fontSize: 27, paddingLeft: 10 }}>
                  {this.state.org_name}
                </Text>
              </div>
            ) : (
              <img src={this.state.org_image} alt="" style={{ width: 200 }} />
            )}
          </div>
        ) : null}

        {!this.state.showForm ? (
          <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title={`Please Enter Your Mobile Number to Confirm Your Identity`}
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item>
                <Text>Mobile Number</Text>
                <Select
                  value={this.state.code}
                  onChange={this.setCode}
                  placeholder="Select Coode"
                >
                  {this.state.countryCode.map(r => {
                    return (
                      <Option key={r.dial_code}>
                        {r.name} {r.dial_code}
                      </Option>
                    );
                  })}
                </Select>
                <Input
                  placeholder="Example-1711123456"
                  type="text"
                  value={this.state.mobile}
                  onChange={this.setMobile}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.getTaskInfo}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Card
            style={{
              margin: 10,
              backgroundColor: "#F5F5F5",
              overflow: "auto",
              maxHeight: 700
            }}
          >
            {/* <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title={`Confirm Pickup/Dropoff`}
            /> */}
            <Card style={{ margin: 10 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  className="login-form-button"
                  style={{ width: 150 }}
                  onClick={() => this.getTaskInfo()}
                >
                  Refresh
                </Button>
                <Button
                  className="login-form-button"
                  style={{ width: 150 }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Change User
                </Button>
              </div>
            </Card>
            <div
              style={{
                display: "flex",
                flexDirection: this.state.height > 850 ? "row" : "column"
              }}
            >
              <Card style={{ margin: 10, minWidth: 250 }}>
                <Timeline mode="alternate">
                  <Timeline.Item
                    color={
                      this.state.task_status === "PENDING" ? "grey" : "green"
                    }
                  >
                    Start Time : {timeConverter(this.state.start_time_view)}
                  </Timeline.Item>
                  {this.state.started_on !== "" ? (
                    <Timeline.Item
                      color={
                        this.state.task_status === "PENDING" ? "grey" : "green"
                      }
                    >
                      Started On : {timeConverter(this.state.started_on)}
                    </Timeline.Item>
                  ) : null}

                  <Timeline.Item
                    color={
                      this.state.task_status === "PENDING" ? "grey" : "green"
                    }
                  >
                    Task Status : {this.state.task_status}
                  </Timeline.Item>
                  {!this.state.picked_from && this.state.showPick ? (
                    <Timeline.Item color="grey">
                      <div
                        style={{
                          marginBottom: 5,
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <Text>Confirm Pickup</Text>
                        <Select
                          value={this.state.picked_from.toString()}
                          onChange={this.handlePicked}
                          disabled={
                            this.state.task_status === "PENDING" ? true : false
                          }
                        >
                          <Option key={true}>True</Option>
                          <Option key={false}>False</Option>
                        </Select>
                      </div>
                    </Timeline.Item>
                  ) : null}
                  <Timeline.Item
                    color={this.state.picked_from ? "green" : "grey"}
                  >
                    Picked Up
                  </Timeline.Item>
                  {!this.state.dropped_to && this.state.showDrop ? (
                    <Timeline.Item color="grey">
                      <div
                        style={{
                          marginBottom: 5,
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <Text>Confirm Drop-off</Text>
                        <Select
                          value={this.state.dropped_to.toString()}
                          onChange={this.handleDropped}
                          disabled={
                            this.state.task_status === "PENDING" ? true : false
                          }
                        >
                          <Option key={true}>True</Option>
                          <Option key={false}>False</Option>
                        </Select>
                      </div>
                    </Timeline.Item>
                  ) : null}
                  <Timeline.Item
                    color={this.state.dropped_to ? "green" : "grey"}
                  >
                    Dropped Off
                  </Timeline.Item>

                  <Timeline.Item
                    color={this.state.dropped_to ? "green" : "grey"}
                  >
                    End Time : {timeConverter(this.state.end_time_view)}
                  </Timeline.Item>
                  {this.state.completed_on !== "" ? (
                    <Timeline.Item
                      color={this.state.dropped_to ? "green" : "grey"}
                    >
                      Completed On : {timeConverter(this.state.completed_on)}
                    </Timeline.Item>
                  ) : null}
                </Timeline>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.updateTaskInfo}
                  style={{ marginTop: 24, marginBottom: 15, width: 150 }}
                >
                  Confirm
                </Button>
              </Card>
              <Card style={{ margin: 10 }}>
                <Descriptions
                  bordered
                  column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                  layout="vertical"
                  style={{ marginBottom: 10 }}
                >
                  <Descriptions.Item label="Start Location">
                    {this.state.start_location}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  bordered
                  column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                  layout="vertical"
                  style={{ marginBottom: 10 }}
                >
                  <Descriptions.Item label="End Location">
                    {this.state.end_location}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  bordered
                  column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                  layout="vertical"
                  style={{ marginBottom: 10 }}
                >
                  <Descriptions.Item label="Assigned Person Name">
                    {this.state.assigned_user_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Assigned Person Mobile">
                    {this.state.assigned_user_no}{" "}
                    <a href={`tel:${this.state.assigned_user_no}`}>
                      {" "}
                      <Icon
                        type="phone"
                        style={{ fontSize: 18, paddingLeft: 15 }}
                      />
                    </a>
                  </Descriptions.Item>
                </Descriptions>
                {this.state.showPick ? (
                  <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    layout="vertical"
                    style={{ marginBottom: 10 }}
                  >
                    <Descriptions.Item label="Pickup Conatct Name">
                      {this.state.contact_name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Pickup Conatct Number">
                      {this.state.contact_no}{" "}
                      <a href={`tel:${this.state.contact_no}`}>
                        {" "}
                        <Icon
                          type="phone"
                          style={{ fontSize: 18, paddingLeft: 15 }}
                        />
                      </a>
                    </Descriptions.Item>
                  </Descriptions>
                ) : null}
                {this.state.showDrop ? (
                  <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    layout="vertical"
                    style={{ marginBottom: 10 }}
                  >
                    <Descriptions.Item label="Dropoff Conatct Name">
                      {this.state.drop_contact_name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Dropoff Conatct Number">
                      {this.state.drop_contact_no}{" "}
                      <a href={`tel:${this.state.drop_contact_no}`}>
                        {" "}
                        <Icon
                          type="phone"
                          style={{ fontSize: 18, paddingLeft: 15 }}
                        />
                      </a>
                    </Descriptions.Item>
                  </Descriptions>
                ) : null}

                <Descriptions
                  bordered
                  column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                  layout="vertical"
                  style={{ marginBottom: 10 }}
                >
                  <Descriptions.Item label="Task Name">
                    {this.state.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Task Code">
                    {this.state.task_code}
                  </Descriptions.Item>
                  <Descriptions.Item label="Task Details">
                    {this.state.details}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Bill">
                    {this.state.price}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
            {this.state.showDrop && this.state.order_ids.length > 0 ? (
              <div>
                <Card style={{ marginLeft: 10, marginBottom: 10 }}>
                  <Button
                    className="login-form-button"
                    style={{ width: 150 }}
                    onClick={() => this.getOrderDetails()}
                  >
                    View Products
                  </Button>
                </Card>
                {this.state.order_items.length > 0 ? (
                  <Card style={{ marginLeft: 10 }}>
                    {this.state.order_items.map(item => (
                      <Descriptions
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        layout="vertical"
                        size="small"
                        style={{
                          marginBottom: 10,
                          boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                        }}
                        key={item.item_id}
                      >
                        <Descriptions.Item label="Product Name">
                          {item.item}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product Price">
                          {item.price}
                        </Descriptions.Item>
                        <Descriptions.Item label="Unit Price">
                          {item.unit_price}
                        </Descriptions.Item>
                        <Descriptions.Item label="Unit">
                          {item.unit}
                        </Descriptions.Item>
                        <Descriptions.Item label="Confirm Delivery">
                          <Switch
                            defaultChecked={false}
                            onChange={checked => {
                              let obj = {
                                item_id: item.item_id,
                                delivery_confirmed: checked
                              };

                              this.setState(
                                {
                                  selected_order_item: Object.assign(
                                    this.state.selected_order_item,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from switch input",
                                    this.state.selected_order_item
                                  );
                                }
                              );
                            }}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label="Customer Remarks">
                          <Input
                            type="text"
                            onChange={evt => {
                              let obj = {
                                item_id: item.item_id,
                                customer_remarks: evt.target.value
                              };

                              this.setState(
                                {
                                  selected_order_item: Object.assign(
                                    this.state.selected_order_item,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from text input",
                                    this.state.selected_order_item
                                  );
                                }
                              );
                            }}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <Button
                            className="login-form-button"
                            htmlType="submit"
                            onClick={() => {
                              let arr = [];
                              arr.push(this.state.selected_order_item);

                              let filteredData = this.state.selected_order_items.filter(
                                i => i.item_id !== item.item_id
                              );

                              this.setState(
                                {
                                  selected_order_items: [
                                    ...arr,
                                    ...filteredData
                                  ]
                                },
                                () => {
                                  console.log(
                                    "final state",
                                    this.state.selected_order_items
                                  );
                                  this.setState({ selected_order_item: {} });
                                }
                              );
                            }}
                          >
                            Save
                          </Button>
                        </Descriptions.Item>
                      </Descriptions>
                    ))}
                    {this.state.selected_order_items.length > 0 ? (
                      <Button
                        className="login-form-button"
                        htmlType="submit"
                        style={{
                          width: 200,
                          boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                        }}
                        onClick={this.giveFeedback}
                      >
                        Give Feedback
                      </Button>
                    ) : null}
                  </Card>
                ) : null}
              </div>
            ) : null}
          </Card>
        )}
        {this.state.height > 1024 ? (
          <Footer
            style={{
              position: "fixed",
              textAlign: "center",
              backgroundColor: "#006479",
              height: this.state.width > 1100 ? 200 : 300,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              bottom: 0,
              marginBottom: 0
            }}
          >
            {this.state.width > 1100 ? (
              <div>
                <div
                  style={{
                    position: "absolute",
                    right: 60,
                    bottom: 60
                  }}
                >
                  <a href="https://play.google.com/store/apps/details?id=com.mtl.conveyance&hl=en">
                    <img
                      src={Play}
                      alt=""
                      style={{ width: 200, height: 80, alignSelf: "center" }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: 30,
                    bottom: 10,
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Title style={{ color: "white", fontSize: 18 }}>
                    Read Our Blog :
                  </Title>
                  <a
                    style={{
                      margin: 5,
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    href="https://www.conveyance.app/blogs/%e0%a6%b8%e0%a6%be%e0%a6%aa%e0%a7%8d%e0%a6%b2%e0%a6%be%e0%a6%87-%e0%a6%9a%e0%a7%87%e0%a6%87%e0%a6%a8-%e0%a6%ae%e0%a7%8d%e0%a6%af%e0%a6%be%e0%a6%a8%e0%a7%87%e0%a6%9c%e0%a6%ae%e0%a7%87%e0%a6%a8%e0%a7%8d/"
                  >
                    <img
                      width={280}
                      height={140}
                      alt="ডেলিভারি ও সাপ্লাই চেইন ম্যানেজমেন্ট যুগোপযোগী করতে কনভেন্স
                    অ্যাপের জন্ম যেভাবে!"
                      src="https://www.conveyance.app/wp-content/uploads/2021/01/ca-post-4.jpeg"
                    />
                  </a>
                </div>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                justifyItems: "center"
              }}
            >
              <a href="https://web.conveyance.app">
                <img
                  src={Logo}
                  alt=""
                  style={{ width: 160, height: 41, alignSelf: "center" }}
                />
              </a>
              {this.state.width < 1100 ? (
                <a href="https://play.google.com/store/apps/details?id=com.mtl.conveyance&hl=en">
                  <img
                    src={Play}
                    alt=""
                    style={{ width: 200, height: 80, alignSelf: "center" }}
                  />
                </a>
              ) : null}
              <Title
                style={{ color: "#faa61a", fontSize: 16, marginBottom: 20 }}
              >
                Supply Chain Made Easy
              </Title>
              <Text style={{ color: "white", fontSize: 15 }}>
                Monico Technologies LTD
              </Text>
              <Text style={{ color: "white", fontSize: 15 }}>
                26-27, Ringroad Adabor, Dhaka
              </Text>
              <Text style={{ color: "#b0bec5", fontSize: 15 }}>
                © All rights reserved by Monico Technologies LTD, 2020
              </Text>
            </div>
          </Footer>
        ) : (
          <div>
            {this.state.showForm ? (
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: 10,
                  paddingTop: 10,
                  backgroundColor: "#006479"
                }}
              >
                <img src={Logo} alt="" style={{ width: 200 }} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}
