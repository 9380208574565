import React, { Component } from "react";
import {
  Card,
  //   Form,
//   Button,
  Typography,
    PageHeader,
  //   Select,
  //   Input,
  //   Tag,
  //   Table,
} from "antd";
import axios from "axios";
// import swal from "sweetalert2";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  //   CUSTOM_FORM_TYPE_CHOICES,
  //   CREATE_CUSTOM_FORM,
  SUBMIT_CUSTOM_FORM,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  //   CHECK_BOOL,
  //   timeConverter,
  isValidURL,
} from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;

class CustomFormSubmittedData extends Component {
  state = {
    associated_with: "",
    custom_form_submitted_data: [],
  };

  componentDidMount() {
    this.getCustomFormSubmissionData();
  }
  getCustomFormSubmissionData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SUBMIT_CUSTOM_FORM}?associated_with=${this.state.associated_with}&form_id=${this.props.location.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_form_submitted_data: response.data.data,
          });
          console.log(
            "response from get custom formsubmission data ",
            response
          );
        })
        .catch((error) => {
          // swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from get custom form submission ", error);
        });
    });
  };

  render() {
    return (
      <Card>
       <PageHeader
          title="Custom Form Submitted Data"
          onBack={() => this.props.history.goBack()}
        />
        {this.state.custom_form_submitted_data.length > 0 ? (
          <div
            style={{
              marginTop: 10,
              width: "100%",
              // boxShadow:
              //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            
            <>
              <>
                <table
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddingTop: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <thead>
                    <tr>
                      {Object.keys(
                        this.state.custom_form_submitted_data[0].submitted_data
                      ).map((data, index) => (
                        <th
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          {data}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.custom_form_submitted_data.map((el, index) => (
                      <tr>
                        {Object.keys(el.submitted_data).map((el2, index) => (
                          <td
                            style={{
                              border: "1px solid grey",
                              borderCollapse: "collapse",
                              paddingTop: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                            key={index}
                          >
                            {Array.isArray(el.submitted_data[el2]) ? (
                              <div>
                                {el.submitted_data[el2].map((i, index) => {
                                  if (isValidURL(i)) {
                                    return (
                                      <img
                                        src={i}
                                        alt=""
                                        style={{
                                          margin: 10,
                                          height: 200,
                                          width: 200,
                                        }}
                                        key={index}
                                      />
                                    );
                                  } else {
                                    return (
                                      <Text
                                        className="task-view-text"
                                        style={{ fontSize: 15 }}
                                        key={index}
                                      >
                                        {`${i},`}
                                      </Text>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <Text
                                className="task-view-text"
                                style={{ fontSize: 15 }}
                                key={index}
                              >
                                {el.submitted_data[el2]}
                              </Text>
                            )}
                          </td>
                        ))}
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            </>
          </div>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(
  CustomFormSubmittedData
);
