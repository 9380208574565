import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader,Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_SUB_ORG_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN,CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;


export default class CreateSubOrgType extends Component {
  state = {
    name: "",
    seq_no:1,
    create_with_same_seq_no: false
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setSequenceNumber = evt => {
    evt.preventDefault();
    this.setState({ seq_no: evt.target.value }, () => {
      console.log(this.state.seq_no);
    });
  };

  setSameSequenceNumber = value => {
    this.setState({ create_with_same_seq_no: CHECK_BOOL(value) }, () => {
      console.log(this.state.create_with_same_seq_no);
    });
  };

  createSubOrgType = () => {
    if(this.state.name===""){
      swal.fire("Info","Please enter name","info");
    } else {
    const data = {
      name: this.state.name,
      seq_no: Number(this.state.seq_no),
      replace_seq_no: this.state.create_with_same_seq_no
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url=`${CREATE_SUB_ORG_TYPE}?access_token=${token}`
      axios({
        method: "post",
        url: url,
        data: data,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from create sub org type", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from create sub org type", error);
        });
    });
    }
    
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Sub Organization Type"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Sequence Number</Text>
            <Input
              placeholder="Service Details"
              type="number"
              value={this.state.seq_no}
              onChange={this.setSequenceNumber}
            />
          </Form.Item>

          <Form.Item>
            <Text>Replace Sequence Number</Text>
            <Select value={this.state.create_with_same_seq_no.toString()} onChange={this.setSameSequenceNumber}>
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button className="login-form-button" htmlType="submit" onClick={this.createSubOrgType}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
