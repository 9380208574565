import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader,Select } from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import { ORDER_FROM_CODE } from "../../api/constants";
import { GET_ACCESS_TOKEN,CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class OrderfromCode extends Component {
  state = {
    order_code: "",
    showNumberInput:true,
    order_id:"",
    create_duplicate:false,
    redirect:false
  };

  setOrderCode = evt => {
    evt.preventDefault();
    this.setState({ order_code: evt.target.value }, () => {
      console.log(this.state.order_code);
    });
  };
  
  setAllowDuplicate = value => {
    this.setState({ create_duplicate: CHECK_BOOL(value) }, () => {
      console.log(this.state.create_duplicate);
    });
  };

  getOrderInfo = () => {
    if(this.state.order_code===""){
      swal.fire("Info","Please enter order code","info");
    } else {
    const data = {
      order_code: this.state.order_code,
      create_duplicate: this.state.create_duplicate
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url=`${ORDER_FROM_CODE}?access_token=${token}`
      axios({
        method: "post",
        url: url,
        data: data,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          if(response.data.message==="Data successfully inserted!"){
            this.setState({order_id:response.data.data._id.$oid},()=>{
              this.setState({redirect:true});
            })
          }
          console.log("response from order by code", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from order by code", error);
        });
    });
    }
    
  };
  render() {
    if(this.state.redirect){
      return (
        <Redirect
          to={{
            pathname: "/viewOrder",
            state: { order_id: this.state.order_id }
          }}
        />
      );
    }
   
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Please Enter Your Order Code"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Order Code</Text>
            <Input
              placeholder="Order Code"
              type="text"
              value={this.state.order_code}
              onChange={this.setOrderCode}
            />
          </Form.Item>
         
          <Form.Item>
            <Text>Allow Duplicate Order</Text>
            <Select
              value={this.state.create_duplicate.toString()}
              onChange={this.setAllowDuplicate}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button className="login-form-button" htmlType="submit" onClick={this.getOrderInfo}>
             Connect Order
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
