import React from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Card,
  Select,
  Tabs,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";
import { Redirect } from "react-router-dom";

import "./login.css";
import { Spinner } from "./loader";
import {
  SIGNUP_URL,
  LOGIN_URL,
  SEND_CODE,
  SEND_EMAIL,
  REVERSE_GEO_COUNTRY,
} from "../api/constants";
import { LOCAL_USER_INFO, validateEmail } from "../utilities/utilities";
import Logo from "../assets/logoblue.png";
import { countryCode } from "./countryCode";
import ReactGA from "react-ga4";

const { Option } = Select;
// const { Footer } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

class Register extends React.Component {
  state = {
    name: "",
    mobile: "",
    email: "",
    password: "",
    is_operator: false,
    confirmPassword: "",
    loading: false,
    toHome: false,
    access_token: "",
    toVerify: false,
    toVerifyEmail: false,
    timezone: "",
    countryCode: [],
    code: "",
    width: window.innerWidth,
    height: window.innerHeight,
    activeKeyParent: "1",
    pre_selected_plan_id: "",
    show_confirm_password: false,
    show_password: false,
  };

  componentDidMount() {
    this.setState(
      {
        countryCode: countryCode,
        pre_selected_plan_id:
          this.props?.match?.params?.pre_selected_plan_id ?? "",
      },
      () => {
        console.log("plan id from param", this.state.pre_selected_plan_id);
      }
    );
    this.getCountry();
    let date = new Date();
    let time = date.getTimezoneOffset();
    this.setState({ timezone: time });
    let token = LOCAL_USER_INFO();
    if (token) {
      this.setState({ toHome: true });
    }
    const handleResize = () => {
      this.setState({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
    let keyParent = JSON.parse(localStorage.getItem("registerKey"));
    if (keyParent) {
      this.setState({ activeKeyParent: keyParent });
    }
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getCountry = () => {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      axios.get(REVERSE_GEO_COUNTRY(latitude, longitude)).then((response) => {
        console.log("response from reverse geo", response.data.address.country);
        if (response.data.address.country === "Bangladesh") {
          this.setState({ code: "+880" });
        } else if (response.data.address.country === "Nepal") {
          this.setState({ code: "+977" });
        } else if (response.data.address.country === "Australia") {
          this.setState({ code: "+61" });
        } else {
          this.setState({ code: "+61" });
        }
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  onChange = (activeKeyParent) => {
    console.log(`onChange ${activeKeyParent}`);
    this.setState(
      {
        activeKeyParent,
      },
      () => {
        localStorage.setItem(
          "registerKey",
          JSON.stringify(this.state.activeKeyParent)
        );
      }
    );
  };

  updateName = (evt) => {
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setCode = (value) => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };

  updateMobile = (evt) => {
    this.setState({ mobile: evt.target.value, email: "" }, () => {
      console.log(this.state.mobile);
    });
  };

  updateEmail = (evt) => {
    this.setState({ email: evt.target.value, mobile: "" }, () => {
      console.log(this.state.email);
    });
  };

  updatePassword = (evt) => {
    this.setState({ password: evt.target.value }, () => {
      console.log(this.state.password);
    });
  };

  updateConfirmPassword = (evt) => {
    this.setState({ confirmPassword: evt.target.value }, () => {
      console.log(this.state.confirmPassword);
    });
  };

  handleRegister = () => {
    if (this.state.name < 3) {
      swal.fire("Error", "Name must be atleast 3 letters", "error");
    } else if (this.state.password.length < 6) {
      swal.fire("Error", "Password must be atleast 6 letters", "error");
    } else if (this.state.password !== this.state.confirmPassword) {
      swal.fire("Error", "Password does not match", "error");
    } else if (this.state.code === "+880" && this.state.mobile.length !== 10) {
      swal.fire(
        "Error",
        "Mobile number must be 10 characters long, excluding country code",
        "error"
      );
    } else {
      this.setState({ loading: true });

      let data = {
        name: this.state.name,
        email: "",
        mobile: this.state.code + this.state.mobile,
        password: this.state.password,
        is_operator: this.state.is_operator,
        org_admin: true,
        tz: this.state.timezone,
        pre_selected_plan_id: this.state.pre_selected_plan_id,
      };

      console.log(data);

      axios({
        method: "post",
        url: SIGNUP_URL,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.data)
        .catch((error) => {
          this.setState({ loading: false });
          console.error("Error:", error);
        })
        .then((response) => {
          console.log("Response from signup url", response);
          if (response.hasOwnProperty("status")) {
            swal.fire("Information", response.status, "info");
          }
          this.setState({ loading: false });

          if (response.status === "user created") {
            let data = {
              login_type: "normal",
              client_id: "conveyance_android",
              client_secret: "c0nv3nappndr01d",
              grant_type: "client_credentials",
              user: this.state.code + this.state.mobile,
              password: this.state.password,
              timezone: this.state.timezone,
              scope: "",
              org_admin: true,
            };

            console.log(data);

            axios({
              method: "post",
              url: LOGIN_URL,
              data: data,
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
            })
              .then((response) => {
                console.log("Coming from login api", response);

                if (response.status === 200) {
                  this.setState({
                    access_token: response.data.access_token,
                    toVerify: true,
                  });
                  response.data.Generated_time = Date.now();
                  localStorage.setItem(
                    "conveyanceUser",
                    JSON.stringify(response.data)
                  );

                  axios({
                    method: "get",
                    url: `${SEND_CODE}?access_token=${this.state.access_token}`,
                  })
                    .then((response) => {
                      console.log("response from code api", response);
                    })
                    .catch((error) => {
                      console.log("error in send code", error);
                    });
                }
              })
              .catch((error) => {
                console.error("Error Happened:", error.response);
                swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              });
          }
        });
    }
  };

  handleRegisterEmail = () => {
    if (this.state.name < 3) {
      swal.fire("Error", "Name must be atleast 3 letters", "error");
    } else if (this.state.password.length < 6) {
      swal.fire("Error", "Password must be atleast 6 letters", "error");
    } else if (this.state.password !== this.state.confirmPassword) {
      swal.fire("Error", "Password does not match", "error");
    } else if (validateEmail(this.state.email) === false) {
      swal.fire("Error", "Please Enter a Valid Email", "error");
    } else {
      this.setState({ loading: true });

      let data = {
        name: this.state.name,
        email: this.state.email,
        mobile: "",
        password: this.state.password,
        is_operator: this.state.is_operator,
        org_admin: true,
        tz: this.state.timezone,
        pre_selected_plan_id: this.state.pre_selected_plan_id,
      };

      console.log(data);

      axios({
        method: "post",
        url: SIGNUP_URL,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.data)
        .catch((error) => {
          this.setState({ loading: false });
          console.error("Error:", error);
        })
        .then((response) => {
          console.log("Response from signup email url", response);
          if (response.hasOwnProperty("status")) {
            swal.fire("Information", response.status, "info");
          }
          this.setState({ loading: false });

          if (response.status === "user created") {
            let data = {
              login_type: "normal",
              client_id: "conveyance_android",
              client_secret: "c0nv3nappndr01d",
              grant_type: "client_credentials",
              user: this.state.email,
              password: this.state.password,
              timezone: this.state.timezone,
              scope: "",
              org_admin: true,
            };

            console.log(data);

            axios({
              method: "post",
              url: LOGIN_URL,
              data: data,
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
            })
              .then((response) => {
                console.log("Coming from login api", response);

                if (response.status === 200) {
                  this.setState({
                    access_token: response.data.access_token,
                    toVerifyEmail: true,
                  });
                  response.data.Generated_time = Date.now();
                  localStorage.setItem(
                    "conveyanceUser",
                    JSON.stringify(response.data)
                  );

                  axios({
                    method: "get",
                    url: `${SEND_EMAIL}?access_token=${this.state.access_token}`,
                  })
                    .then((response) => {
                      console.log("response from code api", response);
                    })
                    .catch((error) => {
                      console.log("error in send code", error);
                    });
                }
              })
              .catch((error) => {
                console.error("Error Happened:", error.response);
                swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              });
          }
        });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.toHome === true) {
      return <Redirect to="/home" />;
    } else if (this.state.toVerify === true) {
      return (
        <Redirect
          to={{
            pathname: "/verifyAccount",
            state: { mobile: this.state.mobile },
          }}
        />
      );
    } else if (this.state.toVerifyEmail === true) {
      return (
        <Redirect
          to={{
            pathname: "/verifyAccountEmail",
            state: { email: this.state.email },
          }}
        />
      );
    }

    return (
      <div
        style={{
          width: this.state.width,
          height: this.state.height,
          backgroundColor: "#EFF3EA",
        }}
      >
        <div style={{ display: "flex" }}>
          <Card
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 15,
              position: "relative",
              top: this.state.height / 20,
            }}
          >
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
              <a href="https://www.conveyance.app/">
                <img
                  src={Logo}
                  alt=""
                  style={{
                    width: 230,
                    position: "relative",
                    padding: 10,
                  }}
                />
              </a>
            </div>
            <Form className="login-form">
              <Tabs
                destroyInactiveTabPane={true}
                activeKey={this.state.activeKeyParent}
                onChange={this.onChange}
              >
                <TabPane tab="Sign up with email" key="1">
                  <Form.Item>
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Username"
                      value={this.state.name}
                      onChange={this.updateName}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Input
                      type="text"
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.updateEmail}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type={this.state.show_password ? "text" : "password"}
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.updatePassword}
                      />
                      <Button
                        onClick={() =>
                          this.setState({
                            show_password: !this.state.show_password,
                          })
                        }
                      >
                        <Icon
                          type={
                            this.state.show_password ? "eye" : "eye-invisible"
                          }
                          style={{
                            marginTop: 3,
                            fontSize: 18,
                            color: "#7B8FA1",
                          }}
                        />
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type={
                          this.state.show_confirm_password ? "text" : "password"
                        }
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.updateConfirmPassword}
                      />
                      <Button
                        onClick={() =>
                          this.setState({
                            show_confirm_password:
                              !this.state.show_confirm_password,
                          })
                        }
                      >
                        <Icon
                          type={
                            this.state.show_confirm_password
                              ? "eye"
                              : "eye-invisible"
                          }
                          style={{
                            marginTop: 3,
                            fontSize: 18,
                            color: "#7B8FA1",
                          }}
                        />
                      </Button>
                    </div>
                  </Form.Item>
                  <div style={{ marginTop: -15, marginBottom: 10 }}>
                    <Text style={{ color: "grey", fontSize: 12 }}>
                      By creating an account, you agree to our Terms of services
                      and <Link to="/privacyPolicy">Privacy Policy</Link>
                    </Text>
                  </div>
                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.handleRegisterEmail}
                    >
                      Sign up
                    </Button>
                  </Form.Item>
                </TabPane>
                <TabPane tab="Sign up with mobile" key="2">
                  <Form.Item>
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Username"
                      value={this.state.name}
                      onChange={this.updateName}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div
                      style={{ display: "flex", flexDirection: "row", flex: 1 }}
                    >
                      <Select
                        value={this.state.code}
                        onChange={this.setCode}
                        placeholder="Select Coode"
                        style={{ width: 140, marginRight: 5 }}
                      >
                        {this.state.countryCode.map((r) => {
                          return (
                            <Option key={r.dial_code}>
                              {r.code} {r.dial_code}
                            </Option>
                          );
                        })}
                      </Select>
                      <Input
                        type="number"
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Mobile"
                        value={this.state.mobile}
                        onChange={this.updateMobile}
                        // style={{display:"flex",flex:1}}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type={this.state.show_password ? "text" : "password"}
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.updatePassword}
                      />
                      <Button
                        onClick={() =>
                          this.setState({
                            show_password: !this.state.show_password,
                          })
                        }
                      >
                        <Icon
                          type={
                            this.state.show_password ? "eye" : "eye-invisible"
                          }
                          style={{
                            marginTop: 3,
                            fontSize: 18,
                            color: "#7B8FA1",
                          }}
                        />
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type={
                          this.state.show_confirm_password ? "text" : "password"
                        }
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.updateConfirmPassword}
                      />
                      <Button
                        onClick={() =>
                          this.setState({
                            show_confirm_password:
                              !this.state.show_confirm_password,
                          })
                        }
                      >
                        <Icon
                          type={
                            this.state.show_confirm_password
                              ? "eye"
                              : "eye-invisible"
                          }
                          style={{
                            marginTop: 3,
                            fontSize: 18,
                            color: "#7B8FA1",
                          }}
                        />
                      </Button>
                    </div>
                  </Form.Item>
                  <div style={{ marginTop: -15, marginBottom: 10 }}>
                    <Text style={{ color: "grey", fontSize: 12 }}>
                      By creating an account, you agree to our Terms of services
                      and <Link to="/privacyPolicy">Privacy Policy</Link>
                    </Text>
                  </div>
                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.handleRegister}
                    >
                      Sign up
                    </Button>
                  </Form.Item>
                </TabPane>
              </Tabs>
            </Form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text>Already have an account?</Text>
              <Link
                to="/"
                style={{ color: "#faa61a", marginLeft: 5, marginTop: -1 }}
              >
                Sign In
              </Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default Register;
