import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import { EDIT_SALES_TARGET } from "../../api/constants";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;

export default class EditSalesTarget extends Component {
  state = {
    target_amount: 0,
    assigned_user: "",
    assigned_user_name: "",
    start_datetime: Date.now(),
    start_datetime_view: moment(),
    end_datetime: Date.now(),
    end_datetime_view: moment(),
    sales_target_id: ""
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({
      sales_target_id: this.props?.location?.state?.sales_target_id
    });
    this.getSalesTargetDetails();
  }

  getSalesTargetDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let sales_target_id = this.props.location.state.sales_target_id;
      let url = `${EDIT_SALES_TARGET}${sales_target_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit sales target details", response);
        this.setState({
          target_amount: response.data.data.target_amount,
          assigned_user: response.data.data.assigned_user,
          start_datetime: response.data.data.start_datetime.$date,
          start_datetime_view: response.data.data.start_datetime.$date,
          end_datetime: response.data.data.end_datetime.$date,
          end_datetime_view: response.data.data.end_datetime.$date
        });
      });
    });
  };

  setTargetAmount = evt => {
    evt.preventDefault();
    this.setState({ target_amount: evt.target.value }, () => {
      console.log(this.state.target_amount);
    });
  };

  editSalesTarget = () => {
    const data = {
      assigned_user: this.state.assigned_user,
      target_amount: Number(this.state.target_amount),
      start_datetime: this.state.start_datetime,
      end_datetime: this.state.end_datetime
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_SALES_TARGET}${this.state.sales_target_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          this.getSalesTargetDetails();
          console.log("response from edit sales target", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit sales target", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title={`Update Sales Target`}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Target Amount</Text>
            <Input
              placeholder="Target Amount"
              type="number"
              value={this.state.target_amount}
              onChange={this.setTargetAmount}
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Start Date *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disabled
                  inputVariant="outlined"
                  value={this.state.start_datetime_view}
                  onChange={this.setStartDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>End Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disabled
                  inputVariant="outlined"
                  value={this.state.end_datetime_view}
                  onChange={this.setEndDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editSalesTarget}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
