import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_SERVICE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class CreateService extends Component {
  state = {
    name: "",
    service_details: "",
    loading: false
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setService = evt => {
    evt.preventDefault();
    this.setState({ service_details: evt.target.value }, () => {
      console.log(this.state.service_details);
    });
  };

  createService = () => {
    if(this.state.name==="" || this.state.service_details===""){
      swal.fire("Info","Please enter name and details","info");
    } else {
      this.setState({ loading: true });
    const data = {
      name: this.state.name,
      service_details: this.state.service_details
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url=`${CREATE_SERVICE}?access_token=${token}`
      axios({
        method: "post",
        url: url,
        data: data,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from create service", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from create service", error);
        });
    });
    }
    
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Service"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Service Details</Text>
            <Input
              placeholder="Service Details"
              type="text"
              value={this.state.service_details}
              onChange={this.setService}
            />
          </Form.Item>

          <Form.Item>
            <Button className="login-form-button" htmlType="submit" onClick={this.createService}>
              Create Service
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
