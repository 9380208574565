import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { ORGANIZATION_SETTINGS_LIST } from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class AttendanceSettings extends Component {
  state = {
    status: false,
    otp_for: [],
    attendance_with_image: false
  };

  componentDidMount() {
    console.log("hello");
  }

  setStatus = value => {
    this.setState({ status: CHECK_BOOL(value) }, () => {
      console.log(this.state.status);
    });
  };
  setImage = value => {
    this.setState({ attendance_with_image: CHECK_BOOL(value) }, () => {
      console.log(this.state.attendance_with_image);
    });
  };

  setOtpFor = value => {
    this.setState({ otp_for: value }, () => {
      console.log(this.state.otp_for);
    });
  };

  createAttendanceSettings = () => {
    const data = {
      attendance_settings: [
        {
          otp: {
            status: this.state.status,
            otp_for: this.state.otp_for
          },
          attendance_with_image: this.state.attendance_with_image
        }
      ]
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from create Attendance Settings", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from create Attendance Settings", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Attendance Settings"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <Text>OTP</Text>
              <Select
                value={this.state.status.toString()}
                onChange={this.setStatus}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>
            {this.state.status ? (
              <Form.Item>
                <Text>OTP For</Text>
                <Select
                  value={this.state.otp_for}
                  onChange={this.setOtpFor}
                  mode="multiple"
                >
                  <Option key="checkin">Checkin</Option>
                  <Option key="checkout">Checkout</Option>
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item>
              <Text>Attendance With Image</Text>
              <Select
                value={this.state.attendance_with_image.toString()}
                onChange={this.setImage}
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.createAttendanceSettings}
              >
                Create Attendance Settings
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    );
  }
}
