import React, { Component } from "react";
import { Card, Typography, Button, Switch, Divider } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  ORDER_ITEM_DETAILS,
  ORG_IMAGE_UPLOAD,
  ORDER_PRODUCT_STATUS_UPDATE,
  ORDER_PRODUCT_TAG
} from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";

import Logo from "../../assets/logo.png";
import "../login.css";

const { Text } = Typography;

export default class OrderItemDetails extends Component {
  state = {
    order_id: "",
    item_id: "",
    parent_product_id: "",
    item: "",
    price: 0,
    qty: "",
    unit: "",
    unit_selling_price: 0,
    sku: "",
    unit_purchase_price: 0,
    mrp: 0,
    inventory_id: "",
    delivery_confirmed: false,
    added: false,
    qc: false,
    item_changed: false,
    added_image_url: "",
    added_image_file: "",
    qc_image_url: "",
    qc_image_file: "",
    org_id: "",
    discount: "",
    item_discount_type: "amount",
    unit_item_discount: 0,
    item_discounted_price: 0
  };

  componentDidMount() {
    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.setState(
      {
        order_id: this.props.match.params.order_id,
        item_id: this.props.match.params.item_id
      },
      () => {
        this.getItemDetails();
      }
    );
    window.location.replace(
      `conveyence://app/root/home/orders/details/${this.props.match.params.order_id}/${this.props.match.params.item_id}`
    );
  }

  getItemDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ORDER_ITEM_DETAILS}${this.state.order_id}/items/${this.state.item_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          console.log("response from order item details", response);
          this.setState({
            item: response.data?.data?.item ?? "",
            parent_product_id: response.data?.data?.parent_product_id ?? "",
            item_discount_type:
              response.data?.data?.item_discount_type ?? "amount",
            unit_item_discount: response.data?.data?.unit_item_discount ?? 0,
            item_discounted_price:
              response.data?.data?.item_discounted_price ?? 0,
            price: response.data?.data?.price ?? "",
            qty: response.data?.data?.qty ?? 0,
            unit: response.data?.data?.unit ?? "",
            unit_selling_price: response.data?.data?.unit_selling_price ?? 0,
            sku: response.data?.data?.sku ??"",
            unit_purchase_price:response.data?.data?.unit_purchase_price ?? 0,
            mrp: response.data?.data?.mrp ??0,
            inventory_id: response.data?.data?.inventory_id ??"",
            discount: response.data?.data?.discount ?? 0,
            delivery_confirmed:
              response.data?.data?.delivery_confirmed ?? false,
            qc: response.data?.data?.qc ?? false,
            added: response.data?.data?.added ?? false,
            item_changed: response.data?.data?.item_changed ?? false,
            qc_image_url: response.data?.data?.qc_image_url ?? "",
            added_image_url: response.data?.data?.added_image_url ?? ""
          });
        })
        .catch(error => {
          console.log("error from order item details",  error?.response?.data?.message??error.message);
        });
    });
  };

  uploadAddedImage = () => {
    GET_ACCESS_TOKEN().then(token => {
      const img = new FormData();

      img.append("imgFile", this.state.added_image_file);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}order_item_added?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              added_image_url: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`
            });
          }
          console.log("response from added image api", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("Error from added image upload", error);
        });
    });
  };

  uploadQCImage = () => {
    GET_ACCESS_TOKEN().then(token => {
      const img = new FormData();

      img.append("imgFile", this.state.qc_image_file);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}order_item_qc?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              qc_image_url: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`
            });
          }
          console.log("response from qc image api", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("Error from qc image upload", error);
        });
    });
  };

  added = checked => {
    if (checked) {
      let data = {
        items: [
          {
            added: checked,
            added_image_url: this.state.added_image_url,
            item_id: this.state.item_id
          }
        ]
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ORDER_PRODUCT_STATUS_UPDATE}${this.state.order_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            this.getItemDetails();
            console.log("response from update order product status", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from update order product status", error);
          });
      });
    }
  };

  qcd = checked => {
    if (checked) {
      let data = {
        items: [
          {
            qc: checked,
            qc_image_url: this.state.added_image_url,
            item_id: this.state.item_id
          }
        ]
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ORDER_PRODUCT_STATUS_UPDATE}${this.state.order_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            this.getItemDetails();
            console.log("response from update order product status", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from update order product status", error);
          });
      });
    }
  };

  delivered = checked => {
    if (checked) {
      let data = {
        items: [
          {
            delivery_confirmed: checked,
            item_id: this.state.item_id
          }
        ]
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ORDER_PRODUCT_STATUS_UPDATE}${this.state.order_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            this.getItemDetails();
            console.log("response from update order product status", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from update order product status", error);
          });
      });
    }
  };

  printTag = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORDER_PRODUCT_TAG}?order_id=${this.state.order_id}&item_ids=${this.state.item_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log("response from order tag print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from order tag print list fmt", error);
        });
    });
  };
  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: 10,
            paddingTop: 5,
            backgroundColor: "#006479"
          }}
        >
          <img src={Logo} alt="" style={{ width: 200 }} />
        </div>
        <Card style={{ margin: 5 }}>
          <Card className="view">
            <p >
              <Text style={{ fontWeight: "bold" }}>Name</Text>:{" "}
              {this.state.item}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Unit</Text>:{" "}
              {this.state.unit}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Unit Selling Price</Text>:{" "}
              {this.state.unit_selling_price}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Unit Purchase Price</Text>:{" "}
              {this.state.unit_purchase_price}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>MRP</Text>:{" "}
              {this.state.mrp}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>SKU</Text>:{" "}
              {this.state.sku}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Quantity</Text>:{" "}
              {this.state.qty}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Price</Text>:{" "}
              {this.state.price}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Discount</Text>:{" "}
              {this.state.discount}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Item Discount Type</Text>:{" "}
              {this.state.item_discount_type}
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Per Item Discount</Text>:{" "}
              {this.state.item_discount_type === "fixed_amount"
                ? this.state.per_item_discount
                : this.state.item_discount_type === "percentage"
                ? `${this.state.per_item_discount}%`
                : 0}
            </p>

            <p >
              <Text style={{ fontWeight: "bold" }}>Discounted Price</Text>:{" "}
              {this.state.item_discounted_price}
            </p>

            <p >
              <Text style={{ fontWeight: "bold" }}>Item Changed</Text>:{" "}
              {this.state.item_changed.toString()}
            </p>
            <Divider />
            {this.state.added_image_url ? (
              <div>
                <p >
                  <Text style={{ fontWeight: "bold" }}>Added Image :</Text>
                </p>
                <img
                  src={this.state.added_image_url}
                  alt="avatar"
                  style={{ width: 100, height: 100 }}
                  onClick={() => {
                    window.open(this.state.added_image_url, "_blank");
                  }}
                />
              </div>
            ) : null}
            <p >
              <Text style={{ fontWeight: "bold" }}>Upload Added image</Text>:{" "}
              <input
                type="file"
                onChange={e => {
                  console.log(
                    "Added Image file inside",
                    this.state.added_image_file
                  );
                  this.setState(
                    {
                      added_image_file: e.target.files[0]
                    },
                    () => {
                      this.uploadAddedImage();
                    }
                  );
                }}
              />
            </p>
            <p >
              <Text style={{ fontWeight: "bold" }}>Added</Text>:{" "}
              <Switch checked={this.state.added} onChange={this.added} />
            </p>
            <Divider />
            {this.state.qc_image_url ? (
              <div>
                <p >
                  <Text style={{ fontWeight: "bold" }}>QC Image :</Text>
                </p>
                <img
                  src={this.state.qc_image_url}
                  alt="avatar"
                  style={{ width: 100, height: 100 }}
                  onClick={() => {
                    window.open(this.state.qc_image_url, "_blank");
                  }}
                />
              </div>
            ) : null}
            <p >
              <Text style={{ fontWeight: "bold" }}>Upload QC image</Text>:{" "}
              <input
                type="file"
                onChange={e => {
                  console.log("QC Image file inside", this.state.qc_image_file);
                  this.setState(
                    {
                      qc_image_file: e.target.files[0]
                    },
                    () => {
                      this.uploadQCImage();
                    }
                  );
                }}
              />
            </p>

            <p >
              <Text style={{ fontWeight: "bold" }}>QC Checked</Text>:{" "}
              <Switch checked={this.state.qc} onChange={this.qcd} />
            </p>
            <Divider />
            <p >
              <Text style={{ fontWeight: "bold" }}>Delivered</Text>:{" "}
              <Switch
                checked={this.state.delivery_confirmed}
                onChange={this.delivered}
              />
            </p>

            <Button
              className="login-form-button"
              htmlType="submit"
              style={{ minWidth: 150, marginTop: 15 }}
              onClick={this.printTag}
            >
              Print Tag
            </Button>
            <Button
              className="login-form-button"
              htmlType="submit"
              style={{ minWidth: 150, marginTop: 15 }}
              onClick={() => {
                window.open(
                  `https://web.conveyance.app/ViewOrder/${this.state.order_id}`
                );
              }}
            >
              View Order
            </Button>
          </Card>
        </Card>
      </div>
    );
  }
}
