import React, { Component } from "react";
import { Card, Button, Checkbox, PageHeader } from "antd";
import CardMaterial from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_ROLE, ORG_PLAN_SERVICE_CHECK } from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";
// import { Spinner } from "../loader";

import "../login.css";

export default class ManagePermissions extends Component {
  state = {
    name: "",
    description: "",
    permissions: "",
    role_id: "",

    asset_create: false,
    asset_list: false,
    asset_update: false,
    asset_detail: false,
    //new
    asset_delete: false,
    add_reminder: false,
    connect_asset_to_user: false,
    connect_asset_to_finder_device: false,
    connect_to_finder: false,

    attendance_create: false,
    attendance_list: false,
    attendance_detail: false,
    //new
    attendance_create_for: false,
    attendance_checkin: false,
    attendance_checkout: false,
    attendance_delete: false,
    attendance_view_route: false,

    custom_fields_create: false,
    custom_fields_list: false,
    custom_fields_update: false,
    custom_fields_detail: false,
    custom_fields_delete: false,

    //new
    organization_create: false,
    organization_list: false,
    organization_update: false,
    organization_detail: false,
    organization_delete: false,
    add_plan_subscription: false,
    change_plan_subscription: false,
    monthly_billing_view: false,
    monthly_payment_view: false,
    change_organization: false,

    place_create: false,
    place_list: false,
    place_update: false,
    update_is_inventory_in_place: false,
    view_place_detail: false,
    // place_detail: false,
    //new
    nearby_place_search: false,
    place_delete: false,
    add_place_reminder: false,
    update_custom_field_data_related_to_place: false,
    view_custom_field_data_related_to_place: false,

    // plan_create: false,
    // plan_list: false,
    // plan_update: false,
    // plan_detail: false,

    // invoice_create: false,
    // invoice_list: false,
    // invoice_update: false,
    // invoice_detail: false,
    // invoice_delete: false,

    task_create: false,
    task_list: false,
    task_update: false,
    view_task_detail: false,
    //new
    add_comment: false,
    view_comment_list: false,
    update_comment: false,
    delete_comment: false,
    update_custom_field_data_related_to_task: false,
    view_custom_field_data_related_to_task: false,
    task_delete: false,
    task_view_route: false,
    task_add_reminder: false,

    //new
    task_type_create: false,
    task_type_list: false,
    task_type_update: false,
    task_type_detail: false,
    task_type_delete: false,

    bill_create: false,
    bill_list: false,
    bill_list_search: false,
    bill_update: false,
    bill_detail: false,
    bill_type_list: false,
    conveyance_bill_approval_api: false,
    bill_item_udpade: false,
    get_miscellaneous_bill_type_choices: false,

    requisition_create: false,
    requisition_list: false,
    requisition_list_search: false,
    requisition_update: false,
    requisition_detail: false,
    requisition_delete: false,
    //new
    generate_bill_from_requisition: false,
    generate_order_from_requisition: false,

    order_create: false,
    order_list: false,
    // order_list_search: false,
    order_update: false,

    view_order_detail: false,
    return_order_create: false,
    return_order_list: false,
    return_order_detail: false,
    pos_order_create: false,
    pos_order_list: false,
    pos_order_detail: false,
    // order_detail: false,
    change_delivery_time: false,
    order_product_status_update: false,
    order_location_update: false,
    add_received_amount: false,
    add_refund_amount: false,
    update_custom_field_data_related_to_order: false,
    view_custom_field_data_related_to_order: false,
    order_delete: false,
    order_add_reminder: false,
    download_order_detailed_to_order: false,
    order_related_payment_method_and_status_update: false,
    order_payment_method_and_status_update_from_task: false,
    order_payment_method_choices: false,
    order_discount_type_choices: false,
    order_item_update: false,
    generate_task_from_order: false,
    generate_multi_task_from_selected_order: false,
    generate_chain_task_from_selected_order: false,
    generate_route_suggestion_from_selected_order: false,
    purchase_order_create: false,
    purchase_order_list: false,
    purchase_order_detail: false,

    promotion_create: false,
    promotion_list: false,
    promotion_update: false,
    promotion_detail: false,

    wallet_list: false,
    wallet_history: false,
    add_money_to_wallet: false,
    adjust_order_with_wallet_balance: false,
    auto_adjust_order_with_wallet_balance: false,

    user_create: false,
    user_list: false,
    user_update: false,
    user_detail: false,
    //new
    user_delete: false,
    view_user_route_history: false,
    connect_user_to_org: false,
    bulk_update_user: false,
    user_mobile_heartbeat_logs: false,
    assigned_role_to_users: false,

    //new
    roles_and_permission_create: false,
    roles_and_permission_list: false,
    roles_and_permission_update: false,
    roles_and_permission_detail: false,
    roles_and_permission_delete: false,
    add_or_update_permissions_to_roles: false,
    view_permissions_against_roles: false,
    view_users_against_roles: false,

    supplier_create: false,
    supplier_list: false,
    supplier_update: false,
    supplier_detail: false,
    supplier_delete: false,

    //new
    item_create: false,
    item_list: false,
    item_update: false,
    item_detail: false,
    item_delete: false,
    get_all_items: false,

    //new
    item_type_create: false,
    item_type_list: false,
    item_type_update: false,
    item_type_detail: false,
    item_type_delete: false,

    product_create: false,
    product_list: false,
    product_update: false,
    product_detail: false,
    //new
    product_delete: false,

    product_category_create: false,
    product_category_list: false,
    product_category_detail: false,
    product_category_update: false,

    unit_create: false,
    unit_list: false,
    unit_update: false,
    unit_detail: false,
    //new
    unit_delete: false,

    procedure_create: false,
    procedure_list: false,
    procedure_update: false,
    procedure_detail: false,
    //new
    procedure_delete: false,

    // payment_create: false,
    // payment_list: false,
    // payment_update: false,
    // payment_detail: false,

    // country_create: false,
    // country_list: false,
    // country_update: false,
    // country_detail: false,
    // country_delete: false,

    route_create: false,
    route_list: false,
    route_update: false,
    route_detail: false,
    //new
    route_delete: false,

    // task_comment_update: false,
    // task_comment_list: false,

    quick_attendance_create: false,
    quick_attendance_list: false,
    quick_attendance_update: false,
    quick_attendance_detail: false,
    //new
    quick_attendance_checkin: false,
    quick_attendance_checkout: false,
    quick_attendance_delete: false,
    quick_attendance_view_route: false,

    //new
    leave_application_create: false,
    leave_application_list: false,
    leave_application_update: false,
    leave_application_detail: false,
    leave_application_delete: false,

    // container_create: false,
    // container_list: false,
    // container_update: false,
    // container_detail: false,

    // parcel_create: false,
    // parcel_list: false,
    // parcel_update: false,
    // parcel_detail: false,

    territory_create: false,
    territory_list: false,
    territory_update: false,
    territory_detail: false,
    //new
    territory_delete: false,

    // mobile_heartbeat_log_create: false,
    // mobile_heartbeat_log_list: false,

    // location_create: false,
    // location_list: false,
    // location_update: false,
    // location_detail: false,
    // offline_location_create: false,

    // get_user_loc_route: false,

    roaster_create: false,
    roaster_list: false,
    roaster_update: false,
    roaster_detail: false,
    //new
    delete_user_from_roaster: false,
    roaster_delete: false,

    roaster_plan_create: false,
    roaster_plan_list: false,
    roaster_plan_update: false,
    roaster_plan_detail: false,
    //new
    roaster_plan_delete: false,

    //new
    visit_plan_create: false,
    visit_plan_list: false,
    visit_plan_update: false,
    visit_plan_detail: false,
    visit_plan_delete: false,
    generate_chain_task_from_selected_plan: false,

    //new
    incident_create: false,
    incident_list: false,
    incident_update: false,
    incident_detail: false,
    incident_delete: false,

    //new
    attendance_settings: false,
    inventory_connection_settings: false,
    tracking_settings: false,
    subscription_billing_settings: false,
    order_settings: false,
    sms_settings: false,
    email_settings: false,

    //new
    attendance_organizational_report: false,
    attendance_organizational_report_with_custom_fields: false,
    single_person_attendance_report: false,
    leave_organizational_report: false,
    daily_task_report: false,
    task_report_based_on_filters: false,
    task_report_based_on_users: false,
    order_report: false,
    asset_report: false,
    multiple_inventory_summary_report: false,
    inventory_detail_report: false,
    inventory_stock_history_report: false,
    inventory_closing_history_report: false,
    inventory_low_stock_report: false,
    download_inventory_details_report: false,
    due_collection_report: false,
    //new
    access_all_data: false,
    access_suborg_data: false,
    access_own_data: false,

    //service and plan
    attendance: false,
    entity: false,
    order: false,
    order_to_task_generation: false,
    requisition: false,
    task: false,
    territory: false,
    visit_plan_to_task_generation: false,

    //inventory
    create_inventory: false,
    modify_inventory_stock: false,
    update_inventory_closing_value: false,
    inventory_list: false,
    inventory_detail: false,
    inventory_closing_history: false,
    inventory_stock_history: false,
    inventory_single_product_stock_history: false,
    inventory_internal_transfer: false,
    orders_by_inventory_product: false,

    //sub_org
    sub_org_type_create: false,
    sub_org_type_list: false,
    sub_org_type_detail: false,
    sub_org_type_update: false,
    sub_org_create: false,
    sub_org_list: false,
    sub_org_detail: false,
    sub_org_update: false,

    //qr_code
    qr_code_create: false,
    qr_code_list: false,
    qr_code_detail: false,

    //due_collection
    due_collection_list: false,
    due_collection_request_handover: false,

    //handover_request
    handover_request_create: false,
    handover_request_list: false,
    handover_request_approve: false,

    //custom_form
    custom_form_create: false,
    custom_form_list: false,
    custom_form_update: false,
    custom_form_detail: false,
    custom_form_delete: false,
    custom_form_data: false,
  };
  componentDidMount() {
    this.setState({ role_id: this.props.location.state.role_id });
    this.getOrgPlanService();
    this.getRole();
  }

  getOrgPlanService = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ORG_PLAN_SERVICE_CHECK}${
        GET_USER_LOCAL().organization.$oid
      }?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("org plan service check", response);
        this.setState({
          attendance: response.data?.data?.attendance ?? false,
          entity: response.data?.data?.entity ?? false,
          order: response.data?.data?.order ?? false,
          order_to_task_generation:
            response.data?.data?.orders_to_task_generation ?? false,
          requisition: response.data?.data?.requisition ?? false,
          task: response.data?.data?.task ?? false,
          territory: response.data?.data?.territory ?? false,
          visit_plan_to_task_generation:
            response.data?.data?.visit_plan_to_task_generation ?? false,
        });
      });
    });
  };

  getRole = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let role_id = this.props.location.state.role_id;
      let url = `${EDIT_ROLE}${role_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit role", response);
        this.setState({
          name: response.data.data.name,
          description: response.data.data.description,
          asset_create: response.data.data.permissions.asset.asset_create,
          asset_list: response.data.data.permissions.asset.asset_list,
          asset_update: response.data.data.permissions.asset.asset_update,
          asset_detail: response.data.data.permissions.asset.asset_detail,
          asset_delete: response.data.data.permissions.asset.asset_delete,
          add_reminder: response.data.data.permissions.asset.add_reminder,
          connect_asset_to_user:
            response.data.data.permissions.asset.connect_asset_to_user,
          connect_asset_to_finder_device:
            response.data.data.permissions.asset.connect_asset_to_finder_device,
          connect_to_finder:
            response.data.data.permissions.asset.connect_to_finder,

          attendance_create:
            response.data.data.permissions.attendance.attendance
              .attendance_create,
          attendance_list:
            response.data.data.permissions.attendance.attendance
              .attendance_list,
          attendance_detail:
            response.data.data.permissions.attendance.attendance
              .attendance_detail,
          attendance_create_for:
            response.data.data.permissions.attendance.attendance
              .attendance_create_for,
          attendance_checkin:
            response.data.data.permissions.attendance.attendance
              .attendance_checkin,
          attendance_checkout:
            response.data.data.permissions.attendance.attendance
              .attendance_checkout,
          attendance_delete:
            response.data.data.permissions.attendance.attendance
              .attendance_delete,
          attendance_view_route:
            response.data.data.permissions.attendance.attendance
              .attendance_view_route,

          custom_fields_create:
            response.data.data.permissions.custom_field.custom_fields_create,
          custom_fields_list:
            response.data.data.permissions.custom_field.custom_fields_list,
          custom_fields_update:
            response.data.data.permissions.custom_field.custom_fields_update,
          custom_fields_detail:
            response.data.data.permissions.custom_field.custom_fields_detail,
          custom_fields_delete:
            response.data.data.permissions.custom_field.custom_fields_delete,

          organization_create:
            response.data.data.permissions.organization.organization_profile
              .organization_create,
          organization_list:
            response.data.data.permissions.organization.organization_profile
              .organization_list,
          organization_update:
            response.data.data.permissions.organization.organization_profile
              .organization_update,
          organization_detail:
            response.data.data.permissions.organization.organization_profile
              .organization_detail,
          organization_delete:
            response.data.data.permissions.organization.organization_profile
              .organization_delete,
          add_plan_subscription:
            response.data.data.permissions.organization.organization_profile
              .add_plan_subscription,
          change_plan_subscription:
            response.data.data.permissions.organization.organization_profile
              .change_plan_subscription,
          monthly_billing_view:
            response.data.data.permissions.organization.organization_profile
              .monthly_billing_view,
          monthly_payment_view:
            response.data.data.permissions.organization.organization_profile
              .monthly_payment_view,
          change_organization:
            response.data.data.permissions.organization.change_organization,

          attendance_settings:
            response.data.data.permissions.organizational_setting
              .attendance_settings,
          inventory_connection_settings:
            response.data.data.permissions.organizational_setting
              .inventory_connection_settings,
          tracking_settings:
            response.data.data.permissions.organizational_setting
              .tracking_settings,
          subscription_billing_settings:
            response.data.data.permissions.organizational_setting
              .subscription_billing_settings,
          order_settings: response.data.data.permissions.organizational_setting.order_settings,
          sms_settings: response.data.data.permissions.organizational_setting.sms_settings,
          email_settings: response.data.data.permissions.organizational_setting.email_settings,

          place_create: response.data.data.permissions.place.place_create,
          place_list: response.data.data.permissions.place.place_list,
          place_update: response.data.data.permissions.place.place_update,
          update_is_inventory_in_place:
            response.data.data.permissions.place.update_is_inventory_in_place,
          view_place_detail:
            response.data.data.permissions.place.place_detail.view_place_detail,
          update_custom_field_data_related_to_place:
            response.data.data.permissions.place.place_detail
              .update_custom_field_data_related_to_place,
          view_custom_field_data_related_to_place:
            response.data.data.permissions.place.place_detail
              .view_custom_field_data_related_to_place,
          nearby_place_search:
            response.data.data.permissions.place.nearby_place_search,
          place_delete: response.data.data.permissions.place.place_delete,
          add_place_reminder:
            response.data.data.permissions.place.add_place_reminder,

          // plan_create: response.data.data.permissions.plan.plan_create,
          // plan_list: response.data.data.permissions.plan.plan_list,
          // plan_update: response.data.data.permissions.plan.plan_update,
          // plan_detail: response.data.data.permissions.plan.plan_detail,

          // invoice_create: response.data.data.permissions.invoice.invoice_create,
          // invoice_list: response.data.data.permissions.invoice.invoice_list,
          // invoice_update: response.data.data.permissions.invoice.invoice_update,
          // invoice_detail: response.data.data.permissions.invoice.invoice_detail,
          // invoice_delete: response.data.data.permissions.invoice.invoice_delete,

          task_create: response.data.data.permissions.task.task.task_create,
          task_list: response.data.data.permissions.task.task.task_list,
          task_update: response.data.data.permissions.task.task.task_update,
          view_task_detail: response.data.data.permissions.task.task.task_detail.view_task_detail,
          task_delete: response.data.data.permissions.task.task.task_delete,
          task_view_route:
            response.data.data.permissions.task.task.task_view_route,
          task_add_reminder:
            response.data.data.permissions.task.task.task_add_reminder,
          add_comment:
            response.data.data.permissions.task.task.task_detail.add_comment,
          view_comment_list:
            response.data.data.permissions.task.task.task_detail
              .view_comment_list,
          update_comment:
            response.data.data.permissions.task.task.task_detail.update_comment,
          delete_comment:
            response.data.data.permissions.task.task.task_detail.delete_comment,
          update_custom_field_data_related_to_task:
            response.data.data.permissions.task.task.task_detail
              .update_custom_field_data_related_to_task,
          view_custom_field_data_related_to_task:
            response.data.data.permissions.task.task.task_detail
              .view_custom_field_data_related_to_task,

          bill_create: response.data.data.permissions.bill.bill.bill_create,
          bill_list: response.data.data.permissions.bill.bill.bill_list,
          bill_list_search:
            response.data.data.permissions.bill.bill.bill_list_search,
          bill_update: response.data.data.permissions.bill.bill.bill_update,
          bill_detail: response.data.data.permissions.bill.bill.bill_detail,
          bill_type_list:
            response.data.data.permissions.bill.bill.bill_type_list,
          conveyance_bill_approval_api:
            response.data.data.permissions.bill.bill
              .conveyance_bill_approval_api,
          bill_item_udpade:
            response.data.data.permissions.bill.bill.bill_item_udpade,
          get_miscellaneous_bill_type_choices:
            response.data.data.permissions.bill.bill
              .get_miscellaneous_bill_type_choices,

          requisition_create:
            response.data.data.permissions.requisition.requisition_create,
          requisition_list:
            response.data.data.permissions.requisition.requisition_list,
          requisition_list_search:
            response.data.data.permissions.requisition.requisition_list_search,
          requisition_update:
            response.data.data.permissions.requisition.requisition_update,
          requisition_detail:
            response.data.data.permissions.requisition.requisition_detail,
          requisition_delete:
            response.data.data.permissions.requisition.requisition_delete,
          generate_bill_from_requisition:
            response.data.data.permissions.requisition
              .generate_bill_from_requisition,
          generate_order_from_requisition:
            response.data.data.permissions.requisition
              .generate_order_from_requisition,

          order_create:
            response.data.data.permissions.order_and_delivery.order
              .order_create,
          order_list:
            response.data.data.permissions.order_and_delivery.order.order_list,
          purchase_order_create:
            response.data.data.permissions.order_and_delivery.order
              .purchase_order_create,
          purchase_order_list:
            response.data.data.permissions.order_and_delivery.order
              .purchase_order_list,
          purchase_order_detail:
            response.data.data.permissions.order_and_delivery.order
              .purchase_order_detail,
          change_delivery_time:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .change_delivery_time,
          order_product_status_update:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .order_product_status_update,
          order_location_update:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .order_location_update,
          add_received_amount:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .add_received_amount,
          add_refund_amount:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .add_refund_amount,
          update_custom_field_data_related_to_order:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .update_custom_field_data_related_to_order,
          view_custom_field_data_related_to_order:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .view_custom_field_data_related_to_order,
          order_delete:
            response.data.data.permissions.order_and_delivery.order
              .order_delete,
          order_update:
            response.data.data.permissions.order_and_delivery.order
              .order_update,
          order_add_reminder:
            response.data.data.permissions.order_and_delivery.order
              .order_add_reminder,
          download_order_detailed_to_order:
            response.data.data.permissions.order_and_delivery.order
              .download_order_detailed_to_order,
          order_related_payment_method_and_status_update:
            response.data.data.permissions.order_and_delivery.order
              .order_related_payment_method_and_status_update,
          order_payment_method_and_status_update_from_task:
            response.data.data.permissions.order_and_delivery.order
              .order_payment_method_and_status_update_from_task,
          order_payment_method_choices:
            response.data.data.permissions.order_and_delivery.order
              .order_payment_method_choices,
          order_discount_type_choices:
            response.data.data.permissions.order_and_delivery.order
              .order_discount_type_choices,
          order_item_update:
            response.data.data.permissions.order_and_delivery.order
              .order_item_update,
          generate_task_from_order:
            response.data.data.permissions.order_and_delivery.order
              .generate_task_from_order,
          generate_multi_task_from_selected_order:
            response.data.data.permissions.order_and_delivery.order
              .generate_multi_task_from_selected_order,
          generate_chain_task_from_selected_order:
            response.data.data.permissions.order_and_delivery.order
              .generate_chain_task_from_selected_order,
          generate_route_suggestion_from_selected_order:
            response.data.data.permissions.order_and_delivery.order
              .generate_route_suggestion_from_selected_order,

          view_order_detail:
            response.data.data.permissions.order_and_delivery.order.order_detail
              .view_order_detail,
          return_order_create:
            response.data.data.permissions.order_and_delivery.order
              .return_order_create,
          return_order_list:
            response.data.data.permissions.order_and_delivery.order
              .return_order_list,
          return_order_detail:
            response.data.data.permissions.order_and_delivery.order
              .return_order_detail,
          pos_order_create:
            response.data.data.permissions.order_and_delivery.order
              .pos_order_create,
          pos_order_list:
            response.data.data.permissions.order_and_delivery.order
              .pos_order_list,
          pos_order_detail:
            response.data.data.permissions.order_and_delivery.order
              .pos_order_detail,

          promotion_create: response.data.data.permissions.order_and_delivery.promotion.promotion_create,
          promotion_list: response.data.data.permissions.order_and_delivery.promotion.promotion_list,
          promotion_update: response.data.data.permissions.order_and_delivery.promotion.promotion_update,
          promotion_detail: response.data.data.permissions.order_and_delivery.promotion.promotion_detail,

                wallet_list: response.data.data.permissions.order_and_delivery.wallet.wallet_list,
          wallet_history: response.data.data.permissions.order_and_delivery.wallet.wallet_history,
          add_money_to_wallet: response.data.data.permissions.order_and_delivery.wallet.add_money_to_wallet,
          adjust_order_with_wallet_balance: response.data.data.permissions.order_and_delivery.wallet.adjust_order_with_wallet_balance,
          auto_adjust_order_with_wallet_balance: response.data.data.permissions.order_and_delivery.wallet.auto_adjust_order_with_wallet_balance,

          user_create: response.data.data.permissions.user.user_create,
          user_list: response.data.data.permissions.user.user_list,
          user_update: response.data.data.permissions.user.user_update,
          user_detail: response.data.data.permissions.user.user_detail,
          user_delete: response.data.data.permissions.user.user_delete,
          view_user_route_history:
            response.data.data.permissions.user.view_user_route_history,
          connect_user_to_org:
            response.data.data.permissions.user.connect_user_to_org,
          bulk_update_user:
            response.data.data.permissions.user.bulk_update_user,
          user_mobile_heartbeat_logs:
            response.data.data.permissions.user.user_mobile_heartbeat_logs,
          assigned_role_to_users:
            response.data.data.permissions.user.assigned_role_to_users,

          roles_and_permission_create:
            response.data.data.permissions.roles_and_permission
              .roles_and_permission_create,
          roles_and_permission_list:
            response.data.data.permissions.roles_and_permission
              .roles_and_permission_list,
          roles_and_permission_update:
            response.data.data.permissions.roles_and_permission
              .roles_and_permission_update,
          roles_and_permission_detail:
            response.data.data.permissions.roles_and_permission
              .roles_and_permission_detail,
          roles_and_permission_delete:
            response.data.data.permissions.roles_and_permission
              .roles_and_permission_delete,
          add_or_update_permissions_to_roles:
            response.data.data.permissions.roles_and_permission
              .add_or_update_permissions_to_roles,
          view_permissions_against_roles:
            response.data.data.permissions.roles_and_permission
              .view_permissions_against_roles,
          view_users_against_roles:
            response.data.data.permissions.roles_and_permission
              .view_users_against_roles,

          supplier_create:
            response.data.data.permissions.bill.supplier.supplier_create,
          supplier_list:
            response.data.data.permissions.bill.supplier.supplier_list,
          supplier_update:
            response.data.data.permissions.bill.supplier.supplier_update,
          supplier_detail:
            response.data.data.permissions.bill.supplier.supplier_detail,
          supplier_delete:
            response.data.data.permissions.bill.supplier.supplier_delete,

          product_create:
            response.data.data.permissions.order_and_delivery.product
              .product_create,
          product_list:
            response.data.data.permissions.order_and_delivery.product
              .product_list,
          product_update:
            response.data.data.permissions.order_and_delivery.product
              .product_update,
          product_detail:
            response.data.data.permissions.order_and_delivery.product
              .product_detail,
          product_delete:
            response.data.data.permissions.order_and_delivery.product
              .product_delete,

          product_category_create: response.data.data.permissions.order_and_delivery.product.product_category.product_category_create,
          product_category_list: response.data.data.permissions.order_and_delivery.product.product_category.product_category_list,
          product_category_detail: response.data.data.permissions.order_and_delivery.product.product_category.product_category_detail,
          product_category_update: response.data.data.permissions.order_and_delivery.product.product_category.product_category_update,

          visit_plan_create:
            response.data.data.permissions.visit_plan.visit_plan_create,
          visit_plan_list:
            response.data.data.permissions.visit_plan.visit_plan_list,
          visit_plan_update:
            response.data.data.permissions.visit_plan.visit_plan_update,
          visit_plan_detail:
            response.data.data.permissions.visit_plan.visit_plan_detail,
          visit_plan_delete:
            response.data.data.permissions.visit_plan.visit_plan_delete,
          generate_chain_task_from_selected_plan:
            response.data.data.permissions.visit_plan
              .generate_chain_task_from_selected_plan,

          incident_create:
            response.data.data.permissions.incident.incident_create,
          incident_list: response.data.data.permissions.incident.incident_list,
          incident_update:
            response.data.data.permissions.incident.incident_update,
          incident_detail:
            response.data.data.permissions.incident.incident_detail,
          incident_delete:
            response.data.data.permissions.incident.incident_delete,

          item_create: response.data.data.permissions.bill.item.item_create,
          item_list: response.data.data.permissions.bill.item.item_list,
          item_update: response.data.data.permissions.bill.item.item_update,
          item_detail: response.data.data.permissions.bill.item.item_detail,
          item_delete: response.data.data.permissions.bill.item.item_delete,
          get_all_items: response.data.data.permissions.bill.item.get_all_items,

          item_type_create:
            response.data.data.permissions.bill.item_type.item_type_create,
          item_type_list:
            response.data.data.permissions.bill.item_type.item_type_list,
          item_type_update:
            response.data.data.permissions.bill.item_type.item_type_update,
          item_type_detail:
            response.data.data.permissions.bill.item_type.item_type_detail,
          item_type_delete:
            response.data.data.permissions.bill.item_type.item_type_delete,

          unit_create:
            response.data.data.permissions.order_and_delivery.unit.unit_create,
          unit_list:
            response.data.data.permissions.order_and_delivery.unit.unit_list,
          unit_update:
            response.data.data.permissions.order_and_delivery.unit.unit_update,
          unit_detail:
            response.data.data.permissions.order_and_delivery.unit.unit_detail,
          unit_delete:
            response.data.data.permissions.order_and_delivery.unit.unit_delete,

          procedure_create:
            response.data.data.permissions.procedure.procedure_create,
          procedure_list:
            response.data.data.permissions.procedure.procedure_list,
          procedure_update:
            response.data.data.permissions.procedure.procedure_update,
          procedure_detail:
            response.data.data.permissions.procedure.procedure_detail,
          procedure_delete:
            response.data.data.permissions.procedure.procedure_delete,

          // payment_create: response.data.data.permissions.payment.payment_create,
          // payment_list: response.data.data.permissions.payment.payment_list,
          // payment_update: response.data.data.permissions.payment.payment_update,
          // payment_detail: response.data.data.permissions.payment.payment_detail,

          // country_create: response.data.data.permissions.country.country_create,
          // country_list: response.data.data.permissions.country.country_list,
          // country_update: response.data.data.permissions.country.country_update,
          // country_detail: response.data.data.permissions.country.country_detail,
          // country_delete: response.data.data.permissions.country.country_delete,

          route_create: response.data.data.permissions.task.route.route_create,
          route_list: response.data.data.permissions.task.route.route_list,
          route_update: response.data.data.permissions.task.route.route_update,
          route_detail: response.data.data.permissions.task.route.route_detail,
          route_delete: response.data.data.permissions.task.route.route_delete,

          // task_comment_update:
          //   response.data.data.permissions.task_comment.task_comment_update,
          // task_comment_list:
          //   response.data.data.permissions.task_comment.task_comment_list,

          quick_attendance_create:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_create,
          quick_attendance_list:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_list,
          quick_attendance_update:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_update,
          quick_attendance_detail:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_detail,
          quick_attendance_checkin:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_checkin,
          quick_attendance_checkout:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_checkout,
          quick_attendance_delete:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_delete,
          quick_attendance_view_route:
            response.data.data.permissions.attendance.quick_attendance
              .quick_attendance_view_route,

          leave_application_create:
            response.data.data.permissions.attendance.leave_application
              .leave_application_create,
          leave_application_list:
            response.data.data.permissions.attendance.leave_application
              .leave_application_list,
          leave_application_update:
            response.data.data.permissions.attendance.leave_application
              .leave_application_update,
          leave_application_detail:
            response.data.data.permissions.attendance.leave_application
              .leave_application_detail,
          leave_application_delete:
            response.data.data.permissions.attendance.leave_application
              .leave_application_delete,

          task_type_create:
            response.data.data.permissions.task.task_type.task_type_create,
          task_type_list:
            response.data.data.permissions.task.task_type.task_type_list,
          task_type_update:
            response.data.data.permissions.task.task_type.task_type_update,
          task_type_detail:
            response.data.data.permissions.task.task_type.task_type_detail,
          task_type_delete:
            response.data.data.permissions.task.task_type.task_type_delete,

          // container_create:
          //   response.data.data.permissions.container.container_create,
          // container_list:
          //   response.data.data.permissions.container.container_list,
          // container_update:
          //   response.data.data.permissions.container.container_update,
          // container_detail:
          //   response.data.data.permissions.container.container_detail,

          // parcel_create: response.data.data.permissions.parcel.parcel_create,
          // parcel_list: response.data.data.permissions.parcel.parcel_list,
          // parcel_update: response.data.data.permissions.parcel.parcel_update,
          // parcel_detail: response.data.data.permissions.parcel.parcel_detail,

          territory_create:
            response.data.data.permissions.order_and_delivery.territory
              .territory_create,
          territory_list:
            response.data.data.permissions.order_and_delivery.territory
              .territory_list,
          territory_update:
            response.data.data.permissions.order_and_delivery.territory
              .territory_update,
          territory_detail:
            response.data.data.permissions.order_and_delivery.territory
              .territory_detail,
          territory_delete:
            response.data.data.permissions.order_and_delivery.territory
              .territory_delete,

          // mobile_heartbeat_log_create:
          //   response.data.data.permissions.mobile_heartbeat_log
          //     .mobile_heartbeat_log_create,
          // mobile_heartbeat_log_list:
          //   response.data.data.permissions.mobile_heartbeat_log
          //     .mobile_heartbeat_log_list,

          // location_create:
          //   response.data.data.permissions.location.location_create,
          // location_list: response.data.data.permissions.location.location_list,
          // location_update:
          //   response.data.data.permissions.location.location_update,
          // location_detail:
          //   response.data.data.permissions.location.location_detail,
          // offline_location_create:
          //   response.data.data.permissions.location.offline_location_create,

          // get_user_loc_route:
          //   response.data.data.permissions.user_location_route
          //     .get_user_loc_route,

          roaster_create:
            response.data.data.permissions.attendance.roaster.roaster_create,
          roaster_list:
            response.data.data.permissions.attendance.roaster.roaster_list,
          roaster_update:
            response.data.data.permissions.attendance.roaster.roaster_update,
          roaster_detail:
            response.data.data.permissions.attendance.roaster.roaster_detail,
          roaster_delete:
            response.data.data.permissions.attendance.roaster.roaster_delete,
          delete_user_from_roaster:
            response.data.data.permissions.attendance.roaster
              .delete_user_from_roaster,

          roaster_plan_create:
            response.data.data.permissions.attendance.roaster_plan
              .roaster_plan_create,
          roaster_plan_list:
            response.data.data.permissions.attendance.roaster_plan
              .roaster_plan_list,
          roaster_plan_update:
            response.data.data.permissions.attendance.roaster_plan
              .roaster_plan_update,
          roaster_plan_detail:
            response.data.data.permissions.attendance.roaster_plan
              .roaster_plan_detail,
          roaster_plan_delete:
            response.data.data.permissions.attendance.roaster_plan
              .roaster_delete,

          attendance_organizational_report:
            response.data.data.permissions.report.attendance_report
              .attendance_organizational_report,
          attendance_organizational_report_with_custom_fields:
            response.data.data.permissions.report.attendance_report
              .attendance_organizational_report_with_custom_fields,
          single_person_attendance_report:
            response.data.data.permissions.report.attendance_report
              .single_person_attendance_report,
          leave_organizational_report:
            response.data.data.permissions.report.attendance_report
              .leave_organizational_report,
          daily_task_report:
            response.data.data.permissions.report.task_report.daily_task_report,
          task_report_based_on_filters:
            response.data.data.permissions.report.task_report
              .task_report_based_on_filters,
          task_report_based_on_users:
            response.data.data.permissions.report.task_report
              .task_report_based_on_users,
          order_report: response.data.data.permissions.report.order_report,
          asset_report: response.data.data.permissions.report.asset_report,
          multiple_inventory_summary_report:
            response.data.data.permissions.report.inventory_report
              .multiple_inventory_summary_report,
          inventory_detail_report:
            response.data.data.permissions.report.inventory_report
              .inventory_detail_report,
          inventory_stock_history_report:
            response.data.data.permissions.report.inventory_report
              .inventory_stock_history_report,
          inventory_closing_history_report:
            response.data.data.permissions.report.inventory_report
              .inventory_closing_history_report,
          inventory_low_stock_report:
            response.data.data.permissions.report.inventory_report
              .inventory_low_stock_report,
          download_inventory_details_report:
            response.data.data.permissions.report.inventory_report
              .download_inventory_details_report,
          due_collection_report:
            response.data.data.permissions.report.due_collection_report,

          access_all_data:
            response.data.data.permissions.data_level.access_all_data,
          access_suborg_data:
            response.data.data.permissions.data_level.access_suborg_data,
          access_own_data:
            response.data.data.permissions.data_level.access_own_data,

          create_inventory: response.data.data.permissions.inventory.create_inventory,
          modify_inventory_stock: response.data.data.permissions.inventory.modify_inventory_stock,
          update_inventory_closing_value: response.data.data.permissions.inventory.update_inventory_closing_value,
          inventory_list: response.data.data.permissions.inventory.inventory_list,
          inventory_detail: response.data.data.permissions.inventory.inventory_detail,
          inventory_closing_history: response.data.data.permissions.inventory.inventory_closing_history,
          inventory_stock_history: response.data.data.permissions.inventory.inventory_stock_history,
          inventory_single_product_stock_history: response.data.data.permissions.inventory.inventory_single_product_stock_history,
          inventory_internal_transfer: response.data.data.permissions.inventory.inventory_internal_transfer,
          orders_by_inventory_product: response.data.data.permissions.inventory.orders_by_inventory_product,

          sub_org_type_create:
            response.data.data.permissions.sub_org.sub_org_type
              .sub_org_type_create,
          sub_org_type_list:
            response.data.data.permissions.sub_org.sub_org_type
              .sub_org_type_list,
          sub_org_type_detail:
            response.data.data.permissions.sub_org.sub_org_type
              .sub_org_type_detail,
          sub_org_type_update:
            response.data.data.permissions.sub_org.sub_org_type
              .sub_org_type_update,
          sub_org_create: response.data.data.permissions.sub_org.sub_org_create,
          sub_org_list: response.data.data.permissions.sub_org.sub_org_list,
          sub_org_detail: response.data.data.permissions.sub_org.sub_org_detail,
          sub_org_update: response.data.data.permissions.sub_org.sub_org_update,

          qr_code_create: response.data.data.permissions.qr_code.qr_code_create,
          qr_code_list: response.data.data.permissions.qr_code.qr_code_list,
          qr_code_detail: response.data.data.permissions.qr_code.qr_code_detail,

          due_collection_list: response.data.data.permissions.due_collection.due_collection_list,
          due_collection_request_handover: response.data.data.permissions.due_collection.due_collection_request_handover,

          handover_request_create: response.data.data.permissions.handover_request.handover_request_create,
          handover_request_list: response.data.data.permissions.handover_request.handover_request_list,
          handover_request_approve: response.data.data.permissions.handover_request.handover_request_approve,

          custom_form_create:
            response.data.data.permissions.custom_form.custom_form_create,
          custom_form_list:
            response.data.data.permissions.custom_form.custom_form_list,
          custom_form_update:
            response.data.data.permissions.custom_form.custom_form_update,
          custom_form_detail:
            response.data.data.permissions.custom_form.custom_form_detail,
          custom_form_delete:
            response.data.data.permissions.custom_form.custom_form_delete,
          custom_form_data:
            response.data.data.permissions.custom_form.custom_form_data,
        });
      });
    });
  };

  updatePermission = () => {
    const data = {
      name: this.state.name,
      description: this.state.description,
      permissions: {
        asset: {
          asset_create: this.state.asset_create,
          asset_list: this.state.asset_list,
          asset_update: this.state.asset_update,
          asset_detail: this.state.asset_detail,
          asset_delete: this.state.asset_delete,
          add_reminder: this.state.add_reminder,
          connect_asset_to_user: this.state.connect_asset_to_user,
          connect_asset_to_finder_device:
            this.state.connect_asset_to_finder_device,
          connect_to_finder: this.state.connect_to_finder,
        },
        attendance: {
          roaster_plan: {
            roaster_plan_create: this.state.roaster_plan_create,
            roaster_plan_list: this.state.roaster_plan_list,
            roaster_plan_update: this.state.roaster_plan_update,
            roaster_plan_detail: this.state.roaster_plan_detail,
            roaster_delete: this.state.roaster_plan_delete,
          },
          roaster: {
            roaster_create: this.state.roaster_create,
            roaster_list: this.state.roaster_list,
            roaster_update: this.state.roaster_update,
            roaster_detail: this.state.roaster_detail,
            delete_user_from_roaster: this.state.delete_user_from_roaster,
            roaster_delete: this.state.roaster_delete,
          },
          attendance: {
            attendance_create: this.state.attendance_create,
            attendance_create_for: this.state.attendance_create_for,
            attendance_checkin: this.state.attendance_checkin,
            attendance_checkout: this.state.attendance_checkout,
            attendance_list: this.state.attendance_list,
            attendance_detail: this.state.attendance_detail,
            attendance_delete: this.state.attendance_delete,
            attendance_view_route: this.state.attendance_view_route,
          },
          quick_attendance: {
            quick_attendance_create: this.state.quick_attendance_create,
            quick_attendance_checkin: this.state.quick_attendance_checkin,
            quick_attendance_checkout: this.state.quick_attendance_checkout,
            quick_attendance_list: this.state.quick_attendance_list,
            quick_attendance_update: this.state.quick_attendance_update,
            quick_attendance_detail: this.state.quick_attendance_detail,
            quick_attendance_delete: this.state.quick_attendance_delete,
            quick_attendance_view_route: this.state.quick_attendance_view_route,
          },
          leave_application: {
            leave_application_create: this.state.leave_application_create,
            leave_application_list: this.state.leave_application_list,
            leave_application_update: this.state.leave_application_update,
            leave_application_detail: this.state.leave_application_detail,
            leave_application_delete: this.state.leave_application_delete,
          },
        },

        custom_field: {
          custom_fields_create: this.state.custom_fields_create,
          custom_fields_list: this.state.custom_fields_list,
          custom_fields_update: this.state.custom_fields_update,
          custom_fields_detail: this.state.custom_fields_detail,
          custom_fields_delete: this.state.custom_fields_delete,
        },
        organization: {
          organization_profile: {
            organization_create: this.state.organization_create,
            organization_list: this.state.organization_list,
            organization_update: this.state.organization_update,
            organization_detail: this.state.organization_detail,
            organization_delete: this.state.organization_delete,
            add_plan_subscription: this.state.add_plan_subscription,
            change_plan_subscription: this.state.change_plan_subscription,
            monthly_billing_view: this.state.monthly_billing_view,
            monthly_payment_view: this.state.monthly_payment_view,
          },
          change_organization: this.state.change_organization,
        },
        organizational_setting: {
          attendance_settings: this.state.attendance_settings,
          inventory_connection_settings:
            this.state.inventory_connection_settings,
          tracking_settings: this.state.tracking_settings,
          subscription_billing_settings:
            this.state.subscription_billing_settings,
          order_settings: this.state.order_settings,
          sms_settings: this.state.sms_settings,
          email_settings: this.state.email_settings,
        },
        place: {
          place_create: this.state.place_create,
          place_list: this.state.place_list,
          place_update: this.state.place_update,
          update_is_inventory_in_place: this.state.update_is_inventory_in_place,
          place_detail: {
            update_custom_field_data_related_to_place:
              this.state.update_custom_field_data_related_to_place,
            view_place_detail: this.state.view_place_detail,
            view_custom_field_data_related_to_place:
              this.state.view_custom_field_data_related_to_place,
          },
          place_delete: this.state.place_delete,
          add_place_reminder: this.state.add_place_reminder,
          nearby_place_search: this.state.nearby_place_search,
        },
        bill: {
          supplier: {
            supplier_create: this.state.supplier_create,
            supplier_list: this.state.supplier_list,
            supplier_update: this.state.supplier_update,
            supplier_detail: this.state.supplier_detail,
            supplier_delete: this.state.supplier_delete,
          },
          item: {
            item_create: this.state.item_create,
            item_list: this.state.item_list,
            item_update: this.state.item_update,
            item_detail: this.state.item_detail,
            item_delete: this.state.item_delete,
            get_all_items: this.state.get_all_items,
          },
          item_type: {
            item_type_create: this.state.item_type_create,
            item_type_list: this.state.item_type_list,
            item_type_update: this.state.item_type_update,
            item_type_detail: this.state.item_type_detail,
            item_type_delete: this.state.item_type_delete,
          },
          bill: {
            bill_create: this.state.bill_create,
            bill_list: this.state.bill_list,
            bill_list_search: this.state.bill_list_search,
            bill_update: this.state.bill_update,
            bill_detail: this.state.bill_detail,
            bill_type_list: this.state.bill_type_list,
            conveyance_bill_approval_api:
              this.state.conveyance_bill_approval_api,
            bill_item_udpade: this.state.bill_item_udpade,
            get_miscellaneous_bill_type_choices:
              this.state.get_miscellaneous_bill_type_choices,
          },
        },
        requisition: {
          requisition_create: this.state.requisition_create,
          requisition_list: this.state.requisition_list,
          requisition_list_search: this.state.requisition_list_search,
          requisition_update: this.state.requisition_update,
          requisition_detail: this.state.requisition_detail,
          requisition_delete: this.state.requisition_delete,
          generate_bill_from_requisition:
            this.state.generate_bill_from_requisition,
          generate_order_from_requisition:
            this.state.generate_order_from_requisition,
        },
        user: {
          user_create: this.state.user_create,
          user_list: this.state.user_list,
          user_update: this.state.user_update,
          user_detail: this.state.user_detail,
          user_delete: this.state.user_delete,
          view_user_route_history: this.state.view_user_route_history,
          connect_user_to_org: this.state.connect_user_to_org,
          bulk_update_user: this.state.bulk_update_user,
          user_mobile_heartbeat_logs: this.state.user_mobile_heartbeat_logs,
          assigned_role_to_users: this.state.assigned_role_to_users,
        },
        roles_and_permission: {
          roles_and_permission_create: this.state.roles_and_permission_create,
          roles_and_permission_list: this.state.roles_and_permission_list,
          roles_and_permission_update: this.state.roles_and_permission_update,
          roles_and_permission_detail: this.state.roles_and_permission_detail,
          roles_and_permission_delete: this.state.roles_and_permission_delete,
          add_or_update_permissions_to_roles:
            this.state.add_or_update_permissions_to_roles,
          view_permissions_against_roles:
            this.state.view_permissions_against_roles,
          view_users_against_roles: this.state.view_users_against_roles,
        },
        visit_plan: {
          visit_plan_create: this.state.visit_plan_create,
          visit_plan_list: this.state.visit_plan_list,
          visit_plan_update: this.state.visit_plan_update,
          visit_plan_detail: this.state.visit_plan_detail,
          visit_plan_delete: this.state.visit_plan_delete,
          generate_chain_task_from_selected_plan:
            this.state.generate_chain_task_from_selected_plan,
        },
        incident: {
          incident_create: this.state.incident_create,
          incident_list: this.state.incident_list,
          incident_update: this.state.incident_update,
          incident_detail: this.state.incident_detail,
          incident_delete: this.state.incident_delete,
        },
        procedure: {
          procedure_create: this.state.procedure_create,
          procedure_list: this.state.procedure_list,
          procedure_update: this.state.procedure_update,
          procedure_detail: this.state.procedure_detail,
          procedure_delete: this.state.procedure_delete,
        },
        task: {
          task_type: {
            task_type_create: this.state.task_type_create,
            task_type_list: this.state.task_type_list,
            task_type_update: this.state.task_type_update,
            task_type_detail: this.state.task_type_detail,
            task_type_delete: this.state.task_type_delete,
          },
          route: {
            route_create: this.state.route_create,
            route_list: this.state.route_list,
            route_update: this.state.route_update,
            route_detail: this.state.route_detail,
            route_delete: this.state.route_delete,
          },
          task: {
            task_create: this.state.task_create,
            task_list: this.state.task_list,
            task_update: this.state.task_update,
            task_detail: {
              add_comment: this.state.add_comment,
              view_task_detail: this.state.view_task_detail,
              view_comment_list: this.state.view_comment_list,
              update_comment: this.state.update_comment,
              delete_comment: this.state.delete_comment,
              update_custom_field_data_related_to_task:
                this.state.update_custom_field_data_related_to_task,
              view_custom_field_data_related_to_task:
                this.state.view_custom_field_data_related_to_task,
            },
            task_delete: this.state.task_delete,
            task_view_route: this.state.task_view_route,
            task_add_reminder: this.state.task_add_reminder,
          },
        },
        order_and_delivery: {
          unit: {
            unit_create: this.state.unit_create,
            unit_list: this.state.unit_list,
            unit_update: this.state.unit_update,
            unit_detail: this.state.unit_detail,
            unit_delete: this.state.unit_delete,
          },
          product: {
            product_create: this.state.product_create,
            product_list: this.state.product_list,
            product_update: this.state.product_update,
            product_detail: this.state.product_detail,
            product_delete: this.state.product_delete,
            product_category: {
              product_category_create: this.state.product_category_create,
              product_category_list: this.state.product_category_list,
              product_category_detail: this.state.product_category_detail,
              product_category_update: this.state.product_category_update,
            },
          },
          territory: {
            territory_create: this.state.territory_create,
            territory_list: this.state.territory_list,
            territory_update: this.state.territory_update,
            territory_detail: this.state.territory_detail,
            territory_delete: this.state.territory_delete,
          },
          promotion: {
            promotion_create: this.state.promotion_create,
            promotion_list: this.state.promotion_list,
            promotion_update: this.state.promotion_update,
            promotion_detail: this.state.promotion_detail,
          },
          wallet: {
            wallet_list: this.state.wallet_list,
            wallet_history: this.state.wallet_history,
            add_money_to_wallet: this.state.add_money_to_wallet,
            adjust_order_with_wallet_balance:
              this.state.adjust_order_with_wallet_balance,
            auto_adjust_order_with_wallet_balance:
              this.state.auto_adjust_order_with_wallet_balance,
          },
          order: {
            order_create: this.state.order_create,
            order_list: this.state.order_list,
            order_update: this.state.order_update,
            purchase_order_create: this.state.purchase_order_create,
            purchase_order_detail: this.state.purchase_order_detail,
            purchase_order_list: this.state.purchase_order_list,
            return_order_create: this.state.return_order_create,
            return_order_list: this.state.return_order_list,
            return_order_detail: this.state.return_order_detail,
            pos_order_create: this.state.pos_order_create,
            pos_order_list: this.state.pos_order_list,
            pos_order_detail: this.state.pos_order_detail,
            order_detail: {
              change_delivery_time: this.state.change_delivery_time,
              view_order_detail: this.state.view_order_detail,
              order_product_status_update:
                this.state.order_product_status_update,
              order_location_update: this.state.order_location_update,
              add_received_amount: this.state.add_received_amount,
              add_refund_amount: this.state.add_refund_amount,
              update_custom_field_data_related_to_order:
                this.state.update_custom_field_data_related_to_order,
              view_custom_field_data_related_to_order:
                this.state.view_custom_field_data_related_to_order,
            },
            order_delete: this.state.order_delete,
            order_add_reminder: this.state.order_add_reminder,
            download_order_detailed_to_order:
              this.state.download_order_detailed_to_order,
            order_related_payment_method_and_status_update:
              this.state.order_related_payment_method_and_status_update,
            order_payment_method_and_status_update_from_task:
              this.state.order_payment_method_and_status_update_from_task,
            order_payment_method_choices:
              this.state.order_payment_method_choices,
            order_discount_type_choices: this.state.order_discount_type_choices,
            order_item_update: this.state.order_item_update,
            generate_task_from_order: this.state.generate_task_from_order,
            generate_multi_task_from_selected_order:
              this.state.generate_multi_task_from_selected_order,
            generate_chain_task_from_selected_order:
              this.state.generate_chain_task_from_selected_order,
            generate_route_suggestion_from_selected_order:
              this.state.generate_route_suggestion_from_selected_order,
          },
        },
        inventory: {
          create_inventory: this.state.create_inventory,
          modify_inventory_stock: this.state.modify_inventory_stock,
          update_inventory_closing_value:
            this.state.update_inventory_closing_value,
          inventory_list: this.state.inventory_list,
          inventory_detail: this.state.inventory_detail,
          inventory_closing_history: this.state.inventory_closing_history,
          inventory_stock_history: this.state.inventory_stock_history,
          inventory_single_product_stock_history:
            this.state.inventory_single_product_stock_history,
          inventory_internal_transfer: this.state.inventory_internal_transfer,
          orders_by_inventory_product: this.state.orders_by_inventory_product,
        },
        sub_org: {
          sub_org_type: {
            sub_org_type_create: this.state.sub_org_type_create,
            sub_org_type_list: this.state.sub_org_type_list,
            sub_org_type_detail: this.state.sub_org_type_detail,
            sub_org_type_update: this.state.sub_org_type_update,
          },
          sub_org_create: this.state.sub_org_create,
          sub_org_list: this.state.sub_org_list,
          sub_org_detail: this.state.sub_org_detail,
          sub_org_update: this.state.sub_org_update,
        },
        qr_code: {
          qr_code_create: this.state.qr_code_create,
          qr_code_list: this.state.qr_code_list,
          qr_code_detail: this.state.qr_code_detail,
        },
        due_collection: {
          due_collection_list: this.state.due_collection_list,
          due_collection_request_handover:
            this.state.due_collection_request_handover,
        },
        handover_request: {
          handover_request_create: this.state.handover_request_create,
          handover_request_list: this.state.handover_request_list,
          handover_request_approve: this.state.handover_request_approve,
        },
        custom_form: {
          custom_form_create: this.state.custom_form_create,
          custom_form_list: this.state.custom_form_list,
          custom_form_update: this.state.custom_form_update,
          custom_form_detail: this.state.custom_form_detail,
          custom_form_delete: this.state.custom_form_delete,
          custom_form_data: this.state.custom_form_data,
        },
        report: {
          attendance_report: {
            attendance_organizational_report:
              this.state.attendance_organizational_report,
            attendance_organizational_report_with_custom_fields:
              this.state.attendance_organizational_report_with_custom_fields,
            single_person_attendance_report:
              this.state.single_person_attendance_report,
            leave_organizational_report: this.state.leave_organizational_report,
          },
          inventory_report: {
            multiple_inventory_summary_report:
              this.state.multiple_inventory_summary_report,
            inventory_detail_report: this.state.inventory_detail_report,
            inventory_stock_history_report:
              this.state.inventory_stock_history_report,
            inventory_closing_history_report:
              this.state.inventory_closing_history_report,
            inventory_low_stock_report: this.state.inventory_low_stock_report,
            download_inventory_details_report:
              this.state.download_inventory_details_report,
          },
          task_report: {
            daily_task_report: this.state.daily_task_report,
            task_report_based_on_filters:
              this.state.task_report_based_on_filters,
            task_report_based_on_users: this.state.task_report_based_on_users,
          },
          order_report: this.state.order_report,
          asset_report: this.state.asset_report,
          due_collection_report: this.state.due_collection_report,
        },
        data_level: {
          access_all_data: this.state.access_all_data,
          access_suborg_data: this.state.access_suborg_data,
          access_own_data: this.state.access_own_data,
        },
      },
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_ROLE}${this.state.role_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit unit", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit role", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title={`Manage Permissions for ${this.state.name} `}
          onBack={() => this.props.history.goBack()}
        />
        <div style={{ margin: 5 }}>
          <Checkbox
            onChange={(e) => {
              this.setState({
                asset_create: e.target.checked,
                asset_list: e.target.checked,
                asset_update: e.target.checked,
                asset_detail: e.target.checked,
                asset_delete: e.target.checked,
                add_reminder: e.target.checked,
                connect_asset_to_user: e.target.checked,
                connect_asset_to_finder_device: e.target.checked,
                connect_to_finder: e.target.checked,

                attendance_create: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_list: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_update: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_detail: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_create_for: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_checkin: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_checkout: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_delete: this.state.attendance
                  ? e.target.checked
                  : false,
                attendance_view_route: this.state.attendance
                  ? e.target.checked
                  : false,

                custom_fields_create: e.target.checked,
                custom_fields_list: e.target.checked,
                custom_fields_update: e.target.checked,
                custom_fields_detail: e.target.checked,
                custom_fields_delete: e.target.checked,

                organization_create: e.target.checked,
                organization_list: e.target.checked,
                organization_update: e.target.checked,
                organization_detail: e.target.checked,
                organization_delete: e.target.checked,
                add_plan_subscription: e.target.checked,
                change_plan_subscription: e.target.checked,
                monthly_billing_view: e.target.checked,
                monthly_payment_view: e.target.checked,
                change_organization: e.target.checked,

                place_create: this.state.entity ? e.target.checked : false,
                place_list: this.state.entity ? e.target.checked : false,
                place_update: this.state.entity ? e.target.checked : false,
                nearby_place_search: this.state.entity
                  ? e.target.checked
                  : false,
                place_delete: this.state.entity ? e.target.checked : false,
                update_is_inventory_in_place:this.state.entity ? e.target.checked : false,
                view_place_detail: this.state.entity ? e.target.checked : false,

                add_place_reminder: this.state.entity
                  ? e.target.checked
                  : false,
                update_custom_field_data_related_to_place: this.state.entity
                  ? e.target.checked
                  : false,
                view_custom_field_data_related_to_place: this.state.entity
                  ? e.target.checked
                  : false,

                task_create: this.state.task ? e.target.checked : false,
                task_list: this.state.task ? e.target.checked : false,
                task_update: this.state.task ? e.target.checked : false,
                view_task_detail: this.state.task ? e.target.checked : false,
                add_comment: this.state.task ? e.target.checked : false,
                view_comment_list: this.state.task ? e.target.checked : false,
                update_comment: this.state.task ? e.target.checked : false,
                delete_comment: this.state.task ? e.target.checked : false,
                update_custom_field_data_related_to_task: this.state.task
                  ? e.target.checked
                  : false,
                view_custom_field_data_related_to_task: this.state.task
                  ? e.target.checked
                  : false,
                task_delete: this.state.task ? e.target.checked : false,
                task_view_route: this.state.task ? e.target.checked : false,
                task_add_reminder: this.state.task ? e.target.checked : false,

                task_type_create: this.state.task ? e.target.checked : false,
                task_type_list: this.state.task ? e.target.checked : false,
                task_type_update: this.state.task ? e.target.checked : false,
                task_type_detail: this.state.task ? e.target.checked : false,
                task_type_delete: this.state.task ? e.target.checked : false,

                bill_create: e.target.checked,
                bill_list: e.target.checked,
                bill_list_search: e.target.checked,
                bill_update: e.target.checked,
                bill_detail: e.target.checked,
                bill_type_list: e.target.checked,
                conveyance_bill_approval_api: e.target.checked,
                bill_item_udpade: e.target.checked,
                get_miscellaneous_bill_type_choices: e.target.checked,

                requisition_create: this.state.requisition
                  ? e.target.checked
                  : false,
                requisition_list: this.state.requisition
                  ? e.target.checked
                  : false,
                requisition_list_search: this.state.requisition
                  ? e.target.checked
                  : false,
                requisition_update: this.state.requisition
                  ? e.target.checked
                  : false,
                requisition_detail: this.state.requisition
                  ? e.target.checked
                  : false,
                requisition_delete: this.state.requisition
                  ? e.target.checked
                  : false,
                generate_bill_from_requisition: this.state.requisition
                  ? e.target.checked
                  : false,
                generate_order_from_requisition: this.state.requisition
                  ? e.target.checked
                  : false,

                order_create: this.state.order ? e.target.checked : false,
                order_list: this.state.order ? e.target.checked : false,
                order_update: this.state.order ? e.target.checked : false,
                purchase_order_create: this.state.purchase_order_create
                  ? e.target.checked
                  : false,
                purchase_order_detail: this.state.purchase_order_detail
                  ? e.target.checked
                  : false,
                purchase_order_list: this.state.purchase_order_list
                  ? e.target.checked
                  : false,
                change_delivery_time: this.state.order
                  ? e.target.checked
                  : false,
                order_product_status_update: this.state.order
                  ? e.target.checked
                  : false,
                order_location_update: this.state.order
                  ? e.target.checked
                  : false,
                add_received_amount: this.state.order
                  ? e.target.checked
                  : false,
                add_refund_amount: this.state.order ? e.target.checked : false,
                update_custom_field_data_related_to_order: this.state.order
                  ? e.target.checked
                  : false,
                view_custom_field_data_related_to_order: this.state.order
                  ? e.target.checked
                  : false,
                order_delete: this.state.order ? e.target.checked : false,
                order_add_reminder: this.state.order ? e.target.checked : false,
                download_order_detailed_to_order: this.state.order
                  ? e.target.checked
                  : false,
                order_related_payment_method_and_status_update: this.state.order
                  ? e.target.checked
                  : false,
                order_payment_method_and_status_update_from_task: this.state
                  .order
                  ? e.target.checked
                  : false,
                order_payment_method_choices: this.state.order
                  ? e.target.checked
                  : false,
                order_discount_type_choices: this.state.order
                  ? e.target.checked
                  : false,
                order_item_update: this.state.order ? e.target.checked : false,
                generate_task_from_order: this.state.order_to_task_generation
                  ? e.target.checked
                  : false,
                generate_multi_task_from_selected_order: this.state
                  .order_to_task_generation
                  ? e.target.checked
                  : false,
                generate_chain_task_from_selected_order: this.state
                  .order_to_task_generation
                  ? e.target.checked
                  : false,
                generate_route_suggestion_from_selected_order: this.state.order
                  ? e.target.checked
                  : false,
                view_order_detail: this.state.order ? e.target.checked : false,
                return_order_create: this.state.order ? e.target.checked : false,
                return_order_list: this.state.order ? e.target.checked : false,
                return_order_detail: this.state.order ? e.target.checked : false,
                pos_order_create: this.state.order ? e.target.checked : false,
                pos_order_list: this.state.order ? e.target.checked : false,
                pos_order_detail: this.state.order ? e.target.checked : false,
                promotion_create: this.state.order ? e.target.checked : false,
                promotion_list: this.state.order ? e.target.checked : false,
                promotion_update: this.state.order ? e.target.checked : false,
                promotion_detail: this.state.order ? e.target.checked : false,

                wallet_list: e.target.checked,
                wallet_history: e.target.checked,
                add_money_to_wallet: e.target.checked,
                adjust_order_with_wallet_balance: e.target.checked,
                auto_adjust_order_with_wallet_balance: e.target.checked,

                user_create: e.target.checked,
                user_list: e.target.checked,
                user_update: e.target.checked,
                user_detail: e.target.checked,
                user_delete: e.target.checked,
                view_user_route_history: e.target.checked,
                connect_user_to_org: e.target.checked,
                bulk_update_user: e.target.checked,
                user_mobile_heartbeat_logs: e.target.checked,
                assigned_role_to_users: e.target.checked,

                roles_and_permission_create: e.target.checked,
                roles_and_permission_list: e.target.checked,
                roles_and_permission_update: e.target.checked,
                roles_and_permission_detail: e.target.checked,
                roles_and_permission_delete: e.target.checked,
                add_or_update_permissions_to_roles: e.target.checked,
                view_permissions_against_roles: e.target.checked,
                view_users_against_roles: e.target.checked,

                supplier_create: e.target.checked,
                supplier_list: e.target.checked,
                supplier_update: e.target.checked,
                supplier_detail: e.target.checked,
                supplier_delete: e.target.checked,

                product_create: e.target.checked,
                product_list: e.target.checked,
                product_update: e.target.checked,
                product_detail: e.target.checked,
                product_delete: e.target.checked,

                product_category_create: e.target.checked,
                product_category_list: e.target.checked,
                product_category_detail: e.target.checked,
                product_category_update: e.target.checked,

                item_create: e.target.checked,
                item_list: e.target.checked,
                item_update: e.target.checked,
                item_detail: e.target.checked,
                item_delete: e.target.checked,
                get_all_items: e.target.checked,

                item_type_create: e.target.checked,
                item_type_list: e.target.checked,
                item_type_update: e.target.checked,
                item_type_detail: e.target.checked,
                item_type_delete: e.target.checked,

                unit_create: e.target.checked,
                unit_list: e.target.checked,
                unit_update: e.target.checked,
                unit_detail: e.target.checked,
                unit_delete: e.target.checked,

                procedure_create: e.target.checked,
                procedure_list: e.target.checked,
                procedure_update: e.target.checked,
                procedure_detail: e.target.checked,
                procedure_delete: e.target.checked,

                route_create: e.target.checked,
                route_list: e.target.checked,
                route_update: e.target.checked,
                route_detail: e.target.checked,
                route_delete: e.target.checked,

                quick_attendance_create: e.target.checked,
                quick_attendance_list: e.target.checked,
                quick_attendance_update: e.target.checked,
                quick_attendance_detail: e.target.checked,
                quick_attendance_checkin: e.target.checked,
                quick_attendance_checkout: e.target.checked,
                quick_attendance_delete: e.target.checked,
                quick_attendance_view_route: e.target.checked,

                leave_application_create: e.target.checked,
                leave_application_list: e.target.checked,
                leave_application_update: e.target.checked,
                leave_application_detail: e.target.checked,
                leave_application_delete: e.target.checked,

                // container_create: e.target.checked,
                // container_list: e.target.checked,
                // container_update: e.target.checked,
                // container_detail: e.target.checked,

                // parcel_create: e.target.checked,
                // parcel_list: e.target.checked,
                // parcel_update: e.target.checked,
                // parcel_detail: e.target.checked,

                territory_create: this.state.territory
                  ? e.target.checked
                  : false,
                territory_list: this.state.territory ? e.target.checked : false,
                territory_update: this.state.territory
                  ? e.target.checked
                  : false,
                territory_detail: this.state.territory
                  ? e.target.checked
                  : false,
                territory_delete: this.state.territory
                  ? e.target.checked
                  : false,

                roaster_create: e.target.checked,
                roaster_list: e.target.checked,
                roaster_update: e.target.checked,
                roaster_detail: e.target.checked,
                delete_user_from_roaster: e.target.checked,
                roaster_delete: e.target.checked,

                roaster_plan_create: e.target.checked,
                roaster_plan_list: e.target.checked,
                roaster_plan_update: e.target.checked,
                roaster_plan_detail: e.target.checked,
                roaster_plan_delete: e.target.checked,

                visit_plan_create: e.target.checked,
                visit_plan_list: e.target.checked,
                visit_plan_update: e.target.checked,
                visit_plan_detail: e.target.checked,
                visit_plan_delete: e.target.checked,
                generate_chain_task_from_selected_plan: this.state
                  .visit_plan_to_task_generation
                  ? e.target.checked
                  : false,

                incident_create: e.target.checked,
                incident_list: e.target.checked,
                incident_update: e.target.checked,
                incident_detail: e.target.checked,
                incident_delete: e.target.checked,

                attendance_settings: e.target.checked,
                inventory_connection_settings: e.target.checked,
                tracking_settings: e.target.checked,
                subscription_billing_settings: e.target.checked,
                order_settings: e.target.checked,
                sms_settings: e.target.checked,
                email_settings: e.target.checked,

                attendance_organizational_report: e.target.checked,
                attendance_organizational_report_with_custom_fields:
                  e.target.checked,
                single_person_attendance_report: e.target.checked,
                leave_organizational_report: e.target.checked,
                daily_task_report: e.target.checked,
                task_report_based_on_filters: e.target.checked,
                task_report_based_on_users: e.target.checked,
                order_report: e.target.checked,
                asset_report: e.target.checked,
                multiple_inventory_summary_report: e.target.checked,
                inventory_detail_report: e.target.checked,
                inventory_stock_history_report: e.target.checked,
                inventory_closing_history_report: e.target.checked,
                inventory_low_stock_report: e.target.checked,
                download_inventory_details_report: e.target.checked,
                due_collection_report: e.target.checked,

                access_all_data: e.target.checked,
                access_suborg_data: e.target.checked,
                access_own_data: e.target.checked,

                create_inventory: e.target.checked,
                modify_inventory_stock: e.target.checked,
                update_inventory_closing_value: e.target.checked,
                inventory_list: e.target.checked,
                inventory_detail: e.target.checked,
                inventory_closing_history: e.target.checked,
                inventory_stock_history: e.target.checked,
                inventory_single_product_stock_history: e.target.checked,
                inventory_internal_transfer: e.target.checked,
                orders_by_inventory_product: e.target.checked,

                sub_org_type_create: e.target.checked,
                sub_org_type_list: e.target.checked,
                sub_org_type_detail: e.target.checked,
                sub_org_type_update: e.target.checked,
                sub_org_create: e.target.checked,
                sub_org_list: e.target.checked,
                sub_org_detail: e.target.checked,
                sub_org_update: e.target.checked,

                qr_code_create: e.target.checked,
                qr_code_list: e.target.checked,
                qr_code_detail: e.target.checked,

                due_collection_list: e.target.checked,
                due_collection_request_handover: e.target.checked,

                handover_request_create: e.target.checked,
                handover_request_list: e.target.checked,
                handover_request_approve: e.target.checked,

                custom_form_create: e.target.checked,
                custom_form_list: e.target.checked,
                custom_form_update: e.target.checked,
                custom_form_detail: e.target.checked,
                custom_form_delete: e.target.checked,
                custom_form_data: e.target.checked,
              });
            }}
          >
            Select All
          </Checkbox>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row1"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState(
                      {
                        asset_create: e.target.checked,
                        asset_list: e.target.checked,
                        asset_update: e.target.checked,
                        asset_detail: e.target.checked,
                        asset_delete: e.target.checked,
                        add_reminder: e.target.checked,
                        connect_asset_to_user: e.target.checked,
                        connect_asset_to_finder_device: e.target.checked,
                        connect_to_finder: e.target.checked,
                      },
                      () => console.log(this.state.asset_create)
                    );
                  }}
                >
                  Asset
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.asset_create}
                  onChange={(e) =>
                    this.setState({ asset_create: e.target.checked }, () =>
                      console.log(this.state.asset_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.asset_list}
                  onChange={(e) =>
                    this.setState({ asset_list: e.target.checked }, () =>
                      console.log(this.state.asset_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.asset_update}
                  onChange={(e) =>
                    this.setState({ asset_update: e.target.checked }, () =>
                      console.log(this.state.asset_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.asset_detail}
                  onChange={(e) =>
                    this.setState({ asset_detail: e.target.checked }, () =>
                      console.log(this.state.asset_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.asset_delete}
                  onChange={(e) =>
                    this.setState({ asset_delete: e.target.checked }, () =>
                      console.log(this.state.asset_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.add_reminder}
                  onChange={(e) =>
                    this.setState({ add_reminder: e.target.checked }, () =>
                      console.log(this.state.add_reminder)
                    )
                  }
                >
                  Add Reminder
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.connect_asset_to_user}
                  onChange={(e) =>
                    this.setState(
                      { connect_asset_to_user: e.target.checked },
                      () => console.log(this.state.connect_asset_to_user)
                    )
                  }
                >
                  Connect to User
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.connect_asset_to_finder_device}
                  onChange={(e) =>
                    this.setState(
                      { connect_asset_to_finder_device: e.target.checked },
                      () =>
                        console.log(this.state.connect_asset_to_finder_device)
                    )
                  }
                >
                  Connect to Finder Device
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.connect_to_finder}
                  onChange={(e) =>
                    this.setState({ connect_to_finder: e.target.checked }, () =>
                      console.log(this.state.connect_to_finder)
                    )
                  }
                >
                  Connect to Finder
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          {this.state.attendance ? (
            <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
              <CardContent>
                <h2
                  style={{
                    fontFamily: "AirbnbCerealApp-ExtraBold",
                    marginLeft: 5,
                    letterSpacing: 1,
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      this.setState(
                        {
                          attendance_create: e.target.checked,
                          attendance_list: e.target.checked,
                          attendance_detail: e.target.checked,
                          attendance_create_for: e.target.checked,
                          attendance_checkin: e.target.checked,
                          attendance_checkout: e.target.checked,
                          attendance_delete: e.target.checked,
                          attendance_view_route: e.target.checked,
                        },
                        () => console.log(this.state.attendance_create)
                      );
                    }}
                  >
                    Attendance
                  </Checkbox>
                </h2>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_create}
                    onChange={(e) =>
                      this.setState(
                        { attendance_create: e.target.checked },
                        () => console.log(this.state.attendance_create)
                      )
                    }
                  >
                    Create Own Attendance
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_list}
                    onChange={(e) =>
                      this.setState({ attendance_list: e.target.checked }, () =>
                        console.log(this.state.attendance_list)
                      )
                    }
                  >
                    View List
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_detail}
                    onChange={(e) =>
                      this.setState(
                        { attendance_detail: e.target.checked },
                        () => console.log(this.state.attendance_detail)
                      )
                    }
                  >
                    View Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_create_for}
                    onChange={(e) =>
                      this.setState(
                        { attendance_create_for: e.target.checked },
                        () => console.log(this.state.attendance_create_for)
                      )
                    }
                  >
                    Create User Attendance
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_checkin}
                    onChange={(e) =>
                      this.setState(
                        { attendance_checkin: e.target.checked },
                        () => console.log(this.state.attendance_checkin)
                      )
                    }
                  >
                    Checkin
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_checkout}
                    onChange={(e) =>
                      this.setState(
                        { attendance_checkout: e.target.checked },
                        () => console.log(this.state.attendance_checkout)
                      )
                    }
                  >
                    Checkout
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_delete}
                    onChange={(e) =>
                      this.setState(
                        { attendance_delete: e.target.checked },
                        () => console.log(this.state.attendance_delete)
                      )
                    }
                  >
                    Delete
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.attendance_view_route}
                    onChange={(e) =>
                      this.setState(
                        { attendance_view_route: e.target.checked },
                        () => console.log(this.state.attendance_view_route)
                      )
                    }
                  >
                    View Route
                  </Checkbox>
                </p>
              </CardContent>
            </CardMaterial>
          ) : null}

          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      custom_fields_create: e.target.checked,
                      custom_fields_list: e.target.checked,
                      custom_fields_update: e.target.checked,
                      custom_fields_detail: e.target.checked,
                      custom_fields_delete: e.target.checked,
                    });
                  }}
                >
                  Custom Field
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_fields_create}
                  onChange={(e) =>
                    this.setState(
                      { custom_fields_create: e.target.checked },
                      () => console.log(this.state.custom_fields_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_fields_list}
                  onChange={(e) =>
                    this.setState(
                      { custom_fields_list: e.target.checked },
                      () => console.log(this.state.custom_fields_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_fields_update}
                  onChange={(e) =>
                    this.setState(
                      { custom_fields_update: e.target.checked },
                      () => console.log(this.state.custom_fields_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_fields_detail}
                  onChange={(e) =>
                    this.setState(
                      { custom_fields_detail: e.target.checked },
                      () => console.log(this.state.custom_fields_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_fields_delete}
                  onChange={(e) =>
                    this.setState(
                      { custom_fields_delete: e.target.checked },
                      () => console.log(this.state.custom_fields_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      organization_create: e.target.checked,
                      organization_list: e.target.checked,
                      organization_update: e.target.checked,
                      organization_detail: e.target.checked,
                      organization_delete: e.target.checked,
                      add_plan_subscription: e.target.checked,
                      change_plan_subscription: e.target.checked,
                      monthly_billing_view: e.target.checked,
                      monthly_payment_view: e.target.checked,
                      change_organization: e.target.checked,
                    });
                  }}
                >
                  Organization
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.organization_create}
                  onChange={(e) =>
                    this.setState(
                      { organization_create: e.target.checked },
                      () => console.log(this.state.organization_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.organization_list}
                  onChange={(e) =>
                    this.setState({ organization_list: e.target.checked }, () =>
                      console.log(this.state.organization_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.organization_update}
                  onChange={(e) =>
                    this.setState(
                      { organization_update: e.target.checked },
                      () => console.log(this.state.organization_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.organization_detail}
                  onChange={(e) =>
                    this.setState(
                      { organization_detail: e.target.checked },
                      () => console.log(this.state.organization_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.organization_delete}
                  onChange={(e) =>
                    this.setState(
                      { organization_delete: e.target.checked },
                      () => console.log(this.state.organization_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.add_plan_subscription}
                  onChange={(e) =>
                    this.setState(
                      { add_plan_subscription: e.target.checked },
                      () => console.log(this.state.add_plan_subscription)
                    )
                  }
                >
                  Add Plan Subscription
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.change_plan_subscription}
                  onChange={(e) =>
                    this.setState(
                      { change_plan_subscription: e.target.checked },
                      () => console.log(this.state.change_plan_subscription)
                    )
                  }
                >
                  Change Plan Subscription
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.monthly_billing_view}
                  onChange={(e) =>
                    this.setState(
                      { monthly_billing_view: e.target.checked },
                      () => console.log(this.state.monthly_billing_view)
                    )
                  }
                >
                  View Monthly Bill
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.monthly_payment_view}
                  onChange={(e) =>
                    this.setState(
                      { monthly_payment_view: e.target.checked },
                      () => console.log(this.state.monthly_payment_view)
                    )
                  }
                >
                  View Monthly Payment
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.change_organization}
                  onChange={(e) =>
                    this.setState(
                      { change_organization: e.target.checked },
                      () => console.log(this.state.change_organization)
                    )
                  }
                >
                  Change Organization
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>

          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      quick_attendance_create: e.target.checked,
                      quick_attendance_list: e.target.checked,
                      quick_attendance_update: e.target.checked,
                      quick_attendance_detail: e.target.checked,
                      quick_attendance_checkin: e.target.checked,
                      quick_attendance_checkout: e.target.checked,
                      quick_attendance_delete: e.target.checked,
                      quick_attendance_view_route: e.target.checked,
                    });
                  }}
                >
                  Quick Attendance
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_create}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_create: e.target.checked },
                      () => console.log(this.state.quick_attendance_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_list}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_list: e.target.checked },
                      () => console.log(this.state.quick_attendance_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_update}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_update: e.target.checked },
                      () => console.log(this.state.quick_attendance_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_detail}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_detail: e.target.checked },
                      () => console.log(this.state.quick_attendance_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_checkin}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_checkin: e.target.checked },
                      () => console.log(this.state.quick_attendance_checkin)
                    )
                  }
                >
                  Checkin
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_checkout}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_checkout: e.target.checked },
                      () => console.log(this.state.quick_attendance_checkout)
                    )
                  }
                >
                  Checkout
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_delete}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_delete: e.target.checked },
                      () => console.log(this.state.quick_attendance_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.quick_attendance_view_route}
                  onChange={(e) =>
                    this.setState(
                      { quick_attendance_view_route: e.target.checked },
                      () => console.log(this.state.quick_attendance_view_route)
                    )
                  }
                >
                  View Route
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row2"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      procedure_create: e.target.checked,
                      procedure_list: e.target.checked,
                      procedure_update: e.target.checked,
                      procedure_detail: e.target.checked,
                      procedure_delete: e.target.checked,
                    });
                  }}
                >
                  Procedure
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.procedure_create}
                  onChange={(e) =>
                    this.setState({ procedure_create: e.target.checked }, () =>
                      console.log(this.state.procedure_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.procedure_list}
                  onChange={(e) =>
                    this.setState({ procedure_list: e.target.checked }, () =>
                      console.log(this.state.procedure_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.procedure_update}
                  onChange={(e) =>
                    this.setState({ procedure_update: e.target.checked }, () =>
                      console.log(this.state.procedure_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.procedure_detail}
                  onChange={(e) =>
                    this.setState({ procedure_detail: e.target.checked }, () =>
                      console.log(this.state.procedure_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.procedure_delete}
                  onChange={(e) =>
                    this.setState({ procedure_delete: e.target.checked }, () =>
                      console.log(this.state.procedure_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          {this.state.entity ? (
            <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
              <CardContent>
                <h2
                  style={{
                    fontFamily: "AirbnbCerealApp-ExtraBold",
                    marginLeft: 5,
                    letterSpacing: 1,
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        place_create: e.target.checked,
                        place_list: e.target.checked,
                        place_update: e.target.checked,
                        nearby_place_search: e.target.checked,
                        place_delete: e.target.checked,
                        add_place_reminder: e.target.checked,
                        update_is_inventory_in_place: e.target.checked,
                        view_place_detail: e.target.checked,
                        update_custom_field_data_related_to_place:
                          e.target.checked,
                        view_custom_field_data_related_to_place:
                          e.target.checked,
                      });
                    }}
                  >
                    Places
                  </Checkbox>
                </h2>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.place_create}
                    onChange={(e) =>
                      this.setState({ place_create: e.target.checked }, () =>
                        console.log(this.state.place_create)
                      )
                    }
                  >
                    Create
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.place_list}
                    onChange={(e) =>
                      this.setState({ place_list: e.target.checked }, () =>
                        console.log(this.state.place_list)
                      )
                    }
                  >
                    View List
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.place_update}
                    onChange={(e) =>
                      this.setState({ place_update: e.target.checked }, () =>
                        console.log(this.state.place_update)
                      )
                    }
                  >
                    Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.update_is_inventory_in_place}
                    onChange={(e) =>
                      this.setState(
                        { update_is_inventory_in_place: e.target.checked },
                        () =>
                          console.log(this.state.update_is_inventory_in_place)
                      )
                    }
                  >
                    Update Inventory In Place
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_place_detail}
                    onChange={(e) =>
                      this.setState(
                        { view_place_detail: e.target.checked },
                        () => console.log(this.state.view_place_detail)
                      )
                    }
                  >
                    View Place Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.nearby_place_search}
                    onChange={(e) =>
                      this.setState(
                        { nearby_place_search: e.target.checked },
                        () => console.log(this.state.nearby_place_search)
                      )
                    }
                  >
                    Nearby Place Search
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.place_delete}
                    onChange={(e) =>
                      this.setState({ place_delete: e.target.checked }, () =>
                        console.log(this.state.place_delete)
                      )
                    }
                  >
                    Delete
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.add_place_reminder}
                    onChange={(e) =>
                      this.setState(
                        { add_place_reminder: e.target.checked },
                        () => console.log(this.state.add_place_reminder)
                      )
                    }
                  >
                    Add Place Reminder
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={
                      this.state.update_custom_field_data_related_to_place
                    }
                    onChange={(e) =>
                      this.setState(
                        {
                          update_custom_field_data_related_to_place:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.update_custom_field_data_related_to_place
                          )
                      )
                    }
                  >
                    Update Custom Field
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_custom_field_data_related_to_place}
                    onChange={(e) =>
                      this.setState(
                        {
                          view_custom_field_data_related_to_place:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.view_custom_field_data_related_to_place
                          )
                      )
                    }
                  >
                    View Custom Field
                  </Checkbox>
                </p>
              </CardContent>
            </CardMaterial>
          ) : null}
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      route_create: e.target.checked,
                      route_list: e.target.checked,
                      route_update: e.target.checked,
                      route_detail: e.target.checked,
                      route_delete: e.target.checked,
                    });
                  }}
                >
                  Route
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.route_create}
                  onChange={(e) =>
                    this.setState({ route_create: e.target.checked }, () =>
                      console.log(this.state.route_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.route_list}
                  onChange={(e) =>
                    this.setState({ route_list: e.target.checked }, () =>
                      console.log(this.state.route_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.route_update}
                  onChange={(e) =>
                    this.setState({ route_update: e.target.checked }, () =>
                      console.log(this.state.route_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.route_detail}
                  onChange={(e) =>
                    this.setState({ route_detail: e.target.checked }, () =>
                      console.log(this.state.route_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.route_delete}
                  onChange={(e) =>
                    this.setState({ route_delete: e.target.checked }, () =>
                      console.log(this.state.route_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      leave_application_create: e.target.value,
                      leave_application_list: e.target.value,
                      leave_application_update: e.target.value,
                      leave_application_detail: e.target.value,
                      leave_application_delete: e.target.value,
                    });
                  }}
                >
                  Leave Application
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.leave_application_create}
                  onChange={(e) =>
                    this.setState(
                      { leave_application_create: e.target.checked },
                      () => console.log(this.state.leave_application_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.leave_application_list}
                  onChange={(e) =>
                    this.setState(
                      { leave_application_list: e.target.checked },
                      () => console.log(this.state.leave_application_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.leave_application_update}
                  onChange={(e) =>
                    this.setState(
                      { leave_application_update: e.target.checked },
                      () => console.log(this.state.leave_application_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.leave_application_detail}
                  onChange={(e) =>
                    this.setState(
                      { leave_application_detail: e.target.checked },
                      () => console.log(this.state.leave_application_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.leave_application_delete}
                  onChange={(e) =>
                    this.setState(
                      { leave_application_delete: e.target.checked },
                      () => console.log(this.state.leave_application_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          {this.state.territory ? (
            <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
              <CardContent>
                <h2
                  style={{
                    fontFamily: "AirbnbCerealApp-ExtraBold",
                    marginLeft: 5,
                    letterSpacing: 1,
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        territory_create: e.target.value,
                        territory_list: e.target.value,
                        territory_update: e.target.value,
                        territory_detail: e.target.value,
                        territory_delete: e.target.value,
                      });
                    }}
                  >
                    Territory
                  </Checkbox>
                </h2>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.territory_create}
                    onChange={(e) =>
                      this.setState(
                        { territory_create: e.target.checked },
                        () => console.log(this.state.territory_create)
                      )
                    }
                  >
                    Create
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.territory_list}
                    onChange={(e) =>
                      this.setState({ territory_list: e.target.checked }, () =>
                        console.log(this.state.territory_list)
                      )
                    }
                  >
                    View List
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.territory_update}
                    onChange={(e) =>
                      this.setState(
                        { territory_update: e.target.checked },
                        () => console.log(this.state.territory_update)
                      )
                    }
                  >
                    Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.territory_detail}
                    onChange={(e) =>
                      this.setState(
                        { territory_detail: e.target.checked },
                        () => console.log(this.state.territory_detail)
                      )
                    }
                  >
                    View Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.territory_delete}
                    onChange={(e) =>
                      this.setState(
                        { territory_delete: e.target.checked },
                        () => console.log(this.state.territory_delete)
                      )
                    }
                  >
                    Delete
                  </Checkbox>
                </p>
              </CardContent>
            </CardMaterial>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row3"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      task_type_create: e.target.checked,
                      task_type_list: e.target.checked,
                      task_type_update: e.target.checked,
                      task_type_detail: e.target.checked,
                      task_type_delete: e.target.checked,
                    });
                  }}
                >
                  Task Type
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_type_create}
                  onChange={(e) =>
                    this.setState({ task_type_create: e.target.checked }, () =>
                      console.log(this.state.task_type_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_type_list}
                  onChange={(e) =>
                    this.setState({ task_type_list: e.target.checked }, () =>
                      console.log(this.state.task_type_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_type_update}
                  onChange={(e) =>
                    this.setState({ task_type_update: e.target.checked }, () =>
                      console.log(this.state.task_type_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_type_detail}
                  onChange={(e) =>
                    this.setState({ task_type_detail: e.target.checked }, () =>
                      console.log(this.state.task_type_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_type_delete}
                  onChange={(e) =>
                    this.setState({ task_type_delete: e.target.checked }, () =>
                      console.log(this.state.task_type_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          {this.state.task ? (
            <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
              <CardContent>
                <h2
                  style={{
                    fontFamily: "AirbnbCerealApp-ExtraBold",
                    marginLeft: 5,
                    letterSpacing: 1,
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        task_create: e.target.checked,
                        task_list: e.target.checked,
                        task_update: e.target.checked,
                        add_comment: e.target.checked,
                        view_task_detail: e.target.checked,
                        view_comment_list: e.target.checked,
                        update_comment: e.target.checked,
                        delete_comment: e.target.checked,
                        update_custom_field_data_related_to_task:
                          e.target.checked,
                        view_custom_field_data_related_to_task:
                          e.target.checked,
                        task_delete: e.target.checked,
                        task_view_route: e.target.checked,
                        task_add_reminder: e.target.checked,
                      });
                    }}
                  >
                    Task
                  </Checkbox>
                </h2>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.task_create}
                    onChange={(e) =>
                      this.setState({ task_create: e.target.checked }, () =>
                        console.log(this.state.task_create)
                      )
                    }
                  >
                    Create
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.task_list}
                    onChange={(e) =>
                      this.setState({ task_list: e.target.checked }, () =>
                        console.log(this.state.task_list)
                      )
                    }
                  >
                    View List
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.task_update}
                    onChange={(e) =>
                      this.setState({ task_update: e.target.checked }, () =>
                        console.log(this.state.task_update)
                      )
                    }
                  >
                    Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_task_detail}
                    onChange={(e) =>
                      this.setState(
                        { view_task_detail: e.target.checked },
                        () => console.log(this.state.view_task_detail)
                      )
                    }
                  >
                    View Task Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.add_comment}
                    onChange={(e) =>
                      this.setState({ add_comment: e.target.checked }, () =>
                        console.log(this.state.add_comment)
                      )
                    }
                  >
                    Add Comment
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_comment_list}
                    onChange={(e) =>
                      this.setState(
                        { view_comment_list: e.target.checked },
                        () => console.log(this.state.view_comment_list)
                      )
                    }
                  >
                    View Comment
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.update_comment}
                    onChange={(e) =>
                      this.setState({ update_comment: e.target.checked }, () =>
                        console.log(this.state.update_comment)
                      )
                    }
                  >
                    Update Comment
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.delete_comment}
                    onChange={(e) =>
                      this.setState({ delete_comment: e.target.checked }, () =>
                        console.log(this.state.delete_comment)
                      )
                    }
                  >
                    Delete Comment
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={
                      this.state.update_custom_field_data_related_to_task
                    }
                    onChange={(e) =>
                      this.setState(
                        {
                          update_custom_field_data_related_to_task:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.update_custom_field_data_related_to_task
                          )
                      )
                    }
                  >
                    Update Custom Fields
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_custom_field_data_related_to_task}
                    onChange={(e) =>
                      this.setState(
                        {
                          view_custom_field_data_related_to_task:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.view_custom_field_data_related_to_task
                          )
                      )
                    }
                  >
                    View custom Fields
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.task_delete}
                    onChange={(e) =>
                      this.setState({ task_delete: e.target.checked }, () =>
                        console.log(this.state.task_delete)
                      )
                    }
                  >
                    Delete Task
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.task_view_route}
                    onChange={(e) =>
                      this.setState({ task_view_route: e.target.checked }, () =>
                        console.log(this.state.task_view_route)
                      )
                    }
                  >
                    View Route
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.task_add_reminder}
                    onChange={(e) =>
                      this.setState(
                        { task_add_reminder: e.target.checked },
                        () => console.log(this.state.task_add_reminder)
                      )
                    }
                  >
                    Add Reminder
                  </Checkbox>
                </p>
              </CardContent>
            </CardMaterial>
          ) : null}
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      bill_create: e.target.checked,
                      bill_list: e.target.checked,
                      bill_list_search: e.target.checked,
                      bill_update: e.target.checked,
                      bill_detail: e.target.checked,
                      bill_type_list: e.target.checked,
                      conveyance_bill_approval_api: e.target.checked,
                      bill_item_udpade: e.target.checked,
                      get_miscellaneous_bill_type_choices: e.target.checked,
                    });
                  }}
                >
                  Bill
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_create}
                  onChange={(e) =>
                    this.setState({ bill_create: e.target.checked }, () =>
                      console.log(this.state.bill_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_list}
                  onChange={(e) =>
                    this.setState({ bill_list: e.target.checked }, () =>
                      console.log(this.state.bill_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_update}
                  onChange={(e) =>
                    this.setState({ bill_update: e.target.checked }, () =>
                      console.log(this.state.bill_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_detail}
                  onChange={(e) =>
                    this.setState({ bill_detail: e.target.checked }, () =>
                      console.log(this.state.bill_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_list_search}
                  onChange={(e) =>
                    this.setState({ bill_list_search: e.target.checked }, () =>
                      console.log(this.state.bill_list_search)
                    )
                  }
                >
                  List Search
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_type_list}
                  onChange={(e) =>
                    this.setState({ bill_type_list: e.target.checked }, () =>
                      console.log(this.state.bill_type_list)
                    )
                  }
                >
                  Bill Type List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.conveyance_bill_approval_api}
                  onChange={(e) =>
                    this.setState(
                      { conveyance_bill_approval_api: e.target.checked },
                      () => console.log(this.state.conveyance_bill_approval_api)
                    )
                  }
                >
                  Conveyance Bill Approval
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bill_item_udpade}
                  onChange={(e) =>
                    this.setState({ bill_item_udpade: e.target.checked }, () =>
                      console.log(this.state.bill_item_udpade)
                    )
                  }
                >
                  Item Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.get_miscellaneous_bill_type_choices}
                  onChange={(e) =>
                    this.setState(
                      { get_miscellaneous_bill_type_choices: e.target.checked },
                      () =>
                        console.log(
                          this.state.get_miscellaneous_bill_type_choices
                        )
                    )
                  }
                >
                  Miscellaneous Bill Type Choices
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          {this.state.requisition ? (
            <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
              <CardContent>
                <h2
                  style={{
                    fontFamily: "AirbnbCerealApp-ExtraBold",
                    marginLeft: 5,
                    letterSpacing: 1,
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        requisition_create: e.target.checked,
                        requisition_list: e.target.checked,
                        requisition_list_search: e.target.checked,
                        requisition_update: e.target.checked,
                        requisition_detail: e.target.checked,
                        requisition_delete: e.target.checked,
                        generate_bill_from_requisition: e.target.checked,
                        generate_order_from_requisition: e.target.checked,
                      });
                    }}
                  >
                    Requisition
                  </Checkbox>
                </h2>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.requisition_create}
                    onChange={(e) =>
                      this.setState(
                        { requisition_create: e.target.checked },
                        () => console.log(this.state.requisition_create)
                      )
                    }
                  >
                    Create
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.requisition_list}
                    onChange={(e) =>
                      this.setState(
                        { requisition_list: e.target.checked },
                        () => console.log(this.state.requisition_list)
                      )
                    }
                  >
                    View List
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.requisition_update}
                    onChange={(e) =>
                      this.setState(
                        { requisition_update: e.target.checked },
                        () => console.log(this.state.requisition_update)
                      )
                    }
                  >
                    Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.requisition_detail}
                    onChange={(e) =>
                      this.setState(
                        { requisition_detail: e.target.checked },
                        () => console.log(this.state.requisition_detail)
                      )
                    }
                  >
                    View Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.requisition_list_search}
                    onChange={(e) =>
                      this.setState(
                        { requisition_list_search: e.target.checked },
                        () => console.log(this.state.requisition_list_search)
                      )
                    }
                  >
                    List Search
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.requisition_delete}
                    onChange={(e) =>
                      this.setState(
                        { requisition_delete: e.target.checked },
                        () => console.log(this.state.requisition_delete)
                      )
                    }
                  >
                    Delete
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.generate_bill_from_requisition}
                    onChange={(e) =>
                      this.setState(
                        { generate_bill_from_requisition: e.target.checked },
                        () =>
                          console.log(this.state.generate_bill_from_requisition)
                      )
                    }
                  >
                    Generate Bill
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.generate_order_from_requisition}
                    onChange={(e) =>
                      this.setState(
                        { generate_order_from_requisition: e.target.checked },
                        () =>
                          console.log(
                            this.state.generate_order_from_requisition
                          )
                      )
                    }
                  >
                    Generate Order
                  </Checkbox>
                </p>
              </CardContent>
            </CardMaterial>
          ) : null}
          {this.state.order ? (
            <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
              <CardContent>
                <h2
                  style={{
                    fontFamily: "AirbnbCerealApp-ExtraBold",
                    marginLeft: 5,
                    letterSpacing: 1,
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        order_create: e.target.checked,
                        order_list: e.target.checked,
                        order_update: e.target.checked,
                        purchase_order_create: e.target.checked,
                        purchase_order_list: e.target.checked,
                        purchase_order_detail: e.target.checked,
                        change_delivery_time: e.target.checked,
                        order_product_status_update: e.target.checked,
                        order_location_update: e.target.checked,
                        add_received_amount: e.target.checked,
                        add_refund_amount: e.target.checked,
                        update_custom_field_data_related_to_order:
                          e.target.checked,
                        view_custom_field_data_related_to_order:
                          e.target.checked,
                        order_delete: e.target.checked,
                        order_add_reminder: e.target.checked,
                        download_order_detailed_to_order: e.target.checked,
                        order_related_payment_method_and_status_update:
                          e.target.checked,
                        order_payment_method_and_status_update_from_task:
                          e.target.checked,
                        order_payment_method_choices: e.target.checked,
                        order_discount_type_choices: e.target.checked,
                        order_item_update: e.target.checked,
                        generate_task_from_order: e.target.checked,
                        generate_multi_task_from_selected_order:
                          e.target.checked,
                        generate_chain_task_from_selected_order:
                          e.target.checked,
                        generate_route_suggestion_from_selected_order:
                          e.target.checked,
                        promotion_create: e.target.checked,
                        promotion_list: e.target.checked,
                        promotion_update: e.target.checked,
                        promotion_detail: e.target.checked,
                        wallet_list: e.target.checked,
                        wallet_history: e.target.checked,
                        add_money_to_wallet: e.target.checked,
                        adjust_order_with_wallet_balance: e.target.checked,
                        auto_adjust_order_with_wallet_balance: e.target.checked,
                        view_order_detail: e.target.checked,
                        return_order_create: e.target.checked,
                        return_order_list: e.target.checked,
                        return_order_detail: e.target.checked,
                        pos_order_create: e.target.checked,
                        pos_order_list: e.target.checked,
                        pos_order_detail: e.target.checked,
                      });
                    }}
                  >
                    Order
                  </Checkbox>
                </h2>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_create}
                    onChange={(e) =>
                      this.setState({ order_create: e.target.checked }, () =>
                        console.log(this.state.order_create)
                      )
                    }
                  >
                    Create
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_list}
                    onChange={(e) =>
                      this.setState({ order_list: e.target.checked }, () =>
                        console.log(this.state.order_list)
                      )
                    }
                  >
                    View List
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_update}
                    onChange={(e) =>
                      this.setState({ order_update: e.target.checked }, () =>
                        console.log(this.state.order_update)
                      )
                    }
                  >
                    Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_order_detail}
                    onChange={(e) =>
                      this.setState(
                        { view_order_detail: e.target.checked },
                        () => console.log(this.state.view_order_detail)
                      )
                    }
                  >
                    View Order Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.purchase_order_create}
                    onChange={(e) =>
                      this.setState(
                        { purchase_order_create: e.target.checked },
                        () => console.log(this.state.purchase_order_create)
                      )
                    }
                  >
                    Purchase Order Create
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.purchase_order_list}
                    onChange={(e) =>
                      this.setState(
                        { purchase_order_list: e.target.checked },
                        () => console.log(this.state.purchase_order_list)
                      )
                    }
                  >
                    Purchase Order List
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.purchase_order_detail}
                    onChange={(e) =>
                      this.setState(
                        { purchase_order_detail: e.target.checked },
                        () => console.log(this.state.purchase_order_detail)
                      )
                    }
                  >
                    Purchase Order Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.return_order_create}
                    onChange={(e) =>
                      this.setState(
                        { return_order_create: e.target.checked },
                        () => console.log(this.state.return_order_create)
                      )
                    }
                  >
                    Create Return Order
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.return_order_list}
                    onChange={(e) =>
                      this.setState(
                        { return_order_list: e.target.checked },
                        () => console.log(this.state.return_order_list)
                      )
                    }
                  >
                    List Return Orders
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.return_order_detail}
                    onChange={(e) =>
                      this.setState(
                        { return_order_detail: e.target.checked },
                        () => console.log(this.state.return_order_detail)
                      )
                    }
                  >
                    Return Order Detail
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.pos_order_create}
                    onChange={(e) =>
                      this.setState(
                        { pos_order_create: e.target.checked },
                        () => console.log(this.state.pos_order_create)
                      )
                    }
                  >
                    Create POS Order
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.pos_order_list}
                    onChange={(e) =>
                      this.setState({ pos_order_list: e.target.checked }, () =>
                        console.log(this.state.pos_order_list)
                      )
                    }
                  >
                    List POS Orders
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.pos_order_detail}
                    onChange={(e) =>
                      this.setState(
                        { pos_order_detail: e.target.checked },
                        () => console.log(this.state.pos_order_detail)
                      )
                    }
                  >
                    POS Order Detail
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.change_delivery_time}
                    onChange={(e) =>
                      this.setState(
                        { change_delivery_time: e.target.checked },
                        () => console.log(this.state.change_delivery_time)
                      )
                    }
                  >
                    Change Delivery Time
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_product_status_update}
                    onChange={(e) =>
                      this.setState(
                        { order_product_status_update: e.target.checked },
                        () =>
                          console.log(this.state.order_product_status_update)
                      )
                    }
                  >
                    Product Status Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_location_update}
                    onChange={(e) =>
                      this.setState(
                        {
                          order_location_update: e.target.checked,
                        },
                        () => console.log(this.state.order_location_update)
                      )
                    }
                  >
                    Location Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.add_received_amount}
                    onChange={(e) =>
                      this.setState(
                        { add_received_amount: e.target.checked },
                        () => console.log(this.state.add_received_amount)
                      )
                    }
                  >
                    Add Received Amount
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.add_refund_amount}
                    onChange={(e) =>
                      this.setState(
                        { add_refund_amount: e.target.checked },
                        () => console.log(this.state.add_refund_amount)
                      )
                    }
                  >
                    Add Refund Amount
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={
                      this.state.update_custom_field_data_related_to_order
                    }
                    onChange={(e) =>
                      this.setState(
                        {
                          update_custom_field_data_related_to_order:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.update_custom_field_data_related_to_order
                          )
                      )
                    }
                  >
                    Update Custom Fields
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.view_custom_field_data_related_to_order}
                    onChange={(e) =>
                      this.setState(
                        {
                          view_custom_field_data_related_to_order:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.view_custom_field_data_related_to_order
                          )
                      )
                    }
                  >
                    View Custom Fields
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_delete}
                    onChange={(e) =>
                      this.setState({ order_delete: e.target.checked }, () =>
                        console.log(this.state.order_delete)
                      )
                    }
                  >
                    Delete Order
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_add_reminder}
                    onChange={(e) =>
                      this.setState(
                        { order_add_reminder: e.target.checked },
                        () => console.log(this.state.order_add_reminder)
                      )
                    }
                  >
                    Add Reminder
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.download_order_detailed_to_order}
                    onChange={(e) =>
                      this.setState(
                        { download_order_detailed_to_order: e.target.checked },
                        () =>
                          console.log(
                            this.state.download_order_detailed_to_order
                          )
                      )
                    }
                  >
                    Download Detailed Report
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={
                      this.state.order_related_payment_method_and_status_update
                    }
                    onChange={(e) =>
                      this.setState(
                        {
                          order_related_payment_method_and_status_update:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state
                              .order_related_payment_method_and_status_update
                          )
                      )
                    }
                  >
                    Payment Method and Status Update
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={
                      this.state
                        .order_payment_method_and_status_update_from_task
                    }
                    onChange={(e) =>
                      this.setState(
                        {
                          order_payment_method_and_status_update_from_task:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state
                              .order_payment_method_and_status_update_from_task
                          )
                      )
                    }
                  >
                    Payment Method and Status Update from Task
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_payment_method_choices}
                    onChange={(e) =>
                      this.setState(
                        { order_payment_method_choices: e.target.checked },
                        () =>
                          console.log(this.state.order_payment_method_choices)
                      )
                    }
                  >
                    Payment Method Choices
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_discount_type_choices}
                    onChange={(e) =>
                      this.setState(
                        { order_discount_type_choices: e.target.checked },
                        () =>
                          console.log(this.state.order_discount_type_choices)
                      )
                    }
                  >
                    Discount Type Choices
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.order_item_update}
                    onChange={(e) =>
                      this.setState(
                        { order_item_update: e.target.checked },
                        () => console.log(this.state.order_item_update)
                      )
                    }
                  >
                    Order Item Update
                  </Checkbox>
                </p>
                {this.state.orders_to_task_generation ? (
                  <p
                    style={{
                      fontFamily: "AirbnbCerealApp-Book",
                      marginLeft: 15,
                    }}
                  >
                    <Checkbox
                      checked={this.state.generate_task_from_order}
                      onChange={(e) =>
                        this.setState(
                          { generate_task_from_order: e.target.checked },
                          () => console.log(this.state.generate_task_from_order)
                        )
                      }
                    >
                      Generate Task
                    </Checkbox>
                  </p>
                ) : null}
                {this.state.orders_to_task_generation ? (
                  <p
                    style={{
                      fontFamily: "AirbnbCerealApp-Book",
                      marginLeft: 15,
                    }}
                  >
                    <Checkbox
                      checked={
                        this.state.generate_multi_task_from_selected_order
                      }
                      onChange={(e) =>
                        this.setState(
                          {
                            generate_multi_task_from_selected_order:
                              e.target.checked,
                          },
                          () =>
                            console.log(
                              this.state.generate_multi_task_from_selected_order
                            )
                        )
                      }
                    >
                      Generate Multiple Task
                    </Checkbox>
                  </p>
                ) : null}
                {this.state.orders_to_task_generation ? (
                  <p
                    style={{
                      fontFamily: "AirbnbCerealApp-Book",
                      marginLeft: 15,
                    }}
                  >
                    <Checkbox
                      checked={
                        this.state.generate_chain_task_from_selected_order
                      }
                      onChange={(e) =>
                        this.setState(
                          {
                            generate_chain_task_from_selected_order:
                              e.target.checked,
                          },
                          () =>
                            console.log(
                              this.state.generate_chain_task_from_selected_order
                            )
                        )
                      }
                    >
                      Generate Chain Task
                    </Checkbox>
                  </p>
                ) : null}

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={
                      this.state.generate_route_suggestion_from_selected_order
                    }
                    onChange={(e) =>
                      this.setState(
                        {
                          generate_route_suggestion_from_selected_order:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state
                              .generate_route_suggestion_from_selected_order
                          )
                      )
                    }
                  >
                    Generate Route Suggestion
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.promotion_create}
                    onChange={(e) =>
                      this.setState(
                        { promotion_create: e.target.checked },
                        () => console.log(this.state.promotion_create)
                      )
                    }
                  >
                    Create Promotion
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.promotion_list}
                    onChange={(e) =>
                      this.setState({ promotion_list: e.target.checked }, () =>
                        console.log(this.state.promotion_list)
                      )
                    }
                  >
                    List Promotions
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.promotion_update}
                    onChange={(e) =>
                      this.setState(
                        { promotion_update: e.target.checked },
                        () => console.log(this.state.promotion_update)
                      )
                    }
                  >
                    Update Promotion
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.promotion_detail}
                    onChange={(e) =>
                      this.setState(
                        { promotion_detail: e.target.checked },
                        () => console.log(this.state.promotion_detail)
                      )
                    }
                  >
                    Promotion Detail
                  </Checkbox>
                </p>
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.wallet_list}
                    onChange={(e) =>
                      this.setState({ wallet_list: e.target.checked }, () =>
                        console.log(this.state.wallet_list)
                      )
                    }
                  >
                    Wallet List
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.wallet_history}
                    onChange={(e) =>
                      this.setState({ wallet_history: e.target.checked }, () =>
                        console.log(this.state.wallet_history)
                      )
                    }
                  >
                    Wallet History
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.add_money_to_wallet}
                    onChange={(e) =>
                      this.setState(
                        { add_money_to_wallet: e.target.checked },
                        () => console.log(this.state.add_money_to_wallet)
                      )
                    }
                  >
                    Add Money to Wallet
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.adjust_order_with_wallet_balance}
                    onChange={(e) =>
                      this.setState(
                        { adjust_order_with_wallet_balance: e.target.checked },
                        () =>
                          console.log(
                            this.state.adjust_order_with_wallet_balance
                          )
                      )
                    }
                  >
                    Adjust Order with Wallet Balance
                  </Checkbox>
                </p>

                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.auto_adjust_order_with_wallet_balance}
                    onChange={(e) =>
                      this.setState(
                        {
                          auto_adjust_order_with_wallet_balance:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.auto_adjust_order_with_wallet_balance
                          )
                      )
                    }
                  >
                    Auto Adjust Order with Wallet Balance
                  </Checkbox>
                </p>
              </CardContent>
            </CardMaterial>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row4"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      user_create: e.target.checked,
                      user_list: e.target.checked,
                      user_update: e.target.checked,
                      user_detail: e.target.checked,
                      user_delete: e.target.checked,
                      view_user_route_history: e.target.checked,
                      connect_user_to_org: e.target.checked,
                      bulk_update_user: e.target.checked,
                      user_mobile_heartbeat_logs: e.target.checked,
                      assigned_role_to_users: e.target.checked,
                    });
                  }}
                >
                  User
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.user_create}
                  onChange={(e) =>
                    this.setState({ user_create: e.target.checked }, () =>
                      console.log(this.state.user_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.user_list}
                  onChange={(e) =>
                    this.setState({ user_list: e.target.checked }, () =>
                      console.log(this.state.user_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.user_update}
                  onChange={(e) =>
                    this.setState({ user_update: e.target.checked }, () =>
                      console.log(this.state.user_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.user_detail}
                  onChange={(e) =>
                    this.setState({ user_detail: e.target.checked }, () =>
                      console.log(this.state.user_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.user_delete}
                  onChange={(e) =>
                    this.setState({ user_delete: e.target.checked }, () =>
                      console.log(this.state.user_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.view_user_route_history}
                  onChange={(e) =>
                    this.setState(
                      { view_user_route_history: e.target.checked },
                      () => console.log(this.state.view_user_route_history)
                    )
                  }
                >
                  View Route History
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.connect_user_to_org}
                  onChange={(e) =>
                    this.setState(
                      { connect_user_to_org: e.target.checked },
                      () => console.log(this.state.connect_user_to_org)
                    )
                  }
                >
                  Connect User to Organization
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.bulk_update_user}
                  onChange={(e) =>
                    this.setState({ bulk_update_user: e.target.checked }, () =>
                      console.log(this.state.bulk_update_user)
                    )
                  }
                >
                  User Bulk Upload
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.user_mobile_heartbeat_logs}
                  onChange={(e) =>
                    this.setState(
                      { user_mobile_heartbeat_logs: e.target.checked },
                      () => console.log(this.state.user_mobile_heartbeat_logs)
                    )
                  }
                >
                  View Mobile Heartbeat Log
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.assigned_role_to_users}
                  onChange={(e) =>
                    this.setState(
                      { assigned_role_to_users: e.target.checked },
                      () => console.log(this.state.assigned_role_to_users)
                    )
                  }
                >
                  Assign Role to User
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      supplier_create: e.target.checked,
                      supplier_list: e.target.checked,
                      supplier_update: e.target.checked,
                      supplier_detail: e.target.checked,
                      supplier_delete: e.target.checked,
                    });
                  }}
                >
                  Supplier
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.supplier_create}
                  onChange={(e) =>
                    this.setState({ supplier_create: e.target.checked }, () =>
                      console.log(this.state.supplier_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.supplier_list}
                  onChange={(e) =>
                    this.setState({ supplier_list: e.target.checked }, () =>
                      console.log(this.state.supplier_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.supplier_update}
                  onChange={(e) =>
                    this.setState({ supplier_update: e.target.checked }, () =>
                      console.log(this.state.supplier_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.supplier_detail}
                  onChange={(e) =>
                    this.setState({ supplier_detail: e.target.checked }, () =>
                      console.log(this.state.supplier_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.supplier_delete}
                  onChange={(e) =>
                    this.setState({ supplier_delete: e.target.checked }, () =>
                      console.log(this.state.supplier_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      product_create: e.target.checked,
                      product_list: e.target.checked,
                      product_update: e.target.checked,
                      product_detail: e.target.checked,
                      product_delete: e.target.checked,
                      product_category_create: e.target.checked,
                      product_category_list: e.target.checked,
                      product_category_detail: e.target.checked,
                      product_category_update: e.target.checked,
                    });
                  }}
                >
                  Product
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_category_create}
                  onChange={(e) =>
                    this.setState(
                      { product_category_create: e.target.checked },
                      () => console.log(this.state.product_category_create)
                    )
                  }
                >
                  Create Product Category
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_category_list}
                  onChange={(e) =>
                    this.setState(
                      { product_category_list: e.target.checked },
                      () => console.log(this.state.product_category_list)
                    )
                  }
                >
                  List Product Categories
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_category_detail}
                  onChange={(e) =>
                    this.setState(
                      { product_category_detail: e.target.checked },
                      () => console.log(this.state.product_category_detail)
                    )
                  }
                >
                  Product Category Detail
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_category_update}
                  onChange={(e) =>
                    this.setState(
                      { product_category_update: e.target.checked },
                      () => console.log(this.state.product_category_update)
                    )
                  }
                >
                  Update Product Category
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_create}
                  onChange={(e) =>
                    this.setState({ product_create: e.target.checked }, () =>
                      console.log(this.state.product_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_list}
                  onChange={(e) =>
                    this.setState({ product_list: e.target.checked }, () =>
                      console.log(this.state.product_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_update}
                  onChange={(e) =>
                    this.setState({ product_update: e.target.checked }, () =>
                      console.log(this.state.product_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_detail}
                  onChange={(e) =>
                    this.setState({ product_detail: e.target.checked }, () =>
                      console.log(this.state.product_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.product_delete}
                  onChange={(e) =>
                    this.setState({ product_delete: e.target.checked }, () =>
                      console.log(this.state.product_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      item_create: e.target.checked,
                      item_list: e.target.checked,
                      item_update: e.target.checked,
                      item_detail: e.target.checked,
                      item_delete: e.target.checked,
                      get_all_items: e.target.checked,
                    });
                  }}
                >
                  Items
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_create}
                  onChange={(e) =>
                    this.setState({ item_create: e.target.checked }, () =>
                      console.log(this.state.item_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_list}
                  onChange={(e) =>
                    this.setState({ item_list: e.target.checked }, () =>
                      console.log(this.state.item_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_update}
                  onChange={(e) =>
                    this.setState({ item_update: e.target.checked }, () =>
                      console.log(this.state.item_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_detail}
                  onChange={(e) =>
                    this.setState({ item_detail: e.target.checked }, () =>
                      console.log(this.state.item_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_delete}
                  onChange={(e) =>
                    this.setState({ item_delete: e.target.checked }, () =>
                      console.log(this.state.item_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.get_all_items}
                  onChange={(e) =>
                    this.setState({ get_all_items: e.target.checked }, () =>
                      console.log(this.state.get_all_items)
                    )
                  }
                >
                  Get All Items
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      item_type_create: e.target.checked,
                      item_type_list: e.target.checked,
                      item_type_update: e.target.checked,
                      item_type_detail: e.target.checked,
                      item_type_delete: e.target.checked,
                    });
                  }}
                >
                  Item Type
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_type_create}
                  onChange={(e) =>
                    this.setState({ item_type_create: e.target.checked }, () =>
                      console.log(this.state.item_type_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_type_list}
                  onChange={(e) =>
                    this.setState({ item_type_list: e.target.checked }, () =>
                      console.log(this.state.item_type_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_type_update}
                  onChange={(e) =>
                    this.setState({ item_type_update: e.target.checked }, () =>
                      console.log(this.state.item_type_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_type_detail}
                  onChange={(e) =>
                    this.setState({ item_type_detail: e.target.checked }, () =>
                      console.log(this.state.item_type_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.item_type_delete}
                  onChange={(e) =>
                    this.setState({ item_type_delete: e.target.checked }, () =>
                      console.log(this.state.item_type_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row5"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      roaster_create: e.target.checked,
                      roaster_list: e.target.checked,
                      roaster_update: e.target.checked,
                      roaster_detail: e.target.checked,
                      delete_user_from_roaster: e.target.checked,
                      roaster_delete: e.target.checked,
                    });
                  }}
                >
                  Roaster
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_create}
                  onChange={(e) =>
                    this.setState({ roaster_create: e.target.checked }, () =>
                      console.log(this.state.roaster_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_list}
                  onChange={(e) =>
                    this.setState({ roaster_list: e.target.checked }, () =>
                      console.log(this.state.roaster_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_update}
                  onChange={(e) =>
                    this.setState({ roaster_update: e.target.checked }, () =>
                      console.log(this.state.roaster_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_detail}
                  onChange={(e) =>
                    this.setState({ roaster_detail: e.target.checked }, () =>
                      console.log(this.state.roaster_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_delete}
                  onChange={(e) =>
                    this.setState({ roaster_delete: e.target.checked }, () =>
                      console.log(this.state.roaster_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.delete_user_from_roaster}
                  onChange={(e) =>
                    this.setState(
                      { delete_user_from_roaster: e.target.checked },
                      () => console.log(this.state.delete_user_from_roaster)
                    )
                  }
                >
                  Delete User from Roaster
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      roaster_plan_create: e.target.checked,
                      roaster_plan_list: e.target.checked,
                      roaster_plan_update: e.target.checked,
                      roaster_plan_detail: e.target.checked,
                      roaster_plan_delete: e.target.checked,
                    });
                  }}
                >
                  Roaster Plan
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_plan_create}
                  onChange={(e) =>
                    this.setState(
                      { roaster_plan_create: e.target.checked },
                      () => console.log(this.state.roaster_plan_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_plan_list}
                  onChange={(e) =>
                    this.setState({ roaster_plan_list: e.target.checked }, () =>
                      console.log(this.state.roaster_plan_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_plan_update}
                  onChange={(e) =>
                    this.setState(
                      { roaster_plan_update: e.target.checked },
                      () => console.log(this.state.roaster_plan_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_plan_detail}
                  onChange={(e) =>
                    this.setState(
                      { roaster_plan_detail: e.target.checked },
                      () => console.log(this.state.roaster_plan_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roaster_plan_delete}
                  onChange={(e) =>
                    this.setState(
                      { roaster_plan_delete: e.target.checked },
                      () => console.log(this.state.roaster_plan_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>

          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      access_all_data: e.target.checked,
                      access_suborg_data: e.target.checked,
                      access_own_data: e.target.checked,
                    });
                  }}
                >
                  Object Permission
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.access_all_data}
                  onChange={(e) =>
                    this.setState({ access_all_data: e.target.checked }, () =>
                      console.log(this.state.access_all_data)
                    )
                  }
                >
                  Access All Data
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.access_suborg_data}
                  onChange={(e) =>
                    this.setState(
                      { access_suborg_data: e.target.checked },
                      () => console.log(this.state.access_suborg_data)
                    )
                  }
                >
                  Access Suborg Data
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.access_own_data}
                  onChange={(e) =>
                    this.setState({ access_own_data: e.target.checked }, () =>
                      console.log(this.state.access_own_data)
                    )
                  }
                >
                  Access Own Data
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      roles_and_permission_create: e.target.checked,
                      roles_and_permission_list: e.target.checked,
                      roles_and_permission_update: e.target.checked,
                      roles_and_permission_detail: e.target.checked,
                      roles_and_permission_delete: e.target.checked,
                      add_or_update_permissions_to_roles: e.target.checked,
                      view_permissions_against_roles: e.target.checked,
                      view_users_against_roles: e.target.checked,
                    });
                  }}
                >
                  Roles and Permissions
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roles_and_permission_create}
                  onChange={(e) =>
                    this.setState(
                      { roles_and_permission_create: e.target.checked },
                      () => console.log(this.state.roles_and_permission_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roles_and_permission_list}
                  onChange={(e) =>
                    this.setState(
                      { roles_and_permission_list: e.target.checked },
                      () => console.log(this.state.roles_and_permission_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roles_and_permission_update}
                  onChange={(e) =>
                    this.setState(
                      { roles_and_permission_update: e.target.checked },
                      () => console.log(this.state.roles_and_permission_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roles_and_permission_detail}
                  onChange={(e) =>
                    this.setState(
                      { roles_and_permission_detail: e.target.checked },
                      () => console.log(this.state.roles_and_permission_detail)
                    )
                  }
                >
                  Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.roles_and_permission_delete}
                  onChange={(e) =>
                    this.setState(
                      { roles_and_permission_delete: e.target.checked },
                      () => console.log(this.state.roles_and_permission_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.add_or_update_permissions_to_roles}
                  onChange={(e) =>
                    this.setState(
                      { add_or_update_permissions_to_roles: e.target.checked },
                      () =>
                        console.log(
                          this.state.add_or_update_permissions_to_roles
                        )
                    )
                  }
                >
                  Add or Update Permissions
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.view_permissions_against_roles}
                  onChange={(e) =>
                    this.setState(
                      { view_permissions_against_roles: e.target.checked },
                      () =>
                        console.log(this.state.view_permissions_against_roles)
                    )
                  }
                >
                  View Permissions
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.view_users_against_roles}
                  onChange={(e) =>
                    this.setState(
                      { view_users_against_roles: e.target.checked },
                      () => console.log(this.state.view_users_against_roles)
                    )
                  }
                >
                  View User Roles
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      unit_create: e.target.checked,
                      unit_list: e.target.checked,
                      unit_update: e.target.checked,
                      unit_detail: e.target.checked,
                      unit_delete: e.target.checked,
                    });
                  }}
                >
                  Unit
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.unit_create}
                  onChange={(e) =>
                    this.setState({ unit_create: e.target.checked }, () =>
                      console.log(this.state.unit_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.unit_list}
                  onChange={(e) =>
                    this.setState({ unit_list: e.target.checked }, () =>
                      console.log(this.state.unit_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.unit_update}
                  onChange={(e) =>
                    this.setState({ unit_update: e.target.checked }, () =>
                      console.log(this.state.unit_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.unit_detail}
                  onChange={(e) =>
                    this.setState({ unit_detail: e.target.checked }, () =>
                      console.log(this.state.unit_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.unit_delete}
                  onChange={(e) =>
                    this.setState({ unit_delete: e.target.checked }, () =>
                      console.log(this.state.unit_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row6"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      visit_plan_create: e.target.checked,
                      visit_plan_list: e.target.checked,
                      visit_plan_update: e.target.checked,
                      visit_plan_detail: e.target.checked,
                      visit_plan_delete: e.target.checked,
                      generate_chain_task_from_selected_plan: e.target.checked,
                    });
                  }}
                >
                  Visit Plan
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.visit_plan_create}
                  onChange={(e) =>
                    this.setState({ visit_plan_create: e.target.checked }, () =>
                      console.log(this.state.visit_plan_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.visit_plan_list}
                  onChange={(e) =>
                    this.setState({ visit_plan_list: e.target.checked }, () =>
                      console.log(this.state.visit_plan_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.visit_plan_update}
                  onChange={(e) =>
                    this.setState({ visit_plan_update: e.target.checked }, () =>
                      console.log(this.state.visit_plan_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.visit_plan_detail}
                  onChange={(e) =>
                    this.setState({ visit_plan_detail: e.target.checked }, () =>
                      console.log(this.state.visit_plan_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.visit_plan_delete}
                  onChange={(e) =>
                    this.setState({ visit_plan_delete: e.target.checked }, () =>
                      console.log(this.state.visit_plan_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
              {this.state.visit_plan_to_task_generation ? (
                <p
                  style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}
                >
                  <Checkbox
                    checked={this.state.generate_chain_task_from_selected_plan}
                    onChange={(e) =>
                      this.setState(
                        {
                          generate_chain_task_from_selected_plan:
                            e.target.checked,
                        },
                        () =>
                          console.log(
                            this.state.generate_chain_task_from_selected_plan
                          )
                      )
                    }
                  >
                    Generate Chain Task
                  </Checkbox>
                </p>
              ) : null}
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      incident_create: e.target.checked,
                      incident_list: e.target.checked,
                      incident_update: e.target.checked,
                      incident_detail: e.target.checked,
                      incident_delete: e.target.checked,
                    });
                  }}
                >
                  Incident
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.incident_create}
                  onChange={(e) =>
                    this.setState({ incident_create: e.target.checked }, () =>
                      console.log(this.state.incident_create)
                    )
                  }
                >
                  Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.incident_list}
                  onChange={(e) =>
                    this.setState({ incident_list: e.target.checked }, () =>
                      console.log(this.state.incident_list)
                    )
                  }
                >
                  View List
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.incident_update}
                  onChange={(e) =>
                    this.setState({ incident_update: e.target.checked }, () =>
                      console.log(this.state.incident_update)
                    )
                  }
                >
                  Update
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.incident_detail}
                  onChange={(e) =>
                    this.setState({ incident_detail: e.target.checked }, () =>
                      console.log(this.state.incident_detail)
                    )
                  }
                >
                  View Detail
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.incident_delete}
                  onChange={(e) =>
                    this.setState({ incident_delete: e.target.checked }, () =>
                      console.log(this.state.incident_delete)
                    )
                  }
                >
                  Delete
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      attendance_settings: e.target.value,
                      inventory_connection_settings: e.target.value,
                      tracking_settings: e.target.value,
                      subscription_billing_settings: e.target.value,
                      order_settings: e.target.checked,
                      sms_settings: e.target.checked,
                      email_settings: e.target.checked,
                    });
                  }}
                >
                  Settings
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.order_settings}
                  onChange={(e) =>
                    this.setState({ order_settings: e.target.checked }, () =>
                      console.log(this.state.order_settings)
                    )
                  }
                >
                  Order Settings
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sms_settings}
                  onChange={(e) =>
                    this.setState({ sms_settings: e.target.checked }, () =>
                      console.log(this.state.sms_settings)
                    )
                  }
                >
                  SMS Settings
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.email_settings}
                  onChange={(e) =>
                    this.setState({ email_settings: e.target.checked }, () =>
                      console.log(this.state.email_settings)
                    )
                  }
                >
                  Email Settings
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.attendance_settings}
                  onChange={(e) =>
                    this.setState(
                      { attendance_settings: e.target.checked },
                      () => console.log(this.state.attendance_settings)
                    )
                  }
                >
                  Attendance Settings
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_connection_settings}
                  onChange={(e) =>
                    this.setState(
                      { inventory_connection_settings: e.target.checked },
                      () =>
                        console.log(this.state.inventory_connection_settings)
                    )
                  }
                >
                  Inventory Connection Settings
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.tracking_settings}
                  onChange={(e) =>
                    this.setState({ tracking_settings: e.target.checked }, () =>
                      console.log(this.state.tracking_settings)
                    )
                  }
                >
                  Tracking Settings
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.subscription_billing_settings}
                  onChange={(e) =>
                    this.setState(
                      { subscription_billing_settings: e.target.checked },
                      () =>
                        console.log(this.state.subscription_billing_settings)
                    )
                  }
                >
                  Subscription Billing Settings
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      attendance_organizational_report: e.target.checked,
                      attendance_organizational_report_with_custom_fields:
                        e.target.checked,
                      single_person_attendance_report: e.target.checked,
                      leave_organizational_report: e.target.checked,
                      daily_task_report: e.target.checked,
                      task_report_based_on_filters: e.target.checked,
                      task_report_based_on_users: e.target.checked,
                      order_report: e.target.checked,
                      asset_report: e.target.checked,
                      multiple_inventory_summary_report: e.target.checked,
                      inventory_detail_report: e.target.checked,
                      inventory_stock_history_report: e.target.checked,
                      inventory_closing_history_report: e.target.checked,
                      inventory_low_stock_report: e.target.checked,
                      download_inventory_details_report: e.target.checked,
                      due_collection_report: e.target.checked,
                    });
                  }}
                >
                  Reports
                </Checkbox>
              </h2>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.attendance_organizational_report}
                  onChange={(e) =>
                    this.setState(
                      { attendance_organizational_report: e.target.checked },
                      () =>
                        console.log(this.state.attendance_organizational_report)
                    )
                  }
                >
                  Organization Attendance Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={
                    this.state
                      .attendance_organizational_report_with_custom_fields
                  }
                  onChange={(e) =>
                    this.setState(
                      {
                        attendance_organizational_report_with_custom_fields:
                          e.target.checked,
                      },
                      () =>
                        console.log(
                          this.state
                            .attendance_organizational_report_with_custom_fields
                        )
                    )
                  }
                >
                  Organization Attendance Report With Custom Fields
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.single_person_attendance_report}
                  onChange={(e) =>
                    this.setState(
                      { single_person_attendance_report: e.target.checked },
                      () =>
                        console.log(this.state.single_person_attendance_report)
                    )
                  }
                >
                  User Based Attendance Report
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.leave_organizational_report}
                  onChange={(e) =>
                    this.setState(
                      { leave_organizational_report: e.target.checked },
                      () => console.log(this.state.leave_organizational_report)
                    )
                  }
                >
                  Organization Leave Report
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.daily_task_report}
                  onChange={(e) =>
                    this.setState({ daily_task_report: e.target.checked }, () =>
                      console.log(this.state.daily_task_report)
                    )
                  }
                >
                  Task Daily Report
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_report_based_on_filters}
                  onChange={(e) =>
                    this.setState(
                      { task_report_based_on_filters: e.target.checked },
                      () => console.log(this.state.task_report_based_on_filters)
                    )
                  }
                >
                  Task Report Based on filter
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.task_report_based_on_users}
                  onChange={(e) =>
                    this.setState(
                      { task_report_based_on_users: e.target.checked },
                      () => console.log(this.state.task_report_based_on_users)
                    )
                  }
                >
                  Task Report Based on User
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.order_report}
                  onChange={(e) =>
                    this.setState({ order_report: e.target.checked }, () =>
                      console.log(this.state.order_report)
                    )
                  }
                >
                  Order Report
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.asset_report}
                  onChange={(e) =>
                    this.setState({ asset_report: e.target.checked }, () =>
                      console.log(this.state.asset_report)
                    )
                  }
                >
                  Asset Report
                </Checkbox>
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.multiple_inventory_summary_report}
                  onChange={(e) =>
                    this.setState(
                      { multiple_inventory_summary_report: e.target.checked },
                      () =>
                        console.log(
                          this.state.multiple_inventory_summary_report
                        )
                    )
                  }
                >
                  Multiple Inventory Summary Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_detail_report}
                  onChange={(e) =>
                    this.setState(
                      { inventory_detail_report: e.target.checked },
                      () => console.log(this.state.inventory_detail_report)
                    )
                  }
                >
                  Inventory Detail Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_stock_history_report}
                  onChange={(e) =>
                    this.setState(
                      { inventory_stock_history_report: e.target.checked },
                      () =>
                        console.log(this.state.inventory_stock_history_report)
                    )
                  }
                >
                  Inventory Stock History Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_closing_history_report}
                  onChange={(e) =>
                    this.setState(
                      { inventory_closing_history_report: e.target.checked },
                      () =>
                        console.log(this.state.inventory_closing_history_report)
                    )
                  }
                >
                  Inventory Closing History Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_low_stock_report}
                  onChange={(e) =>
                    this.setState(
                      { inventory_low_stock_report: e.target.checked },
                      () => console.log(this.state.inventory_low_stock_report)
                    )
                  }
                >
                  Inventory Low Stock Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.download_inventory_details_report}
                  onChange={(e) =>
                    this.setState(
                      { download_inventory_details_report: e.target.checked },
                      () =>
                        console.log(
                          this.state.download_inventory_details_report
                        )
                    )
                  }
                >
                  Download Inventory Details Report
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.due_collection_report}
                  onChange={(e) =>
                    this.setState(
                      { due_collection_report: e.target.checked },
                      () => console.log(this.state.due_collection_report)
                    )
                  }
                >
                  Due Collection Report
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      custom_form_create: e.target.checked,
                      custom_form_list: e.target.checked,
                      custom_form_update: e.target.checked,
                      custom_form_detail: e.target.checked,
                      custom_form_delete: e.target.checked,
                      custom_form_data: e.target.checked,
                    });
                  }}
                >
                  Custom Form Permissions
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_form_create}
                  onChange={(e) =>
                    this.setState(
                      { custom_form_create: e.target.checked },
                      () => console.log(this.state.custom_form_create)
                    )
                  }
                >
                  Custom Form Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_form_list}
                  onChange={(e) =>
                    this.setState({ custom_form_list: e.target.checked }, () =>
                      console.log(this.state.custom_form_list)
                    )
                  }
                >
                  Custom Form List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_form_update}
                  onChange={(e) =>
                    this.setState(
                      { custom_form_update: e.target.checked },
                      () => console.log(this.state.custom_form_update)
                    )
                  }
                >
                  Custom Form Update
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_form_detail}
                  onChange={(e) =>
                    this.setState(
                      { custom_form_detail: e.target.checked },
                      () => console.log(this.state.custom_form_detail)
                    )
                  }
                >
                  Custom Form Detail
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_form_delete}
                  onChange={(e) =>
                    this.setState(
                      { custom_form_delete: e.target.checked },
                      () => console.log(this.state.custom_form_delete)
                    )
                  }
                >
                  Custom Form Delete
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.custom_form_data}
                  onChange={(e) =>
                    this.setState({ custom_form_data: e.target.checked }, () =>
                      console.log(this.state.custom_form_data)
                    )
                  }
                >
                  Custom Form Data
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: 5,
          }}
          id="row7"
        >
          <CardMaterial style={{ flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      create_inventory: e.target.checked,
                      modify_inventory_stock: e.target.checked,
                      update_inventory_closing_value: e.target.checked,
                      inventory_list: e.target.checked,
                      inventory_detail: e.target.checked,
                      inventory_closing_history: e.target.checked,
                      inventory_stock_history: e.target.checked,
                      inventory_single_product_stock_history: e.target.checked,
                      inventory_internal_transfer: e.target.checked,
                      orders_by_inventory_product: e.target.checked,
                    });
                  }}
                >
                  Inventory
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.create_inventory}
                  onChange={(e) =>
                    this.setState({ create_inventory: e.target.checked }, () =>
                      console.log(this.state.create_inventory)
                    )
                  }
                >
                  Create Inventory
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.modify_inventory_stock}
                  onChange={(e) =>
                    this.setState(
                      { modify_inventory_stock: e.target.checked },
                      () => console.log(this.state.modify_inventory_stock)
                    )
                  }
                >
                  Modify Inventory Stock
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.update_inventory_closing_value}
                  onChange={(e) =>
                    this.setState(
                      { update_inventory_closing_value: e.target.checked },
                      () =>
                        console.log(this.state.update_inventory_closing_value)
                    )
                  }
                >
                  Update Inventory Closing Value
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_list}
                  onChange={(e) =>
                    this.setState({ inventory_list: e.target.checked }, () =>
                      console.log(this.state.inventory_list)
                    )
                  }
                >
                  Inventory List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_detail}
                  onChange={(e) =>
                    this.setState({ inventory_detail: e.target.checked }, () =>
                      console.log(this.state.inventory_detail)
                    )
                  }
                >
                  Inventory Detail
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_closing_history}
                  onChange={(e) =>
                    this.setState(
                      { inventory_closing_history: e.target.checked },
                      () => console.log(this.state.inventory_closing_history)
                    )
                  }
                >
                  Inventory Closing History
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_stock_history}
                  onChange={(e) =>
                    this.setState(
                      { inventory_stock_history: e.target.checked },
                      () => console.log(this.state.inventory_stock_history)
                    )
                  }
                >
                  Inventory Stock History
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_single_product_stock_history}
                  onChange={(e) =>
                    this.setState(
                      {
                        inventory_single_product_stock_history:
                          e.target.checked,
                      },
                      () =>
                        console.log(
                          this.state.inventory_single_product_stock_history
                        )
                    )
                  }
                >
                  Inventory Single Product Stock History
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.inventory_internal_transfer}
                  onChange={(e) =>
                    this.setState(
                      { inventory_internal_transfer: e.target.checked },
                      () => console.log(this.state.inventory_internal_transfer)
                    )
                  }
                >
                  Inventory Internal Transfer
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.orders_by_inventory_product}
                  onChange={(e) =>
                    this.setState(
                      { orders_by_inventory_product: e.target.checked },
                      () => console.log(this.state.orders_by_inventory_product)
                    )
                  }
                >
                  Orders by Inventory Product
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      sub_org_type_create: e.target.checked,
                      sub_org_type_list: e.target.checked,
                      sub_org_type_detail: e.target.checked,
                      sub_org_type_update: e.target.checked,
                      sub_org_create: e.target.checked,
                      sub_org_list: e.target.checked,
                      sub_org_detail: e.target.checked,
                      sub_org_update: e.target.checked,
                    });
                  }}
                >
                  Sub Org Permissions
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_type_create}
                  onChange={(e) =>
                    this.setState(
                      { sub_org_type_create: e.target.checked },
                      () => console.log(this.state.sub_org_type_create)
                    )
                  }
                >
                  Sub Org Type Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_type_list}
                  onChange={(e) =>
                    this.setState({ sub_org_type_list: e.target.checked }, () =>
                      console.log(this.state.sub_org_type_list)
                    )
                  }
                >
                  Sub Org Type List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_type_detail}
                  onChange={(e) =>
                    this.setState(
                      { sub_org_type_detail: e.target.checked },
                      () => console.log(this.state.sub_org_type_detail)
                    )
                  }
                >
                  Sub Org Type Detail
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_type_update}
                  onChange={(e) =>
                    this.setState(
                      { sub_org_type_update: e.target.checked },
                      () => console.log(this.state.sub_org_type_update)
                    )
                  }
                >
                  Sub Org Type Update
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_create}
                  onChange={(e) =>
                    this.setState({ sub_org_create: e.target.checked }, () =>
                      console.log(this.state.sub_org_create)
                    )
                  }
                >
                  Sub Org Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_list}
                  onChange={(e) =>
                    this.setState({ sub_org_list: e.target.checked }, () =>
                      console.log(this.state.sub_org_list)
                    )
                  }
                >
                  Sub Org List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_detail}
                  onChange={(e) =>
                    this.setState({ sub_org_detail: e.target.checked }, () =>
                      console.log(this.state.sub_org_detail)
                    )
                  }
                >
                  Sub Org Detail
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.sub_org_update}
                  onChange={(e) =>
                    this.setState({ sub_org_update: e.target.checked }, () =>
                      console.log(this.state.sub_org_update)
                    )
                  }
                >
                  Sub Org Update
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      qr_code_create: e.target.checked,
                      qr_code_list: e.target.checked,
                      qr_code_detail: e.target.checked,
                    });
                  }}
                >
                  QR Code Permissions
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.qr_code_create}
                  onChange={(e) =>
                    this.setState({ qr_code_create: e.target.checked }, () =>
                      console.log(this.state.qr_code_create)
                    )
                  }
                >
                  QR Code Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.qr_code_list}
                  onChange={(e) =>
                    this.setState({ qr_code_list: e.target.checked }, () =>
                      console.log(this.state.qr_code_list)
                    )
                  }
                >
                  QR Code List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.qr_code_detail}
                  onChange={(e) =>
                    this.setState({ qr_code_detail: e.target.checked }, () =>
                      console.log(this.state.qr_code_detail)
                    )
                  }
                >
                  QR Code Detail
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      due_collection_list: e.target.checked,
                      due_collection_request_handover: e.target.checked,
                    });
                  }}
                >
                  Due Collection Permissions
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.due_collection_list}
                  onChange={(e) =>
                    this.setState(
                      { due_collection_list: e.target.checked },
                      () => console.log(this.state.due_collection_list)
                    )
                  }
                >
                  Due Collection List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.due_collection_request_handover}
                  onChange={(e) =>
                    this.setState(
                      { due_collection_request_handover: e.target.checked },
                      () =>
                        console.log(this.state.due_collection_request_handover)
                    )
                  }
                >
                  Due Collection Request Handover
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
          <CardMaterial style={{ marginLeft: 5, flexBasis: "50%" }}>
            <CardContent>
              <h2
                style={{
                  fontFamily: "AirbnbCerealApp-ExtraBold",
                  marginLeft: 5,
                  letterSpacing: 1,
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      handover_request_create: e.target.checked,
                      handover_request_list: e.target.checked,
                      handover_request_approve: e.target.checked,
                    });
                  }}
                >
                  Handover Request Permissions
                </Checkbox>
              </h2>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.handover_request_create}
                  onChange={(e) =>
                    this.setState(
                      { handover_request_create: e.target.checked },
                      () => console.log(this.state.handover_request_create)
                    )
                  }
                >
                  Handover Request Create
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.handover_request_list}
                  onChange={(e) =>
                    this.setState(
                      { handover_request_list: e.target.checked },
                      () => console.log(this.state.handover_request_list)
                    )
                  }
                >
                  Handover Request List
                </Checkbox>
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
                <Checkbox
                  checked={this.state.handover_request_approve}
                  onChange={(e) =>
                    this.setState(
                      { handover_request_approve: e.target.checked },
                      () => console.log(this.state.handover_request_approve)
                    )
                  }
                >
                  Handover Request Approve
                </Checkbox>
              </p>
            </CardContent>
          </CardMaterial>
        </div>
        <Button
          className="login-form-button"
          htmlType="submit"
          style={{ marginTop: 10 }}
          onClick={this.updatePermission}
        >
          Update Permissions
        </Button>
      </Card>
    );
  }
}
