
import React from "react";
import Logo from "../assets/icon.png";

export const Spinner = () => (
  <div
    style={{
      position: "absolute",
      top: window.innerHeight / 2.3,
      left: window.innerWidth / 2,
      transform: "translate(-50%, -50%)",
      margin: "auto",
      textAlign: "center"
    }}
  >
    <div style={{ position: "relative", width: 100, height: 100 }}>
      <img
        src={Logo}
        alt=""
        style={{
          width: "100%",
          borderRadius: "50%",
          animation: "spinY 2s linear infinite"
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          background:
            "linear-gradient(120deg, rgba(255,255,255,0) 30%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 70%)",
          animation: "shine 1.5s linear infinite"
        }}
      />
    </div>
    <style>
      {`
        @keyframes spinY {
          from { transform: rotateY(0deg); }
          to { transform: rotateY(360deg); }
        }
        @keyframes shine {
          from { transform: translateX(-100%); }
          to { transform: translateX(100%); }
        }
      `}
    </style>
  </div>
);
