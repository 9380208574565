import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Icon,
} from "antd";
import axios from "axios";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import swal from "sweetalert2";
import { debounce } from "lodash";
import {
  PLACE_LIST_FOR_PURCHASE_ORDER,
  PLACE_DETAILS_FOR_PURCHASE_ORDER,
  INVENTORY_BY_PLACE,
  CREATE_PRODUCT,
  CATEGORIES_BY_ORG,
  CREATE_ORDER,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  ORDER_NUMBER,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

export default class CreatePurchaseOrder extends Component {
  state = {
    places_for_purchase_order: [],
    place_id: "",
    place_name: "",
    inventories_by_place: [],
    inventory_id: "",
    inventory_name: "",
    endObj: {},
    end_loc: "",
    end_loc_name: "",
    sub_org_id: "",
    assigned_org: "",
    assigned_org_name: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_email: "",
    product_categories: [],
    category_name: "",
    data: [],
    items: [],
    selected_items: [],
    delivery_date_time: Date.now(),
    delivery_date_time_view: moment(),
    current_loc_lat: 23.685,
    current_loc_lon: 90.3563,
    current_loc_name: "",
    order_number: "",
    total_price: 0,
    delivery_charge: 0,
    other_charges: 0,
    bill_amount: 0,
    total_price_before_discount: 0,
    total_discount: 0,
    organization: "",
  };
  componentDidMount() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          current_loc_lat: latitude,
          current_loc_lon: longitude,
        },
        () => {
          GET_ACCESS_TOKEN().then((token) => {
            const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.current_loc_lat}&lng=${this.state.current_loc_lon}&access_token=${token}`;
            axios({
              method: "get",
              url: url,
            }).then((response) => {
              console.log("reverse api response", response);
              let name = response.data.google_loc_data[0].address;
              this.setState({
                current_loc_name: name,
              });
            });
          });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
    this.getPlaceForPurchaseOrder();
    this.getOrderNumber();
  }

  getOrderNumber = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_NUMBER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order Number", response);
          this.setState({
            order_number: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  getProductCategory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CATEGORIES_BY_ORG}${this.state.assigned_org}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ product_categories: response.data?.data ?? [] });
          console.log(
            "data from product category list using org api",
            response
          );
        })
        .catch((error) => {
          console.log("error from product category list using org api", error);
        });
    });
  };

  getProductList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_PRODUCT}?category_id=${this.state.category_name}&product_status=active&inventory_id=${this.state.inventory_id}&enable_order_for_availabl_stock_or_stock_quantity_zero=false&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product", response);
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          console.log("..........................", products);
          this.setState(
            { data: [...this.state.data, ...products1], items: products },
            () => {
              console.log("data joined", this.state.data);
            }
          );
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  itemSearch = debounce((value, event) => {
    let limit = 100;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?category_name=${this.state.category_name}&name=${value}&product_status=active&inventory_id=${this.state.inventory_id}&enable_order_for_availabl_stock_or_stock_quantity_zero=false&limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
          }));

          console.log("..........................", products);
          this.setState(
            { data: [...this.state.data, ...products1], items: products },
            () => {
              console.log("data joined", this.state.data);
            }
          );
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  getPlaceForPurchaseOrder = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PLACE_LIST_FOR_PURCHASE_ORDER}?org_name=&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            places_for_purchase_order: response.data?.data ?? [],
          });
          console.log("data from place purchase order list api", response);
        })
        .catch((error) => {
          console.log("error from place purchase order list api", error);
        });
    });
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PLACE_LIST_FOR_PURCHASE_ORDER}?org_name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("data from place purchase order list api", response);
          this.setState({
            places_for_purchase_order: response.data?.data ?? [],
          });
        })
        .catch((error) => {
          console.log("error from place purchase order list api", error);
        });
    });
  }, 500);

  placeDetailsForPurchaseOrder = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PLACE_DETAILS_FOR_PURCHASE_ORDER}${this.state.place_id}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              endObj: {
                name: response?.data?.data?.name ?? "",
                id: response?.data?.data?._id?.$oid ?? "",
                entity_type: response?.data?.data?.entity_type ?? "",
                loc: response?.data?.data?.loc ?? "",
                address: response?.data?.data?.address ?? "",
                city: response?.data?.data?.city ?? "",
                country: response?.data?.data?.country ?? "",
                post_code: response?.data?.data?.post_code ?? "",
                organization: response?.data?.data?.organization?.$oid ?? "",
              },
              end_loc: response?.data?.data?.loc ?? "",
              end_loc_name: response?.data?.data?.address ?? "",
              sub_org_id: response?.data?.data?.sub_org.id ?? "",
              assigned_org: response.data?.data?.organization?.$oid ?? "",
              assigned_org_name: response.data?.data?.org_name ?? "",
              drop_contact_name: response.data?.data?.contact?.name ?? "",
              drop_contact_no: response.data?.data?.contact?.mobile ?? "",
              drop_contact_email: response.data?.data?.contact?.email ?? "",
              organization: response?.data?.data?.organization?.$oid ?? "",
            },
            () => {
              this.getProductCategory();
            }
          );
          console.log("data from place details api", response);
        })
        .catch((error) => {
          console.log("error from place details api", error);
        });
    });
  };

  getInventoryFromPlace = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INVENTORY_BY_PLACE}${this.state.place_id}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              inventories_by_place: response.data?.data ?? [],
              inventory_id:
                response.data?.data?.length > 0
                  ? response.data.data[0]._id.$oid
                  : "",
              inventory_name:
                response.data?.data?.length > 0
                  ? response.data.data[0].entity_name
                  : "",
            },
            () => this.getProductList()
          );
          console.log("data from inventory by place list api", response);
        })
        .catch((error) => {
          console.log("error from inventory by place list api", error);
        });
    });
  };

  setProductCategory = (value) => {
    this.setState({ category_name: value }, () => {
      this.getProductList();
      console.log(this.state.category_name);
    });
  };

  setPlace = (value) => {
    this.setState({ place_id: value.key, place_name: value.label }, () => {
      this.getInventoryFromPlace();
      this.placeDetailsForPurchaseOrder();
    });
  };

  setInventory = (value) => {
    this.setState(
      { inventory_name: value.label, inventory_id: value.key },
      () => {
        console.log(this.state.inventory_name);
        this.getProductList();
      }
    );
  };

  setTime = (value, dateString) => {
    this.setState(
      {
        delivery_date_time: moment(value).format("x"),
        delivery_date_time_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.delivery_date_time);
        console.log("Selected value: ", this.state.delivery_date_time_view);
      }
    );
  };

  calculateTotal = async () => {
    let final = this.state.data.filter((i) => i.qty !== 0);
    final.map((i) => {
      let total = i.discounted_price;
      let total_before_discount = i.price;
      let total_discount = i.discount;
      this.setState(
        (prevState) => ({
          total_price: prevState.total_price + total,
          total_price_before_discount:
            prevState.total_price_before_discount + total_before_discount,
          total_discount: prevState.total_discount + total_discount,
        }),
        () => {
          this.setState({
            bill_amount:
              Number(this.state.total_price) +
              Number(this.state.delivery_charge) +
              Number(this.state.other_charges),
          });
          console.log("total price", this.state.total_price);
        }
      );

      return null;
    });

    await new Promise((resolve) => resolve());
    this.createOrder();
  };

  createOrder = () => {
    let final = this.state.data.filter((i) => i.qty !== 0);
    let data = {
      current_loc: {
        coordinates: [this.state.current_loc_lon, this.state.current_loc_lat],
      },
      current_loc_name: this.state.current_loc_name,
      order_status: {
        name: "order_placed",
        seq_no: 1,
      },
      order_number: this.state.order_number,
      order_type: "purchase_order",
      organization: this.state.organization,
      name: `${this.state.order_number}-${this.state.end_loc_name}`,
      details: "",
      sub_org: this.state.sub_org_id,
      inventory_id: this.state.inventory_id,
      inventory_entity_name: this.state.inventory_name,
      items: final,
      drop_contact_name: this.state.drop_contact_name,
      drop_contact_no: this.state.drop_contact_no,
      drop_contact_email: this.state.drop_contact_email,
      start_entity: [],
      end_entity: this.state.endObj,
      end_loc: this.state.end_loc,
      end_loc_name: this.state.end_loc_name,
      parcel_dim: {
        pl: 0,
        pw: 0,
        ph: 0,
      },
      unit_dim: 0,
      unit_weight: 0,
      parcel_weight: 0,
      max_weight: 0,
      fragile: false,
      temperature: {
        sensitive: false,
        temperature: null,
        fast: false,
      },
      ucp: false,
      assigned_org: this.state.assigned_org,
      assigned_org_name: this.state.assigned_org_name,
      parcel_wght: null,
      total_price: 0,
      delivery_charge: 0,
      delivery_charge_included: true,
      other_charges: 0,
      other_discount_type: "amount",
      other_discount: 0,
      bill_amount: this.state.bill_amount,
      received_amount: 0,
      received_method: "cash",
      received_date: 0,
      due_amount: 0,
      received: false,
      delivery: true,
      delivered: false,
      delivery_time_flexible: false,
      delivery_date_time: this.state.delivery_date_time,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_ORDER}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if(response.data.message==="Data successfully inserted!"){
            window.location.reload();
          }
          this.getOrderNumber();
          console.log("response from create order", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.getOrderNumber();
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from create order", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Create Purchase Order"
        />
        <Form>
          <Form.Item>
            <Text>Search Place With Organization Name </Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={true}
              onSearch={this.searchPlace}
              value={{
                key: this.state.place_id,
                label: this.state.place_name,
              }}
              onChange={this.setPlace}
              placeholder="Select Start Place"
            >
              {this.state.places_for_purchase_order.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`Name : ${r.name} >>> Organization : ${r.org_name} >>> Contact : ${r.contact.mobile}`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Select Inventory :</Text>
              <Select
                labelInValue
                style={{ width: "100%" }}
                value={{
                  key: this.state.inventory_id,
                  label: this.state.inventory_name,
                }}
                onChange={this.setInventory}
                placeholder="Select Inventory"
              >
                {this.state.inventories_by_place.map((r) => {
                  return <Option key={r._id.$oid}>{r.entity_name}</Option>;
                })}
              </Select>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Delivery On</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.delivery_date_time_view}
                  onChange={this.setTime}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Select Product Category</Text>
              <Select
                value={this.state.category_name}
                onChange={this.setProductCategory}
              >
                {this.state.product_categories.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Search Product </Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue
                onSearch={this.itemSearch}
                onChange={(value, e) => {
                  let item = JSON.parse(e.props.value);
                  if (this.state.data.some((od) => od.item_id === item.id)) {
                    return;
                  } else {
                    let inc = {
                      item: item.item,
                      parent_product_id: item.parent_product_id,
                      available_stock: item.available_stock,
                      stock_quantity: item.stock_quantity,
                      unit_selling_price: item.unit_selling_price,
                      price: item.price,
                      discounted_price: 0,
                      discount: 0,
                      qty: 1,
                      item_discount_type: "amount",
                      unit_item_discount: 0,
                      item_id: item.id,
                      unit: item.unit,
                      sku: item.sku,
                      unit_purchase_price: item.unit_purchase_price,
                      mrp: item.mrp,
                      inventory_id: item.inventory_id,
                    };
                    inc.price = inc.unit_selling_price * inc.qty;
                    inc.discounted_price = inc.unit_selling_price * inc.qty;
                    let temp = [];
                    temp.push(inc);
                    //real data
                    let filteredData = this.state.data.filter(
                      (i) => i.item !== item.item
                    );

                    this.setState({ data: [...temp, ...filteredData] }, () => {
                      this.setState(
                        {
                          data: this.state.data.filter(
                            (value, index, self) =>
                              self.findIndex(
                                (t) => t.item_id === value.item_id
                              ) === index
                          ),
                        },
                        () => {
                          console.log("selection data state", this.state.data);
                        }
                      );
                    });
                    //selected data
                    this.setState(
                      {
                        selected_items: [...this.state.selected_items, ...temp],
                      },
                      () => {
                        this.setState({
                          selected_items: this.state.selected_items.filter(
                            (value, index, self) =>
                              self.findIndex(
                                (t) => t.item_id === value.item_id
                              ) === index
                          ),
                        });
                        console.log(this.state.selected_items);
                      }
                    );
                  }
                }}
                placeholder="Search Item"
              >
                {this.state.items.map((r) => (
                  <Option key={r.id} value={JSON.stringify(r)}>
                    <div>
                      {r.product_img_thumb ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_PATH}${r.product_img_thumb}`}
                          alt="avatar"
                          style={{ width: 100, height: 45 }}
                        />
                      ) : null}
                      {`Name: ${r.item} > Unit: ${r.unit} > Promotions :${r.related_promotion_count} ${
                        this.state.stock_quantity_type === "available_stock"
                          ? `> Available Stock: ${r.available_stock}`
                          : this.state.stock_quantity_type === "stock_quantity"
                          ? `> Stock Quantity: ${r.stock_quantity}`
                          : ""
                      }`}
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </Form.Item>
          {this.state.selected_items.length > 0 ? (
            <Card>
              <Table
                dataSource={this.state.selected_items}
                pagination={false}
                rowKey={(record) => record.item_id}
                size="small"
              >
                <Column title="Product" dataIndex="item" key="product" />

                <Column title="Unit" dataIndex="unit" key="unit" />
                <Column
                  title="Unit Selling Price"
                  key="unit_selling_price"
                  dataIndex="unit_selling_price"
                />

                <Column
                  title="Decrease"
                  key="Decrease"
                  render={(record) => (
                    <span>
                      <Button
                        style={{
                          color: "red",
                        }}
                        type="link"
                        onClick={() => {
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          if (item.qty - 1 <= 0) {
                            swal.fire(
                              "Info",
                              "Quantity can not be zero",
                              "info"
                            );
                            return;
                          }
                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: item.qty - 1,
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          if (inc.item_discount_type === "amount") {
                            let discountedPrice =
                              inc.unit_selling_price - inc.unit_item_discount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          } else {
                            let percentDiscount =
                              (inc.unit_selling_price *
                                inc.unit_item_discount) /
                              100;
                            let discountedPrice =
                              inc.unit_selling_price - percentDiscount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }
                        }}
                      >
                        <Icon type="minus" />
                      </Button>
                    </span>
                  )}
                />

                <Column
                  title="Quantity"
                  key="Quantity"
                  render={(record) => (
                    <span>
                      <Input
                        type="number"
                        value={
                          this.state.data.length > 0
                            ? this.state.data.find(
                                (i) => i.item === record.item
                              )?.qty
                            : 0
                        }
                        onChange={(evt) => {
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          if (Number(evt.target.value <= 0)) {
                            return;
                          }

                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "available_stock" &&
                            Number(evt.target.value) > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type === "none" &&
                            Number(evt.target.value) > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "stock_quantity" &&
                            Number(evt.target.value) > item.stock_quantity
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than stock quantity",
                              "info"
                            );
                            return;
                          }

                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: Number(evt.target.value),
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          if (inc.item_discount_type === "amount") {
                            let discountedPrice =
                              inc.unit_selling_price - inc.unit_item_discount;
                            inc.discounted_price =
                              discountedPrice * Number(evt.target.value);
                            inc.price =
                              inc.unit_selling_price * Number(evt.target.value);
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          } else {
                            let percentDiscount =
                              (inc.unit_selling_price *
                                inc.unit_item_discount) /
                              100;
                            let discountedPrice =
                              inc.unit_selling_price - percentDiscount;
                            inc.discounted_price =
                              discountedPrice * Number(evt.target.value);
                            inc.price =
                              inc.unit_selling_price * Number(evt.target.value);
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }
                        }}
                      />
                    </span>
                  )}
                />
                <Column
                  title="Increase"
                  key="Increase"
                  render={(record) => (
                    <span>
                      <Button
                        style={{
                          color: "green",
                        }}
                        type="link"
                        onClick={() => {
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "available_stock" &&
                            item.qty + 1 > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type === "none" &&
                            item.qty + 1 > item.available_stock
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than available stock",
                              "info"
                            );
                            return;
                          }
                          if (
                            this.state.allow_zero_stock === false &&
                            this.state.stock_quantity_type ===
                              "stock_quantity" &&
                            item.qty + 1 > item.stock_quantity
                          ) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than stock quantity",
                              "info"
                            );
                            return;
                          }
                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: item.qty + 1,
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          if (inc.item_discount_type === "amount") {
                            let discountedPrice =
                              inc.unit_selling_price - inc.unit_item_discount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          } else {
                            let percentDiscount =
                              (inc.unit_selling_price *
                                inc.unit_item_discount) /
                              100;
                            let discountedPrice =
                              inc.unit_selling_price - percentDiscount;
                            inc.discounted_price = discountedPrice * inc.qty;
                            inc.price = inc.unit_selling_price * inc.qty;
                            inc.discount = inc.price - inc.discounted_price;
                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }
                        }}
                      >
                        <Icon type="plus" />
                      </Button>
                    </span>
                  )}
                />

                <Column
                  title="Price"
                  key="price"
                  render={(record) => (
                    <span>
                      <Text>
                        {this.state.data.find(
                          (i) => i.item_id === record.item_id
                        )?.price ?? 0}
                      </Text>
                    </span>
                  )}
                />

                <Column
                  title="Remove"
                  key="Remove"
                  render={(record) => (
                    <span>
                      <Button
                        style={{
                          color: "red",
                        }}
                        type="link"
                        onClick={() => {
                          this.setState({
                            selected_items: this.state.selected_items.filter(
                              (item) => item.item !== record.item
                            ),
                          });
                          let item = this.state.data.find(
                            (i) => i.item === record.item
                          );
                          let inc = {
                            item: item.item,
                            parent_product_id: item.parent_product_id,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: 0,
                            qty: 0,
                            item_discount_type: "amount",
                            unit_item_discount: 0,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id,
                          };
                          let arr = [];
                          arr.push(inc);

                          let filteredData = this.state.data.filter(
                            (i) => i.item !== item.item
                          );

                          this.setState(
                            { data: [...arr, ...filteredData] },
                            () => {
                              console.log("final state", this.state.data);
                            }
                          );
                        }}
                      >
                        <Icon type="close" />
                      </Button>
                    </span>
                  )}
                />
              </Table>
              {/* <Button onClick={()=>{
              let final = this.state.data.filter((i) => i.qty !== 0);
              final.map((i) => {
                let total = i.discounted_price;
                let total_before_discount = i.price;
                let total_discount = i.discount;
                this.setState(
                  (prevState) => ({
                    total_price: prevState.total_price + total,
                    total_price_before_discount:
                      prevState.total_price_before_discount +
                      total_before_discount,
                    total_discount: prevState.total_discount + total_discount,
                  }),
                  () => {
                    this.setState({
                      bill_amount:
                        Number(this.state.total_price) +
                        Number(this.state.delivery_charge) +
                        Number(this.state.other_charges),
                    });
                    console.log("total price", this.state.total_price);
                  }
                );

                return null;
              });
            }} type="primary" style={{marginTop:10}}>Calculate Total</Button>
            <Text style={{marginLeft:10,fontSize:15}}>Total Bill Amount : {this.state.bill_amount}</Text> */}
            </Card>
          ) : null}
          <Form.Item>
            <Button
              style={{ marginTop: 10 }}
              className="login-form-button"
              htmlType="submit"
              onClick={() => this.calculateTotal()}
            >
              Create Purchase Order
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
