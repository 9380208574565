import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Input,
  Table,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import { Spinner } from "../loader";
import {
  ASSET_PROVIDER,
  ASSET_SEARCH,
  CONNECT_FINDERASSET_ORGASSET,
} from "../../api/constants";
import { GET_USER_LOCAL, GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Column } = Table;
const { Option } = Select;

export default class ConnecttoFinder extends Component {
  state = {
    loading: false,
    user_id: "",
    api_key: "",
    connect_key: "",
    assets: [],
    access_token: "",
    expires_on: "",
    provider: "finder",
    org_asset_name: "",
    org_asset_id: "",
    assetData: [],
    finder_asset_name: "",
    finder_asset_id: "",
    finderAssets: [],
  };

  componentDidMount() {
    this.setState({ user_id: GET_USER_LOCAL()._id.$oid });
    this.getSavedAssets();
    this.getAssetList();
    this.getFinderAssets();
  }

  getFinderAssets = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_PROVIDER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Asset Provider get list", response);
          this.setState({
            finderAssets: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from Asset Provider get list", error);
        });
    });
  };

  getAssetList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from asset list", response);
          this.setState({
            assetData: response.data.data,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from asset list api", error);
        });
    });
  };

  handleAssetSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset search list", response);
        })
        .catch((error) => {
          console.log("error from asset search list api", error);
        });
    });
  }, 500);

  setFinderAsset = (value) => {
    this.setState(
      { finder_asset_id: value.key, finder_asset_name: value.label },
      () => {
        console.log(this.state.finder_asset_id);
        console.log(this.state.finder_asset_name);
      }
    );
  };
  setOrgAsset = (value) => {
    this.setState(
      { org_asset_id: value.key, org_asset_name: value.label },
      () => {
        console.log(this.state.org_asset_id);
        console.log(this.state.org_asset_name);
      }
    );
  };

  connectAssets = () => {
    if (this.state.org_asset_id === "" || this.state.finder_asset_id === "") {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        asset_id: this.state.org_asset_id,
        asset_provider_id: this.state.finder_asset_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CONNECT_FINDERASSET_ORGASSET}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log("response from connect to asset", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error?.response?.data?.message??error.message, "error");
            console.log("error from connect to asset", error);
          });
      });
    }
  };

  setConnectkey = (evt) => {
    evt.preventDefault();
    this.setState({ connect_key: evt.target.value }, () => {
      console.log(this.state.connect_key);
    });
  };

  getSavedAssets = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_PROVIDER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Asset Provider get list", response);
          this.setState({
            assets: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from Asset Provider get list", error);
        });
    });
  };

  connectAsset = () => {
    if (this.state.connect_key === "") {
      swal.fire("Warning", "Please Enter Connect key", "warning");
    } else {
      const data = {
        user_id: this.state.user_id,
        connect_key: this.state.connect_key,
        provider: this.state.provider,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${ASSET_PROVIDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            console.log("response from asset provider post", response);
            swal.fire("Info", response.data.message, "info");
            if (response.data.status === "success") {
              this.getSavedAssets();
            }
          })
          .catch((error) => {
            console.log("error from asset provider post", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ minWidth: 500 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Card style={{width:"50%"}}>
            <PageHeader title="Select Asset, Copy Connect Key & Paste Here" />
            <Button
              style={{ maxWidth: 250,marginTop:10 }}
              className="login-form-button"
              htmlType="submit"
              onClick={() =>
                window.open("https://web.finder-lbs.com/connect-asset")
              }
            >
              Login to Finder
            </Button>

            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column",
              }}
            >
              <Form.Item>
                <Text>Paste Connect key</Text>
                <Input
                  placeholder="Paste Connect key"
                  type="text"
                  value={this.state.connect_key}
                  onChange={this.setConnectkey}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ maxWidth: 250 }}
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.connectAsset}
                >
                  Connect Asset
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Card style={{ marginLeft: 5,width:"50%" }}>
            <PageHeader title="Connect Organization Asset to Finder Asset" />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column",
              }}
            >
              <Form.Item>
                <Text>Select Organization Asset</Text>
                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleAssetSearch}
                  value={{
                    key: this.state.org_asset_id,
                    Label: this.state.org_asset_name,
                  }}
                  onChange={this.setOrgAsset}
                  placeholder="Select Asset"
                >
                  {this.state.assetData.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Select Finder Asset</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.finder_asset_id,
                    Label: this.state.finder_asset_name,
                  }}
                  onChange={this.setFinderAsset}
                  placeholder="Select Asset"
                >
                  {this.state.finderAssets.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ maxWidth: 250 }}
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.connectAssets}
                >
                  Connect Assets
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>

        {this.state.assets.length > 0 ? (
          <Card style={{ marginTop: 5 }}>
            <Table
              dataSource={this.state.assets}
              pagination={false}
              rowKey={(record) => record._id.$oid}
              size="small"
              bordered
            >
              <Column title="Asset ID" dataIndex="asset_id" key="asset_id" />
              <Column title="Asset Name" dataIndex="name" key="name" />
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <Link
                      to={{
                        pathname: "/fuelData",
                        state: {
                          asset_id: record.asset_id,
                          asset_provider_id: record._id.$oid,
                          access_token: record.access_token,
                          asset_name: record.name,
                        },
                      }}
                    >
                      <Button type="link" style={{ color: "green" }}>
                        Fuel Data
                      </Button>
                    </Link>
                  </span>
                )}
              />
            </Table>
          </Card>
        ) : null}
      </Card>
    );
  }
}
