import React, { Component } from "react";
import { Card, PageHeader, Typography, Table } from "antd";
import "../App.css";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Spinner } from "../loader";

import { FINDER_ASSET_DISTANCE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

const { Text } = Typography;
const { Column } = Table;

export default class DistanceTravelled extends Component {
  state = {
    asset_id: "",
    asset_provider_id: "",
    asset_name: "",
    registration_number: "",
    total_distance_km: 0,
    // page: 1,
    // limit: 10,
    // total: 0,
    start_date: moment().subtract(7, "days").format("x"),
    start_date_view: moment().subtract(7, "days"),
    end_date: Date.now(),
    end_date_view: moment(),
    distanceData: [],
    loading: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState(
      {
        asset_id: this.props.location.state.asset_id,
        asset_provider_id: this.props?.location?.state?.asset_provider_id,
        asset_number: this.props?.location?.state?.asset_number,
        registration_number: this.props?.location?.state?.registration_number,
      },
      () => this.getAssetDistance()
    );
  }

  getAssetDistance = () => {
    this.setState({ loading: true });
    const data = {
      asset_id: this.state.asset_id,
      asset_provider_id: this.state.asset_provider_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    //   page: this.state.page,
    //   limit: this.state.limit,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${FINDER_ASSET_DISTANCE}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from finder distance", response);
          this.setState({
            distanceData: response.data.daily_distances ?? [],
            // total: response.data.total ?? 0,
            total_distance_km: response.data.total_distance_km ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from finder distance", error);
        });
    });
  };

//   pageChange = (current, pageSize) => {
//     this.setState({ page: current }, () => {
//       console.log("page number", this.state.page);
//       this.getAssetDistance();
//     });
//   };

//   setLimit = (value) => {
//     this.setState({ limit: value }, () => {
//       console.log(this.state.limit);
//       this.getAssetDistance();
//     });
//   };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title={`Finder Asset Distance`}
          onBack={() => this.props.history.goBack()}
        />
        <div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3,marginLeft:5 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  minDate={moment().subtract(30, "days")}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.start_date_view}
                  onChange={(value, dateString) => {
                    let start_date = moment(value);
                    let end_date = moment(this.state.end_date_view);
                    console.log(end_date.diff(start_date, "days"));
                    if (end_date.diff(start_date, "days") > 7) {
                      swal.fire(
                        "Info",
                        "You can only select maximum 7 days data",
                        "info"
                      );
                    } else {
                      this.setState(
                        {
                          start_date: moment(value).format("x"),
                          start_date_view: value,
                          page: 1,
                        },
                        () => {
                          this.getAssetDistance();
                        }
                      );
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  minDate={moment().subtract(30, "days")}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.end_date_view}
                  onChange={(value, dateString) => {
                    let end_date = moment(value);
                    let start_date = moment(this.state.start_date_view);
                    console.log(end_date.diff(start_date, "days"));
                    if (end_date.diff(start_date, "days") > 7) {
                      swal.fire(
                        "Info",
                        "You can only select maximum 7 days data",
                        "info"
                      );
                    } else {
                      this.setState(
                        {
                          end_date: moment(value).format("x"),
                          end_date_view: value,
                          page: 1,
                        },
                        () => {
                          this.getAssetDistance();
                        }
                      );
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column",margin:10 }}>
          <p>
            <Text style={{ fontWeight: "bold" }}>Name</Text>:{" "}
            {this.state.asset_name}
          </p>
          <p>
            <Text style={{ fontWeight: "bold" }}>Registration Number</Text>:{" "}
            {this.state.registration_number}
          </p>
          <p>
            <Text style={{ fontWeight: "bold" }}>Total Distance Travelled</Text>:{" "}
            {`${this.state.total_distance_km} Km`}
          </p>
        </div>
        <Table
          dataSource={this.state.distanceData}
          pagination={false}
          rowKey={(record) => record.date}
          size="small"
          bordered
        >
          <Column
            title="Date"
            key="date"
            render={(record) => <span>{record.date}</span>}
          />
          <Column
            title="Distance Travelled (Km)"
            key="distance_km"
            render={(record) => <span>{record.distance_km}</span>}
          />
        </Table>
        {/* <div style={{ margin: 10 }}>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ margin: 10 }}
            />
          ) : null}
        </div> */}
      </Card>
    );
  }
}
