import React, { Component } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Table,
  Divider,
  Input,
  Pagination,
  Icon,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { saveTaskListParams } from "../../redux/actions/taskListParamsActions";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  EDIT_TASK,
  ADVANCED_TASK_SEARCH,
  CREATE_DRIVER,
  USERWISE_TASK_REPORT,
  NAAFCO_TASK_REPORT_WITH_CUSTOM_FIELDS,
  CREATE_TASK_TYPE,
  NAAFCO_TASK_CRM_REPORT_WITH_CUSTOM_FIELDS_NEW,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
  TASK_SUMMARY_REPORT,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  LOCAL_USER_INFO,
  GET_USER_LOCAL,
  CHECK_BOOL,
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

// import { Spinner } from "../loader";

import "../login.css";

const { Option } = Select;
const { Text, Title } = Typography;
const { Column } = Table;

class AdvancedTaskList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    currentTime: "",
    user_ids: [],
    saveFile: false,
    category: "task_report_without_custom_fields",
    timezone_name: "",
    timezone_choice: "org_timezone",
    name: "",
    details: "",
    task_code: "",
    task_link: "",
    contact_no: "",
    contact_name: "",
    drop_contact_no: "",
    drop_contact_name: "",
    start_loc_name: "",
    end_loc_name: "",
    started_loc_name: "",
    completed_loc_name: "",
    task_status: "",
    assigned_asset_name: "",
    assigned_to_name: "",
    start_time: moment().startOf("month").format("x"),
    start_time_view: moment().startOf("month"),
    end_time: Date.now(),
    end_time_view: moment(),
    accepted_on: "",
    accepted_on_view: moment(),
    started_on: "",
    started_on_view: moment(),
    completed_on: "",
    completed_on_view: moment(),
    datarange: "",
    sort_by: "started_on",
    sort_type: "dsc",
    search_date_time_type: "created_on",
    selected_user_id: "",
    selected_user_name: "",
    from: moment().startOf("day").format("x"),
    from_view: moment().startOf("day"),
    to: Date.now(),
    to_view: moment(),
    naafco_from: Date.now() / 1000,
    naafco_from_view: moment(),
    naafco_to: Date.now() / 1000,
    naafco_to_view: moment(),
    selected_user_id_naafco: "",
    is_order: false,
    filtered: [],
    task_type: "",
    taskTypes: [],
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_task_search: "",
    naafco_crm_from: Date.now() / 1000,
    naafco_crm_from_view: moment(),
    naafco_crm_to: Date.now() / 1000,
    naafco_crm_to_view: moment(),
    sub_org_name: "",
    sub_org_type_name: "",
    task_type_crm: "",
    showLoadingButton: false,
    task_summary_downloading: false,
    sub_org_parent_name: "",
  };
  componentDidMount() {
    this.props.getUserData();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
    this.setState({
      selected_user_id: GET_USER_LOCAL()._id.$oid,
      selected_user_name: GET_USER_LOCAL().name,
    });
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    this.getUserList();
    console.log("redux", this.props.taskListParams);
    if (Object.keys(this.props.taskListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          name: this.props?.taskListParams?.name ?? "",
          sub_org_type_name:
            this.props?.taskListParams?.sub_org_type_name ?? "",
          sub_org_name: this.props?.taskListParams?.sub_org_name ?? "",
          sub_org_parent_name:
            this.props?.taskListParams?.sub_org_parent_name ?? "",
          details: this.props?.taskListParams?.details ?? "",
          task_type: this.props?.taskListParams?.task_type ?? "",
          task_code: this.props?.taskListParams?.task_code ?? "",
          task_link: this.props?.taskListParams?.task_link ?? "",
          sub_org_id_task_search:
            this.props?.taskListParams?.sub_org_id_task_search ?? "",
          contact_no: this.props?.taskListParams?.contact_no ?? "",
          contact_name: this.props?.taskListParams?.contact_name ?? "",
          drop_contact_no: this.props?.taskListParams?.drop_contact_no ?? "",
          drop_contact_name:
            this.props?.taskListParams?.drop_contact_name ?? "",
          start_loc_name: this.props?.taskListParams?.start_loc_name ?? "",
          end_loc_name: this.props?.taskListParams?.end_loc_name ?? "",
          started_loc_name: this.props?.taskListParams?.started_loc_name ?? "",
          completed_loc_name:
            this.props?.taskListParams?.completed_loc_name ?? "",
          task_status: this.props?.taskListParams?.task_status ?? "",
          assigned_asset_name:
            this.props?.taskListParams?.assigned_asset_name ?? "",
          assigned_to_name: this.props?.taskListParams?.assigned_to_name ?? "",
          start_time:
            this.props?.taskListParams?.start_time ??
            moment().startOf("month").format("x"),
          start_time_view:
            this.props?.taskListParams?.start_time_view ??
            moment().startOf("month"),
          end_time: this.props?.taskListParams?.end_time ?? Date.now(),
          end_time_view: this.props?.taskListParams?.end_time_view ?? moment(),
          accepted_on: this.props?.taskListParams?.accepted_on ?? "",
          accepted_on_view:
            this.props?.taskListParams?.accepted_on_view ?? moment(),
          started_on: this.props?.taskListParams?.started_on ?? "",
          started_on_view:
            this.props?.taskListParams?.started_on_view ?? moment(),
          completed_on: this.props?.taskListParams?.completed_on ?? "",
          completed_on_view:
            this.props?.taskListParams?.completed_on_view ?? moment(),
          limit: this.props?.taskListParams?.limit ?? 10,
          page: this.props?.taskListParams?.page ?? 0,
          sort_by: this.props?.taskListParams?.sort_by ?? "created_by",
          sort_type: this.props?.taskListParams?.sort_type ?? "dsc",
          search_date_time_type:
            this.props?.taskListParams?.search_date_time_type ?? "accepted_on",
        },
        () => {
          // this.getTaskList();
          this.getCurrentUserSubOrgs();
        }
      );
    } else {
      this.getCurrentUserSubOrgs();
    }
    this.getTaskType();
  }

  getTaskType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TASK_TYPE}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from task type list", response);

          this.setState({
            taskTypes: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from task type list api", error);
        });
    });
  };

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_task_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getTaskList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  handleSuborgSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState({
            current_user_sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  }, 500);

  setAssignedUser = (value) => {
    this.setState({ selected_user_id: value.key, filtered: [] }, () => {
      console.log(this.state.selected_user_id);
    });
  };

  setAssignedUserNaafco = (value) => {
    this.setState({ selected_user_id_naafco: value, filtered: [] }, () => {
      console.log(this.state.selected_user_id_naafco);
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getTaskList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getTaskList();
      }
    );
  };

  setTaskType = (value) => {
    this.setState({ task_type: value }, () => {
      this.getTaskList();
      console.log(this.state.task_type);
    });
  };
  setTaskTypeCrm = (value) => {
    this.setState({ task_type_crm: value }, () => {
      console.log(this.state.task_type_crm);
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            user_ids: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setTo = (value, dateString) => {
    this.setState(
      {
        to: moment(value).format("x"),
        to_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.to);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState({ from: moment(value).format("x"), from_view: value }, () => {
      console.log("Selected Time: ", this.state.from);
    });
  };
  setToNaafco = (value, dateString) => {
    this.setState(
      {
        naafco_to: moment(value).format("x") / 1000,
        naafco_to_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.naafco_to);
      }
    );
  };
  setFromNaafco = (value, dateString) => {
    this.setState(
      {
        naafco_from: moment(value).format("x") / 1000,
        naafco_from_view: value,
        naafco_to:
          moment(value).add(23, "hours").add(59, "minutes").format("x") / 1000,
      },
      () => {
        console.log("Selected Time: ", this.state.naafco_from);
      }
    );
  };
  setFromNaafcoCrm = (value, dateString) => {
    this.setState(
      {
        naafco_crm_from: moment(value).startOf("day").format("x") / 1000,
        naafco_crm_from_view: value,
        naafco_crm_to:
          moment(value)
            .add(23, "hours")
            .add(59, "minutes")
            .add(59, "seconds")
            .format("x") / 1000,
      },
      () => {
        console.log("Selected Time start: ", this.state.naafco_crm_from);
        console.log("Selected Time end: ", this.state.naafco_crm_to);
      }
    );
  };

  setIsOrder = (value) => {
    this.setState({ is_order: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_order);
    });
  };

  setTimezonetype = (value) => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  downloadUserwiseReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USERWISE_TASK_REPORT}?start_time=${Number(
        this.state.from
      )}&end_time=${Number(this.state.to)}&selected_user_id=${
        this.state.selected_user_id
      }&is_order=${this.state.is_order}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from userwise task report", blob);

          FileSaver.saveAs(
            blob,
            `userwise_task_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from userwise task report", error);
        });
    });
  };
  downloadNaafcoReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${NAAFCO_TASK_REPORT_WITH_CUSTOM_FIELDS}?from=${Number(
        this.state.naafco_from
      )}&to=${Number(this.state.naafco_to)}&search_user=${
        this.state.selected_user_id_naafco
      }&task_type=${this.state.task_type}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from naafco task report", blob);

          FileSaver.saveAs(
            blob,
            `naafco_task_report_with_custom_fields_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from naafco task report", error);
        });
    });
  };
  downloadNaafcoCRMReport = () => {
    this.setState({ showLoadingButton: true });
    let data = {
      from: this.state.naafco_crm_from,
      to: this.state.naafco_crm_to,
      sub_org: this.state.sub_org_id_task_search,
    };
    GET_ACCESS_TOKEN().then((token) => {
      axios({
        method: "post",
        url: `${NAAFCO_TASK_CRM_REPORT_WITH_CUSTOM_FIELDS_NEW}?access_token=${token}`,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ showLoadingButton: false });
          if (response.data.message === "Data successfully inserted!") {
            const url = `${NAAFCO_TASK_CRM_REPORT_WITH_CUSTOM_FIELDS_NEW}?filename=${response.data.data.filename}&access_token=${token}`;
            fetch(url)
              .then((res) => res.blob())
              .then((blob) => {
                console.log("response from naafco crm task report", blob);

                FileSaver.saveAs(
                  blob,
                  `naafco_task_crm_report_with_custom_fields_${moment().format(
                    "DD-MMMM-YYYY_hh:mm A"
                  )}.xlsx`
                );
              })
              .catch((error) => {
                this.setState({ showLoadingButton: false });
                swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                console.log("error from naafco crm task report", error);
              });
          }
          console.log("response from crm report generation", response);
        })
        .catch((error) => {
          this.setState({ showLoadingButton: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from crm report generation", error);
        });
    });
  };

  downloadNaafcoTaskSummaryReport = () => {
    this.setState({ task_summary_downloading: true });
    let date = new Date();
    let time = date.getTime();
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${TASK_SUMMARY_REPORT}?task_type=${this.state.task_type}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_task_search}&ts=${time}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&accepted_on=${this.state.accepted_on}&started_on=${this.state.started_on}&completed_on=${this.state.completed_on}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&search_date_time_type=${this.state.search_date_time_type}&assigned_to_name=${this.state.assigned_to_name}&assigned_asset_name=${this.state.assigned_asset_name}&task_status=${this.state.task_status}&completed_loc_name=${this.state.completed_loc_name}&started_loc_name=${this.state.started_loc_name}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_no=${this.state.drop_contact_no}&drop_contact_name=${this.state.drop_contact_name}&contact_name=${this.state.contact_name}&contact_no=${this.state.contact_no}&task_link=${this.state.task_link}&task_code=${this.state.task_code}&details=${this.state.details}&name=${this.state.name}&fmt=xls&category=task_summary_report&organization=62b2aae9e71f1f18fa519220&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from naafco task summary report", blob);
          this.setState({ task_summary_downloading: false });
          FileSaver.saveAs(
            blob,
            `task_summary_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          this.setState({ task_summary_downloading: false });
          console.log("error from naafco task summary report", error);
        });
    });
  };

  handleSaveFile = (checked) => {
    console.log(this.state.saveFile);
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url =
        this.state.timezone_choice === "org_timezone"
          ? `${ADVANCED_TASK_SEARCH}?sub_org_parent_name=${this.state.sub_org_parent_name}&sub_org_type_name=${this.state.sub_org_type_name}&task_type=${this.state.task_type}&sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_task_search}&search_date_time_type=${this.state.search_date_time_type}&ts=${time}&timezone_choice=${this.state.timezone_choice}&category=${this.state.category}&fmt=xls&page=${this.state.page}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&assigned_to_name=${this.state.assigned_to_name}&assigned_asset_name=${this.state.assigned_asset_name}&task_status=${this.state.task_status}&completed_loc_name=${this.state.completed_loc_name}&started_loc_name=${this.state.started_loc_name}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_no=${this.state.drop_contact_no}&drop_contact_name=${this.state.drop_contact_name}&contact_name=${this.state.contact_name}&contact_no=${this.state.contact_no}&task_link=${this.state.task_link}&task_code=${this.state.task_code}&details=${this.state.details}&name=${this.state.name}&limit=${this.state.limit}&access_token=${token}`
          : `${ADVANCED_TASK_SEARCH}?sub_org_parent_name=${this.state.sub_org_parent_name}&sub_org_type_name=${this.state.sub_org_type_name}&task_type=${this.state.task_type}&sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_task_search}&search_date_time_type=${this.state.search_date_time_type}&ts=${time}&timezone_choice=${this.state.timezone_choice}&category=${this.state.category}&timezone_name=${this.state.timezone_name}&fmt=xls&page=${this.state.page}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&assigned_to_name=${this.state.assigned_to_name}&assigned_asset_name=${this.state.assigned_asset_name}&task_status=${this.state.task_status}&completed_loc_name=${this.state.completed_loc_name}&started_loc_name=${this.state.started_loc_name}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_no=${this.state.drop_contact_no}&drop_contact_name=${this.state.drop_contact_name}&contact_name=${this.state.contact_name}&contact_no=${this.state.contact_no}&task_link=${this.state.task_link}&task_code=${this.state.task_code}&limit=${this.state.limit}&details=${this.state.details}&name=${this.state.name}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from Task list fmt", blob);

          FileSaver.saveAs(
            blob,
            `task_report_${moment().format("DD-MMMM-YYYY_hh:mm A")}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from Task list api", error);
        });
    });
  };
  handleDailyReport = (checked) => {
    console.log(this.state.saveFile);
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url =
        this.state.timezone_choice === "org_timezone"
          ? `${ADVANCED_TASK_SEARCH}?sub_org_parent_name=${
              this.state.sub_org_parent_name
            }&sub_org=${
              this.state.sub_org_id_task_search
            }&ts=${time}&timezone_choice=${
              this.state.timezone_choice
            }&fmt=xls&search_end_time=true&end_time=${moment()
              .endOf("day")
              .format("x")}&start_time=${moment()
              .startOf("day")
              .format("x")}&search_start_time=true&category=${
              this.state.category
            }&page=${
              this.state.page
            }&search_date_time_type=completed_on&access_token=${token}`
          : `${ADVANCED_TASK_SEARCH}?sub_org_parent_name=${
              this.state.sub_org_parent_name
            }&sub_org=${
              this.state.sub_org_id_task_search
            }&ts=${time}&timezone_choice=${
              this.state.timezone_choice
            }&timezone_name=${
              this.state.timezone_name
            }&fmt=xls&search_end_time=true&end_time=${moment()
              .endOf("day")
              .format("x")}&start_time=${moment()
              .startOf("day")
              .format("x")}&search_start_time=true&&category=${
              this.state.category
            }&page=${
              this.state.page
            }&search_date_time_type=completed_on&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from daily Task list fmt", blob);

          FileSaver.saveAs(
            blob,
            `daily_task_report_${moment().format("DD-MMMM-YYYY_hh:mm A")}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from Task list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            user_ids: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  getTaskList = debounce(() => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));

    let reduxData = {
      name: this.state.name,
      sub_org_name: this.state.sub_org_name,
      sub_org_type_name: this.state.sub_org_type_name,
      sub_org_parent_name: this.state.sub_org_parent_name,
      details: this.state.details,
      task_type: this.state.task_type,
      task_code: this.state.task_code,
      task_link: this.state.task_link,
      sub_org_id_task_search: this.state.sub_org_id_task_search,
      contact_no: this.state.contact_no,
      contact_name: this.state.contact_name,
      drop_contact_no: this.state.drop_contact_no,
      drop_contact_name: this.state.drop_contact_name,
      start_loc_name: this.state.start_loc_name,
      end_loc_name: this.state.end_loc_name,
      started_loc_name: this.state.started_loc_name,
      completed_loc_name: this.state.completed_loc_name,
      task_status: this.state.task_status,
      assigned_asset_name: this.state.assigned_asset_name,
      assigned_to_name: this.state.assigned_to_name,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
      accepted_on: this.state.accepted_on,
      accepted_on_view: this.state.accepted_on_view,
      started_on: this.state.started_on,
      started_on_view: this.state.started_on_view,
      completed_on: this.state.completed_on,
      completed_on_view: this.state.completed_on_view,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      search_date_time_type: this.state.search_date_time_type,
      limit: this.state.limit,
      page: this.state.page,
    };
    this.props.saveTaskListParams(reduxData);
    console.log("reduxdata going in", reduxData);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ADVANCED_TASK_SEARCH}?sub_org_parent_name=${this.state.sub_org_parent_name}&task_type=${this.state.task_type}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_task_search}&ts=${time}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&accepted_on=${this.state.accepted_on}&started_on=${this.state.started_on}&completed_on=${this.state.completed_on}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&search_date_time_type=${this.state.search_date_time_type}&assigned_to_name=${this.state.assigned_to_name}&assigned_asset_name=${this.state.assigned_asset_name}&task_status=${this.state.task_status}&completed_loc_name=${this.state.completed_loc_name}&started_loc_name=${this.state.started_loc_name}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_no=${this.state.drop_contact_no}&drop_contact_name=${this.state.drop_contact_name}&contact_name=${this.state.contact_name}&contact_no=${this.state.contact_no}&task_link=${this.state.task_link}&task_code=${this.state.task_code}&details=${this.state.details}&name=${this.state.name}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Task list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from Task list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        name: "",
        sub_org_name: "",
        sub_org_type_name: "",
        sub_org_parent_name: "",
        details: "",
        task_type: "",
        task_code: "",
        task_link: "",
        // sub_org_id_task_search: "",
        contact_no: "",
        contact_name: "",
        drop_contact_no: "",
        drop_contact_name: "",
        start_loc_name: "",
        end_loc_name: "",
        started_loc_name: "",
        completed_loc_name: "",
        task_status: "",
        assigned_asset_name: "",
        assigned_to_name: "",
        sort_by: "started_on",
        sort_type: "dsc",
        search_date_time_type: "created_on",
        start_time: moment().startOf("month").format("x"),
        start_time_view: moment().startOf("month"),
        end_time: Date.now(),
        end_time_view: moment(),
        accepted_on: "",
        accepted_on_view: moment(),
        started_on: "",
        started_on_view: moment(),
        completed_on: "",
        completed_on_view: moment(),
        page: 0,
        limit: 10,
        current_user_sub_org_id: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.current_user_sub_org_id,
        sub_org_id_task_search: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.sub_org_id_task_search,
      },
      () => {
        this.getTaskList();
      }
    );
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getTaskList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getTaskList();
    });
  };

  deleteTask = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_TASK}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  setReporttype = (value) => {
    this.setState({ category: value }, () => {
      console.log(this.state.category);
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getTaskList();
    });
  };

  render() {
    // const UserList = this.state.user_ids.map(r => {
    //   return (
    //     <Option
    //       key={r._id.$oid}
    //     >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
    //   );
    // });

    // const FliteredList = this.state.filtered.map(r => {
    //   return (
    //     <Option
    //       key={r._id.$oid}
    //     >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
    //   );
    // });
    return (
      <Card style={{ margin: 10, minWidth: 1000 }}>
        {this.props.userProfileData.task.task.task_create ? (
          <Link to="/createTask">
            <Tooltip placement="top" title="Create Task">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}
        {this.props.userProfileData.report.task_report
          .task_report_based_on_filters ||
        this.props.userProfileData.report.task_report.daily_task_report ? (
          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{ marginTop: 10, display: "flex", flexDirection: "column" }}
              >
                <Text> Timezone : {""}</Text>
                <Select
                  value={this.state.timezone_choice}
                  onChange={this.setTimezonetype}
                  placeholder="View as"
                >
                  <Option key="user_timezone">User</Option>
                  <Option key="org_timezone">Organization</Option>
                </Select>
              </div>
              <div
                style={{ marginTop: 10,marginLeft:4, display: "flex", flexDirection: "column" }}
              >
                <Text>Report Type : {""}</Text>
                <Select
                  style={{ minWidth: 175 }}
                  value={this.state.category}
                  onChange={this.setReporttype}
                  placeholder="View as"
                >
                  <Option key="task_report_without_custom_fields">
                    Without Custom Fields
                  </Option>
                  <Option key="task_report_with_custom_fields">
                    With Custom Fields
                  </Option>
                  <Option key="task_detail_report_without_custom_fields">
                    Detailed Without Custom Fields
                  </Option>
                  <Option key="task_detail_report_with_custom_fields">
                    Detailed With Custom Fields
                  </Option>
                </Select>
              </div>
              {this.props.userProfileData.report.task_report
                .task_report_based_on_filters ? (
                <div style={{ marginTop: 31,marginLeft:3, textAlign: "center" }}>
                  <Button
                    style={{ backgroundColor: "#1A3337", color: "white" }}
                    onClick={this.handleSaveFile}
                  >
                    Download Report
                  </Button>
                </div>
              ) : null}
              {this.props.userProfileData.report.task_report
                .daily_task_report ? (
                <div style={{ marginTop: 31,marginLeft:2, textAlign: "center" }}>
                  <Button
                    style={{ backgroundColor: "#1A3337", color: "white" }}
                    onClick={this.handleDailyReport}
                  >
                    Download Daily Report
                  </Button>
                </div>
              ) : null}
            </div>
          </Card>
        ) : null}
        {this.props.userProfileData.report.task_report
          .task_report_based_on_users ? (
          <Card style={{ marginTop: 5 }}>
            <Title level={4}>User Wise Report Options </Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{ marginTop: 19 }}
                    inputProps={{
                      style: {
                        padding: 10,
                        height: 14,
                        // width: 130,
                      },
                    }}
                    inputVariant="outlined"
                    value={this.state.from_view}
                    onChange={this.setFrom}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{ marginTop: 19 }}
                    inputProps={{
                      style: {
                        padding: 10,
                        height: 14,
                        // width: 130,
                      },
                    }}
                    inputVariant="outlined"
                    value={this.state.to_view}
                    onChange={this.setTo}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 5,
                  width: 250,
                }}
              >
                <Text>Search User</Text>
                <Select
                  disabled={GET_USER_LOCAL().org_admin ? false : true}
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: 250 }}
                  value={{
                    key: this.state.selected_user_id,
                    label: this.state.selected_user_name,
                  }}
                  onChange={this.setAssignedUser}
                  placeholder="Select User"
                >
                  {this.state.user_ids.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 5,
                }}
              >
                <Text>Order in Task</Text>
                <Select
                  value={this.state.is_order.toString()}
                  onChange={this.setIsOrder}
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </div>
              <Button
                onClick={this.downloadUserwiseReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 25,
                  // marginLeft: 2,
                }}
              >
                Download Userwise Report
              </Button>
            </div>
          </Card>
        ) : null}

        {GET_USER_LOCAL().organization.$oid === "62b2aae9e71f1f18fa519220" &&
        GET_USER_LOCAL().org_admin ? (
          <Card style={{ marginTop: 5 }}>
            <Title level={4}>
              NAAFCO Task Report With Custom Fields (Available Upto 24 Hours
              from Starting Date)
            </Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{ marginTop: 19 }}
                    inputProps={{
                      style: {
                        padding: 10,
                        height: 14,
                        // width: 130,
                      },
                    }}
                    inputVariant="outlined"
                    value={this.state.naafco_from_view}
                    onChange={this.setFromNaafco}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {/* <Text style={{ paddingTop: 20 }}>---</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.naafco_to_view}
                    onChange={this.setToNaafco}
                  />
                </MuiPickersUtilsProvider>
              </div> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 5,
                  width: 250,
                }}
              >
                <Text>Search User</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: 250 }}
                  value={this.state.selected_user_id_naafco}
                  onChange={this.setAssignedUserNaafco}
                  placeholder="Select User"
                >
                  {this.state.user_ids.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>

              <Button
                onClick={this.downloadNaafcoReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 25,
                  marginLeft: 10,
                }}
              >
                Download Report
              </Button>
              {/* <Button
                onClick={this.downloadNaafcoCRMReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 10,
                  marginLeft: 10
                }}
              >
                Download CRM Report
              </Button> */}
            </div>
          </Card>
        ) : null}
        {/* {GET_USER_LOCAL().organization.$oid === "62b2aae9e71f1f18fa519220" &&
        GET_USER_LOCAL().org_admin ? (
          <Card style={{ marginTop: 5 }}>
            <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
              NAAFCO CRM Report (Available Upto 24 Hours from Starting Date)
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.naafco_crm_from_view}
                    onChange={this.setFromNaafcoCrm}
                  />
                </MuiPickersUtilsProvider>
              </div>
        

              <Button
                loading={this.state.showLoadingButton}
                onClick={this.downloadNaafcoCRMReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 10,
                  marginLeft: 10
                }}
              >
                {this.state.showLoadingButton
                  ? "Please Wait"
                  : "Download CRM Report"}
              </Button>
            </div>
          </Card>
        ) : null} */}

        <Card style={{ marginTop: 5 }}>
          <Title level={4}>
            Select date range within which you would like to find your tasks
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  style={{ marginTop: 19 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      // width: 130,
                    },
                  }}
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  style={{ marginTop: 19 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      // width: 130,
                    },
                  }}
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{ marginTop: 5, display: "flex", flexDirection: "column" }}
            >
              <Text> Search By : {""}</Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getTaskList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                placeholder="Search By"
              >
                <Option key="created_on">Created on</Option>
                <Option key="accepted_on">Accepted On</Option>
                <Option key="started_on">Started On</Option>
                <Option key="completed_on">Completed On</Option>
                <Option key="start_time">Start Time</Option>
                <Option key="end_time">End Time</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
                minWidth: 200,
              }}
            >
              <Text>Task Type</Text>
              <Select
                style={{ minWidth: 200 }}
                value={this.state.task_type}
                onChange={this.setTaskType}
                placeholder="Task Type"
              >
                {this.state.taskTypes.map((r) => {
                  return <Option key={r.name}>{r.name}</Option>;
                })}
              </Select>
            </div>
            {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <div style={{ marginTop: 5 }}>
            

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{  display: "flex", flexDirection: "column" }}
              >
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSuborgSearch}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{ marginLeft: 5, display: "flex", flexDirection: "column" }}
              >
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </div>
        ) : null}
          </div>
        </Card>
        

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          {this.props.userProfileData.data_level.access_suborg_data ||
          this.props.userProfileData.data_level.access_all_data ? (
            <div style={{ margin: 10, textAlign: "center" }}>
              <Button
                loading={this.state.task_summary_downloading}
                onClick={this.downloadNaafcoTaskSummaryReport}
                style={{
                  minWidth: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginLeft: 10,
                }}
              >
                Download Task Summary
              </Button>
            </div>
          ) : null}
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Code</Text>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_code", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_code", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_code}
                  onChange={(evt) => {
                    this.setState(
                      { task_code: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Code"
            render={(text, record) => (
              <span>{`${record?.task_code ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Link</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_link", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_link", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_link}
                  onChange={(evt) => {
                    this.setState(
                      { task_link: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="task_link"
            render={(text, record) => (
              <span>{`${record?.task_link ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getTaskList();
                    });
                  }}
                />
              </>
            }
            key="name"
            render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Task Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="task_type"
            render={(text, record) => (
              <span>{`${record?.task_type ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Details</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "details", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "details", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.details}
                  onChange={(evt) => {
                    this.setState(
                      { details: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="details"
            key="description"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Status</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_status", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_status", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_status}
                  onChange={(evt) => {
                    this.setState(
                      { task_status: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="task_status"
            key="task_status"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Assigned To</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "assigned_to_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "assigned_to_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.assigned_to_name}
                  onChange={(evt) => {
                    this.setState(
                      { assigned_to_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="assigned_to"
            render={(text, record) => (
              <span>{record?.assigned_to?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Assigned Asset</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "assigned_asset_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "assigned_asset_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.assigned_asset_name}
                  onChange={(evt) => {
                    this.setState(
                      { assigned_asset_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="assigned_asset"
            render={(text, record) => (
              <span>{record?.assigned_asset?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Vehicle Load</Text>
                </div>

                <Input disabled />
              </>
            }
            key="vehicle_load"
            render={(text, record) => <span>{record?.vehicle_load ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Organization Name</Text>
                  <Input
                    value={this.state.sub_org_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Sub Organization"
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Organization Type</Text>
                  <Input
                    value={this.state.sub_org_type_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_type_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Sub Organization Type"
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Parent Sub Organization</Text>
                  <Input
                    value={this.state.sub_org_parent_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_parent_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Parent Sub Organization"
            render={(text, record) => (
              <span>{record.sub_org?.parent_sub_org_data?.name ?? ""}</span>
            )}
          />
          {GET_USER_LOCAL().organization.$oid === "62b2aae9e71f1f18fa519220" ? (
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Total Farmers </Text>
                    {/* <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "total_number_of_farmers_from_custom_fields", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "total_number_of_farmers_from_custom_fields", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div> */}
                  </div>

                  {/* <Input
                  value={this.state.total_number_of_farmers_from_custom_fields}
                  onChange={evt => {
                    this.setState(
                      { total_number_of_farmers_from_custom_fields: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getTaskList(), 2000);
                      }
                    );
                  }}
                /> */}
                </>
              }
              key="total_number_of_farmers_from_custom_fields"
              render={(text, record) => (
                <span>
                  {record?.total_number_of_farmers_from_custom_fields ?? ""}
                </span>
              )}
            />
          ) : null}

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Accepted On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "accepted_on", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "accepted_on", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input disabled />

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.accepted_on_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          accepted_on: moment(value).format("x"),
                          accepted_on_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="accepted_on"
            render={(text, record) => (
              <span>
                {record.accepted_on.$date
                  ? timeConverter(record?.accepted_on?.$date)
                  : ""}
              </span>
            )}
          />
          {/* <Column
            title={
              <>
                Accepted Location
                <Input></Input>
              </>
            }
            dataIndex="accepted_loc_name"
            key="accepted_loc_name"
          /> */}
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Start Time</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_time", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_time", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.start_time_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          start_time: moment(value).format("x"),
                          start_time_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="start_time"
            render={(text, record) => (
              <span>
                {record.start_time.$date
                  ? timeConverter(record?.start_time?.$date)
                  : ""}
              </span>
            )}
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Started On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "started_on", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "started_on", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.accepted_on_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          accepted_on: moment(value).format("x"),
                          accepted_on_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="started_on"
            render={(text, record) => (
              <span>
                {record.started_on.$date
                  ? timeConverter(record?.started_on?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Started Location</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { started_loc_name: "task_code", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { started_loc_name: "task_code", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.started_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { started_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="started_loc_name"
            key="started_loc_name"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Start Location</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_loc_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_loc_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.start_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { start_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="start_loc_name"
            key="start_loc_name"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>End Time</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_time", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_time", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.end_time_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          end_time: moment(value).format("x"),
                          end_time_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="end_time"
            render={(text, record) => (
              <span>
                {record.end_time.$date
                  ? timeConverter(record?.end_time?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Completed On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "completed_on", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "accepted_on", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.accepted_on_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          accepted_on: moment(value).format("x"),
                          accepted_on_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="completed_on"
            render={(text, record) => (
              <span>
                {record.completed_on.$date
                  ? timeConverter(record?.completed_on?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Completed Location</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "completed_loc_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "completed_loc_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.completed_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { completed_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="completed_loc_name"
            key="completed_loc_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>
                    Total Distance from Started to Completed
                  </Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="total_distance_from_started_to_completed"
            key="total_distance_from_started_to_completed"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>End Location</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_loc_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_loc_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.end_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { end_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="end_loc_name"
            key="end_loc_name"
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Pickup Contact</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.contact_name}
                  onChange={(evt) => {
                    this.setState(
                      { contact_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="contact_name"
            render={(text, record) => (
              <span>{`${record?.contact_name ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Pickup Contact No.</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_no", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "contact_no", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.contact_no}
                  onChange={(evt) => {
                    this.setState(
                      { contact_no: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="contact_no"
            render={(text, record) => (
              <span>{`${record?.contact_no ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Drop Contact</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.drop_contact_name}
                  onChange={(evt) => {
                    this.setState(
                      { drop_contact_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="drop_contact_name"
            render={(text, record) => (
              <span>{`${record?.drop_contact_name ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Drop Contact No.</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_no", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_no", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.drop_contact_no}
                  onChange={(evt) => {
                    this.setState(
                      { drop_contact_no: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="drop_contact_no"
            render={(text, record) => (
              <span>{`${record?.drop_contact_no ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Orders</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="total_orders"
            key="total_orders"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Duration (In Minutes)</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="duration_min"
            key="duration_min"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Start Time Delay (In Minutes)</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="delayed_by_started_min"
            key="delayed_by_started_min"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Completed Time Delay (In Minutes)</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="delayed_by_completed_min"
            key="delayed_by_completed_min"
          />
          {/* <Column
            title="Delay (In minutes)"
            dataIndex="delay_mins"
            key="delay_mins"
          /> */}
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.task.task.task_add_reminder ? (
                  <Link
                    to={{
                      pathname: "/createReminder",
                      state: {
                        task_id: record._id.$oid,
                        task_name: record.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Add Reminder
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.incident.incident_create ? (
                  <Link
                    to={{
                      pathname: "/createIncident",
                      state: {
                        task_id: record._id.$oid,
                        task_name: record.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Add Incident
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.task.task.task_detail
                  .view_task_detail ? (
                  <Link
                    to={{
                      pathname: "/viewTask",
                      state: {
                        task_id: record._id.$oid,
                        assigned_to: record.assigned_to,
                        is_public: record.is_public,
                        is_started: record.started,
                        is_accepted: record.accepted,
                        is_completed: record.completed,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}

                {this.props.userProfileData.task.task.task_update ? (
                  <Link
                    to={{
                      pathname: "/editTask",
                      state: {
                        task_id: record._id.$oid,
                        assigned_to: record.assigned_to,
                        is_public: record.is_public,
                        is_started: record.started,
                        is_accepted: record.accepted,
                        is_completed: record.completed,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}

                {(GET_USER_LOCAL().org_admin ||
                  this.props.userProfileData.task.task.task_view_route) &&
                record.task_status === "COMPLETED" ? (
                  <Link
                    to={{
                      pathname: "/userRoute",
                      state: {
                        search_user: record?.assigned_to?._id?.$oid,
                        start_time: record?.started_on?.$date,
                        end_time: record?.completed_on?.$date,
                        user_name: record?.assigned_to?.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "blue" }}>
                      View Route
                    </Button>
                  </Link>
                ) : GET_USER_LOCAL()._id.$oid ===
                    record?.assigned_to?._id?.$oid &&
                  record.task_status === "COMPLETED" ? (
                  <Link
                    to={{
                      pathname: "/userRoute",
                      state: {
                        search_user: record?.assigned_to?._id?.$oid,
                        start_time: record?.started_on?.$date,
                        end_time: record?.completed_on?.$date,
                        user_name: record?.assigned_to?.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "blue" }}>
                      View Route
                    </Button>
                  </Link>
                ) : null}

                <div>
                  {LOCAL_USER_INFO().user.is_staff ||
                  this.props.userProfileData.task.task.task_delete ? (
                    <div>
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteTask(record._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null}
                </div>
                {record.hasOwnProperty("order_ids") && record.assigned_to ? (
                  <div>
                    {record.assigned_to._id.$oid ===
                    GET_USER_LOCAL()._id.$oid ? (
                      <div>
                        <Link
                          to={{
                            pathname: "/viewOrder",
                            state: {
                              order_id: record.order_ids[0],
                              assigned_to: record.assigned_to._id.$oid,
                            },
                          }}
                        >
                          <Button type="link" style={{ color: "gold" }}>
                            Order
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </span>
            )}
          />
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  taskListParams: state.taskListParams.taskListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { saveTaskListParams, getUserData })(
  AdvancedTaskList
);
