import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input,
  Tag
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { debounce } from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  EDIT_ROASTER,
  CREATE_ROASTER_PLAN,
  CREATE_DRIVER,
  COPY_ROASTER
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditRoaster extends Component {
  state = {
    roaster_id: "",
    roaster_plan_id: "",
    name: "",
    roaster_name: "",
    roaster_plan_name: "",
    day_name: "",
    start_time: "",
    end_time: "",
    date: "",
    timezone_name: "",
    data: [],
    roaster_plan: [],
    assigned_users: [],
    user_data: [],
    filtered: [],
    previous_users: [],
    delete_assigned_users: [],
    searchDataDelete: [],
    from: "",
    from_view: moment(),
    to: "",
    to_view: moment()
  };

  componentDidMount() {
    this.setState({ roaster_id: this.props.location.state.roaster_id });
    this.getRoasterDetails();
    this.getRoasterPlanList();
    this.getUserList();

    // let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.setState({ timezone_name: timezone }, () => {
    //   console.log("time zone", this.state.timezone_name);
    // });
  }

  getRoasterDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_ROASTER}${this.props.location.state.roaster_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response from roaster details", response);
        this.setState({
          roaster_name: response.data?.data?.name ?? "",
          roaster_plan: response.data?.data?.roaster_plan ?? {},
          roaster_plan_name: response.data?.data?.roaster_plan?.name ?? "",
          previous_users: response.data?.data?.assigned_users ?? []
          // roaster_plan_id: response.data?.data?.roaster_plan?._id ?? ""
        });
      });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?limit=100&d=false&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from user list", response);
          this.setState({
            user_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from user list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ roaster_name: evt.target.value }, () => {
      console.log(this.state.roaster_name);
    });
  };

  handleSearch = debounce(value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?limit=100&name=${value}&d=false&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from user list", response);
          this.setState({
            user_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from user list api", error);
        });
    });
  }, 500);

  handlesearchDelete = evt => {
    const result = this.state.previous_users.filter(user => {
      return user.name.toLowerCase().includes(evt.target.value.toLowerCase());
    });
    console.log(result);
    this.setState({ searchDataDelete: result });
  };

  setTo = (value, dateString) => {
    this.setState(
      { to: moment(value).format("x") / 1000, to_view: value },
      () => {
        console.log("Selected Time: ", this.state.to);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState(
      { from: moment(value).format("x") / 1000, from_view: value },
      () => {
        console.log("Selected Time: ", this.state.from);
      }
    );
  };

  getRoasterPlanList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ROASTER_PLAN}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
          console.log("data from roaster plan list api", response);
        })
        .catch(error => {
          console.log("error from roaster plan list api", error);
        });
    });
  };

  setRoasterPlan = (value, info) => {
    console.log("object values", info.props);
    this.setState({ roaster_plan_id: value }, () => {
      console.log("roaster plan id array", this.state.roaster_plan_id);
      let tempObj = {
        _id: info.props.info._id.$oid,
        name: info.props.info.name,
        timezone_name: info.props.info.timezone_name,
        start_time: info.props.info.start_time,
        end_time: info.props.info.end_time
      };
      this.setState({ roaster_plan: tempObj }, () => {
        console.log("final object", this.state.roaster_plan);
      });
    });
  };

  editRoaster = () => {
    const data = {
      roaster_id: this.state.roaster_id,
      name: this.state.roaster_name,
      roaster_plan: this.state.roaster_plan,
      add_assigned_users: this.state.assigned_users,
      delete_assigned_users: this.state.delete_assigned_users,
      from: this.state.from,
      to: this.state.to
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_ROASTER}${this.state.roaster_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit roaster", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit roaster", error);
        });
    });
  };

  copyRoaster = () => {
    if (this.state.from === "" || this.state.to === "") {
      swal.fire("Info", "Please select roaster start and end date", "info");
    } else {
      const data = {
        name: this.state.roaster_name,
        roaster_plan: this.state.roaster_plan,
        assigned_users: this.state.previous_users,
        from: this.state.from,
        to: this.state.to
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${COPY_ROASTER}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            console.log("response from create roaster", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from create roaster", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            onBack={() => this.props.history.goBack()}
            title="Update Roster"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <Text>Name</Text>
              <Input
                placeholder="Name"
                type="text"
                value={this.state.roaster_name}
                onChange={this.setName}
              />
            </Form.Item>

            <Card>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>Current Roster Plan</Text>
                <Tag color="blue">{this.state.roaster_plan_name}</Tag>
              </div>
            </Card>

            <Form.Item>
              <Text>Select Roster Plan</Text>
              <Select
                value={this.state.roaster_plan_id}
                onChange={this.setRoasterPlan}
                placeholder="Select Roster Plan"
              >
                {this.state.data.map(r => {
                  return (
                    <Option key={r._id.$oid} info={r}>
                      {r.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Text>Roster Start Date</Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.from_view}
                    onChange={this.setFrom}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Text>Roster End Date</Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.to_view}
                    onChange={this.setTo}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Item>

            <Form.Item>
              <Text>Add New Users (For Edit Only)</Text>
              <Select
                labelInValue
                mode="multiple"
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                value={this.state.assigned_users}
                onSearch={this.handleSearch}
                onChange={value => {
                  this.setState({ assigned_users: value }, () => {
                    console.log(this.state.assigned_users);
                  });
                }}
                placeholder="Search Users"
              >
                {this.state.user_data.map(r => {
                  return (
                    <Option
                      key={r._id.$oid}
                    >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Card style={{ marginBottom: 5 }}>
              <Text>Delete Assigned User (For Edit Only)</Text>
              <Input
                placeholder="Search User Name"
                // onSearch={this.handlesearch}
                onChange={this.handlesearchDelete}
                size="large"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  borderRadius: 25,
                  border: "2px solid grey"
                }}
              />
              {this.state.searchDataDelete.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 2,
                    marginTop: 15
                  }}
                >
                  {this.state.searchDataDelete.map(user => {
                    return (
                      <Tag
                        style={{ margin: 4, fontSize: 16 }}
                        color={
                          this.state.delete_assigned_users.some(
                            duser => duser.key === user.id
                          )
                            ? "red"
                            : "cyan"
                        }
                        key={user.id}
                        onClick={() => {
                          if (
                            this.state.delete_assigned_users.some(
                              duser => duser.key === user.id
                            )
                          ) {
                            this.setState(
                              {
                                delete_assigned_users: this.state.delete_assigned_users.filter(
                                  duser => duser.key !== user.id
                                )
                              },
                              () => {
                                console.log(
                                  "deleted user removed",
                                  this.state.delete_assigned_users
                                );
                              }
                            );
                          } else {
                            let obj = {
                              key: user.id
                            };
                            let temp = [];
                            temp.push(obj);
                            this.setState(
                              {
                                delete_assigned_users: [
                                  ...this.state.delete_assigned_users,
                                  ...temp
                                ]
                              },
                              () => {
                                console.log(
                                  "deleted user",
                                  this.state.delete_assigned_users
                                );
                              }
                            );
                          }
                        }}
                      >{`${user.name}>${user.mobile}`}</Tag>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 2,
                    marginTop: 15
                  }}
                >
                  {this.state.previous_users.map(user => {
                    return (
                      <Tag
                        style={{ margin: 4, fontSize: 16 }}
                        color={
                          this.state.delete_assigned_users.some(
                            duser => duser.key === user.id
                          )
                            ? "red"
                            : "cyan"
                        }
                        key={user.id}
                        onClick={() => {
                          if (
                            this.state.delete_assigned_users.some(
                              duser => duser.key === user.id
                            )
                          ) {
                            this.setState(
                              {
                                delete_assigned_users: this.state.delete_assigned_users.filter(
                                  duser => duser.key !== user.id
                                )
                              },
                              () => {
                                console.log(
                                  "deleted user removed",
                                  this.state.delete_assigned_users
                                );
                              }
                            );
                          } else {
                            let obj = {
                              key: user.id
                            };
                            let temp = [];
                            temp.push(obj);
                            this.setState(
                              {
                                delete_assigned_users: [
                                  ...this.state.delete_assigned_users,
                                  ...temp
                                ]
                              },
                              () => {
                                console.log(
                                  "deleted user",
                                  this.state.delete_assigned_users
                                );
                              }
                            );
                          }
                        }}
                      >{`${user.name}>${user.mobile}`}</Tag>
                    );
                  })}
                </div>
              )}
            </Card>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.editRoaster}
              >
                Update Roster
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.copyRoaster}
              >
                Copy This Roster
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    );
  }
}
