import React, { Component } from 'react';
import {
    Card,
    Form,
    Button,
    Typography,
    PageHeader,
    Select
  } from "antd";
  import axios from "axios";
  import swal from "sweetalert2";
  import { CREATE_ORGANIZATION, SWITCH_ORGANIZATION } from "../../api/constants";
  import { GET_ACCESS_TOKEN ,LOCAL_USER_INFO, GET_USER} from "../../utilities/utilities";
  import { Spinner } from "../loader";
  
  import "../login.css";
  
  const { Text } = Typography;
  const { Option } = Select;

export default class ChangeOrganization extends Component {
    state = {
        organization:"",
        org_id:"",
        user_id:"",
        loading: false,
        data: [],
        filtered:[]
      };
    
      componentDidMount() {
        let user = LOCAL_USER_INFO().user._id;
        this.setState({user_id:user});
        this.getOrgList();
      }
    
      getOrgList = () => {
        let limit = 1000;
        GET_ACCESS_TOKEN().then(token => {
          const url = `${CREATE_ORGANIZATION}?get_connected_orgs=true&limit=${limit}&access_token=${token}`;

          axios({
            method: "get",
            url: url
          })
            .then(response => {
              this.setState({ data: response.data.data });
              console.log("data from org list api", response);
            })
            .catch(error => {
              console.log("error from org list api", error);
            });
        });
      };
    
      
      setOrg = value => {
        this.setState({ organization: value.label,org_id:value.key }, () => {
          console.log(this.state.organization);
        });
      };

      handleSearch=(value)=>{
        const result = this.state.data.filter(org=> {
          return org.name.toLowerCase().includes(value.toLowerCase())
        })
        console.log(result)
        this.setState({filtered:result})

      }
    
      changeOrganization = () => {
        if(this.state.org_id===""){
          swal.fire("info","Please Select Organization","info");
        } else {
          this.setState({ loading: true });
          let data = {
              organization:this.state.org_id
          }
          GET_ACCESS_TOKEN().then(token => {
            let url=`${SWITCH_ORGANIZATION}${this.state.user_id}?access_token=${token}`

            axios({
              method: "put",
              url: url,
              data:data,
              headers: { 
                'content-type': 'application/x-www-form-urlencoded' 
              }
            })
              .then(response => {
                this.setState({ loading: false });
                swal.fire("Info", response.data.message, "info");
                if(response.data.message==="Data successfully updated!"){
                  GET_USER();
                  window.location.reload();
                }
                console.log("response from change org", response);
              })
              .catch(error => {
                this.setState({ loading: false });
                swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                console.log("error from change org", error);
              });
          });
        }
          
        
      };
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        }

        const UserList=this.state.data.map(r => {
          return <Option key={r._id.$oid}>{r.name}</Option>;
        })

        const FliteredList = this.state.filtered.map(r => {
          return <Option key={r._id.$oid}>{r.name}</Option>;
        })
        return (
          <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Change Organization"
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
             
              <Form.Item>
                <Text>Search Organization</Text>
                <Select
                  showSearch={true}
                  labelInValue
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  value={{ key: this.state.org_id, label: this.state.organization }}
                  onChange={this.setOrg}
                  placeholder="Select Organization"
                >
                   {this.state.filtered.length===0?(
                    UserList
                  ):(
                    FliteredList
                  )}
                </Select>
              </Form.Item>
    
              <Form.Item>
                <Button className="login-form-button" htmlType="submit" onClick={this.changeOrganization}>
                  Change Organization
                </Button>
              </Form.Item>
            </Form>
          </Card>
        );
      }
}
