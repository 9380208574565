import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_SERVICE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class EditService extends Component {
  state = {
    name: "",
    service_details: "",
    loading: false,
    service_id: ""
  };
  componentDidMount() {
    console.log(this.props.history);
    this.setState({ service_id: this.props.location.state.service_id });

    GET_ACCESS_TOKEN().then(token => {
      let service_id = this.props.location.state.service_id;
      let url = `${EDIT_SERVICE}${service_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit service", response);
        this.setState({
          name: response.data.data.name,
          service_details: response.data.data.service_details
        });
      });
    });
  }
  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setService = evt => {
    evt.preventDefault();
    this.setState({ service_details: evt.target.value }, () => {
      console.log(this.state.service_details);
    });
  };

  updateService = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      service_details: this.state.service_details,
      service_id: this.state.service_id
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_SERVICE}${this.state.service_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit service", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit service", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Edit Service"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Form.Item>
            <Text>Service Details</Text>

            <Input
              placeholder="Service Details"
              type="text"
              value={this.state.service_details}
              onChange={this.setService}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateService}
            >
              Update Service
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
