import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_CONTAINER,
  CREATE_ORDER,
  EDIT_ORDER,
  EDIT_PARCEL
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditParcel extends Component {
  state = {
    name: "",
    orders: [],
    order_id: "",
    items: [],
    loc_lat: 23.685,
    loc_lon: 90.3563,
    pl: null,
    pw: null,
    ph: null,
    unit_dim: null,
    unit_weight: null,
    parcel_weight: null,
    max_weight: null,
    fragile: false,
    sensitive: false,
    fast: false,
    temperature: null,
    containers: [],
    container: "",
    device_id: "",
    connect_key: "",
    parcel_id: "",
    loading: false
  };

  componentDidMount() {
    this.getParcelDetails();
    this.getOrder();
    this.getContainer();
  }

  getParcelDetails = () => {
    this.setState({ parcel_id: this.props.location.state.parcel_id });

    GET_ACCESS_TOKEN().then(token => {
      let parcel_id = this.props.location.state.parcel_id;
      let url = `${EDIT_PARCEL}${parcel_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro parcel view", response.data.data);
        this.setState({
            name:response.data.data.name,
            order_id:response.data.data.order_id,
            items:response.data.data.items,
            pl:response.data.data.pl,
            pw:response.data.data.pw,
            ph:response.data.data.ph,
            unit_dim:response.data.data.unit_dim,
            unit_weight:response.data.data.unit_weight,
            parcel_weight:response.data.data.parcel_weight,
            max_weight:response.data.data.max_weight,
            fragile:response.data.data.fragile,
            sensitive:response.data.data.temperature.sensitive,
            fast:response.data.data.temperature.fast,
            temperature:response.data.data.temperature.temperature,
            container:response.data.data.container
        });
      });
    });
  };

  getOrder = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ORDER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ orders: response.data.data });
          console.log("orders", response.data.data);
        })
        .catch(error => {
          console.log("error from orders list api", error);
        });
    });
  };
  getContainer = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_CONTAINER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ containers: response.data.data });
          console.log("containers", response.data.data);
        })
        .catch(error => {
          console.log("error from containers list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setpl = evt => {
    evt.preventDefault();
    this.setState({ pl: evt.target.value }, () => {
      console.log(this.state.pl);
    });
  };
  setpw = evt => {
    evt.preventDefault();
    this.setState({ pw: evt.target.value }, () => {
      console.log(this.state.pw);
    });
  };
  setph = evt => {
    evt.preventDefault();
    this.setState({ ph: evt.target.value }, () => {
      console.log(this.state.ph);
    });
  };
  setDim = evt => {
    evt.preventDefault();
    this.setState({ unit_dim: evt.target.value }, () => {
      console.log(this.state.unit_dim);
    });
  };
  setWeight = evt => {
    evt.preventDefault();
    this.setState({ unit_weight: evt.target.value }, () => {
      console.log(this.state.unit_weight);
    });
  };
  setPweight = evt => {
    evt.preventDefault();
    this.setState({ parcel_weight: evt.target.value }, () => {
      console.log(this.state.parcel_weight);
    });
  };
  setMaxweight = evt => {
    evt.preventDefault();
    this.setState({ max_weight: evt.target.value }, () => {
      console.log(this.state.max_weight);
    });
  };

  setFragile = value => {
    this.setState({ fragile: CHECK_BOOL(value) }, () => {
      console.log(this.state.fragile);
    });
  };
  setSensitive = value => {
    this.setState({ sensitive: CHECK_BOOL(value) }, () => {
      console.log(this.state.sensitive);
    });
  };
  setFast = value => {
    this.setState({ fast: CHECK_BOOL(value) }, () => {
      console.log(this.state.fast);
    });
  };
  setTemperature = evt => {
    this.setState({ temperature: evt.target.value }, () => {
      console.log(this.state.temperature);
    });
  };

  setContainer = value => {
    this.setState({ container: value }, () => {
      console.log(this.state.container);
    });
  };
  setOrder = value => {
    this.setState({ order_id: value }, () => {
      console.log(this.state.order_id);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_ORDER}${this.state.order_id}?access_token=${token}`;
        axios({
          method: "get",
          url: url
        }).then(response => {
          this.setState(
            {
              items: response.data.data.items
            },
            () => {
              console.log("response fro edit order", this.state.items);
            }
          );
        });
      });
    });
  };

  updateParcel = () => {
    if (
      this.state.name === "" ||
      this.state.pl === null ||
      this.state.ph === null ||
      this.state.pw === null ||
      this.state.unit_dim === null ||
      this.state.unit_weight === null ||
      this.state.parcel_weight === null ||
      this.state.max_weight === null
    ) {
      swal.fire(
        "warning",
        "Please fill up name,dimension,parcel weight,maximum weight,length,width and height",
        "warning"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        order_id: this.state.order_id,
        items: this.state.items,
        start_loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        end_loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        pl: Number(this.state.pl),
        pw: Number(this.state.pw),
        ph: Number(this.state.ph),
        unit_dim: Number(this.state.unit_dim),
        unit_weight: Number(this.state.unit_weight),
        parcel_weight: Number(this.state.parcel_weight),
        max_weight: Number(this.state.max_weight),
        fragile: this.state.fragile,
        temperature: {
          sensitive: this.state.sensitive,
          temperature: Number(this.state.temperature),
          fast: this.state.fast
        },
        container: this.state.container,
        device_id: this.state.device_id,
        connect_key: this.state.connect_key
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_PARCEL}${this.state.parcel_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");

            console.log("response from update parcel", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from update parcel", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Update Parcel"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Order</Text>
            <Select
              value={this.state.order_id.toString()}
              onChange={this.setOrder}
              placeholder="Select Order"
            >
              {this.state.orders.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Parcel length (In Feet)</Text>
            <Input
              placeholder="Parcel length"
              type="number"
              value={this.state.pl}
              onChange={this.setpl}
            />
          </Form.Item>
          <Form.Item>
            <Text>Parcel Width (In Feet)</Text>
            <Input
              placeholder="Parcel Width"
              type="number"
              value={this.state.pw}
              onChange={this.setpw}
            />
          </Form.Item>
          <Form.Item>
            <Text>Parcel Height (In Feet)</Text>
            <Input
              placeholder="Parcel Height"
              type="number"
              value={this.state.ph}
              onChange={this.setph}
            />
          </Form.Item>
          <Form.Item>
            <Text>Parcel Weight (In Kilogram)</Text>
            <Input
              placeholder="Parcel Weight"
              type="number"
              value={this.state.parcel_weight}
              onChange={this.setPweight}
            />
          </Form.Item>
          <Form.Item>
            <Text>Unit Dimension</Text>
            <Input
              placeholder="Unit Dimension"
              type="number"
              value={this.state.unit_dim}
              onChange={this.setDim}
            />
          </Form.Item>
         
          <Form.Item>
            <Text>Unit Weight (In Kilogram)</Text>
            <Input
              placeholder="Unit Weight"
              type="number"
              value={this.state.unit_weight}
              onChange={this.setWeight}
            />
          </Form.Item>
          <Form.Item>
            <Text>Unit Maximum Weight (In Kilogram)</Text>
            <Input
              placeholder="Unit Maximum Weight"
              type="number"
              value={this.state.max_weight}
              onChange={this.setMaxweight}
            />
          </Form.Item>
          <Form.Item>
            <Text>Fragile</Text>
            <Select
              value={this.state.fragile.toString()}
              onChange={this.setFragile}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Sensitive</Text>
            <Select
              value={this.state.sensitive.toString()}
              onChange={this.setSensitive}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Fast</Text>
            <Select value={this.state.fast.toString()} onChange={this.setFast}>
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Temperature</Text>
            <Input
              placeholder="Temperature"
              type="number"
              value={this.state.temperature}
              onChange={this.setTemperature}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Container</Text>
            <Select
              value={this.state.container.toString()}
              onChange={this.setContainer}
              placeholder="Select Container"
            >
              {this.state.containers.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateParcel}
            >
              Update Parcel
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
