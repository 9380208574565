import React, { Component } from "react";
import {
  Card,
  Typography,
  PageHeader,
  Button,
  Collapse,
  Table,
  Divider,
  Tag
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  EDIT_ASSET,
  ASSET_TAG_PRINT,
  ASSET_HISTORY,
  TASK_FROM_ASSET
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  LOCAL_USER_INFO,
  randomId
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Text } = Typography;
const { Panel } = Collapse;
const { Column } = Table;

export default class ViewAsset extends Component {
  state = {
    name: "",
    asset: "",
    user_id: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    connect_key: [],
    asset_id: "",
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    pin: "",
    created_on: "",
    loading: false,
    org_id: "",
    assetTasks: [],
    assetBills: []
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.getAsset();
    this.assetHistoryBill();
    this.assetHistoryTask();
    this.setState({ asset_id: this.props.match.params.asset_id });
    window.location.replace(
      `conveyence://app/root/assets/details/${this.props.match.params.asset_id}`
    );
  }

  getAsset = () => {
    GET_ACCESS_TOKEN().then(token => {
      let asset_id = this.props.match.params.asset_id;
      let url = `${EDIT_ASSET}${asset_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro view asset", response.data.data);
        this.setState({
          name: response.data.data.name,
          asset: response.data.data.asset,
          user_id: response.data.data.user_id,
          address: response.data.data.address,
          city: response.data.data.city,
          country: response.data.data.country,
          created_on: response.data.data.created_on.$date,
          pin: response.data.data.pin
        });
      });
    });
  };

  assetHistoryTask = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ASSET_HISTORY}${this.state.asset_id}?field_for=task&sort_type=asc&sort_by=start_time&access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response from asset task history", response);
        this.setState({ assetTasks: response.data.data });
      });
    });
  };

  assetHistoryBill = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ASSET_HISTORY}${this.state.asset_id}?field_for=bill&access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response from asset bill history", response);
        this.setState({ assetBills: response.data.data });
      });
    });
  };

  assetTagprint = (item_id, item) => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ASSET_TAG_PRINT}?asset_id=${this.state.asset_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log("response from asset print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.asset_tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from asset print list fmt", error);
        });
    });
  };

  generateTask = () => {
    //   this.setState({ loading: true });
    let data = {
      name: this.state.name,
      details: "",
      end_loc: "",
      end_loc_name: "",
      task_code: randomId(10),
      price: 0,
      start_loc: "",
      start_loc_name: "",
      end_time: "",
      start_time: "",
      assigned_to: this.state.user_id,
      assigned_to_name: "",
      start_dist: 1000,
      end_dist: 1000,
      trip_duration: 0,
      delay_mins: null,
      assigned_asset: this.state.asset_id,
      assigned_asset_name: this.state.name,
      prev_task_id: "",
      m_task_id: "",
      contact_no: "",
      contact_name: "",
      drop_contact_name:"",
      drop_contact_no:"",
      colors: []
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${TASK_FROM_ASSET}${this.state.asset_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          // this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from generate task from asset put", response);
        })
        .catch(error => {
          // this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from generate task from asset put", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="View Asset"
        />
        <Card className="view">
          <p >
            <Text style={{ fontWeight: "bold" }}> Name</Text>: {this.state.name}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Asset</Text>:{" "}
            {this.state.asset}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>User ID</Text>:{" "}
            {this.state.user_id}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Address</Text>:{" "}
            {this.state.address}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>City</Text>: {this.state.city}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Country</Text>:{" "}
            {this.state.country}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Container PIN</Text>:{" "}
            {this.state.pin}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Asset</Text>:{" "}
            {this.state.asset}
          </p>
          <p >
            <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
          <Button
            onClick={this.generateTask}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Generate Task
          </Button>
          <Button
            onClick={this.assetTagprint}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Print Tag
          </Button>
        </Card>
        <Card className="view">
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Asset History"
          />
          <Collapse>
            <Panel header={`Total Tasks Assigned : ${this.state.assetTasks.length}`} key="1">
              <Table
                dataSource={this.state.assetTasks}
                pagination={false}
                rowKey={record => record._id.$oid}
                size="small"
                bordered
              >
                <Column
                  title="Task Name"
                  key="name"
                  render={(text, record) => (
                    <span>{`${record?.task_code ?? ""} : ${record?.name ??
                      ""}`}</span>
                  )}
                />
                <Column
                  title="Task Status"
                  dataIndex="task_status"
                  key="task_status"
                />
                <Column
                  title="Assigned To"
                  key="assigned_to"
                  render={(text, record) => (
                    <span>{record?.assigned_to?.name ?? ""}</span>
                  )}
                />
                <Column
                  title="Assigned Asset"
                  key="assigned_asset"
                  render={(text, record) => (
                    <span>{record?.assigned_asset?.name ?? ""}</span>
                  )}
                />

                <Column
                  title="Start Time"
                  key="start_time"
                  render={(text, record) => (
                    <span>
                      {record.start_time.$date
                        ? timeConverter(record?.start_time?.$date)
                        : ""}
                    </span>
                  )}
                />

                <Column
                  title="Started Location"
                  dataIndex="started_loc_name"
                  key="started_loc_name"
                />
                <Column
                  title="Start Location"
                  dataIndex="start_loc_name"
                  key="start_loc_name"
                />
                <Column
                  title="End Time"
                  key="end_time"
                  render={(text, record) => (
                    <span>
                      {record.end_time.$date
                        ? timeConverter(record?.end_time?.$date)
                        : ""}
                    </span>
                  )}
                />

                <Column
                  title="End Location"
                  dataIndex="end_loc_name"
                  key="end_loc_name"
                />

                <Column
                  title="Drop Contact"
                  key="drop_contact_name"
                  render={(text, record) => (
                    <span>{`${record?.drop_contact_name ??
                      ""} : ${record?.drop_contact_no ?? ""}`}</span>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <Link
                        to={{
                          pathname: "/viewTask",
                          state: {
                            task_id: record._id.$oid,
                            assigned_to: record.assigned_to,
                            is_public: record.is_public,
                            is_started: record.started,
                            is_accepted: record.accepted,
                            is_completed: record.completed
                          }
                        }}
                      >
                        <Button type="link" style={{ color: "green" }}>
                          View
                        </Button>
                      </Link>
                      <Divider type="vertical" />
                      <Link
                        to={{
                          pathname: "/editTask",
                          state: {
                            task_id: record._id.$oid,
                            assigned_to: record.assigned_to,
                            is_public: record.is_public,
                            is_started: record.started,
                            is_accepted: record.accepted,
                            is_completed: record.completed
                          }
                        }}
                      >
                        <Button type="link" style={{ color: "green" }}>
                          Edit
                        </Button>
                      </Link>
                      <div>
                        {LOCAL_USER_INFO().user.is_staff === true ? (
                          <div>
                            <Divider type="vertical" />
                            <Button
                              type="link"
                              style={{ color: "red" }}
                              onClick={() => {
                                if (window.confirm("Delete the item?")) {
                                  this.deleteTask(record._id.$oid);
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      {record.hasOwnProperty("order_ids") &&
                      record.assigned_to ? (
                        <div>
                          {record.assigned_to._id.$oid ===
                          GET_USER_LOCAL()._id.$oid ? (
                            <div>
                              <Divider type="vertical" />
                              <Link
                                to={{
                                  pathname: "/viewOrder",
                                  state: {
                                    order_id: record.order_ids[0],
                                    assigned_to: record.assigned_to._id.$oid
                                  }
                                }}
                              >
                                <Button type="link" style={{ color: "gold" }}>
                                  Order
                                </Button>
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </span>
                  )}
                />
              </Table>
            </Panel>
            <Panel header={`Total Bills Assigned : ${this.state.assetBills.length}`} key="2">
              <Table
                dataSource={this.state.assetBills}
                pagination={false}
                rowKey={record => record._id.$oid}
                size="small"
                bordered
              >
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Details" dataIndex="details" key="details" />
                <Column title="Bill Type" dataIndex="misc_bill_type" />
                <Column
                  title="Approved Price"
                  dataIndex="total_approved_price"
                  key="total_approved_price"
                />
                <Column
                  title="Authorised Price"
                  dataIndex="total_authorized_price"
                  key="total_authorized_price"
                />
                <Column
                  title="Recommended Price"
                  dataIndex="total_recommended_price"
                  key="total_recommended_price"
                />
                <Column
                  title="Total Price"
                  dataIndex="total_price"
                  key="total_price"
                />
                {/* <Column
            title="Supplier Name"
            dataIndex="supplier.name"
            key="supplier.name"
          /> */}
                <Column
                  title="Material Name"
                  dataIndex="items"
                  key="items"
                  render={items => (
                    <span>
                      {items.map(item => (
                        <Tag
                          color="blue"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2
                          }}
                        >
                          {item.item}
                        </Tag>
                      ))}
                    </span>
                  )}
                />
                <Column
                  title="Material Type"
                  dataIndex="items"
                  key="items.mtype"
                  render={items => (
                    <span>
                      {items.map(item => (
                        <Tag
                          color="blue"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2
                          }}
                        >
                          {item?.mtype?.name ?? ""}
                        </Tag>
                      ))}
                    </span>
                  )}
                />

                <Column
                  title="Item Quantity"
                  dataIndex="items"
                  key="items2"
                  render={items => (
                    <span>
                      {items.map(item => (
                        <Tag
                          color="green"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2
                          }}
                        >
                          {item.qty} {item.unit}
                        </Tag>
                      ))}
                    </span>
                  )}
                />
                <Column
                  title="Item Price"
                  dataIndex="items"
                  key="items3"
                  render={items => (
                    <span>
                      {items.map(item => (
                        <Tag
                          color="red"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2
                          }}
                        >
                          {item.price}
                        </Tag>
                      ))}
                    </span>
                  )}
                />
               
                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <Link
                        to={{
                          pathname: "/viewBill",
                          state: { bill_id: record._id.$oid }
                        }}
                      >
                        <Button type="link" style={{ color: "green" }}>
                          View
                        </Button>
                      </Link>
                      {record.misc_bill_type === "Purchase(General)" ||
                      record.misc_bill_type === "Purchase(Assets)" ||
                      record.misc_bill_type === "Entertainment" ? (
                        <Link
                          to={{
                            pathname: "/editBill",
                            state: { bill_id: record._id.$oid }
                          }}
                        >
                          <Button type="link" style={{ color: "green" }}>
                            Edit
                          </Button>
                        </Link>
                      ) : null}

                      {GET_USER_LOCAL().org_admin ? (
                        <div>
                          <Button
                            type="link"
                            style={{ color: "red" }}
                            onClick={() => {
                              if (window.confirm("Delete the item?")) {
                                this.deleteOrder(record._id.$oid);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : null}
                      {record.requisition_id !== "" ? (
                        <div>
                          <Link
                            to={{
                              pathname: "/editRequisition",
                              state: { req_id: record.requisition_id }
                            }}
                          >
                            <Button type="link" style={{ color: "gold" }}>
                              Requisition
                            </Button>
                          </Link>
                        </div>
                      ) : null}
                    </span>
                  )}
                />
              </Table>
            </Panel>
          </Collapse>
        </Card>
      </Card>
    );
  }
}
