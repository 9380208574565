import React, { Component } from "react";
import {
  Card,
  Typography,
  PageHeader,
  Switch,
  Button,
  Input,
  Table,
  Icon,
  Radio,
  Checkbox,
  Tag,
  Form,
  Select,
  Modal,
  Tooltip,
  Spin,
  Slider,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import moment from "moment";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  EDIT_ORDER_WITH_PROMOTION,
  ORDER_LOCATION_UPDATE,
  // ORDER_PRODUCT_STATUS_UPDATE,
  // ORG_IMAGE_UPLOAD,
  ORDER_PRODUCT_TAG,
  ORDER_TAG_PRINT,
  ORDER_INVOICE_PRINT,
  CREATE_CUSTOM_FIELD,
  ADD_CUSTOM_FIELDS_TO_TASK,
  GEO_CODING_API,
  REVERSE_GEO,
  MULTI_IMAGE_UPLOAD,
  USER_ROUTE,
  RETURN_INVENTORY_PRODUCT_REASON_CHOICES,
  CREATE_CUSTOM_FORM,
  SUBMIT_CUSTOM_FORM,
  EDIT_CUSTOM_FORM_SUBMITTED_DATA,
  // RETURN_PRODUCT_TO_INVENTORY
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  randomId,
  isValidURL,
} from "../../utilities/utilities";
import swal from "sweetalert2";
import ReactGA from "react-ga4";
import FileSaver from "file-saver";

import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Column } = Table;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40],
});

const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [20, 30],
});

class ViewOrder extends Component {
  state = {
    order_number: "",
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    drop_contact_name: "",
    drop_contact_no: "",
    drop_contact_email: "",
    start_entity_name: "",
    start_entity_key: "",
    start_entity_address: "",
    end_entity_name: "",
    end_entity_key: "",
    end_loc: "",
    end_loc_name: "",
    delivery: true,
    delivered: false,
    rescheduled: false,
    startObj: {},
    endObj: {},
    pl: null,
    pw: null,
    ph: null,
    assigned_org_name: "",
    assigned_org_id: "",
    parcel_wght: null,
    total_price: null,
    delivery_charge: null,
    other_charges: null,
    bill_amount: null,
    received_amount: null,
    due_amount: null,
    // received: false,
    total_discount_on_product: null,
    other_discount: null,
    other_discount_type: "amount",
    delivery_date_time: moment(),
    delivery_date_time_view: moment(),
    modify_on: "",
    data: [],
    dataS: [],
    orgList: [],
    loading: false,
    visible: false,
    showMap: false,
    showEntity: false,
    showProduct: false,
    delivery_time_flexible: false,
    delivery_charge_included: false,
    paid: false,
    payment_method: "",
    settled: false,
    cancelled: false,

    query: "",
    start_location: "",
    value: [],
    dataMap: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    editEnd: false,
    zoom: 15,
    get_connected_orgs: true,
    products: [],
    edit: false,
    assigned_to: "",
    task_id: "",
    total_discount: 0,
    placed_by_name: "",
    placed_by_mobile_number: "",
    placed_on: "",
    addedData: [],
    qcData: [],
    deliveryData: [],
    checked: false,
    selected: "",
    deselected: "",
    added_image_file: "",
    added_image_url: "",
    qc_image_url: "",
    qc_image_file: "",
    order_id: "",
    org_id: "",
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {},
    discount: "",
    created_by_name: "",
    completed_tasks: [],
    routeData: [],
    total_refund: 0,
    refundable_amount: 0,
    total_refunded_amount: 0,
    total_received_amount: 0,
    received_history: [],
    refunded_history: [],
    order_status: "",
    sub_org_name: "",
    sub_org_type_name: "",
    inventory_name: "",
    display_return_Item_modal: false,
    return_item_id: "",
    add_or_subtract_reason_choices: [],
    add_or_subtract_reason: "",
    returned_qty: 0,
    cost_amount: 0,
    order_type: "sales",
    customFormData: [],
    custom_form_id: "",
    custom_form_fields: [],
    show_custom_form_modal: false,
    custom_form_field_post_object: {},
    custom_form_submitted_data: [],
    document_id: "",
    view_submitted_data_modal: false,
    linked_order_number: "",
  };

  componentDidMount() {
    this.props.getUserData();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({
      org_id: GET_USER_LOCAL().organization.$oid,
      showMap: this.props?.location?.state?.showMap ?? false,
    });
    if (this.props.location.state.assigned_to) {
      this.setState(
        { assigned_to: this.props.location.state.assigned_to },
        () => {
          console.log("assigned user id", this.state.assigned_to);
        }
      );
    }
    this.getCustomFields();
    this.getOrderDetails();
    this.getReturnReasonChoices();
  }

  getCustomFormSubmissionData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SUBMIT_CUSTOM_FORM}?associated_with=${this.state.order_id}&form_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_form_submitted_data: response.data.data,
          });
          console.log(
            "response from get custom formsubmission data ",
            response
          );
        })
        .catch((error) => {
          // swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from get custom form submission ", error);
        });
    });
  };

  getCustomFormData = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?associated_with=orders&_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form lists", response);
          this.setState(
            {
              customFormData: response.data?.data ?? [],
            },
            () => {
              this.getCustomFormSubmissionData();
            }
          );
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };

  getCustomFormDataWithId = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FORM}?associated_with=orders&_id=${this.state.custom_form_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from custom form lists", response);
          this.setState(
            {
              custom_form_id:
                response.data.data.length === 1
                  ? response.data.data[0]._id.$oid
                  : "",
              custom_form_fields:
                response.data.data.length === 1
                  ? response.data.data[0].fields
                  : [],
            },
            () => {
              this.getCustomFormSubmissionData();
            }
          );
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from custom form lists", error);
        });
    });
  };

  updateCustomFormData = () => {
    if (Object.keys(this.state.custom_form_field_post_object).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        form_id: this.state.custom_form_id,
        submission_data: this.state.custom_form_field_post_object,
        associated_with: this.state.order_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${SUBMIT_CUSTOM_FORM}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (response.data.message === "Data successfully updated!") {
              this.getCustomFormSubmissionData();
              this.setState({ custom_form_field_post_object: {} });
            }
            console.log("response from custom form task data post ", response);
          })
          .catch((error) => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from custom form task data post ", error);
          });
      });
    }
  };

  editCustomFormSubmittedData = () => {
    if (Object.keys(this.state.custom_form_field_post_object).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        form_id: this.state.custom_form_id,
        submission_data: this.state.custom_form_field_post_object,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_CUSTOM_FORM_SUBMITTED_DATA}${this.state.document_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (
              response.data.message === "Form submission updated successfully"
            ) {
              this.getCustomFormSubmissionData();
              this.setState({ custom_form_field_post_object: {} });
            }
            console.log(
              "response from custom form order submission data put ",
              response
            );
          })
          .catch((error) => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log(
              "error from custom form order submission data put ",
              error
            );
          });
      });
    }
  };

  getReturnReasonChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${RETURN_INVENTORY_PRODUCT_REASON_CHOICES}?access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            add_or_subtract_reason_choices: response.data.data,
          });
          console.log("response from return reason choices", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from return reason choices", error);
        });
    });
  };
  getCustomFields = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=order&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_fields: response.data.data,
          });
          console.log("response from get custom fields", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };

  getOrderDetails = () => {
    this.setState({ order_id: this.props.location.state.order_id });

    GET_ACCESS_TOKEN().then((token) => {
      let order_id = this.props.location.state.order_id;
      let url = `${EDIT_ORDER_WITH_PROMOTION}${order_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view order", response.data.data);
        this.setState(
          {
            name: response.data.data.name,
            linked_order_number:
              response.data?.data?.linked_order_data?.order_number ?? "",
            order_type: response.data?.data?.order_type ?? "sales",
            details: response.data.data.details,
            inventory_name: response.data.data.inventory_entity_name,
            sub_org_name: response?.data?.data?.sub_org?.name ?? "",
            sub_org_type_name:
              response?.data?.data?.sub_org?.sub_org_type_data?.name ?? "",
            assigned_org_id: response.data.data.assigned_org.$oid,
            order_status: response.data?.data?.order_status?.name ?? "",
            assigned_org_name: response.data.data.assigned_org_name,
            bill_amount: response.data.data.bill_amount,
            delivered: response.data.data.delivered,
            delivery: response.data.data.delivery,
            delivery_charge: response.data.data.delivery_charge,
            delivery_date_time:
              response?.data?.data?.delivery_date_time?.$date ?? Date.now(),
            // delivery_date_time_view: response.data.data.delivery_date_time.$date,
            drop_contact_email: response.data.data.drop_contact_email,
            drop_contact_name: response.data.data.drop_contact_name,
            drop_contact_no: response.data.data.drop_contact_no,
            due_amount: response.data.data.due_amount,
            end_loc: response.data.data.end_loc,
            created_by_name: response.data.data.created_by_name,
            end_location_lon: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[0]
              : 90.3563,
            end_location_lat: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[1]
              : 23.685,
            center_lon: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[0]
              : 90.3563,
            center_lat: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[1]
              : 23.685,
            end_loc_name: response.data.data.end_loc_name,
            order_number: response.data.data.order_number,
            other_charges: response.data.data.other_charges,
            pl: response.data.data.parcel_dim.pl,
            pw: response.data.data.parcel_dim.pw,
            ph: response.data.data.parcel_dim.ph,
            parcel_wght: response.data.data.parcel_wght,
            // received: response.data?.data?.received??false,
            total_received_amount:
              response.data?.data?.total_received_amount ?? 0,
            rescheduled: response.data.data.rescheduled,
            delivery_time_flexible:
              response.data.data.delivery_time_flexible || false,
            total_price: response.data.data.total_price,
            discount: response.data.data.discount,
            other_discount: response.data?.data?.other_discount ?? 0,
            other_discount_type:
              response.data?.data?.other_discount_type ?? "amount",
            total_refund: response.data?.data?.total_refund ?? 0,
            total_refunded_amount:
              response.data?.data?.total_refunded_amount ?? 0,
            refundable_amount: response.data?.data?.refundable_amount ?? 0,
            received_history: response.data?.data?.received_history ?? [],
            refunded_history: response.data?.data?.refunded_history ?? [],
            total_discount_on_product:
              response.data?.data?.discount_on_products ?? 0,
            start_entity_name: response.data.data.start_entity.name,
            end_entity_name: response.data.data.end_entity.name,
            products: response.data.data.items,
            modify_on: response.data.data.modified_on.$date,
            start_entity_address: response.data.data.start_entity.address,
            delivery_charge_included:
              response.data.data.delivery_charge_included,
            paid: response.data.data.paid,
            payment_method: response.data.data.payment_method
              ? response.data.data.payment_method
              : "",
            settled: response.data.data.settled,
            cancelled: response.data.data.cancelled,
            task_id: response.data.data.task_ids[0] || "",
            total_discount: response.data?.data?.total_discount ?? 0,
            placed_by_name: response.data?.data?.placed_by?.name ?? "",
            placed_by_mobile_number:
              response.data?.data?.placed_by?.mobile_number ?? "",
            placed_on:
              response.data?.data?.placed_on?.$date ??
              response.data.data.delivery_date_time.$date,
            getCustomFieldData: response.data?.data?.custom_fields ?? {},
            completed_tasks: response.data?.data?.completed_tasks ?? [],
          },
          () => {
            this.getCustomFormData();
          }
        );
      });
    });
  };

  handleCancelledchange = (checked) => {
    this.setState({ cancelled: checked }, () => {
      console.log(this.state.cancelled);
    });
  };

  handleDeliverychange = (checked) => {
    this.setState({ delivery: checked }, () => {
      console.log(this.state.delivery);
    });
  };

  handleDelieveredchange = (checked) => {
    this.setState({ delivered: checked }, () => {
      console.log(this.state.delivered);
    });
  };

  handlePaidchange = (checked) => {
    this.setState({ paid: checked }, () => {
      console.log(this.state.paid);
    });
  };

  handleSettledchange = (checked) => {
    this.setState({ settled: checked }, () => {
      console.log(this.state.settled);
    });
  };

  handleDeliveryFlexiblechange = (checked) => {
    this.setState({ delivery_time_flexible: checked }, () => {
      console.log(this.state.delivery_time_flexible);
    });
  };

  handleDeliveryChargechange = (checked) => {
    this.setState({ delivery_charge_included: checked }, () => {
      console.log(this.state.delivery_charge_included);
    });
  };

  setTime = (value, dateString) => {
    this.setState(
      {
        delivery_date_time: moment(value).format("x"),
        delivery_date_time_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.delivery_date_time);
        console.log("Selected value: ", this.state.delivery_date_time_view);
      }
    );
  };

  setReceivedAmount = (evt) => {
    evt.preventDefault();
    this.setState({ received_amount: evt.target.value }, () => {
      console.log(this.state.received_amount);
    });
  };

  updateOrderStatus = () => {
    let data = {
      task_id: this.state.task_id,
      delivery: this.state.delivery,
      delivered: this.state.delivered,
      rescheduled: this.state.rescheduled,
      // received: this.state.received,
      delivery_time_flexible: this.state.delivery_time_flexible,
      delivery_charge_included: this.state.delivery_charge_included,
      paid: this.state.paid,
      settled: this.state.settled,
      cancelled: this.state.cancelled,
      received_amount: Number(this.state.received_amount),
      delivery_date_time: this.state.delivery_date_time,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_ORDER_WITH_PROMOTION}${this.state.order_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          this.getOrderDetails();
          console.log("response from update order status", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from update order status", error);
        });
    });
  };

  // added = () => {
  //   if (this.state.addedData.length === 0) {
  //     swal.fire("Info", "Please Select an Item!", "info");
  //   } else {
  //     let data = {
  //       items: this.state.addedData
  //     };
  //     console.log(data);
  //     GET_ACCESS_TOKEN().then(token => {
  //       let url = `${ORDER_PRODUCT_STATUS_UPDATE}${this.state.order_id}?access_token=${token}`;
  //       axios({
  //         method: "put",
  //         url: url,
  //         data: data,
  //         headers: {
  //           "content-type": "application/x-www-form-urlencoded"
  //         }
  //       })
  //         .then(response => {
  //           swal.fire("Success", response.data.message, "success");
  //           if (response.data.message === "Data successfully updated!") {
  //             this.setState({ products: response.data.data.items });
  //           }
  //           this.getOrderDetails();
  //           console.log("response from update order product status", response);
  //         })
  //         .catch(error => {
  //           swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //           console.log("error from update order product status", error);
  //         });
  //     });
  //   }
  // };

  // qcd = () => {
  //   if (this.state.qcData.length === 0) {
  //     swal.fire("Info", "Please Select an Item!", "info");
  //   } else {
  //     let data = {
  //       items: this.state.qcData
  //     };
  //     console.log(data);
  //     GET_ACCESS_TOKEN().then(token => {
  //       let url = `${ORDER_PRODUCT_STATUS_UPDATE}${this.state.order_id}?access_token=${token}`;
  //       axios({
  //         method: "put",
  //         url: url,
  //         data: data,
  //         headers: {
  //           "content-type": "application/x-www-form-urlencoded"
  //         }
  //       })
  //         .then(response => {
  //           swal.fire("Success", response.data.message, "success");
  //           if (response.data.message === "Data successfully updated!") {
  //             this.setState({ products: response.data.data.items });
  //           }
  //           this.getOrderDetails();
  //           console.log("response from update order product status", response);
  //         })
  //         .catch(error => {
  //           swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //           console.log("error from update order product status", error);
  //         });
  //     });
  //   }
  // };

  // delivered = () => {
  //   if (this.state.deliveryData.length === 0) {
  //     swal.fire("Info", "Please Select an Item!", "info");
  //   } else {
  //     let data = {
  //       items: this.state.deliveryData
  //     };
  //     console.log(data);
  //     GET_ACCESS_TOKEN().then(token => {
  //       let url = `${ORDER_PRODUCT_STATUS_UPDATE}${this.state.order_id}?access_token=${token}`;
  //       axios({
  //         method: "put",
  //         url: url,
  //         data: data,
  //         headers: {
  //           "content-type": "application/x-www-form-urlencoded"
  //         }
  //       })
  //         .then(response => {
  //           swal.fire("Success", response.data.message, "success");
  //           this.getOrderDetails();
  //           console.log("response from update order product status", response);
  //         })
  //         .catch(error => {
  //           swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //           console.log("error from update order product status", error);
  //         });
  //     });
  //   }
  // };

  // uploadAddedImage = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const img = new FormData();

  //     img.append("imgFile", this.state.added_image_file);

  //     console.log("in upload", img);

  //     axios({
  //       method: "put",
  //       url: `${ORG_IMAGE_UPLOAD}order_item_added?access_token=${token}`,
  //       data: img,
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //       .then(response => {
  //         swal.fire("Info", response.data.message, "info");
  //         if (response.data.message === "Image successfully uploaded!") {
  //           this.setState({
  //             added_image_url: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`
  //           });
  //         }
  //         console.log("response from added image api", response);
  //       })
  //       .catch(error => {
  //         swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //         console.log("Error from added image upload", error);
  //       });
  //   });
  // };
  // uploadQCImage = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const img = new FormData();

  //     img.append("imgFile", this.state.qc_image_file);

  //     console.log("in upload", img);

  //     axios({
  //       method: "put",
  //       url: `${ORG_IMAGE_UPLOAD}order_item_qc?access_token=${token}`,
  //       data: img,
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //       .then(response => {
  //         swal.fire("Info", response.data.message, "info");
  //         if (response.data.message === "Image successfully uploaded!") {
  //           this.setState({
  //             qc_image_url: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`
  //           });
  //         }
  //         console.log("response from qc image api", response);
  //       })
  //       .catch(error => {
  //         swal.fire("Error",  error?.response?.data?.message??error.message, "error");
  //         console.log("Error from qc image upload", error);
  //       });
  //   });
  // };

  addTo = (record) => {
    let addedArray = [];
    addedArray.push(record.item_id);
    this.setState(
      {
        addedData: [...this.state.addedData, ...addedArray],
      },
      () => {
        console.log("added data", this.state.addedData);
      }
    );
  };

  removefrom = (record) => {
    let addedFiltered = this.state.addedData.filter(
      (m) => m !== record.item_id
    );
    this.setState({ addedData: addedFiltered }, () => {
      console.log("added data", this.state.addedData);
    });
  };

  printTag = (item_id, item) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_PRODUCT_TAG}?order_id=${this.state.order_id}&item_ids=${item_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then((response) => {
          console.log("response from order tag print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch((error) => {
          console.log("error from order tag print list fmt", error);
        });
    });
  };

  printMultipleTags = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_PRODUCT_TAG}?order_id=${this.state.order_id}&item_ids=${this.state.addedData}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then((response) => {
          console.log(
            "response from order tag print multiple list fmt",
            response
          );
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch((error) => {
          console.log("error from order tag print multiple list fmt", error);
        });
    });
  };

  orderPrintTag = (item_id, item) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_TAG_PRINT}?order_id=${this.state.order_id}&org_id=${this.state.org_id}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from order print pdf ", blob);

          FileSaver.saveAs(
            blob,
            `detailed_order_pos_invoice_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch((error) => {
          console.log("error from order print pdf", error);
        });
    });
  };

  orderInvoiceTag = (item_id, item) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_INVOICE_PRINT}?order_id=${this.state.order_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then((response) => {
          console.log(
            "response from only order invoice print list fmt",
            response
          );
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.order_tag_invoice_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch((error) => {
          console.log("error from only order invoice print list fmt", error);
        });
    });
  };

  updateCustomOrderData = (checked) => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "order",
        custom_fields: this.state.custom_field_data,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.order_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Success", response.data.message, "success");
            if (response.data.message === "Data successfully updated!") {
              this.getOrderDetails();
            }
            console.log(
              "response from custom field order data update ",
              response
            );
          })
          .catch((error) => {
            this.getOrderDetails();
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from custom field order data update ", error);
          });
      });
    }
  };

  handleStart = (value) => {
    this.setState({ query: value, dataMap: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        setTimeout(() => {
          axios.get(GEO_CODING_API(this.state.query)).then((response) => {
            console.log(response);
            const data = response.data.features.map((res) => ({
              id: res.properties.place_id,
              value: res.properties.display_name,
            }));
            this.setState({ dataMap: data, fetch: false });
            console.log(response.data.features);
          });
        }, 3000);
      }
    });
  };

  handleStartChange = (value) => {
    this.setState({ value, dataMap: [], fetch: false });

    return this.setState({ end_loc_name: value.label }, () => {
      axios.get(GEO_CODING_API(this.state.end_loc_name)).then((response) => {
        this.setState(
          {
            end_location_lat: response.data.features[0].geometry.coordinates[1],
            end_location_lon: response.data.features[0].geometry.coordinates[0],
            center_lon: response.data.features[0].geometry.coordinates[0],
            center_lat: response.data.features[0].geometry.coordinates[1],
          },
          () => {
            this.setState({
              end_loc: {
                coordinates: [
                  this.state.end_location_lon,
                  this.state.end_location_lat,
                ],
              },
            });
          }
        );
      });
    });
  };

  updatePositionStart = (evt) => {
    console.log(evt.target.getLatLng());

    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        this.setState({
          end_loc: {
            coordinates: [
              this.state.end_location_lon,
              this.state.end_location_lat,
            ],
          },
        });
        axios
          .get(
            REVERSE_GEO(
              this.state.end_location_lat,
              this.state.end_location_lon
            )
          )
          .then((response) => {
            let name = response.data.features[0].properties.display_name;
            let id = response.data.features[0].properties.place_id;
            this.setState({
              end_loc_name: name,
              value: { key: id, label: name },
            });
          });
      }
    );
  };

  updateEndLocation = () => {
    const data = {
      end_loc: {
        coordinates: [this.state.end_location_lon, this.state.end_location_lat],
      },
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ORDER_LOCATION_UPDATE}${this.state.order_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Data successfully updated!") {
            this.getOrderDetails();
          }
          console.log("response from order location update ", response);
        })
        .catch((error) => {
          this.getOrderDetails();
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from order location update ", error);
        });
    });
  };

  getRouteHistory = (e, task) => {
    console.log("checkbox", e.target.checked);
    console.log("task", task);
    if (e.target.checked) {
      let date = new Date();
      let time = date.getTime();
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${USER_ROUTE}?limit=1000&ts=${time}&search_start_time=${true}&search_end_time=${true}&start_time=${
          task.started_on.$date
        }&end_time=${task.completed_on.$date}&search_user=${
          task.assigned_to
        }&access_token=${token}`;

        axios({
          method: "get",
          url: url,
        })
          .then((response) => {
            console.log("response from user route api", response);
            if (response.data.data.length === 0) {
              swal.fire("Info", "No Route Data Found", "info");
            } else {
              this.setState({ routeData: response.data.data });
            }
          })
          .catch((error) => {
            console.log("error from user route api", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const { custom_field_data } = this.state;
    const { custom_form_field_post_object } = this.state;
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          onBack={() => this.props.history.goBack()}
          title={`Order Number : ${this.state.order_number}`}
        />
        {this.state.assigned_to !== "" &&
        this.state.assigned_to === GET_USER_LOCAL()._id.$oid ? (
          <Card
            style={{
              margin: 10,
              // boxShadow:
              //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Delivery"
                    unCheckedChildren="Delivery"
                    checked={this.state.delivery}
                    onChange={this.handleDeliverychange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Delivered"
                    unCheckedChildren="Delivered"
                    checked={this.state.delivered}
                    onChange={this.handleDelieveredchange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Rescheduled"
                    unCheckedChildren="Rescheduled"
                    checked={this.state.rescheduled}
                    onChange={this.handleRescheduledchange}
                    disabled
                  />
                </div>
                {/* <div
                  style={{
                    marginRight: 5
                  }}
                >
                  <Switch
                    checkedChildren="Received"
                    unCheckedChildren="Received"
                    checked={this.state.received}
                    onChange={this.handleReceivedchange}
                    disabled
                  />
                </div> */}
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Paid"
                    unCheckedChildren="Paid"
                    checked={this.state.paid}
                    onChange={this.handlePaidchange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Settled"
                    unCheckedChildren="Settled"
                    checked={this.state.settled}
                    onChange={this.handleSettledchange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Flexible Time "
                    unCheckedChildren="Flexible Time "
                    checked={this.state.delivery_time_flexible}
                    onChange={this.handleDeliveryFlexiblechange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Included Charge "
                    unCheckedChildren="Included Charge "
                    checked={this.state.delivery_charge_included}
                    onChange={this.handleDeliveryChargechange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5,
                  }}
                >
                  <Switch
                    checkedChildren="Cancelled"
                    unCheckedChildren="Cancelled"
                    checked={this.state.cancelled}
                    onChange={this.handleCancelledchange}
                  />
                </div>
              </div>
            </Card>
            <Card style={{ marginTop: 10 }}>
              <div
                style={{
                  marginRight: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text>Delivery On</Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={moment(this.state.delivery_date_time_view)}
                    onChange={this.setTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Card>
            <Card style={{ marginTop: 10 }}>
              <div
                style={{
                  marginRight: 5,
                }}
              >
                <Text>Received Amount</Text>
                <Input
                  placeholder="Received Amount"
                  type="number"
                  value={this.state.received_amount}
                  onChange={this.setReceivedAmount}
                />
              </div>
            </Card>

            <Button
              onClick={this.updateOrderStatus}
              className="login-form-button"
              style={{ marginTop: 10 }}
            >
              Update Status
            </Button>
          </Card>
        ) : null}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Card style={{ display: "flex", flexBasis: "100%" }}>
              <p >
                <Text style={{ fontWeight: "bold" }}>Order Name</Text>:{" "}
                {this.state.name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Order Type</Text>:{" "}
                {this.state.order_type}
              </p>
              {this.state.order_type === "return_order" ? (
                <p >
                  <Text style={{ fontWeight: "bold" }}>
                    Linked Order Number
                  </Text>
                  : {this.state.linked_order_number}
                </p>
              ) : null}

              <p >
                <Text style={{ fontWeight: "bold" }}>Details</Text>:{" "}
                {this.state.details}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Sub Organization Name
                </Text>
                : {this.state.sub_org_name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Sub Organization Type
                </Text>
                : {this.state.sub_org_type_name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Inventory Name</Text>:{" "}
                {this.state.inventory_name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Created By</Text>:{" "}
                {this.state.created_by_name}
              </p>
              {this.state.order_type === "return_order" ? null : (
                <>
                  <p >
                    <Text style={{ fontWeight: "bold" }}>
                      Pickup Place Name
                    </Text>
                    : {this.state.start_entity_name}
                  </p>
                  <p >
                    <Text style={{ fontWeight: "bold" }}>
                      Pickup Place Address
                    </Text>
                    :{this.state.start_entity_address}
                  </p>
                </>
              )}

              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Shop/Dealer/Depo Name
                </Text>
                : {this.state.end_entity_name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Shop/Dealer/Depo Address
                </Text>
                : {`${this.state.end_loc_name} `}
                {this.state.end_loc.coordinates ? null : (
                  <Button
                    type="link"
                    onClick={() => this.setState({ showMap: true })}
                  >
                    <Icon type="warning" style={{ color: "yellow" }} />
                    Add Coordinates
                  </Button>
                )}
              </p>
              {/* <p >
                <Text style={{ fontWeight: "bold" }}>Customer Name</Text>:{" "}
                {this.state.placed_by_name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Customer Mobile</Text>:{" "}
                {this.state.placed_by_mobile_number}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Order Date</Text>:{" "}
                {timeConverter(this.state.placed_on)}
              </p> */}

              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Client Contact Person
                </Text>
                : {this.state.drop_contact_name}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Client Contact Number
                </Text>
                : {this.state.drop_contact_no}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Client Contact Email</Text>
                : {this.state.drop_contact_email}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Delivery date</Text>:{" "}
                {timeConverter(this.state.delivery_date_time)}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Assigned Organization
                </Text>
                : {this.state.assigned_org_name}
              </p>
              <div style={{ display: "flex" }}>
                {this.props.userProfileData.order_and_delivery.order
                  .download_order_detailed_to_order &&
                this.state.order_type === "sales" ? (
                  <Button
                    onClick={this.orderPrintTag}
                    className="login-form-button"
                    style={{ marginTop: 10 }}
                  >
                    Print Order
                  </Button>
                ) : null}
                {this.state.order_type === "sales" ? (
                  <Button
                    onClick={this.orderInvoiceTag}
                    className="login-form-button"
                    style={{ marginTop: 10 }}
                  >
                    Print Invoice
                  </Button>
                ) : null}
              </div>
            </Card>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Card style={{ display: "flex", flexBasis: "100%" }}>
              <Text style={{ margin: 5 }}>Select All</Text>
              <button
                style={{
                  height: 15,
                  width: 14,
                  border: "1px solid grey",
                  backgroundColor:
                    this.state.addedData.length > 0 ? "#1E88E5" : "white",
                }}
                onClick={() => {
                  if (this.state.addedData.length > 0) {
                    this.setState({ addedData: [] }, () => {
                      console.log("all data removed", this.state.addedData);
                    });
                  } else {
                    this.state.products.forEach((product) => {
                      this.state.addedData.push(product.item_id);
                      this.setState({ addedData: this.state.addedData }, () => {
                        console.log("all data added", this.state.addedData);
                      });
                      return null;
                    });
                  }
                }}
              ></button>
              {this.state.addedData.length > 0 &&
              this.state.order_type === "sales" ? (
                <Button
                  style={{
                    margin: 5,
                    color: "white",
                    backgroundColor: "#1A3337",
                  }}
                  htmlType="submit"
                  onClick={this.printMultipleTags}
                >
                  Print Selected Tags
                </Button>
              ) : null}
              <Table
                dataSource={this.state.products}
                pagination={false}
                rowKey={() => randomId(10)}
                size="small"
                bordered
              >
                <Column
                  title=""
                  key="item_id"
                  render={(text, record) => (
                    <button
                      style={{
                        height: 15,
                        width: 14,
                        border: "1px solid grey",
                        backgroundColor: this.state.addedData.some(
                          (d) => d === record.item_id
                        )
                          ? "#1E88E5"
                          : "white",
                      }}
                      onClick={() => {
                        if (
                          this.state.addedData.some((d) => d === record.item_id)
                        ) {
                          this.removefrom(record);
                        } else {
                          this.addTo(record);
                        }
                      }}
                    ></button>
                  )}
                />
                <Column title="Name" dataIndex="item" key="item" />
                <Column title="Unit" dataIndex="unit" key="unit" />
                <Column
                  title="Unit Selling Price"
                  dataIndex="unit_selling_price"
                  key="unit_selling_price"
                />
                <Column
                  title="Discount Type"
                  render={(text, record) => (
                    <span>
                      <Text>{record.item_discount_type ?? ""}</Text>
                    </span>
                  )}
                />
                <Column
                  title="Per Item Discount"
                  render={(text, record) => (
                    <span>
                      <Text>
                        {record.item_discount_type === "fixed_amount"
                          ? record.per_item_discount
                          : record.item_discount_type === "percentage"
                          ? `${record.per_item_discount}%`
                          : 0}
                      </Text>
                    </span>
                  )}
                />

                <Column title="Quantity" dataIndex="qty" key="qty" />
                <Column
                  title="Free Quantity"
                  render={(text, record) => (
                    <span>
                      <Text>
                        {record.free_qty??0}
                      </Text>
                    </span>
                  )}
                />
                <Column title="Price" dataIndex="price" key="price" />
                {/* <Column title="Discount" dataIndex="discount" key="discount" /> */}
                <Column
                  title="Discount"
                  render={(text, record) => (
                    <span>
                      <Text>{record.item_discount ?? 0}</Text>
                    </span>
                  )}
                />
                <Column
                  title="Discounted Price"
                  render={(text, record) => (
                    <span>
                      <Text>{record.item_discounted_price ?? 0}</Text>
                    </span>
                  )}
                />

                <Column
                  title="Status"
                  render={(text, record) => (
                    <span>
                      {record.item_changed ? (
                        <Text style={{ color: "red" }}>Cancelled</Text>
                      ) : (
                        <Text style={{ color: "green" }}>Active</Text>
                      )}
                    </span>
                  )}
                />
                {/* <Column
                  title="Added Image (Max Size 90 KB)"
                  render={(text, record) => (
                    <span>
                      <input
                        type="file"
                        onChange={e => {
                          if (this.state.added_image_url !== "") {
                            swal.fire(
                              "Warning",
                              "select the previous data before uploading next added image",
                              "warning"
                            );
                          } else {
                            console.log(
                              "Added Image file inside",
                              this.state.added_image_file
                            );
                            this.setState(
                              {
                                added_image_file: e.target.files[0]
                              },
                              () => {
                                this.uploadAddedImage();
                              }
                            );
                          }
                        }}
                      />
                    </span>
                  )}
                /> */}
                <Column
                  title="Added Image"
                  render={(text, record) => (
                    <span>
                      {record.added_image_url ? (
                        <img
                          src={record?.added_image_url}
                          alt="avatar"
                          style={{ width: 100, height: 100 }}
                          onClick={() => {
                            window.open(record?.added_image_url, "_blank");
                          }}
                        />
                      ) : null}
                    </span>
                  )}
                />
                {/* <Column
                  title="QC Image (Max Size 90 KB)"
                  render={(text, record) => (
                    <span>
                      <input
                        type="file"
                        onChange={e => {
                          if (this.state.qc_image_url !== "") {
                            swal.fire(
                              "Warning",
                              "select the previous data before uploading next qc image",
                              "warning"
                            );
                          } else {
                            console.log(
                              "QC Image file inside",
                              this.state.qc_image_file
                            );
                            this.setState(
                              {
                                qc_image_file: e.target.files[0]
                              },
                              () => {
                                this.uploadQCImage();
                              }
                            );
                          }
                        }}
                      />
                    </span>
                  )}
                /> */}
                <Column
                  title="QC Image"
                  render={(text, record) => (
                    <span>
                      {record.qc_image_url ? (
                        <img
                          src={record?.qc_image_url}
                          alt="avatar"
                          style={{ width: 100, height: 100 }}
                          onClick={() => {
                            window.open(record?.qc_image_url, "_blank");
                          }}
                        />
                      ) : null}
                    </span>
                  )}
                />
                <Column
                  title="Added"
                  render={(text, record) => (
                    <span style={{ marginLeft: 15 }}>
                      {record.added ? (
                        <Icon type="check" style={{ color: "green" }} />
                      ) : (
                        <Icon type="close" style={{ color: "red" }} />
                      )}
                    </span>
                  )}
                />
                <Column
                  title="QC"
                  render={(text, record) => (
                    <span style={{ marginLeft: 8 }}>
                      {record.qc ? (
                        <Icon type="check" style={{ color: "green" }} />
                      ) : (
                        <Icon type="close" style={{ color: "red" }} />
                      )}
                    </span>
                  )}
                />
                <Column
                  title="Delivered"
                  render={(text, record) => (
                    <span style={{ marginLeft: 15 }}>
                      {record.delivery_confirmed ? (
                        <Icon type="check" style={{ color: "green" }} />
                      ) : (
                        <Icon type="close" style={{ color: "red" }} />
                      )}
                    </span>
                  )}
                />
                {this.props.userProfileData.order_and_delivery.order
                  .order_detail.order_product_status_update &&
                this.state.order_type === "sales" ? (
                  <Column
                    title="Action"
                    render={(text, record) => (
                      <span>
                        <Button
                          type="link"
                          style={{ color: "green" }}
                          onClick={() => {
                            window.open(
                              `https://web.conveyance.app/OrderItemDetails/${this.state.order_id}/${record.item_id}`
                            );
                          }}
                        >
                          Update Status
                        </Button>
                      </span>
                    )}
                  />
                ) : null}
                {/* {this.props.userProfileData.data_level.access_all_data ||
                this.props.userProfileData.data_level.access_suborg_data ? (
                  <Column
                    title="Action"
                    render={(text, record) => (
                      <span>
                        <Button
                          type="link"
                          style={{ color: "green" }}
                          onClick={() => {
                            this.setState({display_return_Item_modal:true,return_item_id:record.item_id})
                          }}
                        >
                          Return to Invenotry
                        </Button>
                      </span>
                    )}
                  />
                ) : null} */}
                {this.state.order_type === "sales" ? (
                  <Column
                    title="Action"
                    render={(text, record) => (
                      <span>
                        <Button
                          type="link"
                          onClick={() =>
                            this.printTag(record.item_id, record.item)
                          }
                        >
                          Print Tag
                        </Button>
                      </span>
                    )}
                  />
                ) : null}
              </Table>
              {/* <div
                style={{ display: "flex", flexDirection: "row", padding: 20 }}
              >
                <Button
                  style={{ margin: 5 }}
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.added}
                >
                  Save
                </Button>
                <Button
                  style={{ margin: 5 }}
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.qcd}
                >
                  Quality Checked
                </Button>
                <Button
                  style={{ margin: 5 }}
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.delivered}
                >
                  Delivered
                </Button>
              </div> */}
            </Card>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <Card className="view">
              
              <p >
                <Text style={{ fontWeight: "bold" }}>Parcel Weight</Text>:{" "}
                {this.state.parcel_wght} KG
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Parcel Width</Text>:{" "}
                {this.state.pw} Feet
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Parcel Height</Text>:{" "}
                {this.state.ph} Feet
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Parcel Length</Text>:{" "}
                {this.state.pl} Feet
              </p>
            </Card> */}
            <Card style={{ display: "flex", flexBasis: "50%" }}>
              <p >
                <Text style={{ fontWeight: "bold" }}>Order Status</Text>:{" "}
                {this.state.order_status}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Total</Text>:{" "}
                {this.state.total_price}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Delivery Charge</Text>:{" "}
                {this.state.delivery_charge}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Other Charges</Text>:{" "}
                {this.state.other_charges}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Other Discount Type</Text>:{" "}
                {this.state.other_discount_type}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Other Discount</Text>:{" "}
                {this.state.other_discount_type === "fixed_amount"
                  ? this.state.other_discount
                  : this.state.other_discount_type === "percentage"
                  ? `${this.state.other_discount}%`
                  : 0}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Discount on Products</Text>
                : {this.state.total_discount_on_product}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Total Discount</Text>:{" "}
                {this.state.total_discount}
              </p>

              <p >
                <Text style={{ fontWeight: "bold" }}>
                  {this.state.order_type === "return_order"
                    ? "Return Amount"
                    : "Bill Amount"}
                </Text>
                : {this.state.bill_amount}
              </p>
              

              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Total Received Amount
                </Text>
                : {this.state.total_received_amount}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Due</Text>:{" "}
                {this.state.due_amount}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Refundable Amount</Text>:{" "}
                {this.state.refundable_amount}
              </p>

              <p >
                <Text style={{ fontWeight: "bold" }}>
                  Total Refunded Amount
                </Text>
                : {this.state.total_refunded_amount}
              </p>
            </Card>
            <Card style={{ display: "flex", flexBasis: "50%" }}>
              {/* <p >
                <Text style={{ fontWeight: "bold" }}>Delivery</Text>:{" "}
                {this.state.delivery.toString()}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Delivered</Text>:{" "}
                {this.state.delivered.toString()}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Paid</Text>:{" "}
                {this.state.paid.toString()}
              </p>
              <p >
                <Text style={{ fontWeight: "bold" }}>Payment Method</Text>:{" "}
                {this.state.payment_method}
              </p> */}
              {/* <p >
                <Text style={{ fontWeight: "bold" }}>Received</Text>:{" "}
                {this.state.received.toString()}
              </p> */}
              {/* <p >
                <Text style={{ fontWeight: "bold" }}>Rescheduled</Text>:{" "}
                {this.state.rescheduled.toString()}
              </p> */}
              <p >
                <Text style={{ fontWeight: "bold" }}>Payment Details:</Text>
              </p>
              {this.state.received_history.length > 0 ? (
                <Card style={{ marginBottom: 10 }}>
                  <p >
                    <Text style={{ fontWeight: "bold" }}>Received History</Text>
                  </p>
                  <Table
                    dataSource={this.state.received_history}
                    pagination={false}
                    rowKey={randomId(10)}
                    size="small"
                    bordered
                  >
                    <Column
                      title="Received Amount"
                      dataIndex="received_amount"
                      key="received_amount"
                    />
                    <Column
                      title="Received Method"
                      dataIndex="received_method"
                      key="received_method"
                    />
                    <Column
                      title="Received Date"
                      key="received_date.$date"
                      render={(text, record) => (
                        <span>
                          {record.received_date.$date
                            ? timeConverter(record.received_date.$date)
                            : ""}
                        </span>
                      )}
                    />
                    <Column
                      title="Received Entry Date"
                      key="received_entry_date.$date"
                      render={(text, record) => (
                        <span>
                          {record.received_entry_date.$date
                            ? timeConverter(record.received_entry_date.$date)
                            : ""}
                        </span>
                      )}
                    />
                    />
                  </Table>
                </Card>
              ) : null}
              {this.state.refunded_history.length > 0 ? (
                <Card>
                  <p >
                    <Text style={{ fontWeight: "bold" }}>Refunded History</Text>
                  </p>
                  <Table
                    dataSource={this.state.refunded_history}
                    pagination={false}
                    rowKey={randomId(10)}
                    size="small"
                    bordered
                  >
                    <Column
                      title="Refunded Amount"
                      dataIndex="refunded_amount"
                      key="refunded_amount"
                    />
                    <Column
                      title="Refunded Method"
                      dataIndex="refunded_method"
                      key="refunded_method"
                    />
                    <Column
                      title="Refunded Date"
                      key="refunded_date.$date"
                      render={(text, record) => (
                        <span>
                          {record.refunded_date.$date
                            ? timeConverter(record.refunded_date.$date)
                            : ""}
                        </span>
                      )}
                    />
                    <Column
                      title="Refunded Entry Date"
                      key="refunded_entry_date.$date"
                      render={(text, record) => (
                        <span>
                          {record.refunded_entry_date.$date
                            ? timeConverter(record.refunded_entry_date.$date)
                            : ""}
                        </span>
                      )}
                    />
                  </Table>
                </Card>
              ) : null}
            </Card>
          </div>
        </div>
        <Card
          style={{
            marginTop: 10,
            width: "100%",
            // boxShadow:
            //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Select Custom Form</Text>
              <Select
                style={{ width: 200 }}
                value={this.state.custom_form_id}
                onChange={(value) =>
                  this.setState({ custom_form_id: value }, () =>
                    this.getCustomFormDataWithId()
                  )
                }
              >
                {this.state.customFormData.map((r) => {
                  return <Option key={r._id.$oid}>{r.title}</Option>;
                })}
              </Select>
            </div>
            {this.state.custom_form_fields.length > 0 ? (
              <Button
                style={{ marginLeft: 10, marginTop: 21 }}
                type="primary"
                onClick={() => this.setState({ show_custom_form_modal: true })}
              >
                View Form
              </Button>
            ) : null}
          </div>
        </Card>
        {this.state.custom_form_submitted_data.length > 0 &&
        this.props.userProfileData.custom_form.custom_form_data ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              // boxShadow:
              //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <>
              <>
                <table
                  style={{
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                    paddingTop: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <thead>
                    <tr>
                      {Object.keys(
                        this.state.custom_form_submitted_data[0].submitted_data
                      ).map((data, index) => (
                        <th
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          {data}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.custom_form_submitted_data.map((el, index) => (
                      <tr>
                        {Object.keys(el.submitted_data).map((el2, index) => (
                          <td
                            style={{
                              border: "1px solid grey",
                              borderCollapse: "collapse",
                              paddingTop: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                            key={index}
                          >
                            {Array.isArray(el.submitted_data[el2]) ? (
                              <div>
                                {el.submitted_data[el2].map((i, index) => {
                                  if (isValidURL(i)) {
                                    return (
                                      <img
                                        src={i}
                                        alt=""
                                        style={{
                                          margin: 10,
                                          height: 200,
                                          width: 200,
                                        }}
                                        key={index}
                                      />
                                    );
                                  } else {
                                    return (
                                      <Text
                                        className="task-view-text"
                                        style={{ fontSize: 15 }}
                                        key={index}
                                      >
                                        {`${i},`}
                                      </Text>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <Text
                                className="task-view-text"
                                style={{ fontSize: 15 }}
                                key={index}
                              >
                                {el.submitted_data[el2]}
                              </Text>
                            )}
                          </td>
                        ))}
                        <td
                          style={{
                            border: "1px solid grey",
                            borderCollapse: "collapse",
                            paddingTop: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                          key={index}
                        >
                          <Button
                            type="link"
                            onClick={() =>
                              this.setState({
                                document_id: el._id.$oid,
                                view_submitted_data_modal: true,
                              })
                            }
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            </>
          </Card>
        ) : null}
        {Object.keys(this.state.getCustomFieldData).length !== 0 &&
        this.props.userProfileData.order_and_delivery.order.order_detail
          .view_custom_field_data_related_to_order ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              // boxShadow:
              //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Custom Field Data"
            />
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.completed_tasks.length > 0 &&
        this.state.routeData.length > 0 ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              // boxShadow:
              //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Map
              center={[this.state.center_lat, this.state.center_lon]}
              zoom={this.state.zoom}
              style={{ height: 800 }}
              onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                  VERSION: "3.49.2",
                }}
                type={"roadmap"}
              />

              {this.state.routeData.map((route) => (
                <Marker
                  key={route._id.$oid}
                  position={[
                    route.loc.coordinates[1],
                    route.loc.coordinates[0],
                  ]}
                  icon={red}
                >
                  <Popup>
                    <span>{timeConverter(route.timestamp.$date)}</span>
                  </Popup>
                </Marker>
              ))}
              <Polyline
                color="purple"
                positions={[
                  this.state.routeData.map((m) => [
                    m.loc.coordinates[1],
                    m.loc.coordinates[0],
                  ]),
                ]}
              />
            </Map>
          </Card>
        ) : null}

        {this.state.completed_tasks.length > 0 && GET_USER_LOCAL().org_admin ? (
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              // boxShadow:
              //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Select Task To View Route History"
            />
            {this.state.completed_tasks.map((task) => (
              <Checkbox
                style={{ marginTop: 10 }}
                key={task.task_id}
                onChange={(e) => this.getRouteHistory(e, task)}
              >{`Task Code : ${task.task_code}-Assigned User:${task.assigned_to_user_name}`}</Checkbox>
            ))}
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 &&
        this.props.userProfileData.order_and_delivery.order.order_detail
          .update_custom_field_data_related_to_order ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 10,
                width: "100%",
                // boxShadow:
                //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)",
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>{`${f.label} (Type : ${f._type}) : `}</Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={(e) => {
                            let obj = {
                              [f.key_name]: e.target.value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={(checkedValues) => {
                            let obj = {
                              [f.key_name]: checkedValues,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={(evt) => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value,
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then((token) => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                  .then((response) => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map((res) => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp,
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          ),
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch((error) => {
                                    swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value),
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value,
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText,
                                  ],
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle,
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      ),
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white",
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: [],
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomOrderData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}
        <Modal
          title="Order Custom Form"
          visible={this.state.show_custom_form_modal}
          onCancel={() => this.setState({ show_custom_form_modal: false })}
          footer={null}
        >
          <Form>
            {this.state.custom_form_fields.map((f, index) => (
              <Form.Item key={f.name}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>{`${f.name} : `}</Text>
                  {f.field_type === "text" ? (
                    <Input
                      type={f.input_type}
                      onChange={(evt) => {
                        if (f.input_type === "text") {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          let obj = {
                            [f.name]: Number(evt.target.value),
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form text field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : f.field_type === "dropdown" ? (
                    <Select
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from dropdown input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item) => {
                        return <Option key={item}>{item}</Option>;
                      })}
                    </Select>
                  ) : f.field_type === "checkbox" ? (
                    <Checkbox.Group
                      options={f.options}
                      onChange={(checkedValues) => {
                        let obj = {
                          [f.name]: checkedValues,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from checkbox input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "radio" ? (
                    <Radio.Group
                      onChange={(e) => {
                        let obj = {
                          [f.name]: e.target.value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item, index) => {
                        return (
                          <Radio key={index} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : f.field_type === "image_upload" ? (
                    <Input
                      type="file"
                      multiple={true}
                      name={"file[]"}
                      onChange={(evt) => {
                        console.log(evt.target.files);
                        const { files } = evt.target;
                        GET_ACCESS_TOKEN().then((token) => {
                          const img = new FormData();

                          for (let i = 0; i < files.length; i++) {
                            img.append(`imgFile`, files[i]);
                          }

                          console.log("in upload", img);

                          axios({
                            method: "put",
                            url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                            data: img,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                            .then((response) => {
                              swal.fire("Info", response.data.message, "info");
                              if (
                                response.data.message ===
                                "Image successfully uploaded!"
                              ) {
                                let temp = [];
                                response.data.data.map((res) => {
                                  temp.push(
                                    `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                  );
                                  return null;
                                });

                                let customObj = {
                                  [f.name]: temp,
                                };
                                this.setState(
                                  {
                                    custom_form_field_post_object:
                                      Object.assign(
                                        this.state
                                          .custom_form_field_post_object,
                                        customObj
                                      ),
                                  },
                                  () => {
                                    console.log(
                                      "from image input",
                                      this.state.custom_form_field_post_object
                                    );
                                  }
                                );
                              }
                              console.log("response from image api", response);
                            })
                            .catch((error) => {
                              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                              console.log("Error from upload", error);
                            });
                        });
                      }}
                    />
                  ) : f.field_type === "numeric_slider" ? (
                    <Slider
                      min={Number(f.min_value)}
                      max={Number(f.max_value)}
                      defaultValue={0}
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "conditional" ? (
                    <Input
                      disabled={
                        custom_form_field_post_object.hasOwnProperty(
                          f.condition_field
                        ) &&
                        custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                          ? false
                          : true
                      }
                      onChange={(evt) => {
                        if (
                          custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                        ) {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data for cnditional field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateCustomFormData}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Edit Custom Form Data"
          visible={this.state.view_submitted_data_modal}
          onCancel={() => this.setState({ view_submitted_data_modal: false })}
          footer={null}
        >
          <Form>
            {this.state.custom_form_fields.map((f, index) => (
              <Form.Item key={f.name}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>{`${f.name} : `}</Text>
                  {f.field_type === "text" ? (
                    <Input
                      type={f.input_type}
                      onChange={(evt) => {
                        if (f.input_type === "text") {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          let obj = {
                            [f.name]: Number(evt.target.value),
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form text field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        }
                      }}
                    />
                  ) : f.field_type === "dropdown" ? (
                    <Select
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from dropdown input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item) => {
                        return <Option key={item}>{item}</Option>;
                      })}
                    </Select>
                  ) : f.field_type === "checkbox" ? (
                    <Checkbox.Group
                      options={f.options}
                      onChange={(checkedValues) => {
                        let obj = {
                          [f.name]: checkedValues,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from checkbox input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "radio" ? (
                    <Radio.Group
                      onChange={(e) => {
                        let obj = {
                          [f.name]: e.target.value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    >
                      {f.options.map((item, index) => {
                        return (
                          <Radio key={index} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  ) : f.field_type === "image_upload" ? (
                    <Input
                      type="file"
                      multiple={true}
                      name={"file[]"}
                      onChange={(evt) => {
                        console.log(evt.target.files);
                        const { files } = evt.target;
                        GET_ACCESS_TOKEN().then((token) => {
                          const img = new FormData();

                          for (let i = 0; i < files.length; i++) {
                            img.append(`imgFile`, files[i]);
                          }

                          console.log("in upload", img);

                          axios({
                            method: "put",
                            url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                            data: img,
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                            .then((response) => {
                              swal.fire("Info", response.data.message, "info");
                              if (
                                response.data.message ===
                                "Image successfully uploaded!"
                              ) {
                                let temp = [];
                                response.data.data.map((res) => {
                                  temp.push(
                                    `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                  );
                                  return null;
                                });

                                let customObj = {
                                  [f.name]: temp,
                                };
                                this.setState(
                                  {
                                    custom_form_field_post_object:
                                      Object.assign(
                                        this.state
                                          .custom_form_field_post_object,
                                        customObj
                                      ),
                                  },
                                  () => {
                                    console.log(
                                      "from image input",
                                      this.state.custom_form_field_post_object
                                    );
                                  }
                                );
                              }
                              console.log("response from image api", response);
                            })
                            .catch((error) => {
                              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
                              console.log("Error from upload", error);
                            });
                        });
                      }}
                    />
                  ) : f.field_type === "numeric_slider" ? (
                    <Slider
                      min={Number(f.min_value)}
                      max={Number(f.max_value)}
                      defaultValue={0}
                      onChange={(value) => {
                        let obj = {
                          [f.name]: value,
                        };

                        this.setState(
                          {
                            custom_form_field_post_object: Object.assign(
                              this.state.custom_form_field_post_object,
                              obj
                            ),
                          },
                          () => {
                            console.log(
                              "from radio input",
                              this.state.custom_form_field_post_object
                            );
                          }
                        );
                      }}
                    />
                  ) : f.field_type === "conditional" ? (
                    <Input
                      disabled={
                        custom_form_field_post_object.hasOwnProperty(
                          f.condition_field
                        ) &&
                        custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                          ? false
                          : true
                      }
                      onChange={(evt) => {
                        if (
                          custom_form_field_post_object[f.condition_field] ===
                          f.condition_value
                        ) {
                          let obj = {
                            [f.name]: evt.target.value,
                          };

                          this.setState(
                            {
                              custom_form_field_post_object: Object.assign(
                                this.state.custom_form_field_post_object,
                                obj
                              ),
                            },
                            () => {
                              console.log(
                                "custom form data for cnditional field",
                                this.state.custom_form_field_post_object
                              );
                            }
                          );
                        } else {
                          return;
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.editCustomFormSubmittedData}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Select Location"
          visible={this.state.showMap}
          onOk={() => {
            if (
              this.state.end_location_lat === "" ||
              this.state.end_location_lon === ""
            ) {
              swal.fire("Info", "Please update your end location", "info");
            } else {
              this.setState({ showMap: false }, () => this.updateEndLocation());
            }
          }}
          onCancel={() => this.setState({ showMap: false })}
          width={window.innerWidth - 100}
          closable={true}
        >
          <Form>
            <Form.Item>
              <Text>Search Location</Text>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {this.state.editEnd === true ? (
                  <Input
                    type="text"
                    placeholder="Start Location"
                    value={this.state.end_loc_name}
                    onChange={(evt) =>
                      this.setState({ end_loc_name: evt.target.value })
                    }
                  />
                ) : (
                  <Select
                    showSearch={true}
                    labelInValue
                    value={this.state.value}
                    notFoundContent={fetch ? <Spin size="small" /> : null}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    placeholder="Search Location"
                    onSearch={this.handleStart}
                    onChange={this.handleStartChange}
                  >
                    {this.state.dataMap.map((r) => {
                      return <Option key={r.id}>{r.value}</Option>;
                    })}
                  </Select>
                )}
                {this.state.end_loc_name === "" ? null : (
                  <Tooltip
                    title={
                      this.state.editStart ? "Search Location" : "Edit Location"
                    }
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        this.setState({ editEnd: !this.state.editEnd })
                      }
                    >
                      <Icon type={this.state.editEnd ? "search" : "edit"} />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </Form.Item>
          </Form>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2",
              }}
              type={"roadmap"}
            />

            <Marker
              position={[
                this.state.end_location_lat,
                this.state.end_location_lon,
              ]}
              draggable
              onDragend={this.updatePositionStart}
              icon={green}
            >
              <Popup>
                <span>From :{this.state.end_loc_name}</span>
              </Popup>
            </Marker>
          </Map>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(ViewOrder);
