import React, { Component } from "react";
import { Card, PageHeader, Typography, Select, Button, Table } from "antd";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Polyline, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import axios from "axios";
import swal from "sweetalert2";
import { Spinner } from "../loader";
import moment from "moment";
import FileSaver from "file-saver";

import { USER_ROUTE } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  truncateText,
} from "../../utilities/utilities";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const createNumberedIcon = (number, color) => {
  return L.divIcon({
    html: `<div style="background-color: ${color};color:white; border: 2px solid #1A3337; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: 14px;">
      ${number}
    </div>`,
    className: "",
    iconSize: [35, 35],
    iconAnchor: [15, 15],
  });
};

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

const createArrowIcon = (rotation) =>
  new L.DivIcon({
    html: `
      <div style="
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(${rotation - 96}deg);
      ">
        <svg width="30" height="20" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 35 L25 20 L5 5 Z" fill="#3674B5" stroke="#3674B5" stroke-width="3" stroke-linejoin="round"/>
         
        </svg>
      </div>
    `,
    className: "custom-arrow-icon", // Prevent default Leaflet styles
    iconSize: [28, 28],
    iconAnchor: [12, 10], // Centering
  });
export default class UserRoute extends Component {
  state = {
    search_user: "",
    search_start_time: true,
    search_end_time: true,
    start_time: "",
    end_time: "",
    user_name: "",
    routeData: [],
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 15,
    accuracy: "all",
    limit: 500,
    total: 0,
    currentIndex: 0,
    time: "",
    isPlaying: false,
    progress: 0,
    show_card: false,
    animatedPath: [],
    arrows: [],
    arrow_icon: [],
    loading: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUserRoute();
  }

  getBearing = (lat1, lon1, lat2, lon2) => {
    const toRad = (deg) => (deg * Math.PI) / 180;
    const toDeg = (rad) => (rad * 180) / Math.PI;

    const dLon = toRad(lon2 - lon1);
    const y = Math.sin(dLon) * Math.cos(toRad(lat2));
    const x =
      Math.cos(toRad(lat1)) * Math.sin(toRad(lat2)) -
      Math.sin(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.cos(dLon);
    const bearing = toDeg(Math.atan2(y, x));

    return (bearing + 360) % 360;
  };

  updateArrows = (lat1, lon1, lat2, lon2) => {
    const midpoint = [(lat1 + lat2) / 2, (lon1 + lon2) / 2]; // Find midpoint

    // Calculate the bearing (rotation angle) for arrow direction
    const bearing = this.getBearing(lat1, lon1, lat2, lon2);

    // Update state with a growing list of arrows
    this.setState((prevState) => ({
      arrow_icon: [
        ...prevState.arrow_icon,
        {
          position: midpoint,
          angle: bearing,
        },
      ],
    }));
  };

  startAnimation = () => {
    if (this.state.isPlaying || this.state.routeData.length < 2) return;

    this.setState({ isPlaying: true, progress: 0 });

    let currentIndex = this.state.currentIndex;
    let animatedPath = [
      [
        this.state.routeData[0].loc.coordinates[1],
        this.state.routeData[0].loc.coordinates[0],
      ],
    ];

    const animateStep = () => {
      if (currentIndex >= this.state.routeData.length - 1) {
        this.setState({ isPlaying: false });
        return;
      }

      const [lat1, lon1] = animatedPath[animatedPath.length - 1];
      const nextPoint = this.state.routeData[currentIndex + 1];

      const [lat2, lon2] = [
        nextPoint.loc.coordinates[1],
        nextPoint.loc.coordinates[0],
      ];

      const locationName =
        nextPoint.location_address?.data[0]?.name || "Unknown";
      const timestamp = nextPoint.timestamp?.$date || "N/A";

      this.updateArrows(lat1, lon1, lat2, lon2);

      let progress = 0;
      const speed = 0.05; // Adjusted speed for smoother movement

      const interpolate = () => {
        if (!this.state.isPlaying) return; // Stop if animation is paused

        if (progress >= 1) {
          currentIndex++;
          animatedPath.push([lat2, lon2]);

          // Calculate arrow direction
          const bearing = this.getBearing(lat1, lon1, lat2, lon2);
          let newArrows = [...this.state.arrows];

          if (animatedPath.length % 3 === 0) {
            newArrows.push({
              position: [lat2, lon2],
              angle: bearing,
              locationName, // Store location name
              timestamp, // Store timestamp
            });
          }

          this.setState(
            {
              animatedPath,
              arrows: newArrows,
              progress: 0,
            },
            () => console.log(this.state.arrows)
          );

          requestAnimationFrame(animateStep);
          return;
        }

        // Smooth interpolation
        const newLat = lat1 + (lat2 - lat1) * progress;
        const newLon = lon1 + (lon2 - lon1) * progress;

        this.setState((prevState) => ({
          animatedPath: [...prevState.animatedPath, [newLat, newLon]],
        }));

        progress += speed;
        requestAnimationFrame(interpolate);
      };

      interpolate();
    };

    requestAnimationFrame(animateStep);
  };

  // pauseAnimation = () => {
  //   this.setState({ isPlaying: false });
  // };

  resetAnimation = () => {
    this.setState({
      isPlaying: false,
      currentIndex: 0,
      progress: 0,
      animatedPath: [],
      arrows: [],
      arrow_icon: [],
    });
  };

  setAccuracy = (value) => {
    this.setState({ accuracy: value }, () => {
      console.log(this.state.accuracy);
      this.getUserRoute();
    });
  };

  filterClosePoints(routeData) {
    if (routeData.length < 2) return routeData;

    return routeData.filter((point, index) => {
      if (index === 0) return true;
      const prevPoint = routeData[index - 1].loc.coordinates;
      const currPoint = point.loc.coordinates;
      const distance = Math.sqrt(
        Math.pow(currPoint[0] - prevPoint[0], 2) +
          Math.pow(currPoint[1] - prevPoint[1], 2)
      );
      return distance > 0.001;
    });
  }

  getUserRoute = () => {
    this.setState(
      {
        loading: true,
        search_user: this.props.location.state.search_user,
        start_time:
          this.props?.location?.state?.start_time ??
          moment().startOf("day").format("x"),
        end_time:
          this.props?.location?.state?.end_time ??
          moment().endOf("day").format("x"),
        user_name: this.props.location.state.user_name,
      },
      () => {
        console.log("date", this.state.start_time);
        let date = new Date();
        let time = date.getTime();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${USER_ROUTE}?sort_type=asc&limit=${this.state.limit}&ts=${time}&search_start_time=${this.state.search_start_time}&search_end_time=${this.state.search_end_time}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&search_user=${this.state.search_user}&accuracy=${this.state.accuracy}&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              this.setState({ loading: false });
              console.log("response from user route api", response);
              if (response.data.data.length === 0) {
                swal.fire("Info", "No Route Data Found", "info");
              } else {
                this.setState(
                  {
                    routeData: this.filterClosePoints(
                      response.data?.data ?? []
                    ),
                    total: response.data.total,
                  },
                  () => {
                    if (this.state.routeData.length > 0) {
                      this.setState({
                        animatedPath: [
                          [
                            this.state.routeData[0].loc.coordinates[1],
                            this.state.routeData[0].loc.coordinates[0],
                          ],
                        ],
                        center_lat: this.state.routeData[0].loc.coordinates[1],
                        center_lon: this.state.routeData[0].loc.coordinates[0],
                        time: this.state.routeData[0].timestamp.$date,
                      });
                    }
                  }
                );
              }
            })
            .catch((error) => {
              this.setState({ loading: false });
              console.log("error from user route api", error);
            });
        });
      }
    );
  };

  downloadRouteHistoryReportExcel = () => {
    this.setState({ loading: true });
    let date = new Date();
    let time = date.getTime();
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_ROUTE}?fmt=xlsx&sort_type=asc&limit=${this.state.limit}&ts=${time}&search_start_time=${this.state.search_start_time}&search_end_time=${this.state.search_end_time}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&search_user=${this.state.search_user}&accuracy=${this.state.accuracy}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          this.setState({ loading: false });
          console.log("response from user route history excel report", blob);
          FileSaver.saveAs(
            blob,
            `user_route_history_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from user active deactive excel report", error);
        });
    });
  };
  downloadRouteHistoryReportPdf = () => {
    this.setState({ loading: true });
    let date = new Date();
    let time = date.getTime();
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_ROUTE}?fmt=pdf&sort_type=asc&limit=${this.state.limit}&ts=${time}&search_start_time=${this.state.search_start_time}&search_end_time=${this.state.search_end_time}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&search_user=${this.state.search_user}&accuracy=${this.state.accuracy}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          this.setState({ loading: false });
          console.log("response from user route history pdf report", blob);
          FileSaver.saveAs(
            blob,
            `user_route_history_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from user active deactive excel report", error);
        });
    });
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title={`Route History for ${this.state.user_name}`}
          onBack={() => this.props.history.goBack()}
        />
        <Card style={{ margin: 10 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Text className="task-view-text">
              From: {""}
              <Text>{timeConverter(this.state.start_time)}</Text>
            </Text>
            <Text className="task-view-text">
              To: {""}
              <Text>{timeConverter(this.state.end_time)}</Text>
            </Text>
            <div style={{ marginLeft: 10, marginTop: 5 }}>
              <Text> Accuracy : {""}</Text>
              <Select
                style={{ minWidth: 100 }}
                value={this.state.accuracy}
                onChange={this.setAccuracy}
                placeholder="Accuracy"
              >
                <Option key="all">Show all</Option>
                <Option key="low">Low</Option>
                <Option key="medium">Medium</Option>
                <Option key="high">High</Option>
              </Select>
            </div>
          </div>
        </Card>
        {this.state.total > 500 ? (
          <Card style={{ margin: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                style={{ marginRight: 5 }}
                onClick={() =>
                  this.setState({ limit: this.state.limit - 500 }, () => {
                    this.getUserRoute();
                  })
                }
                disabled={this.state.limit <= 500 ? true : false}
              >
                View Less Data
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 5 }}
                onClick={() =>
                  this.setState({ limit: this.state.limit + 500 }, () => {
                    this.getUserRoute();
                  })
                }
                disabled={this.state.limit >= this.state.total ? true : false}
              >
                View More Data
              </Button>
            </div>
          </Card>
        ) : null}

        <Card style={{ margin: 10, width: window.innerWidth }}>
          <Text style={{ color: "#FFB72B", fontSize: "large" }}>
            Location data accuracy depends on device settings. The quality of
            the routes may vary based on devices.
          </Text>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 700, width: window.innerWidth / 1.25 }}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2",
              }}
              type={"roadmap"}
            />

            {this.state.animatedPath.length > 1 && (
              <Polyline
                lineCap="round"
                lineJoin="round"
                weight={6}
                opacity={0.8}
                color="#3674B5"
                positions={this.state.animatedPath}
              />
            )}
            {this.state.arrow_icon.length > 1 &&
              this.state.zoom > 15 &&
              this.state.arrow_icon.map((arrow) => (
                <Marker
                  position={arrow.position}
                  icon={createArrowIcon(arrow.angle)}
                />
              ))}
            <div className="sidebar">
              <h3>Travel Timeline</h3>
              {this.state.routeData.length > 0 ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    className="start-btn"
                    style={{ marginRight: 5 }}
                    onClick={this.startAnimation}
                    disabled={this.state.isPlaying}
                  >
                    Play
                  </Button>

                  <Button
                    className="start-btn"
                    style={{ marginRight: 5 }}
                    onClick={this.resetAnimation}
                  >
                    Reset
                  </Button>
                  {/* <Button
                  type="primary"
                  style={{ marginRight: 5 }}
                  onClick={this.pauseAnimation}
                  disabled={!this.state.isPlaying}
                >
                  Pause
                </Button> */}
                </div>
              ) : null}
              <div className="timeline">
                {this.state.arrows.map((loc, index) => (
                  <div
                    key={index}
                    className="timeline-card"
                    onClick={() =>
                      this.setState({
                        center_lat: loc.position[0],
                        center_lon: loc.position[1],
                        zoom: 17,
                      })
                    }
                  >
                    <h4>{truncateText(loc.locationName || "Unknown")}</h4>
                    <p>
                      <strong>Time:</strong>{" "}
                      {timeConverter(loc.timestamp ?? Date.now())}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {this.state.arrows.map((arrow, index) => (
              <Marker
                key={index}
                position={arrow.position}
                icon={createNumberedIcon(index + 1, "#FF6868")}
              >
                <Popup>
                  <span>{`${arrow.locationName} - ${timeConverter(
                    arrow.timestamp
                  )}`}</span>
                </Popup>
              </Marker>
            ))}

            {/* {this.state.show_card ? (
              <Card
                size="small"
                title={this.state.name}
                extra={
                  <Button
                    type="link"
                    onClick={() => this.setState({ show_card: false })}
                  >
                    Close
                  </Button>
                }
                style={{
                  position: "absolute",
                  right: 20,
                  top: 60,
                  zIndex: 400,
                  paddingTop: 7,
                  paddingRight: 1,
                  width: 300,
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #FBFBFB",
                    borderRadius: 5,
                    borderLeft: "5px solid #3795BD",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>TimeStamp:</Text>{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    {timeConverter(this.state.time)}
                  </Text>
                </div>
              </Card>
            ) : null} */}
          </Map>
        </Card>
        {GET_USER_LOCAL().org_admin || GET_USER_LOCAL().is_staff ? (
          <Card style={{ margin: 10 }}>
            <Button type="link" onClick={this.downloadRouteHistoryReportExcel} style={{marginBottom:10}}>Download Excel</Button>
            <Button type="link" onClick={this.downloadRouteHistoryReportPdf} style={{marginBottom:10}}>Download PDF</Button>
            <Table
              dataSource={this.state.routeData}
              pagination={false}
              rowKey={(record) => record._id.$oid}
              size="small"
              bordered
            >
              <Column
                title="Data Status"
                key="Data Status"
                render={(text, record) => <span>{record?.data_status}</span>}
              />
              <Column
                title="Address"
                key="Address"
                render={(text, record) => (
                  <span>{record?.location_address?.data[0]?.name ?? ""}</span>
                )}
              />
              <Column
                title="Timestamp"
                key="timestamp"
                render={(text, record) => (
                  <span>{timeConverter(record.timestamp.$date)}</span>
                )}
              />
              <Column
                title="Longitude"
                key="Longitude"
                render={(text, record) => (
                  <span>{record?.loc?.coordinates[0] ?? ""}</span>
                )}
              />
              <Column
                title="Lattitude"
                key="Lattitude"
                render={(text, record) => (
                  <span>{record?.loc?.coordinates[1] ?? ""}</span>
                )}
              />
              <Column
                title="Accuracy"
                key="Accuracy"
                render={(text, record) => <span>{record?.accuracy}</span>}
              />
              <Column
                title="Altitude"
                key="Altitude"
                render={(text, record) => <span>{record?.altitude}</span>}
              />
              <Column
                title="Bearing"
                key="Bearing"
                render={(text, record) => <span>{record?.bearing}</span>}
              />
              <Column
                title="Speed"
                key="Speed"
                render={(text, record) => <span>{record?.speed}</span>}
              />
            </Table>
          </Card>
        ) : null}
      </Card>
    );
  }
}
