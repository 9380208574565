import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_INVENTORY, CREATE_ENTITY } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateInventory extends Component {
  state = {
    entity_id: "",
    entity_name: "",
    data: []
  };

  componentDidMount() {
    this.getEntityList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getEntityList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ENTITY}?limit=100&is_inventory=false&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch(error => {
          console.log("error from entity list api", error);
        });
    });
  };

  handleSearch = debounce(value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ENTITY}?limit=100&name=${value}&is_inventory=false&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch(error => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  setEntity = value => {
    this.setState({ entity_id: value.key, entity_name: value.label }, () => {
      console.log(this.state.entity_id);
    });
  };
  createInventory = () => {
    let data = {
      entity_id: this.state.entity_id
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_INVENTORY}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          this.getEntityList();
          console.log("response from inventory connect list create", response);
        })
        .catch(error => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from inventory connect list create", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          //   onBack={() => this.props.history.goBack()}
          title="Create Inventory"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Search Place</Text>
            <Select
              showSearch={true}
              labelInValue
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              value={{
                key: this.state.entity_id,
                label: this.state.entity_name
              }}
              onChange={this.setEntity}
              placeholder="Search Place"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createInventory}
            >
              Create Inventory
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
