import React, { Component } from 'react';
import { Card, Form, Input, Button, Typography, PageHeader} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_ROLE} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
// import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class CreateRole extends Component {
    state = {
        name: "",
        description: ""
      };
    
    
      setName = evt => {
        evt.preventDefault();
        this.setState({ name: evt.target.value }, () => {
          console.log(this.state.name);
        });
      };
      setDescription = evt => {
        evt.preventDefault();
        this.setState({ description: evt.target.value }, () => {
          console.log(this.state.description);
        });
      };
      
    
      createRole = () => {
        if(this.state.name === "" ){
          swal.fire("info","Please enter role name","info");
        }else{
        const data = {
          name: this.state.name,
          description: this.state.description,
          permissions: "",
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url=`${CREATE_ROLE}?access_token=${token}`
          axios({
            method: "post",
            url:url ,
            data: data,
            headers: { 
              'content-type': 'application/x-www-form-urlencoded' 
            }
          })
            .then(response => {
              swal.fire("Info", response.data.message, "info");
              console.log("response from create role", response);
            })
            .catch(error => {
              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              console.log("error from create role", error);
            });
        });
      }
    }
      render() {
        return (
          <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Create Role"
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item>
                <Text>Name</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={this.setDescription}
                />
              </Form.Item>
    
              
              <Form.Item>
                <Button className="login-form-button" htmlType="submit" onClick={this.createRole}>
                  Create Role
                </Button>
              </Form.Item>
            </Form>
          </Card>
        );
      }
}
