import React, { Component } from "react";
import {
  Card,
  Button,
  Icon,
  Typography,
  Divider,
  Avatar,
  Menu,
  Dropdown,
  Switch,
  Input,
  Form,
  Select,
  Spin,
  Tag,
  Slider
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import CardMaterial from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ButtonMaterial from "@material-ui/core/Button";

import {
  EDIT_ORDER,
  CREATE_ORDER,
  GENERATE_MULTIPLE_TASK_FROM_ORDER,
  // REVERSE_GEO,
  // GEO_CODING_API,
  ROUTE_SUGGESTION,
  NEARBY_USERS,
  NEARBY_ASSETS,
  CHAIN_TASK_FROM_ORDER,
  CREATE_DRIVER,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  randomId,
  CHECK_BOOL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Text, Title } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40]
});
const orders = new L.Icon({
  iconUrl: require("../.././assets/orders.png"),
  iconSize: [20, 35]
});

const ordersstart = new L.Icon({
  iconUrl: require("../.././assets/ordersstart.png"),
  iconSize: [20, 35]
});
const selected = new L.Icon({
  iconUrl: require("../.././assets/selected.png"),
  iconSize: [20, 35]
});
const polyline = new L.Icon({
  iconUrl: require("../.././assets/polyline.png"),
  iconSize: [25, 40]
});

const userImage = new L.Icon({
  iconUrl: require("../.././assets/user.png"),
  iconSize: [25, 40]
});

const assets = new L.Icon({
  iconUrl: require("../.././assets/assets.png"),
  iconSize: [30, 20]
});

class MyOrders extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
    param: "upcoming",
    search_start_time: false,
    search_end_time: false,
    start_date_view: moment(),
    end_date_view: moment(),
    startTimeStamp: Date.now(),
    endTimeStamp: Date.now(),
    multiSelect: false,
    checked: false,
    multiOrderData: [],
    center_lat: 23.8103,
    center_lon: 90.4125,
    start_location_lat: 23.8103,
    start_location_lon: 90.4125,
    end_location_lat: 23.8203,
    end_location_lon: 90.4125,
    start_location: "",
    end_location: "",
    zoom: 12,
    start_search_range: "",
    end_search_range: "",
    value: [],
    value1: [],
    loc_data: [],
    showOrdersonMap: false,
    deliveryType: "dropoff",
    pickupData: [],
    dropoffData: [],
    showPolyline: false,
    polyLineData: [],
    waypoints: [],
    nearbyUsers: [],
    assignedUser: "",
    showNearbyUsers: false,
    isRunning: false,
    nearbyAssets: [],
    assignedAsset: "",
    secondaryUsers: [],
    showNearbyAssets: false,
    showDropdownForm: false,
    chainTaskDataPick: [],
    chainTaskDataDrop: [],
    userAssetRadius: 0,
    primaryUserLocation: {},
    assetLocation: {},
    startLocationType: "default",
    assignedAssetName: "",
    task_assign_user_data: [],
    filteredUser: [],
    task_assign_user: "",
    task_assign_user_name: "",
    select_order: true
  };
  componentDidMount() {
    this.props.getUserData();
    window.scrollTo(0, 0);
    this.getOrderList();
    this.getUserList();
    console.log(this.props.param);
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  handleParamChange = value => {
    this.setState({ param: value }, () => {
      console.log(this.state.param);
      this.getOrderList();
    });
  };

  getOrderList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ORDER}?limit=10&page=${this.state.page}&${this.props.param}=true&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from order list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from order list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 10000;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ task_assign_user_data: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  };

  setAssignedUser = value => {
    this.setState(
      {
        task_assign_user: value.key,
        task_assign_user_name: value.label.split(">")[0],
        filtered: [],
        select_order: true
      },
      () => {
        console.log(this.state.task_assign_user_name);
      }
    );
  };

  handleSearchUser = debounce(value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ task_assign_user_data: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);

  handleStartTime = checked => {
    this.setState({ search_start_time: checked }, () => {
      console.log(this.state.search_start_time);
      this.getOrderList();
    });
  };

  handleEndTime = checked => {
    this.setState({ search_end_time: checked }, () => {
      console.log(this.state.search_end_time);
      this.getOrderList();
    });
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { startTimeStamp: moment(value).format("x"), start_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.startTimeStamp);
        let date = new Date();
        let time = date.getTime();
        console.log(moment(time));
        GET_ACCESS_TOKEN().then(token => {
          const url = `${CREATE_ORDER}?limit=1000&${this.props.param}=true&ts=${time}&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&access_token=${token}`;
          axios({
            method: "get",
            url: url
          })
            .then(response => {
              console.log("response from order list", response);
              this.setState({
                data:
                  this.state.page === 0
                    ? response.data.data
                    : [...this.state.data, ...response.data.data],
                total: response.data.total
              });
            })
            .catch(error => {
              console.log("error from order list api", error);
            });
        });
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      { endTimeStamp: moment(value).format("x"), end_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.endTimeStamp);
        let date = new Date();
        let time = date.getTime();
        console.log(moment(time));
        GET_ACCESS_TOKEN().then(token => {
          const url = `${CREATE_ORDER}?limit=1000&ts=${time}&${this.props.param}=true&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&access_token=${token}`;
          axios({
            method: "get",
            url: url
          })
            .then(response => {
              console.log("response from order list", response);
              this.setState({
                data:
                  this.state.page === 0
                    ? response.data.data
                    : [...this.state.data, ...response.data.data],
                total: response.data.total
              });
            })
            .catch(error => {
              console.log("error from order list api", error);
            });
        });
      }
    );
  };

  setStartRange = evt => {
    this.setState({ start_search_range: evt.target.value });
    console.log(this.state.start_search_range);
  };
  setEndRange = evt => {
    this.setState({ end_search_range: evt.target.value });
    console.log(this.state.end_search_range);
  };

  handleStart = debounce(value => {
    this.setState({ query: value, loc_data: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.query}&access_token=${token}`
            )
            .then(response => {
              console.log(response);
              const data = response.data.google_loc_datas.map(res => ({
                id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
                value: `${res.name}>${res.address}`
              }));
              this.setState({ loc_data: data, fetch: false });
              console.log(response.data.google_loc_datas);
            });
        });
      }
    });
  }, 500);

  handleStartChange = value => {
    this.setState({ value, loc_data: [], fetch: false });

    return this.setState({
      start_location: value.label.split(">")[0],
      start_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      start_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2]
    });
  };

  handleEndChange = value => {
    this.setState({ value1: value, loc_data: [], fetch: false });

    return this.setState({
      end_location: value.label.split(">")[0],
      end_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      end_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2]
    });
  };

  updatePositionStart = evt => {
    console.log(evt.target.getLatLng());

    this.setState(
      {
        start_location_lat: evt.target.getLatLng().lat,
        start_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
            )
            .then(response => {
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                start_location: name,
                value: { key: id, label: name }
              });
            });
        });
      }
    );
  };

  updatePositionEnd = evt => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.end_location_lat}&lng=${this.state.end_location_lon}&access_token=${token}`
            )
            .then(response => {
              console.log("response for end marker change", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                end_location: name,
                value1: { key: id, label: name }
              });
            });
        });
      }
    );
  };

  searchOrdersbyLocation = () => {
    if (
      !this.state.startTimeStamp ||
      !this.state.endTimeStamp ||
      !this.state.end_search_range ||
      !this.state.start_search_range ||
      !this.state.start_location ||
      !this.state.end_location
    ) {
      swal.fire(
        "Warning",
        "Please enter start time,end time,start location,end location,start location range,end location range to search orders",
        "warning"
      );
    } else {
      let date = new Date();
      let time = date.getTime();
      console.log(moment(time));
      GET_ACCESS_TOKEN().then(token => {
        const url = `${CREATE_ORDER}?eloc=[${this.state.end_location_lon},${this.state.end_location_lat}]&end_max_distance=${this.state.end_search_range}&start_max_distance=${this.state.start_search_range}&sloc=[${this.state.start_location_lon},${this.state.start_location_lat}]&limit=1000&ts=${time}&${this.props.param}=true&search_end_time=${this.state.search_end_time}&search_start_time=${this.state.search_start_time}&start_time=${this.state.startTimeStamp}&end_time=${this.state.endTimeStamp}&access_token=${token}`;
        axios({
          method: "get",
          url: url
        })
          .then(response => {
            console.log("response from order list", response);
            if (response.data.data.length > 0) {
              this.setState({ showOrdersonMap: true });
            } else {
              this.setState({ showOrdersonMap: false });
            }
            this.setState({
              data:
                this.state.page === 0
                  ? response.data.data
                  : [...this.state.data, ...response.data.data],
              total: response.data.total
            });
          })
          .catch(error => {
            console.log("error from order list api", error);
          });
      });
    }
  };

  deliveryTypeChange = value => {
    this.setState({ deliveryType: value }, () => {
      if (this.state.deliveryType === "dropoff") {
        this.setState({ pickupData: [] });
      }
      if (this.state.deliveryType === "pickup") {
        this.setState({ dropoffData: [] });
      }
      console.log(this.state.deliveryType);
    });
  };

  handleisrunning = value => {
    this.setState({ isRunning: CHECK_BOOL(value) }, () => {
      console.log(this.state.isRunning);
    });
  };

  uniqueArray = arr => {
    const seen = new Set();
    const filteredArr = arr.filter(el => {
      const duplicate = seen.has(el.order_id);
      seen.add(el.order_id);
      return !duplicate;
    });
    return filteredArr;
  };

  suggestRoutes = () => {
    if (this.state.deliveryType === "dropoff") {
      if (this.state.dropoffData.length < 2) {
        swal.fire(
          "Warning",
          "Select atleast two different orders for route suggestion",
          "warning"
        );
      } else {
        GET_ACCESS_TOKEN().then(token => {
          let url = `${ROUTE_SUGGESTION}?access_token=${token}`;
          let data = {
            start_loc:
              this.state.startLocationType === "user"
                ? {
                    coordinates: [
                      this.state.primaryUserLocation.coordinates[1],
                      this.state.primaryUserLocation.coordinates[0]
                    ]
                  }
                : this.state.startLocationType === "asset"
                ? this.state.assetLocation
                : {
                    coordinates: [
                      this.state.start_location_lon,
                      this.state.start_location_lat
                    ]
                  },
            end_loc: {
              coordinates: [
                this.state.end_location_lon,
                this.state.end_location_lat
              ]
            },
            delivery_type: this.state.deliveryType,
            orders: this.state.dropoffData
          };
          console.log(data);
          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              if (response.data.message === "Route generated successfully") {
                this.setState(
                  {
                    polyLineData:
                      response.data?.data?.trips[0]?.geometry?.coordinates ??
                      [],
                    waypoints: response.data?.data?.waypoints ?? []
                  },
                  () => {
                    this.setState({
                      showPolyline: true,
                      showDropdownForm: true
                    });
                  }
                );
              }
              swal.fire("Success", response.data.message, "success");
              console.log("response from route suggestion dropoff", response);
            })
            .catch(error => {
              // this.setState({ loading: false });
              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              console.log("error from route suggestion dropoff", error);
            });
        });
      }
    } else {
      if (this.state.pickupData.length < 2) {
        swal.fire(
          "Warning",
          "Select atleast two different orders for route suggestion",
          "warning"
        );
      } else {
        GET_ACCESS_TOKEN().then(token => {
          let url = `${ROUTE_SUGGESTION}?access_token=${token}`;
          let data = {
            start_loc:
              this.state.startLocationType === "user"
                ? {
                    coordinates: [
                      this.state.primaryUserLocation.coordinates[1],
                      this.state.primaryUserLocation.coordinates[0]
                    ]
                  }
                : this.state.startLocationType === "asset"
                ? this.state.assetLocation
                : {
                    coordinates: [
                      this.state.start_location_lon,
                      this.state.start_location_lat
                    ]
                  },
            end_loc: {
              coordinates: [
                this.state.end_location_lon,
                this.state.end_location_lat
              ]
            },
            delivery_type: this.state.deliveryType,
            orders: this.state.pickupData
          };
          console.log(data);
          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded"
            }
          })
            .then(response => {
              if (response.data.message === "Route generated successfully") {
                this.setState(
                  {
                    polyLineData:
                      response.data?.data?.trips[0]?.geometry?.coordinates ??
                      [],
                    waypoints: response.data?.data?.waypoints ?? []
                  },
                  () => {
                    this.setState({
                      showPolyline: true,
                      showDropdownForm: true
                    });
                  }
                );
              }

              swal.fire("Success", response.data.message, "success");
              console.log("response from route suggestion pickup", response);
            })
            .catch(error => {
              // this.setState({ loading: false });
              swal.fire("Error",  error?.response?.data?.message??error.message, "error");
              console.log("error from route suggestion pickup", error);
            });
        });
      }
    }
  };

  changeUserRadius = value => {
    this.setState({ userAssetRadius: value }, () => {
      this.getNearbyUsers();
      this.getNearbyAssets();
      console.log(this.state.userAssetRadius);
    });
  };

  getNearbyUsers = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${NEARBY_USERS}?limit=1000&loc=[${this.state.start_location_lon},${this.state.start_location_lat}]&radius=${this.state.userAssetRadius}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from nearby users", response);
          this.setState({
            nearbyUsers: response?.data?.data ?? [],
            showNearbyUsers: true
          });
        })
        .catch(error => {
          console.log("error from nearby users", error);
        });
    });
  };

  getNearbyAssets = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = this.state.isRunning
        ? `${NEARBY_ASSETS}?end_time=${this.state.endTimeStamp}&end_loc=[${this.state.end_location_lon},${this.state.end_location_lat}]&is_running=${this.state.isRunning}&loc=[${this.state.start_location_lon},${this.state.start_location_lat}]&radius=${this.state.userAssetRadius}&access_token=${token}`
        : `${NEARBY_ASSETS}?limit=1000&is_running=${this.state.isRunning}&loc=[${this.state.start_location_lon},${this.state.start_location_lat}]&radius=${this.state.userAssetRadius}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from nearby assets", response);
          if (response.data.data.length > 0) {
            this.setState({
              nearbyAssets: response?.data?.data ?? [],
              showNearbyAssets: true
            });
          }
        })
        .catch(error => {
          console.log("error from nearby assets", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getOrderList();
      }
    );
  };

  deleteOrder = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_ORDER}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  genchainTask = () => {
    if (this.state.deliveryType === "dropoff") {
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CHAIN_TASK_FROM_ORDER}?access_token=${token}`;
        let data = {
          task_gen_datas: this.state.chainTaskDataDrop,
          assigned_to: this.state.assignedUser,
          assigned_asset: this.state.assignedAsset,
          assign_connected_users: this.state.secondaryUsers
        };
        console.log(data);
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            // this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log(
              "response from generate chain task from multiple order post",
              response
            );
          })
          .catch(error => {
            // this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log(
              "error from generate chain task from multiple order post",
              error
            );
          });
      });
    } else {
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CHAIN_TASK_FROM_ORDER}?access_token=${token}`;
        let data = {
          task_gen_datas: this.state.chainTaskDataPick,
          assigned_to: this.state.assignedUser,
          assigned_asset: this.state.assignedAsset,
          assign_connected_users: this.state.secondaryUsers
        };
        console.log(data);
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            // this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log(
              "response from generate chain task from multiple order post",
              response
            );
          })
          .catch(error => {
            // this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log(
              "error from generate chain task from multiple order post",
              error
            );
          });
      });
    }
  };

  generatetask = () => {
    if (this.state.multiOrderData.length === 0) {
      swal.fire("Info", "Please Select Orders !", "info");
    } else {
      GET_ACCESS_TOKEN().then(token => {
        let url = `${GENERATE_MULTIPLE_TASK_FROM_ORDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: this.state.multiOrderData,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            // this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log(
              "response from generate task from multiple order post",
              response
            );
          })
          .catch(error => {
            // this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log(
              "error from generate task from multiple order post",
              error
            );
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const UserList = this.state.task_assign_user_data.map(r => {
      return <Option key={r._id.$oid}>{`${r.name}>${r.mobile}`}</Option>;
    });

    const FliteredList = this.state.filteredUser.map(r => {
      return <Option key={r._id.$oid}>{`${r.name}>${r.mobile}`}</Option>;
    });
    return (
      <div>
        <Card style={{ margin: 3 }}>
          {/* <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)",
              marginBottom: 10
            }}
            title=""
          /> */}

          <Title level={4}>
            Select daterange within which you would like to find your orders
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div style={{ margin: 5, marginTop: 15 }}>
              <Switch
                checkedChildren="Search Start Time"
                unCheckedChildren="Search Start Time"
                onChange={this.handleStartTime}
                defaultChecked={this.state.search_start_time}
              />
            </div>
            {this.state.search_start_time ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.start_date_view}
                    onChange={this.setStartTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
            ) : null}
            <div style={{ margin: 5, marginTop: 15 }}>
              <Switch
                checkedChildren="Search End Time"
                unCheckedChildren="Search End Time"
                onChange={this.handleEndTime}
                defaultChecked={this.state.search_end_time}
              />
            </div>

            {this.state.search_end_time ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.end_date_view}
                    onChange={this.setEndTime}
                  />
                </MuiPickersUtilsProvider>
              </div>
            ) : null}
          </div>
        </Card>

        <Card style={{ margin: 3 }}>
          {this.state.showOrdersonMap ? (
            <div>
              <Title level={4}>
                Select delivery type and then click on the marker inside the map
                to select the order
              </Title>
              {this.state.pickupData.length > 0 ? (
                <Card style={{ marginBottom: 5 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.pickupData.map((order, index) => (
                      <Tag
                        color="blue"
                        style={{ paddingTop: 4 }}
                        key={index}
                        onClick={() => {
                          this.setState({
                            pickupData: this.state.pickupData.filter(data => {
                              return data.order_number !== order.order_number;
                            })
                          });
                          this.setState({
                            chainTaskDataPick: this.state.chainTaskDataPick.filter(
                              data => {
                                return data.order_number !== order.order_number;
                              }
                            )
                          });
                        }}
                      >
                        {`Order Number : ${order.order_number}`}
                        <Icon type="close" />
                      </Tag>
                    ))}

                    <Button
                      type="link"
                      onClick={() =>
                        this.setState({ pickupData: [], chainTaskDataPick: [] })
                      }
                    >
                      Clear Data
                    </Button>
                  </div>
                </Card>
              ) : null}
              {this.state.dropoffData.length > 0 ? (
                <Card style={{ marginBottom: 5 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.dropoffData.map((order, index) => (
                      <Tag
                        color="blue"
                        style={{ paddingTop: 4 }}
                        key={index}
                        onClick={() => {
                          this.setState({
                            dropoffData: this.state.dropoffData.filter(data => {
                              return data.order_number !== order.order_number;
                            })
                          });
                          this.setState({
                            chainTaskDataDrop: this.state.chainTaskDataDrop.filter(
                              data => {
                                return data.order_number !== order.order_number;
                              }
                            )
                          });
                        }}
                      >
                        {`Order Number : ${order.order_number}`}{" "}
                        <Icon type="close" />
                      </Tag>
                    ))}
                    <Button
                      type="link"
                      onClick={() =>
                        this.setState({
                          dropoffData: [],
                          chainTaskDataDrop: []
                        })
                      }
                    >
                      Clear Data
                    </Button>
                  </div>
                </Card>
              ) : null}
            </div>
          ) : null}

          <div style={{ display: "flex", flexDirection: "row" }}>
            {!this.state.showOrdersonMap ? (
              <Card style={{ marginRight: 10 }}>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>
                      Start Location (Move the green marker inside the map)
                    </Text>
                    <Select
                      showSearch={true}
                      labelInValue
                      value={this.state.value}
                      notFoundContent={fetch ? <Spin size="small" /> : null}
                      style={{ width: window.innerWidth / 2.8 }}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      placeholder="Search Start Location"
                      onSearch={this.handleStart}
                      onChange={this.handleStartChange}
                      optionLabelProp="label"
                    >
                      {this.state.loc_data.map(r => {
                        return (
                          <Option key={r.id} label={r.value}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <Text>{r.value.split(">")[0]}</Text>
                                <Text
                                  style={{ fontSize: 10, color: "#9CB4CC" }}
                                >
                                  {r.value.split(">")[1]}
                                </Text>
                              </div>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>
                      End Location (Move the orange marker inside the map)
                    </Text>
                    <Select
                      showSearch={true}
                      labelInValue
                      value={this.state.value1}
                      notFoundContent={fetch ? <Spin size="small" /> : null}
                      style={{ width: window.innerWidth / 2.8 }}
                      filterOption={false}
                      showArrow={false}
                      placeholder="Search End Location"
                      onSearch={this.handleStart}
                      onChange={this.handleEndChange}
                      optionLabelProp="label"
                    >
                      {this.state.loc_data.map(r => {
                        return (
                          <Option key={r.id} label={r.value}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <Text>{r.value.split(">")[0]}</Text>
                                <Text
                                  style={{ fontSize: 10, color: "#9CB4CC" }}
                                >
                                  {r.value.split(">")[1]}
                                </Text>
                              </div>
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Text>Start Location Range (In Kilometers)</Text>
                  <Input
                    placeholder="Start Location Range (In Kilometers)"
                    type="text"
                    value={this.state.start_search_range}
                    onChange={this.setStartRange}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>End Location Range (In Kilometers)</Text>
                  <Input
                    placeholder="End Location Range (In Kilometers)"
                    type="text"
                    value={this.state.end_search_range}
                    onChange={this.setEndRange}
                  />
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Running Asset</Text>
                    <Select
                      value={this.state.isRunning.toString()}
                      onChange={this.handleisrunning}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </div>
                </Form.Item>
                {/* <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>User & Asset Search Radius</Text>
                    <Slider
                      defaultValue={1}
                      onAfterChange={this.changeUserRadius}
                      min={1}
                      max={1000}
                      step={1}
                    />
                  </div>
                </Form.Item> */}
                <Form.Item>
                  <Button
                    className="login-form-button"
                    htmlType="submit"
                    onClick={this.searchOrdersbyLocation}
                  >
                    Search Orders
                  </Button>
                </Form.Item>
              </Card>
            ) : (
              <Card style={{ marginRight: 10, width: 400 }}>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Select delivery Type</Text>
                    <Select
                      value={this.state.deliveryType}
                      onChange={this.deliveryTypeChange}
                    >
                      <Option key="dropoff">dropoff</Option>
                      <Option key="pickup">pickup</Option>
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Select Start Location</Text>
                    <Select
                      value={this.state.startLocationType}
                      onChange={value =>
                        this.setState({ startLocationType: value })
                      }
                    >
                      <Option key="default">default</Option>
                      <Option key="user">user</Option>
                      <Option key="asset">asset</Option>
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>User & Asset Search Radius</Text>
                    <Slider
                      defaultValue={1}
                      onAfterChange={this.changeUserRadius}
                      min={1}
                      max={1000}
                      step={1}
                    />
                  </div>
                </Form.Item>

                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Primary User</Text>
                    <Select
                      defaultValue="Select User"
                      onChange={value => {
                        this.setState(
                          {
                            assignedUser: this.state.nearbyUsers[value].user_id,
                            primaryUserLocation: this.state.nearbyUsers[value]
                              .loc
                          },
                          () => {
                            console.log(this.state.assignedUser);
                            console.log(this.state.primaryUserLocation);
                          }
                        );
                      }}
                      placeholder="Select User"
                    >
                      {this.state.nearbyUsers.length > 0
                        ? this.state.nearbyUsers.map((r, index) => {
                            return <Option key={index}>{r.name}</Option>;
                          })
                        : null}
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Secondary Users</Text>
                    <Select
                      mode="multiple"
                      value={this.state.secondaryUsers}
                      onChange={value => {
                        this.setState({ secondaryUsers: value }, () => {
                          console.log(this.state.secondaryUsers);
                        });
                      }}
                      placeholder="Select Secondary Users"
                    >
                      {this.state.nearbyUsers.length > 0
                        ? this.state.nearbyUsers.map(r => {
                            return <Option key={r.user_id}>{r.name}</Option>;
                          })
                        : null}
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Asset</Text>
                    <Select
                      value={this.state.assignedAssetName}
                      onChange={value => {
                        this.setState(
                          {
                            assignedAsset: this.state.nearbyAssets[value]._id
                              .$oid,
                            assetLocation: this.state.nearbyAssets[value].loc,
                            assignedAssetName: this.state.nearbyAssets[value]
                              .asset
                          },
                          () => {
                            console.log(this.state.assignedAsset);
                            console.log(this.state.assetLocation);
                          }
                        );
                      }}
                      placeholder="Select Asset"
                    >
                      {this.state.nearbyAssets.length > 0
                        ? this.state.nearbyAssets.map((r, index) => {
                            return <Option key={index}>{r.asset}</Option>;
                          })
                        : null}
                    </Select>
                  </div>
                </Form.Item>
                {this.props.userProfileData.order_and_delivery.order
                  .generate_route_suggestion_from_selected_order ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.suggestRoutes}
                      style={{ width: 300 }}
                    >
                      Suggest Routes
                    </Button>
                  </Form.Item>
                ) : null}

                {this.state.showDropdownForm &&
                this.props.userProfileData.order_and_delivery.order
                  .generate_chain_task_from_selected_order ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.genchainTask}
                      style={{ width: 250 }}
                    >
                      Generate Chain Task
                    </Button>
                  </Form.Item>
                ) : null}
              </Card>
            )}

            {this.state.showPolyline ? (
              <Map
                center={[this.state.center_lat, this.state.center_lon]}
                zoom={this.state.zoom}
                style={{ height: 700, minWidth: 400 }}
                onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
              >
                {/* <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.49.2"
                  }}
                  type={"roadmap"}
                />

                {this.state.waypoints.length > 0 ? (
                  <div>
                    {this.state.waypoints.map((points, index) => {
                      return (
                        <Marker
                          position={[points.location[1], points.location[0]]}
                          icon={
                            index === 0
                              ? green
                              : index === this.state.waypoints.length - 1
                              ? red
                              : polyline
                          }
                          key={points.hint}
                        >
                          <Popup>
                            <span>
                              {points.order_number
                                ? `${index + 1}. Order Number : ${
                                    points.order_number
                                  }`
                                : index === 0
                                ? `${index + 1}. Start Location`
                                : index === this.state.waypoints.length - 1
                                ? `${index + 1}. End Location`
                                : null}
                            </span>
                          </Popup>
                        </Marker>
                      );
                    })}
                  </div>
                ) : null}

                {this.state.showNearbyUsers ? (
                  <div>
                    {this.state.nearbyUsers.length > 0
                      ? this.state.nearbyUsers.map(user => {
                          return (
                            <Marker
                              position={[
                                user.loc.coordinates[0],
                                user.loc.coordinates[1]
                              ]}
                              icon={userImage}
                              key={user.user_id}
                              // onClick={() => {
                              //   this.setState(
                              //     { assignedUser: user.user_id },
                              //     () => {
                              //       console.log(this.state.assignedUser);
                              //     }
                              //   );
                              // }}
                            >
                              <Popup>
                                <span>
                                  User :{`${user.name}-${user.mobile}`}
                                </span>
                              </Popup>
                            </Marker>
                          );
                        })
                      : null}
                  </div>
                ) : null}

                {this.state.showNearbyAssets ? (
                  <div>
                    {this.state.nearbyAssets.length > 0
                      ? this.state.nearbyAssets.map(ast => {
                          return (
                            <Marker
                              position={[
                                ast.loc.coordinates[1],
                                ast.loc.coordinates[0]
                              ]}
                              icon={assets}
                              key={ast._id.$oid}
                              // onClick={() => {
                              //   this.setState(
                              //     { assignedUser: user.user_id },
                              //     () => {
                              //       console.log(this.state.assignedUser);
                              //     }
                              //   );
                              // }}
                            >
                              <Popup>
                                <span>Asset :{`${ast.name}-${ast.asset}`}</span>
                              </Popup>
                            </Marker>
                          );
                        })
                      : null}
                  </div>
                ) : null}
                {this.state.polyLineData.length > 0 ? (
                  <Polyline
                    color="purple"
                    positions={[this.state.polyLineData.map(m => [m[1], m[0]])]}
                  />
                ) : null}
              </Map>
            ) : (
              <Map
                center={[this.state.center_lat, this.state.center_lon]}
                zoom={this.state.zoom}
                style={{ height: 700, minWidth: 400 }}
                onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
              >
                {/* <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.49.2"
                  }}
                  type={"roadmap"}
                />

                {this.state.showOrdersonMap ? null : (
                  <Marker
                    position={[
                      this.state.start_location_lat,
                      this.state.start_location_lon
                    ]}
                    draggable
                    onDragend={this.updatePositionStart}
                    icon={green}
                  >
                    <Popup>
                      <span>Order Start :{this.state.start_location}</span>
                    </Popup>
                  </Marker>
                )}

                {this.state.showOrdersonMap ? null : (
                  <Marker
                    position={[
                      this.state.end_location_lat,
                      this.state.end_location_lon
                    ]}
                    draggable
                    onDragend={this.updatePositionEnd}
                    icon={red}
                  >
                    <Popup>
                      <span>Order End :{this.state.end_location}</span>
                    </Popup>
                  </Marker>
                )}

                {!this.state.showOrdersonMap ? null : (
                  <div>
                    {this.state.deliveryType === "dropoff"
                      ? this.state.data.map(order => {
                          return (
                            <Marker
                              // position={[
                              //   order.end_loc?.coordinates[1]??23.8203,
                              //   order.end_loc?.coordinates[0]??90.4125
                              // ]}
                              position={[
                                order.end_loc.coordinates
                                  ? order.end_loc?.coordinates[1]
                                  : 23.8203,
                                order.end_loc.coordinates
                                  ? order.end_loc?.coordinates[0]
                                  : 90.4125
                              ]}
                              // draggable
                              // onDragend={this.updatePositionEnd}
                              icon={
                                this.state.dropoffData.some(
                                  el => el.order_number === order.order_number
                                )
                                  ? selected
                                  : orders
                              }
                              key={order._id.$oid}
                              onClick={() => {
                                let endData = {
                                  order_id: order._id.$oid,
                                  order_number: order.order_number,
                                  loc: order.end_loc
                                };
                                let endArray = [];
                                endArray.push(endData);
                                this.setState(
                                  {
                                    dropoffData: [
                                      ...this.state.dropoffData,
                                      ...endArray
                                    ]
                                  },
                                  () => {
                                    this.setState(
                                      {
                                        dropoffData: this.uniqueArray(
                                          this.state.dropoffData
                                        )
                                      },
                                      () => {
                                        console.log(this.state.dropoffData);
                                      }
                                    );
                                  }
                                );
                                //multi order part
                                let dataCD = {
                                  name: order.name,
                                  order_number: order.order_number,
                                  details: order.details,
                                  end_loc: order.end_loc,
                                  end_loc_name: order.end_loc_name,
                                  task_code: randomId(10),
                                  price: order.bill_amount,
                                  start_loc:
                                    order?.start_entity?.loc ?? order.end_loc,
                                  start_loc_name:
                                    order?.start_entity?.address ??
                                    order.end_loc_name,
                                  end_time: order.delivery_date_time.$date,
                                  start_time: order.delivery_date_time.$date,
                                  assigned_to: "",
                                  assigned_to_name: "",
                                  start_dist: 1000,
                                  end_dist: 1000,
                                  trip_duration: 0,
                                  delay_mins: null,
                                  assigned_asset: "",
                                  assigned_asset_name: "",
                                  prev_task_id: "",
                                  m_task_id: "",
                                  contact_no: "",
                                  contact_name: "",
                                  drop_contact_name: order.drop_contact_name,
                                  drop_contact_no: order.drop_contact_no,
                                  colors: [],
                                  order_id: order._id.$oid
                                };
                                let arrayCD = [];
                                arrayCD.push(dataCD);
                                this.setState(
                                  {
                                    chainTaskDataDrop: [
                                      ...this.state.chainTaskDataDrop,
                                      ...arrayCD
                                    ]
                                  },
                                  () => {
                                    this.setState(
                                      {
                                        chainTaskDataDrop: this.uniqueArray(
                                          this.state.chainTaskDataDrop
                                        )
                                      },
                                      () => {
                                        console.log(
                                          this.state.chainTaskDataDrop
                                        );
                                      }
                                    );
                                  }
                                );
                              }}
                            >
                              <Popup>
                                <span>
                                  End Location Name :{order.end_loc_name}
                                </span>
                              </Popup>
                            </Marker>
                          );
                        })
                      : null}
                  </div>
                )}
                {!this.state.showOrdersonMap ? null : (
                  <div>
                    {this.state.deliveryType === "pickup"
                      ? this.state.data.map(order => {
                          return (
                            <Marker
                              position={[
                                order.start_entity.loc.coordinates[1],
                                order.start_entity.loc.coordinates[0]
                              ]}
                              // draggable
                              // onDragend={this.updatePositionEnd}
                              icon={
                                this.state.pickupData.some(
                                  el => el.order_number === order.order_number
                                )
                                  ? selected
                                  : ordersstart
                              }
                              key={order._id.$oid}
                              onClick={() => {
                                let startData = {
                                  order_id: order._id.$oid,
                                  order_number: order.order_number,
                                  loc: order.start_entity.loc
                                };
                                let startArray = [];
                                startArray.push(startData);
                                this.setState(
                                  {
                                    pickupData: [
                                      ...this.state.pickupData,
                                      ...startArray
                                    ]
                                  },
                                  () => {
                                    this.setState(
                                      {
                                        pickupData: this.uniqueArray(
                                          this.state.pickupData
                                        )
                                      },
                                      () => {
                                        console.log(this.state.pickupData);
                                      }
                                    );
                                  }
                                );
                                //multi order part
                                let dataCP = {
                                  name: order.name,
                                  order_number: order.order_number,
                                  details: order.details,
                                  end_loc: order.end_loc,
                                  end_loc_name: order.end_loc_name,
                                  task_code: randomId(10),
                                  price: order.bill_amount,
                                  start_loc:
                                    order?.start_entity?.loc ?? order.end_loc,
                                  start_loc_name:
                                    order?.start_entity?.address ??
                                    order.end_loc_name,
                                  end_time: order.delivery_date_time.$date,
                                  start_time: order.delivery_date_time.$date,
                                  assigned_to: "",
                                  assigned_to_name: "",
                                  start_dist: 1000,
                                  end_dist: 1000,
                                  trip_duration: 0,
                                  delay_mins: null,
                                  assigned_asset: "",
                                  assigned_asset_name: "",
                                  prev_task_id: "",
                                  m_task_id: "",
                                  contact_no: "",
                                  contact_name: "",
                                  drop_contact_name: order.drop_contact_name,
                                  drop_contact_no: order.drop_contact_no,
                                  colors: [],
                                  order_id: order._id.$oid
                                };
                                let arrayCP = [];
                                arrayCP.push(dataCP);
                                this.setState(
                                  {
                                    chainTaskDataPick: [
                                      ...this.state.chainTaskDataPick,
                                      ...arrayCP
                                    ]
                                  },
                                  () => {
                                    this.setState(
                                      {
                                        chainTaskDataPick: this.uniqueArray(
                                          this.state.chainTaskDataPick
                                        )
                                      },
                                      () => {
                                        console.log(
                                          this.state.chainTaskDataPick
                                        );
                                      }
                                    );
                                  }
                                );
                              }}
                            >
                              <Popup>
                                <span>
                                  Start Location Name :{order.start_entity.name}
                                </span>
                              </Popup>
                            </Marker>
                          );
                        })
                      : null}
                  </div>
                )}

                {this.state.showNearbyUsers ? (
                  <div>
                    {this.state.nearbyUsers.length > 0
                      ? this.state.nearbyUsers.map(user => {
                          return (
                            <Marker
                              position={[
                                user.loc.coordinates[0],
                                user.loc.coordinates[1]
                              ]}
                              icon={userImage}
                              key={user.user_id}
                              // onClick={() => {
                              //   this.setState(
                              //     { assignedUser: user.user_id },
                              //     () => {
                              //       console.log(this.state.assignedUser);
                              //     }
                              //   );
                              // }}
                            >
                              <Popup>
                                <span>
                                  User :{`${user.name}-${user.mobile}`}
                                </span>
                              </Popup>
                            </Marker>
                          );
                        })
                      : null}
                  </div>
                ) : null}

                {this.state.showNearbyAssets ? (
                  <div>
                    {this.state.nearbyAssets.length > 0
                      ? this.state.nearbyAssets.map(ast => {
                          return (
                            <Marker
                              position={[
                                ast.loc.coordinates[1],
                                ast.loc.coordinates[0]
                              ]}
                              icon={assets}
                              key={ast._id.$oid}
                              // onClick={() => {
                              //   this.setState(
                              //     { assignedUser: user.user_id },
                              //     () => {
                              //       console.log(this.state.assignedUser);
                              //     }
                              //   );
                              // }}
                            >
                              <Popup>
                                <span>Asset :{`${ast.name}-${ast.asset}`}</span>
                              </Popup>
                            </Marker>
                          );
                        })
                      : null}
                  </div>
                ) : null}
              </Map>
            )}
          </div>
        </Card>

        <Card style={{ margin: 3 }}>
          {this.state.multiSelect?null:(
            <Button
            onClick={() =>
              this.setState({
                multiSelect: true,
                select_order: false
              })
            }
            style={{
              backgroundColor: this.state.multiSelect ? "#faa61a" : "#0d98ad",
              color: "white",
              marginBottom: 25,
              position: "absolute",
              right: 10,
              top: 10
            }}
          >
            Select Multiple Orders
          </Button>
          )}
          
          {this.state.multiSelect ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 400,
                  textAlign: "center",
                  alignSelf: "center",
                  margin: 10
                }}
              >
                <Text style={{ fontWeight: "bolder" }}>
                  {" "}
                  Assign User First Then Select Orders (Optional)
                </Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  labelInValue
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearchUser}
                  style={{ width: "100%" }}
                  value={{
                    key: this.state.task_assign_user,
                    label: this.state.task_assign_user_name
                  }}
                  onChange={this.setAssignedUser}
                  placeholder="Select User"
                >
                  {this.state.filteredUser.length === 0
                    ? UserList
                    : FliteredList}
                </Select>
              </div>
              <Button
                type="link"
                style={{ margin: 10 }}
                onClick={() => this.setState({ select_order: true })}
              >
                Skip User Selection
              </Button>
              <Button
                type="link"
                style={{ margin: 10 }}
                onClick={() =>
                  this.setState({
                    select_order: true,
                    multiSelect: false,
                    task_assign_user: "",
                    task_assign_user_name: "",
                    multiOrderData:[]
                  })
                }
              >
                Reset
              </Button>
              {this.props.userProfileData.order_and_delivery.order
                .generate_multi_task_from_selected_order ? (
                <Button
                  className="login-form-button"
                  onClick={this.generatetask}
                >
                  Generate Task
                </Button>
              ) : null}
            </div>
          ) : null}

          {this.state.select_order ? (
            <div className="cardscontainer">
              {this.state.data.map(order => (
                <CardMaterial
                  key={order._id.$oid}
                  className="cardsingle"
                  style={{
                    marginTop: 30,
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    backgroundColor:
                      this.state.dropoffData.some(
                        el => el.order_number === order.order_number
                      ) ||
                      this.state.pickupData.some(
                        el => el.order_number === order.order_number
                      ) ||
                      this.state.multiOrderData.some(
                        d => d.order_id === order._id.$oid
                      )
                        ? "#B0BEC5"
                        : null
                  }}
                >
                  <CardContent>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Dropdown
                        overlay={
                          <Menu>
                            {this.props.userProfileData.order_and_delivery.order
                              .generate_task_from_order ? (
                              <Menu.Item>
                                <Link
                                  to={{
                                    pathname: "/genTask",
                                    state: { order_id: order._id.$oid }
                                  }}
                                >
                                  <span style={{ fontSize: 17 }}>
                                    Generate Task
                                  </span>
                                </Link>
                              </Menu.Item>
                            ) : null}

                            <Menu.Item>
                              <Link
                                to={{
                                  pathname: "/parcelFromOrder",
                                  state: { order_id: order._id.$oid }
                                }}
                              >
                                <span style={{ fontSize: 17 }}>
                                  Generate Parcel
                                </span>
                              </Link>
                            </Menu.Item>
                          </Menu>
                        }
                        placement="topCenter"
                      >
                        <Icon
                          type="menu"
                          style={{
                            color: "#263238",
                            marginTop: 8,
                            marginRight: 5
                          }}
                        />
                      </Dropdown>
                      <h2
                        style={{
                          marginLeft: 5,
                          letterSpacing: 1
                        }}
                      >
                        {order.name}
                      </h2>
                    </div>

                    <Divider dashed={true} />
                    <p>
                      <Icon
                        type="clock-circle"
                        style={{ color: "#263238", margin: 5 }}
                      />
                      <Text style={{ fontWeight: "bold" }}>Delivery Time</Text>:{" "}
                      {timeConverter(order.delivery_date_time.$date)}
                    </p>
                    <p>
                      <Icon
                        type="shopping"
                        style={{ color: "#263238", margin: 5 }}
                      />
                      <Text style={{ fontWeight: "bold" }}>Shop Name</Text>:{" "}
                      {order.end_entity.name}
                    </p>

                    <p>
                      <Icon
                        type="environment"
                        style={{ color: "#263238", margin: 5 }}
                      />
                      <Text style={{ fontWeight: "bold" }}>
                        Delivery Location
                      </Text>
                      : {order.end_entity.address}
                      {order.end_loc.coordinates ? null : (
                        <Link
                          to={{
                            pathname: "/viewOrder",
                            state: { order_id: order._id.$oid, showMap: true }
                          }}
                        >
                          <Button type="link">
                            <Icon type="warning" style={{ color: "yellow" }} />
                            Add Coordinates
                          </Button>
                        </Link>
                      )}
                    </p>
                    <Divider dashed={true} />
                    <p>
                      <Text style={{ fontWeight: "bold" }}>Order Number</Text>:{" "}
                      {order.order_number}
                    </p>
                    <p>
                      <Text style={{ fontWeight: "bold" }}>Order Item</Text>:{" "}
                      {order.items.length}
                    </p>
                    <p>
                      <Text style={{ fontWeight: "bold" }}>Total</Text>: ৳{" "}
                      {order.total_price}
                    </p>
                    <p>
                      <Text style={{ fontWeight: "bold" }}>
                        Delivery Charge
                      </Text>
                      : ৳ {order.delivery_charge}
                    </p>
                    <p>
                      <Text style={{ fontWeight: "bold" }}>Other Charges</Text>:
                      ৳ {order.other_charges}
                    </p>
                    <p>
                      <Text style={{ fontWeight: "bold" }}>Billed Amount</Text>:
                      ৳ {order.bill_amount}
                    </p>
                    <Divider dashed={true} />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p>Assigned Organization :</p>
                        <p>
                          <Avatar style={{ marginRight: 5 }}>
                            {order.assigned_org_name
                              ? order.assigned_org_name[0]
                              : "U"}
                          </Avatar>
                          {order.assigned_org_name
                            ? order.assigned_org_name
                            : "Unknown"}
                        </p>
                      </div>
                    </div>
                  </CardContent>
                  <CardActions>
                    {this.state.multiSelect ? (
                      <ButtonMaterial
                        onClick={() => {
                          if (
                            this.state.multiOrderData.some(
                              data => data.order_id === order._id.$oid
                            )
                          ) {
                            let removed = this.state.multiOrderData.filter(
                              obj => {
                                return obj.order_id !== order._id.$oid;
                              }
                            );
                            this.setState({ multiOrderData: removed }, () => {
                              console.log(
                                "after removal",
                                this.state.multiOrderData
                              );
                            });
                          } else {
                            let data = {
                              name: order.name,
                              details: order.details,
                              end_loc: order.end_loc,
                              end_loc_name: order.end_loc_name,
                              task_code: randomId(10),
                              price: order.bill_amount,
                              start_loc:
                                order?.start_entity?.loc ?? order.end_loc,
                              start_loc_name:
                                order?.start_entity?.address ??
                                order.end_loc_name,
                              end_time: order.delivery_date_time.$date,
                              start_time: order.delivery_date_time.$date,
                              assigned_to: this.state.task_assign_user,
                              assigned_to_name: this.state
                                .task_assign_user_name,
                              start_dist: 1000,
                              end_dist: 1000,
                              trip_duration: 0,
                              delay_mins: null,
                              assigned_asset: "",
                              prev_task_id: "",
                              m_task_id: "",
                              contact_no: "",
                              contact_name: "",
                              drop_contact_name: order.drop_contact_name,
                              drop_contact_no: order.drop_contact_no,
                              colors: [],
                              order_id: order._id.$oid
                            };
                            let array = [];
                            array.push(data);
                            this.setState(
                              {
                                multiOrderData: [
                                  ...this.state.multiOrderData,
                                  ...array
                                ]
                              },
                              () => {
                                console.log(
                                  "view the final array",
                                  this.state.multiOrderData
                                );
                              }
                            );
                          }
                        }}
                        color={
                          this.state.multiOrderData.some(
                            d => d.order_id === order._id.$oid
                          )
                            ? "secondary"
                            : "primary"
                        }
                      >
                        {this.state.multiOrderData.some(
                          d => d.order_id === order._id.$oid
                        )
                          ? "Deselect"
                          : "Select"}
                      </ButtonMaterial>
                    ) : null}

                    <Link
                      to={{
                        pathname: "/viewOrder",
                        state: { order_id: order._id.$oid }
                      }}
                    >
                      <ButtonMaterial
                        onClick={() => {
                          this.setState({ showModal: true });
                        }}
                        color="primary"
                      >
                        View
                      </ButtonMaterial>
                    </Link>
                    {/* {this.props.userProfileData.order_and_delivery.order
                      .order_update ? ( */}
                      <Link
                        to={{
                          pathname: "/editOrder",
                          state: { order_id: order._id.$oid }
                        }}
                      >
                        <ButtonMaterial color="primary">Edit</ButtonMaterial>
                      </Link>
                    {/* ) : null} */}
                    {this.props.userProfileData.order_and_delivery.order
                      .order_delete ? (
                      <ButtonMaterial
                        color="secondary"
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteOrder(order._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </ButtonMaterial>
                    ) : null}
                  </CardActions>
                </CardMaterial>
              ))}
            </div>
          ) : null}

          {this.state.total > 10 ? (
            <div style={{ textAlign: "center", marginTop: 15 }}>
              <Button
                style={{ color: "white", backgroundColor: "#0d98ad" }}
                onClick={this.handleLoadMore}
              >
                Load More ...
              </Button>
            </div>
          ) : null}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(MyOrders);
