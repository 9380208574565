import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tag,
  Modal,
  Icon,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_PRODUCT,
  CREATE_UNIT,
  CREATE_INVENTORY,
  CREATE_PRODUCT_CATEGORY,
  ORG_IMAGE_UPLOAD,
} from "../../api/constants";
import { debounce } from "lodash";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import CreateUnit from "../unit/createUnit";
// import CreateShop from "../shop/createShop";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditProduct extends Component {
  state = {
    name: "",
    product_id: "",
    description: "",
    unit_purchase_price: null,
    unit_selling_price: null,
    stock_quantity: 0,
    product_status: "active",
    mrp: null,
    sku: "",
    entity_id: "",
    entity_name: "",
    tags: [],
    text: "",
    loading: false,
    data: [],
    entities: [],
    redirectOrder: false,
    showUnit: false,
    showEntity: false,
    product_category_id: "",
    product_category_name: "",
    product_categories: [],
    is_parent_product: false,
    for_name: "product",
    imgFile: null,
    imgUrl: null,
    imgUrlThumbnail: null,
    unit: "",
    low_stock_quantity: 0,
  };
  componentDidMount() {
    this.getUnitList();
    this.getEntityList();
    this.getProductCategory();
    this.getProductDetails();
    this.setState({ product_id: this.props.location.state.product_id });
  }

  getProductDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let product_id = this.props.location.state.product_id;
      let url = `${EDIT_PRODUCT}${product_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit product", response);
        this.setState({
          name: response.data?.data?.name ?? "",
          is_parent_product: response.data?.data?.is_parent_product ?? false,
          description: response.data?.data?.description ?? "",
          low_stock_quantity: response.data?.data?.low_stock_quantity ?? 0,
          product_category_id: response.data?.data?.category_id ?? "",
          product_category_name: response.data?.data?.category_name ?? "",
          entity_id: response.data?.data?.inventory_id ?? "",
          product_status: response.data?.data?.product_status ?? "",
          sku: response.data?.data?.sku ?? "",
          unit: response.data?.data?.unit ?? "",
          unit_purchase_price: response.data?.data?.unit_purchase_price ?? 0,
          unit_selling_price: response.data?.data?.unit_selling_price ?? 0,
          stock_quantity: response.data?.data?.stock_quantity ?? 0,
          imgUrl: response.data?.data?.product_img ?? null,
          imgUrlThumbnail: response.data?.data?.product_img_thumb ?? null,
          mrp: response.data?.data?.mrp ?? 0,
          tags: response.data?.data?.tags ?? [],
        });
      });
    });
  };

  getProductCategory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT_CATEGORY}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ product_categories: response.data.data });
          console.log("data from product category list api", response);
        })
        .catch((error) => {
          console.log("error from product category list api", error);
        });
    });
  };

  getUnitList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_UNIT}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ data: response.data.data });
          console.log("data from unit list api", response);
        })
        .catch((error) => {
          console.log("error from unit list api", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ entities: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?limit=100&entity_name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ entities: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  setProductCategory = (value) => {
    this.setState(
      { product_category_id: value.key, product_category_name: value.label },
      () => {
        console.log(this.state.product_category_name);
      }
    );
  };

  setEntity = (value) => {
    this.setState({ entity_id: value.key, entity_name: value.label }, () => {
      console.log(this.state.entity_id);
    });
  };

  setText = (evt) => {
    evt.preventDefault();
    this.setState({ text: evt.target.value }, () => {
      console.log(this.state.text);
    });
  };

  setTags = () => {
    if (this.state.text === "") {
      return;
    } else {
      let array = [];
      array.push(this.state.text);
      this.setState({ tags: [...this.state.tags, ...array] }, () => {
        this.setState({ text: "" });
        console.log(this.state.tags);
      });
    }
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDescription = (evt) => {
    evt.preventDefault();
    this.setState({ description: evt.target.value }, () => {
      console.log(this.state.description);
    });
  };
  setPurchasePrice = (evt) => {
    evt.preventDefault();
    this.setState({ unit_purchase_price: evt.target.value }, () => {
      console.log(this.state.unit_purchase_price);
    });
  };
  setSellingPrice = (evt) => {
    evt.preventDefault();
    this.setState({ unit_selling_price: evt.target.value }, () => {
      console.log(this.state.unit_selling_price);
    });
  };
  setMrp = (evt) => {
    evt.preventDefault();
    this.setState({ mrp: evt.target.value }, () => {
      console.log(this.state.mrp);
    });
  };
  setSku = (evt) => {
    evt.preventDefault();
    this.setState({ sku: evt.target.value }, () => {
      console.log(this.state.sku);
    });
  };
  setLowStockQuantity = (evt) => {
    evt.preventDefault();
    this.setState({ low_stock_quantity: evt.target.value }, () => {
      console.log(this.state.low_stock_quantity);
    });
  };
  setUnit = (value) => {
    this.setState({ unit: value }, () => {
      console.log(this.state.unit);
    });
  };

  setProductStatus = (value) => {
    this.setState({ product_status: value }, () => {
      console.log(this.state.product_status);
    });
  };
  setStockQuantity = (evt) => {
    evt.preventDefault();
    this.setState({ stock_quantity: evt.target.value }, () => {
      console.log(this.state.stock_quantity);
    });
  };

  uploadImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}${this.state.for_name}?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              imgUrl: `${response.data.data.upload_path}`,
              imgUrlThumbnail: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  editProduct = () => {
    const data = {
      name: this.state.name,
      inventory_id: this.state.entity_id,
      category_id: this.state.product_category_id,
      description: this.state.description,
      product_status: this.state.product_status,
      unit_purchase_price: Number(this.state.unit_purchase_price),
      unit_selling_price: Number(this.state.unit_selling_price),
      stock_quantity: Number(this.state.stock_quantity),
      mrp: Number(this.state.mrp),
      sku: this.state.sku,
      unit: this.state.unit,
      tags: this.state.tags,
      product_img: this.state.imgUrl,
      product_img_thumb: this.state.imgUrlThumbnail,
      low_stock_quantity: Number(this.state.low_stock_quantity),
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_PRODUCT}${this.state.product_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit product", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit product", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
         <PageHeader
          title="Edit Product"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              disabled={this.state.is_parent_product ? false : true}
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Description</Text>
            <Input
              placeholder="Description"
              type="text"
              value={this.state.description}
              onChange={this.setDescription}
            />
          </Form.Item>
          {this.state.imgUrlThumbnail ? (
            <img
              src={`${process.env.REACT_APP_BASE_PATH}${this.state.imgUrlThumbnail}`}
              alt="avatar"
              style={{ width: 200, height: 200 }}
            />
          ) : null}
          <Form.Item>
            <Text>Upload Product Image (Image must be under 100 KB)</Text>

            <input
              type="file"
              onChange={(e) => {
                console.log("Image file inside", this.state.imgUrl);
                this.setState(
                  {
                    imgUrl: URL.createObjectURL(e.target.files[0]),
                    imgFile: e.target.files[0],
                  },
                  () => {
                    this.uploadImage();
                  }
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Product Category</Text>
            <Select
              labelInValue
              value={{
                key: this.state.product_category_id,
                label: this.state.product_category_name,
              }}
              onChange={this.setProductCategory}
            >
              {this.state.product_categories.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Inventory</Text>
            <Select
              showSearch={true}
              labelInValue
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              disabled={
                this.state.entity_id !== "" || this.state.is_parent_product
                  ? true
                  : false
              }
              value={{
                key: this.state.entity_id,
                label: this.state.entity_name,
              }}
              onChange={this.setEntity}
              placeholder="Search Inventory"
            >
              {this.state.entities.map((r) => {
                return <Option key={r._id.$oid}>{r.entity_name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Unit Purchase Price</Text>
            <Input
              placeholder="Unit Purchase Price"
              type="number"
              disabled={this.state.entity_id === "" ? false : true}
              value={this.state.unit_purchase_price}
              onChange={this.setPurchasePrice}
            />
          </Form.Item>
          <Form.Item>
            <Text>Unit Selling Price</Text>
            <Input
              placeholder="Unit Selling Price"
              type="number"
              value={this.state.unit_selling_price}
              onChange={this.setSellingPrice}
            />
          </Form.Item>
          <Form.Item>
            <Text>MRP</Text>
            <Input
              placeholder="MRP"
              type="number"
              value={this.state.mrp}
              onChange={this.setMrp}
            />
          </Form.Item>
          <Form.Item>
            <Text>Stock Quantity</Text>
            <Input
              placeholder="Stock Quantity"
              disabled={this.state.is_parent_product ? true : false}
              type="number"
              value={this.state.stock_quantity}
              onChange={this.setStockQuantity}
            />
          </Form.Item>
          <Form.Item>
            <Text>Low Stock Quantity</Text>
            <Input
              placeholder="Low Stock Quantity"
              disabled={this.state.is_parent_product ? true : false}
              type="number"
              value={this.state.low_stock_quantity}
              onChange={this.setLowStockQuantity}
            />
          </Form.Item>
          <Form.Item>
            <Text>Product Status</Text>
            <Select
              value={this.state.product_status}
              onChange={this.setProductStatus}
            >
              <Option key="active">Active</Option>
              <Option key="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>SKU</Text>
            <Input
              placeholder="SKU"
              type="number"
              value={this.state.sku}
              onChange={this.setSku}
            />
          </Form.Item>

          <Form.Item>
            <Text>
              Unit{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showUnit: true })}
              >
                <Icon type="plus" style={{ fontSize: 20 }} />
              </Button>
            </Text>
            <Select
              value={this.state.unit}
              onChange={this.setUnit}
              placeholder="Select Unit"
            >
              {this.state.data.map((r) => {
                return <Option key={r._id.$oid}>{r.unit}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Tags</Text>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                placeholder="Tags"
                type="text"
                value={this.state.text}
                onChange={this.setText}
              />
              <Button style={{ marginLeft: 5 }} onClick={this.setTags}>
                Add
              </Button>
            </div>
            <Text>Tag Names</Text>
            <Card style={{ marginTop: 5 }}>
              {this.state.tags.map((tag, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {tag}
                  </Tag>
                );
              })}
            </Card>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editProduct}
            >
              Update Product
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Create New Unit"
          visible={this.state.showUnit}
          onOk={() => {
            this.setState({ showUnit: false });
            this.getUnitList();
          }}
          onCancel={() => {
            this.setState({ showUnit: false });
            this.getUnitList();
          }}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
        >
          <CreateUnit />
        </Modal>
      </Card>
    );
  }
}
