import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Pagination,
  Table,
  Input,
  Modal,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_DRIVER,
  CONNECT_ASSET,
  ASSET_SEARCH,
  ALLOCATED_DRIVER_LIST,
  CHANGE_ALLOCATED_DRIVER,
  // CURRENT_USER_SUB_ORG_LIST,
  // GET_FIRST_CHILD_SUB_ORG_LIST,
  // ASSET_PROVIDER,
  // CONNECT_FINDERASSET_ORGASSET,
} from "../../api/constants";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class ConnectAssetToUser extends Component {
  state = {
    driver_id: "",
    pin: "",
    loading: false,
    userData: [],
    assetData: [],
    name: "",
    device_name: "",
    device_id: "",
    allocated_drivers: [],
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_asset_search: "",
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    asset_number: "",
    mobile: "",
    email: "",
    driver_name: "",
    change_allocated_driver_id: "",
    change_allocated_driver_name: "",
    asset_id: "",
    show_change_allocated_driver_modal: false,
    sub_org_name:""
  };

  componentDidMount() {
    this.getUserList();
    this.getAssetList();
    this.getAllocatedDriversList();
  }

  // getCurrentUserSubOrgs = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

  //     axios({
  //       method: "get",
  //       url: url,
  //     })
  //       .then((response) => {
  //         console.log("response from current user sub org list", response);

  //         this.setState(
  //           {
  //             current_user_sub_orgs: response.data.data,
  //             current_user_sub_org_id:
  //               response.data.data.length > 0 &&
  //               !this.props.userProfileData.data_level.access_all_data
  //                 ? response?.data?.data[0].id
  //                 : "",
  //             sub_org_id_asset_search:
  //               response.data.data.length > 0 &&
  //               !this.props.userProfileData.data_level.access_all_data
  //                 ? response?.data?.data[0].id
  //                 : "",
  //           },
  //           () => {
  //             this.getAllocatedDriversList();
  //             if (this.state.current_user_sub_org_id !== "") {
  //               GET_ACCESS_TOKEN().then((token) => {
  //                 const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

  //                 axios({
  //                   method: "get",
  //                   url: url,
  //                 })
  //                   .then((response) => {
  //                     console.log(
  //                       "response from first child sub org list",
  //                       response
  //                     );

  //                     this.setState({
  //                       first_child_sub_orgs: response.data.data,
  //                     });
  //                   })
  //                   .catch((error) => {
  //                     console.log(
  //                       "error from first child sub org list api",
  //                       error
  //                     );
  //                   });
  //               });
  //             }
  //           }
  //         );
  //       })
  //       .catch((error) => {
  //         console.log("error from current user sub org list api", error);
  //       });
  //   });
  // };

  // setCurrentUserSubOrg = (value) => {
  //   this.setState(
  //     { current_user_sub_org_id: value, sub_org_id_asset_search: value },
  //     () => {
  //       this.getAllocatedDriversList();
  //       GET_ACCESS_TOKEN().then((token) => {
  //         const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

  //         axios({
  //           method: "get",
  //           url: url,
  //         })
  //           .then((response) => {
  //             console.log("response from first child sub org list", response);

  //             this.setState({
  //               first_child_sub_orgs: response.data.data,
  //             });
  //           })
  //           .catch((error) => {
  //             console.log("error from first child sub org list api", error);
  //           });
  //       });
  //     }
  //   );
  // };
  // setFirstChildSubOrg = (value) => {
  //   this.setState(
  //     { first_child_sub_org_id: value, sub_org_id_asset_search: value },
  //     () => {
  //       this.getAllocatedDriversList();
  //     }
  //   );
  // };

  getAllocatedDriversList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ALLOCATED_DRIVER_LIST}?sub_org_name=${this.state.sub_org_name}&asset_number=${this.state.asset_number}&mobile=${this.state.mobile}&email=${this.state.email}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from allocated drivers list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from allocated drivers list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        asset_number: "",
        mobile: "",
        email: "",
        driver_name: "",
        sub_org_id_asset_search:"",
        sub_org_name:"",
        page: 0,
        limit: 10,
      },
      () => {
        this.getAllocatedDriversList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getAllocatedDriversList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAllocatedDriversList();
    });
  };

  setValue = (value) => {
    this.setState({ driver_id: value.key, name: value.label }, () => {
      console.log(this.state.driver_id);
    });
  };
  setDriverChangeValue = (value) => {
    this.setState(
      {
        change_allocated_driver_id: value.key,
        change_allocated_driver_name: value.label,
      },
      () => {
        console.log(this.state.change_allocated_driver_id);
      }
    );
  };
  setAsset = (value) => {
    let pin = value.label.slice("#");

    this.setState({ device_id: value.key, pin: pin[2] }, () => {
      console.log(this.state.device_id);
      console.log(this.state.pin);
    });
  };

  getAssetList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from asset list", response);
          this.setState({
            assetData: response.data.data,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from asset list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=10&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);
  handleAssetSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset search list", response);
        })
        .catch((error) => {
          console.log("error from asset search list api", error);
        });
    });
  }, 500);

  connectToAsset = () => {
    if (
      this.state.pin === "" ||
      this.state.driver_id === "" ||
      this.state.device_id === ""
    ) {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        driver_id: this.state.driver_id,
        device_id: this.state.device_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CONNECT_ASSET}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "ifo");
            console.log("response from connect to asset", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from connect to asset", error);
          });
      });
    }
  };
  changeAllocatedDriver = () => {
    const data = {
      user_id: this.state.change_allocated_driver_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CHANGE_ALLOCATED_DRIVER}${this.state.asset_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            asset_id: "",
            show_change_allocated_driver_modal: false,
          });
          swal.fire("Info", response.data.message, "info");
          this.getAllocatedDriversList();
          console.log("response from change allocated driver", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from change allocated driver", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Card style={{ marginTop: 5 }}>
          <PageHeader
           
            title="Connect Asset to Driver"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Form.Item>
              <Text>Select Driver</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearch}
                value={{ key: this.state.driver_id, Label: this.state.name }}
                onChange={this.setValue}
              >
                {this.state.userData.map((r) => {
                  return (
                    <Option
                      key={r._id.$oid}
                    >{`${r.name} (Org Status - ${r.organization_status})`}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Select Asset</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleAssetSearch}
                value={{
                  key: this.state.device_id,
                  Label: this.state.device_name,
                }}
                onChange={this.setAsset}
                placeholder="Select Asset"
              >
                {this.state.assetData.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.connectToAsset}
              >
                Connect Driver
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card style={{ marginTop: 3 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.current_user_sub_orgs.length < 1 ? true : false
                }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <Text> Child Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.first_child_sub_orgs.length < 1 ? true : false
                }
                value={this.state.first_child_sub_org_id}
                onChange={this.setFirstChildSubOrg}
              >
                {this.state.first_child_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div> */}
            <div
              style={{
                marginLeft: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>{`Limit : `}</Text>
              <Select value={this.state.limit} onChange={this.setLimit}>
                <Option key={10}>10</Option>
                <Option key={25}>25</Option>
                <Option key={50}>50</Option>
                <Option key={100}>100</Option>
              </Select>
            </div>
            <div style={{ marginLeft: 5, marginTop: 20, textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#1A3337", color: "white" }}
                onClick={this.refresh}
              >
                Refresh
              </Button>
            </div>
          </div>
          <Table
            dataSource={this.state.data}
            pagination={false}
            rowKey={(record) => record._id.$oid}
            size="small"
            bordered
          >
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Driver Name</Text>
                  </div>

                  <Input disabled />
                </>
              }
              dataIndex="driver_name"
              key="driver_name"
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Mobile</Text>
                  </div>

                  <Input
                    value={this.state.mobile}
                    onChange={(evt) => {
                      this.setState(
                        { mobile: evt.target.value, page: 0 },
                        () => {
                          this.getAllocatedDriversList();
                        }
                      );
                    }}
                  />
                </>
              }
              dataIndex="driver_mobile"
              key="driver_mobile"
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Email</Text>
                  </div>

                  <Input
                    value={this.state.email}
                    onChange={(evt) => {
                      this.setState(
                        { email: evt.target.value, page: 0 },
                        () => {
                          this.getAllocatedDriversList();
                        }
                      );
                    }}
                  />
                </>
              }
              dataIndex="driver_email"
              key="driver_email"
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Sub Org Name</Text>
                  </div>

                  <Input
                    value={this.state.sub_org_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_name: evt.target.value, page: 0 },
                        () => {
                          this.getAllocatedDriversList();
                        }
                      );
                    }}
                  />
                </>
              }
              key="sub_org"
              render={(text, record) => <span>{record.sub_org.name}</span>}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Asset Name</Text>
                  </div>
                  <Input disabled />
                </>
              }
              key="name"
              render={(text, record) => <span>{record.name}</span>}
            />
            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Registration Number</Text>
                  </div>

                  <Input
                    value={this.state.asset_number}
                    onChange={(evt) => {
                      this.setState(
                        { asset_number: evt.target.value, page: 0 },
                        () => {
                          this.getAllocatedDriversList();
                        }
                      );
                    }}
                  />
              
                </>
              }
              key="registration_number"
              render={(text, record) => (
                <span>{record.registration_number}</span>
              )}
            />

            <Column
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Text ellipsis={true}>Created On</Text>
                  </div>
                  <Input disabled />
                </>
              }
              key="created_by"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  <Button type="link" style={{ color: "green" }} onClick={()=>this.setState({asset_id:record._id.$oid,show_change_allocated_driver_modal:true})}>
                    Change Driver
                  </Button>
                </span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page + 1}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
        <Modal
          title="Change Allocated Driver"
          visible={this.state.show_change_allocated_driver_modal}
          onCancel={() => {
            this.setState({ show_change_allocated_driver_modal: false });
            this.getAllocatedDriversList();
          }}
          // width={window.innerWidth - 200}
          footer={null}
          closable={true}
        >
          <Form>
            <Form.Item>
              <Text>Select Driver</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearch}
                value={{
                  key: this.state.change_allocated_driver_id,
                  Label: this.state.change_allocated_driver_name,
                }}
                onChange={this.setDriverChangeValue}
              >
                {this.state.userData.map((r) => {
                  return (
                    <Option
                      key={r._id.$oid}
                    >{`${r.name} (Org Status - ${r.organization_status})`}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.changeAllocatedDriver}
              >
                Change Driver
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(ConnectAssetToUser);
