import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_DRIVER, CREATE_TERRITORY } from "../../api/constants";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Map, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default class CreateTerritory extends Component {
  state = {
    name: "",
    assigned_users: [],
    user_data: [],
    filtered: [],
    loading: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 12,
    polygon_data: []
  };
  componentDidMount() {
    this.getUserList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?limit=1000&d=false&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from user list", response);
          this.setState({
            user_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from user list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  handleSearch = value => {
    const result = this.state.user_data.filter(user => {
      return user.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  // _onEdited = e => {
  //   let numEdited = 0;
  //   e.layers.eachLayer(layer => {
  //     numEdited += 1;
  //   });
  //   console.log(`_onEdited: edited ${numEdited} layers`, e);
  // };

  _onCreated = e => {
    this.setState(
      {
        polygon_data: e.layer.editing.latlngs[0][0].map(long => [
          long.lng,
          long.lat
        ])
      },
      () => {
        let temp_poly_array = [];
        temp_poly_array.push(this.state.polygon_data[0]);
        this.setState(
          { polygon_data: [...this.state.polygon_data, ...temp_poly_array] },
          () => {
            console.log("_onCreated: polygon data:", this.state.polygon_data);
          }
        );
      }
    );
  };

  // _onDeleted = e => {
  //   let numDeleted = 0;
  //   e.layers.eachLayer(layer => {
  //     numDeleted += 1;
  //   });
  //   console.log(`onDeleted: removed ${numDeleted} layers`, e);
  // };

  createTerritory = () => {
    if (
      this.state.name === "" ||
      this.state.assigned_users.length < 1 ||
      this.state.polygon_data.length < 1
    ) {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      const data = {
        name: this.state.name,
        area: {
          type: "Polygon",
          coordinates: [this.state.polygon_data]
        },
        assigned_users: this.state.assigned_users
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_TERRITORY}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from create territory", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from create territory", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const UserList = this.state.user_data.map(r => {
      return <Option key={r._id.$oid}>{`${r.name}>${r.mobile}`}</Option>;
    });

    const FliteredList = this.state.filtered.map(r => {
      return <Option key={r._id.$oid}>{`${r.name}>${r.mobile}`}</Option>;
    });

    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Territory"
        />
        <div>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{ KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",VERSION:"3.49.2" }}
              type={"roadmap"}
            />

            <FeatureGroup>
              <EditControl
                position="topright"
                // onEdited={this._onEdited}
                onCreated={this._onCreated}
                // onDeleted={this._onDeleted}
                draw={{
                  rectangle: false,
                  circle: false,
                  polyline: false,
                  marker: false,
                  circlemarker: false
                }}
              />
              {/* <Polygon positions={multiPolygon} />; */}
            </FeatureGroup>
          </Map>
        </div>
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Search Users</Text>
            <Select
              labelInValue
              mode="multiple"
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              value={this.state.assigned_users}
              onSearch={this.handleSearch}
              onChange={value => {
                this.setState({ assigned_users: value }, () => {
                  console.log(this.state.assigned_users);
                });
              }}
              placeholder="Search Users"
            >
              {this.state.filtered.length === 0 ? UserList : FliteredList}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createTerritory}
            >
              Create Territory
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
