import React, { Component } from "react";
import {
  Card,
  PageHeader,
  Button,
  Table,
  Pagination,
  // Typography,
  // Select,
  // Form
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { FINDER_FUEL_HISTORY } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

// const { Text } = Typography;
// const { Option } = Select;
const { Column } = Table;

export default class FuelData extends Component {
  state = {
    access_token: "",
    asset_id: "",
    time_from: Date.now(),
    time_from_view: moment(),
    time_to: Date.now(),
    time_to_view: moment(),
    // name: "",
    // starting_fuel: "",
    // ending_fuel: "",
    // fuel_usage: "",
    // fuel_intake: "",
    // displayFuelData: false,
    // timezone_name: "",
    // timezone_choice: "org_timezone",
    // history_by: "timestamp",
    // taskData: [],
    // task_name: "",
    // task_id: "",
    // chained_task: false,
    page: 1,
    limit: 10,
    total: 0,
    asset_provider_id: "",
    asset_name: "",
    fuel_history_data: [],
    loading_search: false,
  };

  componentDidMount() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);
    this.setState({
      access_token: this.props.location.state.access_token,
      asset_id: this.props.location.state.asset_id,
      asset_provider_id: this.props.location.state.asset_provider_id,
      asset_name: this.props.location.state.asset_name,
    });
    // this.getTaskData();
  }

  // getTaskData = () => {
  //   let date = new Date();
  //   let time = date.getTime();
  //   console.log(moment(time));
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url = `${CREATE_TASK}?limit=1000&ts=${time}&assigned=true&accepted=true&started=true&completed=true&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url,
  //     })
  //       .then((response) => {
  //         console.log("response from Task list completed", response);
  //         this.setState({
  //           taskData: response.data.data,
  //         });
  //       })
  //       .catch((error) => {
  //         console.log("error from Task list api completed", error);
  //       });
  //   });
  // };

  getFueldata = () => {
    if (this.state.time_from === "" || this.state.time_to === "") {
      swal.fire("Warning", "Please Enter Start and End Time", "warning");
    } else {
      this.setState({ loading_search: true });
      let data = {
        asset_id: this.state.asset_provider_id,
        asset_provider_id: this.state.asset_provider_id,
        start_date: this.state.time_from,
        end_date: this.state.time_to,
        page: this.state.page,
        limit: this.state.limit,
      };
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${FINDER_FUEL_HISTORY}?access_token=${token}`;

        axios({
          method: "post",
          data: data,
          url: url,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            console.log("response from fuelHistory", response);
            this.setState({
              fuel_history_data: response.data?.data ?? [],
              total: response.data?.total ?? 0,
              loading_search: false,
            });
          })
          .catch((error) => {
            this.setState({ loading_search: false });
            console.log("error from fuelHistory", error);
          });
      });
    }
  };

  handleSaveFile = (checked) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url =
        this.state.history_by === "timestamp"
          ? `${FINDER_FUEL_HISTORY}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&asset_id=${this.state.asset_id}&time_from=${this.state.time_from}&time_to=${this.state.time_to}&access_token=${token}`
          : `${FINDER_FUEL_HISTORY}?fmt=xls&task_id=${this.state.task_id}&chained_task=${this.state.chained_task}&asset_id=${this.state.asset_id}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from Attendance list fmt", blob);

          FileSaver.saveAs(
            blob,
            `fuel_report_${moment().format("DD-MMMM-YYYY_hh:mm A")}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from Attendance list api", error);
        });
    });
  };

  // setTimezonetype = (value) => {
  //   this.setState({ timezone_choice: value }, () => {
  //     console.log(this.state.timezone_choice);
  //   });
  // };

  // setHistorytype = (value) => {
  //   this.setState({ history_by: value }, () => {
  //     console.log(this.state.history_by);
  //   });
  // };

  // setTask = (value) => {
  //   this.setState({ task_name: value.label, task_id: value.key }, () => {
  //     console.log(this.state.task_id);
  //   });
  // };

  // setChainTask = (value) => {
  //   this.setState({ chained_task: CHECK_BOOL(value) }, () => {
  //     console.log(this.state.chained_task);
  //   });
  // };

  setStartTime = (value, dateString) => {
    this.setState(
      { time_from: moment(value).format("x"), time_from_view: value },
      () => {
        console.log("Selected Time: ", this.state.time_from);
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      { time_to: moment(value).format("x"), time_to_view: value },
      () => {
        console.log("Selected Time: ", this.state.time_to);
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getFueldata();
    });
  };
  render() {
    return (
      <div>
        <Card style={{ margin: 10 }}>
          <PageHeader
            title={`Fuel data for ${this.state.asset_name}`}
            onBack={() => this.props.history.goBack()}
          />
          {/* <Card style={{ marginTop: 10 }}>
            <div style={{ textAlign: "center" }}>
              <Text> Fuel History By : {""}</Text>
              <Select
                value={this.state.history_by}
                onChange={this.setHistorytype}
                placeholder="Fuel History By"
              >
                <Option key="timestamp">Time</Option>
                <Option key="task">Task</Option>
              </Select>
            </div>
          </Card> */}

          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    minDate={moment().subtract(30, "days")}
                    disableFuture
                    inputVariant="outlined"
                    value={this.state.time_from_view}
                    onChange={(value, dateString) => {
                      let start_date = moment(value);
                      let end_date = moment(this.state.time_to_view);
                      console.log(end_date.diff(start_date, "days"));
                      if (end_date.diff(start_date, "days") > 7) {
                        swal.fire(
                          "Info",
                          "You can only select maximum 7 days data",
                          "info"
                        );
                      } else {
                        this.setStartTime(value, dateString);
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    minDate={moment().subtract(30, "days")}
                    disableFuture
                    inputVariant="outlined"
                    value={this.state.time_to_view}
                    onChange={(value, dateString) => {
                      let end_date = moment(value);
                      let start_date = moment(this.state.time_from_view);
                      console.log(end_date.diff(start_date, "days"));
                      if (end_date.diff(start_date, "days") > 7) {
                        swal.fire(
                          "Info",
                          "You can only select maximum 7 days data",
                          "info"
                        );
                      } else {
                        this.setEndTime(value, dateString);
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {/* <div style={{ margin: 10 }}>
                <Text> Timezone : {""}</Text>
                <Select
                  value={this.state.timezone_choice}
                  onChange={this.setTimezonetype}
                  placeholder="View as"
                >
                  <Option key="user_timezone">User</Option>
                  <Option key="org_timezone">Organization</Option>
                </Select>
              </div> */}
              <Button
                loading={this.state.loading_search}
                style={{
                  width: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 15,
                  marginRight: 10,
                }}
                // className="login-form-button"
                onClick={() => this.getFueldata()}
                // style={{ marginTop: 10, width: 250 }}
              >
                {" "}
                {this.state.loading_search ? "Searching..." : "Search"}
              </Button>

              <Button
                onClick={this.handleSaveFile}
                style={{
                  width: 200,
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 15,
                }}
              >
                Download
              </Button>
            </div>
          </div>

          <Table
            dataSource={this.state.fuel_history_data}
            pagination={false}
            rowKey={(record) => record.time}
            size="small"
            bordered
          >
            <Column
              title="Calculated Fuel (in Litre)"
              key="calculated_fuel"
              render={(text, record) => <span>{record.calculated_fuel}</span>}
            />
            <Column
              title="Date"
              key="time"
              render={(text, record) => (
                <span>{timeConverter(record.time * 1000)}</span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
      </div>
    );
  }
}
