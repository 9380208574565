import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import {
  MAINTENANCE_PRIORITY_LEVEL,
  MAINTENANCE_TYPE_CHOICES,
  MAINTENANCE_STATUS_CHOICES,
  CREATE_MAINTENANCE,
  ASSET_SEARCH,
  CREATE_ENTITY,
} from "../../api/constants";
import { debounce } from "lodash";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default class CreateMaintenance extends Component {
  state = {
    maintenance_priority_level_choices: [],
    priority_level: "",
    maintenance_type_choices: [],
    maintenance_type: [],
    maintenance_status_choices: [],
    maintenance_status: "",
    entities: [],
    assigned_workshop_id: "",
    assets: [],
    asset_id: "",
    odometer_reading: "",
    issue_detail: "",
    approximate_start_time: Date.now(),
    approximate_start_time_view: moment(),
    approximate_days: 1,
  };

  componentDidMount() {
    this.getMaintenancePriorityLevel();
    this.getEntityList();
    this.getAssetList();
    this.getMaintenanceTypeChoices();
    this.getMaintenanceStatusChoices();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getMaintenanceTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${MAINTENANCE_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            maintenance_type_choices: response.data.maintenance_type_choices,
            maintenance_type:
              response.data?.maintenance_type_choices?.length > 0
                ? [response.data?.maintenance_type_choices[0]]
                : [],
          });
          console.log("data from maintenance type", response);
        })
        .catch((error) => {
          console.log("error from maintenance type", error);
        });
    });
  };
  getMaintenancePriorityLevel = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${MAINTENANCE_PRIORITY_LEVEL}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            maintenance_priority_level_choices:
              response.data.maintenance_priority_level_choices,

            priority_level:
              response.data?.maintenance_priority_level_choices?.length > 0
                ? response.data?.maintenance_priority_level_choices[0]
                : "",
          });
          console.log(
            "data from product category list apimaintenance priority level",
            response
          );
        })
        .catch((error) => {
          console.log("error from maintenance priority level", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ entities: response.data.data });
          console.log("data from place list api", response);
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  };
  getAssetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assets: response.data.data });
          console.log("data from asset list api", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  };

  getMaintenanceStatusChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${MAINTENANCE_STATUS_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            maintenance_status_choices:
              response.data.maintenance_status_choices,
            maintenance_status:
              response.data?.maintenance_status_choices?.length > 0
                ? response.data?.maintenance_status_choices[0]
                : "",
          });
          console.log("data from maintenance staus", response);
        })
        .catch((error) => {
          console.log("error from maintenance staus", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ entities: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);
  handleSearchAsset = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?limit=100&name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assets: response.data.data });
          console.log("data from asset list api", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  }, 500);

  CreateMaintenance = () => {
    if (this.state.asset_id === "" || this.state.odometer_reading === "") {
      swal.fire(
        "warning",
        "Please enter asset and odometer reading",
        "warning"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        asset_id: this.state.asset_id,
        odometer_reading: this.state.odometer_reading,
        issue_detail: this.state.issue_detail,
        priority_level: this.state.priority_level,
        approximate_start_time: this.state.approximate_start_time,
        approximate_days: Number(this.state.approximate_days),
        maintenance_type: this.state.maintenance_type,
        assigned_workshop_id: this.state.assigned_workshop_id,
        maintenance_status: this.state.maintenance_status,
        loc: [],
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_MAINTENANCE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");

            console.log("response from create product", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire(
              "Error",
              error?.response?.data?.message ?? error.message,
              "error"
            );
            console.log("error from create product", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader title="Create Maintenance (* Marked Fields are Mandatory)" />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Card style={{ width: "50%" }}>
              <Form.Item>
                <Text>Search Asset *</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearchAsset}
                  value={this.state.asset_id}
                  onChange={(value) => {
                    this.setState({ asset_id: value });
                  }}
                >
                  {this.state.assets.map((r) => {
                    return <Option key={r._id.$oid}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>Odometer Reading *</Text>
                <Input
                  type="text"
                  value={this.state.odometer_reading}
                  onChange={(e) => {
                    this.setState({ odometer_reading: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Issue Detail</Text>
                <TextArea
                  rows={4}
                  type="text"
                  value={this.state.issue_detail}
                  onChange={(e) => {
                    this.setState({ issue_detail: e.target.value });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Priority Level</Text>
                <Select
                  value={this.state.priority_level}
                  onChange={(value) => {
                    this.setState({ priority_level: value });
                  }}
                >
                  {this.state.maintenance_priority_level_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Card>
            <Card style={{ width: "50%", marginLeft: 5 }}>
              <Form.Item>
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Approximate Start Time</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.approximate_start_time_view}
                      onChange={(value, dateString) => {
                        this.setState(
                          {
                            approximate_start_time: moment(value).format("x"),
                            approximate_start_time_view: value,
                          },
                          () => {
                            console.log(
                              "Selected Time: ",
                              this.state.approximate_start_time
                            );
                            console.log(
                              "Selected value: ",
                              this.state.approximate_start_time_view
                            );
                          }
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>
              <Form.Item>
                <Text>Approximate Days</Text>
                <Input
                  type="number"
                  value={this.state.approximate_days}
                  onChange={(e) => {
                    this.setState({ approximate_days: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Maintenance Type</Text>
                <Select
                  value={this.state.maintenance_type}
                  mode="multiple"
                  onChange={(value) => {
                    this.setState({ maintenance_type: value });
                  }}
                >
                  {this.state.maintenance_type_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Search Assigned Workshop</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  value={this.state.assigned_workshop_id}
                  onChange={(value) => {
                    this.setState({ assigned_workshop_id: value });
                  }}
                >
                  {this.state.entities.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Maintenance Status</Text>
                <Select
                  value={this.state.maintenance_status}
                  onChange={(value) => {
                    this.setState({ maintenance_status: value });
                  }}
                >
                  {this.state.maintenance_status_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Card>
          </div>

          <Form.Item>
            <Button
              style={{ marginTop: 5 }}
              className="login-form-button"
              htmlType="submit"
              onClick={this.CreateMaintenance}
            >
              Create Maintenance
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
