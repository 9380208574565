import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_TASK_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;

export default class CreateTaskType extends Component {
  state = {
    name: "",
    data: []
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  createTaskType = () => {
    if (this.state.name === "") {
      swal.fire("Info", "Please enter name of the type", "info");
    } else {
      const data = {
        name: this.state.name.replace(/\s+/g, ' ').trim()
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_TASK_TYPE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            console.log("response from create task type", response);
          })
          .catch(error => {
            swal.fire("Error",  error?.response?.data?.message??error.message, "error");
            console.log("error from create task type", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Create Task Type"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createTaskType}
            >
              Create Task Type
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
