import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import {
  EDIT_BILL,
  CREATE_SUPPLIER,
  CREATE_PROCEDURE,
  ASSET_SEARCH,
  CREATE_BILL_TYPE,
  CREATE_TASK,
  CREATE_ATTENDANCE,
  ALL_MATERIAL,
  APPROVAL_SYSTEM_CHOICES,
  BILL_ITEMS_EDIT,
  CREATE_DRIVER,
  CREATE_INCIDENT,
  CURRENT_USER_SUB_ORG_LIST,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import CreateMaterial from "../material/createMaterial";
// import CreateMaterialType from "../mtype/createMtype";
import CreateSupplier from "../supplier/createSupplier";
// import CreateUnit from "../unit/createUnit";
import { debounce } from "lodash";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class EditBill extends Component {
  state = {
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    contact_no: "",
    supplier_name: "",
    supplier_name_key: "",
    rcmnd_price: null,
    appr_price: null,
    approved: "pending",
    recommended: "pending",
    authorised: "pending",
    data: [],
    dataS: [],
    loading: false,
    order_id: "",
    products: [],
    edit: false,
    procedureData: [],
    recommender_id: "",
    authoriser_id: "",
    approver_id: "",
    asset_id: "",
    asset_name: "",
    assetData: [],
    miscellaneous_bill_type_choices: [],
    misc_bill_type: "",
    taskData: [],
    started: [],
    completed: [],
    task_name: "",
    task_id: "",
    attendanceData: [],
    attendance_name: "",
    attendance_id: "",
    showSupplier: false,
    showEntity: false,
    showProduct: false,
    showUnit: false,
    bill_id: "",
    bill_no: "",
    approval_system_choices: [],
    filtered_items: [],
    selected_items: [],
    item_price: 0,
    item_unit_price: 0,
    item_recommended_price: 0,
    item_authorized_price: 0,
    item_approved_price: 0,
    item_qty: 0,
    item_name: "",
    item_unit: "",
    item_id: "",
    item_edit_modal: false,
    generated_for: "",
    users: [],
    incidents: [],
    incident_id: "",
    incident_name: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    sub_org_name:""
  };

  componentDidMount() {
    this.props.getUserData();
    this.getProcedureList();
    this.getProductList();
    this.getSupplierList();
    this.getBill();
    this.getAssetList();
    this.getBillTypeChoices();
    this.started();
    this.getAttendanceList();
    this.getApprovalSystemChoices();
    this.getUserList();
    this.getIncident();
    this.getCurrentUserSubOrgs();
    this.setState({ bill_id: this.props.location.state.bill_id });
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState({
            current_user_sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getIncident = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${CREATE_INCIDENT}?name=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("incident", response);
          this.setState({ incidents: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearchUser = debounce((value) => {
    console.log("searc text", value);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setUser = (value) => {
    this.setState({ generated_for: value }, () => {
      console.log(this.state.generated_for);
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState({ current_user_sub_org_id: value.key,sub_org_name:value.label });
  };

  getApprovalSystemChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${APPROVAL_SYSTEM_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from approval system choices", response);
          this.setState({
            approval_system_choices:
              response.data.approval_system_status_choices,
          });
        })
        .catch((error) => {
          console.log("error from approval system choices", error);
        });
    });
  };

  getAttendanceList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ATTENDANCE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from Attendance list", response);
          this.setState({
            attendanceData: response.data.data,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from attendance list api", error);
        });
    });
  };

  completed = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TASK}?limit=1000&ts=${time}&assigned=true&accepted=true&started=true&completed=true&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Task list completed", response);
          this.setState(
            {
              completed: response.data.data,
            },
            () => {
              this.setState(
                { taskData: this.state.started.concat(this.state.completed) },
                () => {
                  console.log("taskdata for admin", this.state.taskData);
                }
              );
            }
          );
        })
        .catch((error) => {
          console.log("error from Task list api completed", error);
        });
    });
  };

  started = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TASK}?limit=1000&ts=${time}&assigned=true&accepted=true&started=true&completed=false&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Task list started", response);
          this.setState(
            {
              started: response.data.data,
            },
            () => {
              this.completed();
            }
          );
        })
        .catch((error) => {
          console.log("error from Task list api", error);
        });
    });
  };

  getBillTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            miscellaneous_bill_type_choices: response.data.data,
          });
          console.log("response from bill type choices", response);
        })
        .catch((error) => {
          console.log("error from bill type choices api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${ASSET_SEARCH}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset list", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  };

  getProcedureList = () => {
    let limit = 1000;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PROCEDURE}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from procedure list", response);
          let requistion = response.data.data.filter(
            (proce) => proce.name.toLowerCase() === "bill"
          );
          let recommender_id = requistion[0].recommender;
          let authoriser_id = requistion[0].authorizer;
          let approver_id = requistion[0].approver;
          this.setState(
            {
              recommender_id: recommender_id,
              authoriser_id: authoriser_id,
              approver_id: approver_id,
            },
            () => {
              console.log("recommender", this.state.recommender_id);
              console.log("auhtorizer", this.state.authoriser_id);
              console.log("approver", this.state.approver_id);
            }
          );
        })
        .catch((error) => {
          console.log("error from procedure list api", error);
        });
    });
  };

  searchIncident = (value) => {
    this.getIncident(value);
  };
  setIncident = (value) => {
    this.setState(
      { incident_id: value.key, incident_name: value.label },
      () => {
        console.log(this.state.incident_name);
      }
    );
  };

  getBill = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let bill_id = this.props.location.state.bill_id;
      let url = `${EDIT_BILL}${bill_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit bill", response.data.data);
        if (response.data.message === "Bill not found") {
          this.props.history.goBack();
          swal.fire("Info", response.data.message, "info");
        }
        this.setState(
          {
            name: response.data.data.name,
            bill_no: response.data?.data?.bill_no ?? "",
            details: response.data.data.details,
            price: response.data.data.total_price,
            contact_no: response.data.data.contact_no,
            supplier_name: response.data.data.supplier.name,
            supplier_name_key: response.data.data.supplier.id,
            rcmnd_price: response.data.data.rcmnd_price,
            appr_price: response.data.data.appr_price,
            approved: response.data?.data?.approved ?? "pending",
            recommended: response.data?.data?.recommended ?? "pending",
            authorised: response.data?.data?.authorized ?? "pending",
            products: response.data.data.items,
            asset_id: response.data.data.asset_id,
            asset_name: response.data?.data?.asset_name ?? "",
            incident_id: response.data.data.incident_id,
            incident_name: response.data?.data?.incident_name ?? "",
            misc_bill_type: response.data?.data?.misc_bill_type?.name ?? "",
            task_id: response.data?.data?.task_id ?? "",
            task_name: response.data?.data?.task_name ?? "",
            attendance_id: response.data?.data?.attendance_id ?? "",
            generated_for: response.data?.data?.generated_for ?? "",
            attendance_name: response.data?.data?.attendance_name ?? "",
            current_user_sub_org_id: response.data?.data?.sub_org?.id ?? "",
            sub_org_name: response.data?.data?.sub_org?.name ?? "",
          },
          () => {
            // this.getProductList();
          }
        );
      });
    });
  };

  getSupplierList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 1000;
      const url = `${CREATE_SUPPLIER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ dataS: response.data.data });
        })
        .catch((error) => {
          console.log("error from supplier list api", error);
        });
    });
  };

  getProductList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ALL_MATERIAL}?search=true&search_text=${this.state.misc_bill_type}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_price: p.unit_price,
            price: p.price,
            unit: p.unit,
            qty: 0,
            recommended_price: p.recommended_price,
            authorized_price: p.authorized_price,
            approved_price: p.approved_price,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_price: p.unit_price,
            price: p.price,
            unit: p.unit,
            qty: 0,
            recommended_price: p.recommended_price,
            authorized_price: p.authorized_price,
            approved_price: p.approved_price,
          }));

          console.log("..........................", products);
          this.setState({ data: products1, items: products });
        })
        .catch((error) => {
          console.log("error from supplier list api", error);
        });
    });
  };

  itemSearch = (value) => {
    const result = this.state.items.filter((item) => {
      return item.item.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_items: result });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setDetails = (evt) => {
    evt.preventDefault();
    this.setState({ details: evt.target.value }, () => {
      console.log(this.state.details);
    });
  };

  setContact = (evt) => {
    evt.preventDefault();
    this.setState({ contact_no: evt.target.value }, () => {
      console.log(this.state.contact_no);
    });
  };
  setAssignedAsset = (value) => {
    this.setState({ asset_name: value.label, asset_id: value.key }, () => {
      console.log(this.state.asset_id);
    });
  };
  setSupplier = (value) => {
    this.setState(
      { supplier_name: value.label, supplier_name_key: value.key },
      () => {
        console.log(this.state.supplier_name);
      }
    );
  };
  setTask = (value) => {
    this.setState({ task_name: value.label, task_id: value.key }, () => {
      console.log(this.state.task_id);
    });
  };
  setAttendance = (value) => {
    this.setState(
      { attendance_name: value.label, attendance_id: value.key },
      () => {
        console.log(this.state.attendance_id);
      }
    );
  };
  setBillType = (value) => {
    this.setState({ misc_bill_type: value }, () => {
      this.getProductList();
      console.log(this.state.misc_bill_type);
    });
  };
  setRcprice = (evt) => {
    evt.preventDefault();
    this.setState({ rcmnd_price: evt.target.value }, () => {
      console.log(this.state.rcmnd_price);
    });
  };
  setApprice = (evt) => {
    evt.preventDefault();
    this.setState({ appr_price: evt.target.value }, () => {
      console.log(this.state.appr_price);
    });
  };

  setApprroved = (value) => {
    this.setState({ approved: value }, () => {
      console.log(this.state.approved);
    });
  };
  setRecommended = (value) => {
    this.setState({ recommended: value }, () => {
      console.log(this.state.recommended);
    });
  };
  setAuthorized = (value) => {
    this.setState({ authorised: value }, () => {
      console.log(this.state.authorised);
    });
  };

  editBill = () => {
    this.setState({ loading: true });
    let final = this.state.data.filter((i) => i.qty !== 0);
    let data = {
      name: this.state.name,
      bill_no: this.state.bill_no,
      details: this.state.details,
      asset_id: this.state.asset_id,
      incident_id: this.state.incident_id,
      generated_for: this.state.generated_for,
      asset_name: this.state.asset_name,
      items: final,
      contact_no: this.state.contact_no,
      supplier: {
        name: this.state.supplier_name,
        id: this.state.supplier_name_key,
      },
      approved: this.state.approved,
      recommended: this.state.recommended,
      authorized: this.state.authorised,
      misc_bill_type_id: this.state.misc_bill_type,
      task_id: this.state.task_id,
      task_name: this.state.task_name,
      attendance_id: this.state.attendance_id,
      attendance_name: this.state.attendance_name,
      sub_org: this.state.current_user_sub_org_id,
    };
    console.log("edit true", data);

    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_BILL}${this.state.bill_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit bill", response);
          this.getBill();
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from edit bill", error);
        });
    });
  };

  updateItem = (
    item_id,
    item_name,
    item_unit,
    item_unit_price,
    item_qty,
    item_price,
    item_recommended_price,
    item_authorized_price,
    item_approved_price
  ) => {
    this.setState({ item_edit_modal: false });
    let final = [
      ...[
        {
          id: item_id,
          item: item_name,
          unit: item_unit,
          unit_price: item_unit_price,
          qty: item_qty,
          price: item_price,
          recommended_price: item_recommended_price,
          authorized_price: item_authorized_price,
          approved_price: item_approved_price,
        },
      ],
      ...this.state.products.filter((item) => item.id !== item_id),
    ];
    const data = {
      items: final,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${BILL_ITEMS_EDIT}${this.state.bill_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit bill item", response);
          this.setState({
            item_price: 0,
            item_name: "",
            item_unit: "",
            item_unit_price: 0,
            item_qty: 0,
            item_id: "",
            item_recommended_price: 0,
            item_authorized_price: 0,
            item_approved_price: 0,
          });
          this.getBill();
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          this.setState({
            item_price: 0,
            item_name: "",
            item_unit: "",
            item_unit_price: 0,
            item_qty: 0,
            item_id: "",
            item_recommended_price: 0,
            item_authorized_price: 0,
            item_approved_price: 0,
          });
          console.log("error from edit trasnport bill", error);
        });
    });
  };

  deleteBillItem = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = ` ${BILL_ITEMS_EDIT}${this.state.bill_id}?item_id=${id}&access_token=${token}`;
      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          this.getBill();
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error",  error?.response?.data?.message??error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    const ItemList = this.state.items
      .filter((item) => !this.state.products.some((p) => item.id === p.id))
      .map((r) => {
        return (
          <Option key={r.id} value={JSON.stringify(r)}>
            {r.item}
          </Option>
        );
      });

    const FilteredItem = this.state.filtered_items
      .filter((item) => !this.state.products.some((p) => item.id === p.id))
      .map((r) => {
        return (
          <Option key={r.id} value={JSON.stringify(r)}>
            {r.item}
          </Option>
        );
      });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          title="Edit Bill"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Form.Item>
                <Text>Name</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              <Form.Item>
                <Text>Details</Text>
                <TextArea
                  rows={4}
                  placeholder="Details"
                  type="text"
                  value={this.state.details}
                  onChange={this.setDetails}
                />
              </Form.Item>
              <Form.Item>
                <Text>Contact Number</Text>
                <Input
                  placeholder="+8801711..."
                  type="text"
                  value={this.state.contact_no}
                  onChange={this.setContact}
                />
              </Form.Item>
              <Form.Item>
                <Text>Bill Type</Text>
                <Select
                  value={this.state.misc_bill_type}
                  onChange={this.setBillType}
                  placeholder="Bill Type"
                >
                  {this.state.miscellaneous_bill_type_choices
                    .filter((bill) => bill.name !== "Conveyance")
                    .map((r) => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Sub Org</Text>
                <Select
                  style={{ minWidth: 200 }}
                  labelInValue
                  value={{key:this.state.current_user_sub_org_id,label:this.state.sub_org_name}}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </Form.Item>
              {this.props.userProfileData.data_level.access_suborg_data ||
              this.props.userProfileData.data_level.access_all_data ? (
                <Form.Item>
                  <Text>Bill Created For</Text>
                  <Select
                    value={this.state.generated_for}
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    onSearch={this.handleSearchUser}
                    onChange={this.setUser}
                  >
                    {this.state.users.map((r) => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {/* {GET_USER_LOCAL().org_admin ? ( */}
              <Form.Item>
                <Text>Search Incident for Bill</Text>
                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue
                  value={{
                    key: this.state.incident_id,
                    label: this.state.incident_name,
                  }}
                  onSearch={this.searchIncident}
                  onChange={this.setIncident}
                >
                  {this.state.incidents.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Search Task for Bill</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.task_id,
                    label: this.state.task_name,
                  }}
                  onChange={this.setTask}
                  placeholder="Select Task for Bill"
                >
                  {this.state.taskData.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>
                  Supplier Name{" "}
                  <Button
                    type="link"
                    onClick={() => this.setState({ showSupplier: true })}
                  >
                    Create Supplier
                  </Button>
                </Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.supplier_name_key,
                    label: this.state.supplier_name,
                  }}
                  onChange={this.setSupplier}
                  placeholder="Select Supplier"
                >
                  {this.state.dataS.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Assign to (Asset)</Text>
                <Select
                  labelInValue
                  style={{ width: "100%" }}
                  value={{
                    key: this.state.asset_id,
                    label: this.state.asset_name,
                  }}
                  onChange={this.setAssignedAsset}
                  placeholder="Select Asset"
                >
                  {this.state.assetData.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>Add New Items (Optional)</Text>
                <Card>
                  <Table
                    dataSource={this.state.products}
                    pagination={false}
                    rowKey={(record) => record.item}
                    size="small"
                  >
                    <Column title="Item" dataIndex="item" key="product" />

                    <Column
                      title="Recommended Price"
                      dataIndex="recommended_price"
                      key="recommended_price"
                    />
                    <Column
                      title="Authorized Price"
                      dataIndex="authorized_price"
                      key="authorized_price"
                    />
                    <Column
                      title="Approved Price"
                      dataIndex="approved_price"
                      key="approved_price"
                    />
                    <Column title="Unit" dataIndex="unit" key="unit" />
                    <Column
                      title="Unit Price"
                      dataIndex="unit_price"
                      key="unit_price"
                    />
                    <Column title="Quantity" key="qty" dataIndex="qty" />
                    <Column title="Price" dataIndex="price" key="price" />
                    <Column
                      title="Action"
                      key="action"
                      render={(text, record) => (
                        <span>
                          {this.props.userProfileData.bill.bill
                            .bill_item_udpade ? (
                            <Button
                              type="link"
                              style={{ color: "green" }}
                              onClick={() =>
                                this.setState(
                                  {
                                    item_price: record.price,
                                    item_name: record.item,
                                    item_unit: record.unit,
                                    item_unit_price: record.unit_price,
                                    item_qty: record.qty,
                                    item_id: record.id,
                                    item_recommended_price:
                                      record.recommended_price,
                                    item_authorized_price:
                                      record.authorized_price,
                                    item_approved_price: record.approved_price,
                                  },
                                  () => this.setState({ item_edit_modal: true })
                                )
                              }
                            >
                              Edit
                            </Button>
                          ) : null}
                          {this.props.userProfileData.bill.bill
                            .bill_item_udpade ? (
                            <Button
                              type="link"
                              style={{ color: "red" }}
                              onClick={() => this.deleteBillItem(record.id)}
                            >
                              Delete
                            </Button>
                          ) : null}
                        </span>
                      )}
                    />
                  </Table>
                  <Text
                    style={{ margin: 5 }}
                  >{`Total Price : ${this.state.price}`}</Text>
                  <Form.Item>
                    <Text>
                      Search Item{" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showEntity: true })}
                      >
                        Create Item
                      </Button>
                    </Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue
                      onSearch={this.itemSearch}
                      onChange={(value, e) => {
                        let item = JSON.parse(e.props.value);
                        let inc = {
                          item: item.item,
                          unit_price: item.unit_price,
                          price: item.price,
                          mtype: item.mtype,
                          qty: 1,
                          unit: item.unit,
                          recommended_price: item.recommended_price,
                          authorized_price: item.authorized_price,
                          approved_price: item.approved_price,
                          id: item.id,
                          unit_price_disable: false,
                        };
                        inc.price = inc.unit_price * inc.qty;
                        let temp = [];
                        temp.push(inc);
                        //real data
                        let filteredData = this.state.data.filter(
                          (i) => i.item !== item.item
                        );

                        this.setState(
                          { data: [...temp, ...filteredData] },
                          () => {
                            this.setState(
                              {
                                data: this.state.data.filter(
                                  (value, index, self) =>
                                    self.findIndex((t) => t.id === value.id) ===
                                    index
                                ),
                              },
                              () => {
                                console.log(
                                  "selection data state",
                                  this.state.data
                                );
                              }
                            );
                          }
                        );
                        //selected data
                        this.setState(
                          {
                            selected_items: [
                              ...this.state.selected_items,
                              ...temp,
                            ],
                          },
                          () => {
                            this.setState({
                              selected_items: this.state.selected_items.filter(
                                (value, index, self) =>
                                  self.findIndex((t) => t.id === value.id) ===
                                  index
                              ),
                            });
                            console.log(this.state.selected_items);
                          }
                        );
                      }}
                      placeholder="Search Item"
                    >
                      {this.state.filtered_items.length === 0
                        ? ItemList
                        : FilteredItem}
                    </Select>
                  </Form.Item>
                  {this.state.selected_items.length > 0 ? (
                    <Table
                      dataSource={this.state.selected_items}
                      pagination={false}
                      rowKey={(record) => record.id}
                      size="small"
                    >
                      <Column title="Item" dataIndex="item" key="product" />
                      <Column
                        title="Unit"
                        dataIndex="unit"
                        key="unit"
                        style={{ paddingLeft: 15 }}
                      />
                      <Column
                        title="Unit Price"
                        key="unit_price"
                        render={(record) => (
                          <span
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Input
                              type="number"
                              disabled={
                                this.state.data.find(
                                  (i) => i.item === record.item
                                ).unit_price_disable
                              }
                              value={
                                this.state.data.find(
                                  (i) => i.item === record.item
                                ).unit_price
                              }
                              onChange={(evt) => {
                                let item = this.state.data.find(
                                  (i) => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: Number(evt.target.value),
                                  price: item.price,
                                  mtype: item.mtype,
                                  qty: item.qty,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price,
                                  unit_price_disable: item.unit_price_disable,
                                  id: record.id,
                                };
                                inc.price = Number(evt.target.value) * inc.qty;
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  (i) => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            />
                            <Button
                              type="link"
                              onClick={() => {
                                let item = this.state.data.find(
                                  (i) => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: item.price,
                                  mtype: item.mtype,
                                  qty: item.qty,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price,
                                  unit_price_disable: item.unit_price_disable,
                                  id: record.id,
                                };
                                inc.unit_price_disable =
                                  !inc.unit_price_disable;
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  (i) => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            >
                              Edit Unit Price
                            </Button>
                          </span>
                        )}
                      />
                      <Column
                        title="Decrease"
                        key="Decrease"
                        render={(record) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "red",
                              }}
                              shape="circle"
                              onClick={() => {
                                if (
                                  this.state.data.find(
                                    (i) => i.item === record.item
                                  ).qty <= 0
                                ) {
                                  return;
                                } else {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: item.price,
                                    mtype: item.mtype,
                                    qty: item.qty - 1,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.price = inc.unit_price * inc.qty;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }
                              }}
                            >
                              <Icon type="minus" />
                            </Button>
                          </span>
                        )}
                      />

                      <Column
                        title="Quantity"
                        key="quantity"
                        render={(record) => (
                          <span>
                            <Input
                              type="number"
                              value={
                                this.state.data.find(
                                  (i) => i.item === record.item
                                ).qty
                              }
                              onChange={(evt) => {
                                if (Number(evt.target.value <= 0)) {
                                  return;
                                } else {
                                  let item = this.state.data.find(
                                    (i) => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: item.price,
                                    mtype: item.mtype,
                                    qty: Number(evt.target.value),
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price,
                                    unit_price_disable: item.unit_price_disable,
                                    id: record.id,
                                  };
                                  inc.price =
                                    inc.unit_price * Number(evt.target.value);
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    (i) => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }
                              }}
                            />
                          </span>
                        )}
                      />
                      <Column
                        title="Increase"
                        key="Increase"
                        render={(record) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "green",
                              }}
                              shape="circle"
                              onClick={() => {
                                let item = this.state.data.find(
                                  (i) => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: item.price,
                                  mtype: item.mtype,
                                  qty: item.qty + 1,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price,
                                  unit_price_disable: item.unit_price_disable,
                                  id: record.id,
                                };
                                inc.price = inc.unit_price * inc.qty;
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  (i) => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            >
                              <Icon type="plus" />
                            </Button>
                          </span>
                        )}
                      />
                      <Column
                        title="Price"
                        key="price"
                        render={(record) => (
                          <span
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Input
                              type="number"
                              disabled={
                                !this.state.data.find(
                                  (i) => i.item === record.item
                                ).unit_price_disable
                              }
                              value={
                                this.state.data.find(
                                  (i) => i.item === record.item
                                ).price
                              }
                              onChange={(evt) => {
                                let item = this.state.data.find(
                                  (i) => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: Number(evt.target.value),
                                  mtype: item.mtype,
                                  qty: item.qty,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price,
                                  unit_price_disable: item.unit_price_disable,
                                  id: record.id,
                                };
                                inc.unit_price =
                                  Number(evt.target.value) / inc.qty;
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  (i) => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            />
                            <Button
                              type="link"
                              onClick={() => {
                                let item = this.state.data.find(
                                  (i) => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: item.price,
                                  mtype: item.mtype,
                                  qty: item.qty,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price,
                                  unit_price_disable: item.unit_price_disable,
                                  id: record.id,
                                };
                                inc.unit_price_disable =
                                  !inc.unit_price_disable;
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  (i) => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            >
                              Edit Price
                            </Button>
                          </span>
                        )}
                      />
                      <Column
                        title="Remove"
                        key="Remove"
                        render={(record) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "red",
                              }}
                              shape="circle"
                              onClick={() => {
                                this.setState({
                                  selected_items:
                                    this.state.selected_items.filter(
                                      (item) => item.item !== record.item
                                    ),
                                });
                                let item = this.state.data.find(
                                  (i) => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: 0,
                                  mtype: item.mtype,
                                  qty: 0,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price,
                                  id: record.id,
                                };
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  (i) => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            >
                              <Icon type="close" />
                            </Button>
                          </span>
                        )}
                      />
                    </Table>
                  ) : null}
                </Card>
              </Form.Item>

              <Form.Item>
                <Text>Recommended</Text>
                <Select
                  value={this.state.recommended}
                  onChange={this.setRecommended}
                  disabled={
                    this.state.recommender_id === GET_USER_LOCAL().role_id
                      ? false
                      : true
                  }
                >
                  {this.state.approval_system_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Authorized</Text>
                <Select
                  value={this.state.authorised}
                  onChange={this.setAuthorized}
                  disabled={
                    this.state.authoriser_id === GET_USER_LOCAL().role_id
                      ? false
                      : true
                  }
                >
                  {this.state.approval_system_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Approved</Text>
                <Select
                  value={this.state.approved}
                  onChange={this.setApprroved}
                  disabled={
                    this.state.approver_id === GET_USER_LOCAL().role_id
                      ? false
                      : true
                  }
                >
                  {this.state.approval_system_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
            <Modal
              title={this.state.item_name}
              visible={this.state.item_edit_modal}
              onOk={() =>
                this.setState(
                  {
                    item_price: Number(
                      this.state.item_unit_price * this.state.item_qty
                    ),
                  },
                  () =>
                    this.updateItem(
                      this.state.item_id,
                      this.state.item_name,
                      this.state.item_unit,
                      this.state.item_unit_price,
                      this.state.item_qty,
                      this.state.item_price,
                      this.state.item_recommended_price,
                      this.state.item_authorized_price,
                      this.state.item_approved_price
                    )
                )
              }
              onCancel={() =>
                this.setState({
                  item_edit_modal: false,
                  item_price: 0,
                  item_name: "",
                  item_unit: "",
                  item_unit_price: 0,
                  item_qty: 0,
                  item_id: "",
                  item_recommended_price: 0,
                  item_authorized_price: 0,
                  item_approved_price: 0,
                })
              }
            >
              <Form
                style={{
                  display: "flex",
                  alignSelf: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Form.Item>
                <Text>Unit Price</Text>
                <Input
                  placeholder="Unit Price"
                  type="number"
                  value={this.state.item_unit_price}
                  onChange={evt =>
                    this.setState({ item_unit_price: Number(evt.target.value) })
                  }
                />
              </Form.Item> */}
                <Form.Item>
                  <Text>Quantity</Text>
                  <Input
                    placeholder="Quantity"
                    type="number"
                    value={this.state.item_qty}
                    onChange={(evt) =>
                      this.setState({ item_qty: Number(evt.target.value) })
                    }
                  />
                </Form.Item>

                {this.state.recommender_id === GET_USER_LOCAL().role_id ? (
                  <Form.Item>
                    <Text>Recommended Price</Text>
                    <Input
                      placeholder="Recommended Price"
                      type="number"
                      value={this.state.item_recommended_price}
                      onChange={(evt) =>
                        this.setState({
                          item_recommended_price: Number(evt.target.value),
                        })
                      }
                    />
                  </Form.Item>
                ) : null}
                {this.state.authoriser_id === GET_USER_LOCAL().role_id ? (
                  <Form.Item>
                    <Text>Authorized Price</Text>
                    <Input
                      placeholder="Authorized Price"
                      type="number"
                      value={this.state.item_authorized_price}
                      onChange={(evt) =>
                        this.setState({
                          item_authorized_price: Number(evt.target.value),
                        })
                      }
                    />
                  </Form.Item>
                ) : null}
                {this.state.approver_id === GET_USER_LOCAL().role_id ? (
                  <Form.Item>
                    <Text>Approved Price</Text>
                    <Input
                      placeholder="Approved Price"
                      type="number"
                      value={this.state.item_approved_price}
                      onChange={(evt) =>
                        this.setState({
                          item_approved_price: Number(evt.target.value),
                        })
                      }
                    />
                  </Form.Item>
                ) : null}
              </Form>
            </Modal>
            <Modal
              title="Create New Item"
              visible={this.state.showEntity}
              onOk={() => {
                this.setState({ showEntity: false });
                this.getProductList();
              }}
              onCancel={() => {
                this.setState({ showEntity: false });
                this.getProductList();
              }}
              width={window.innerWidth - 200}
              closable={true}
              destroyOnClose={true}
            >
              <CreateMaterial />
            </Modal>

            <Modal
              title="Create New Supplier"
              visible={this.state.showSupplier}
              onOk={() => {
                this.setState({ showSupplier: false });
                this.getSupplierList();
              }}
              onCancel={() => {
                this.setState({ showSupplier: false });
                this.getSupplierList();
              }}
              width={window.innerWidth - 200}
              closable={true}
              destroyOnClose={true}
            >
              <CreateSupplier />
            </Modal>
          </div>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editBill}
            >
              Update Bill
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(EditBill);
